<template>
  <div>
    <div class="row filter" v-if="!showAddAccount">
      <div class="col-12 left-filter">
        <!-- Left -->
        <div class="col-8 col-md-6 left">
          <div class="tab" v-if="activeAccount && false">
            <router-link :to="{
              name: 'ai-reports',
              params: {
                accountId: getActiveSocialMediaAccount,
              },
            }" :class="{ active: $route.name == 'ai-reports' }">AI Reports</router-link>
            <router-link v-if="!isCompetitor()" :to="{
              name: 'insights',
              params: {
                accountId: getActiveSocialMediaAccount,
              },
            }" :class="{ active: $route.name == 'insights' }">Insights</router-link>
          </div>
        </div>
        <div class="col text-right d-sm-none mobile-filter">
          <span @click="mobileRangeModal = true">
            <img @error="(e) => (e.target.src = $defaultCardAvatar)" src="@/assets/images/calander.svg" alt />
          </span>
        </div>
        <!-- Right -->
        <div class="col-12 col-md-6 text-right pr-0 topfilter d-none d-sm-block">
          <range-filter :range="range"></range-filter>
          <DatePicker class="datepicker" v-model="date" ref="datepicker" placeholder="Select Date"
            :disabled-date="disabledDates" range>
            <template v-slot:header="{ emit }">
              <button class="btn radio p-0 d-block w-100 text-left" @click="setRange('ALL')">
                <radio-button :checked="range == 'ALL'"></radio-button>All Time
              </button>
              <button class="btn radio p-0 d-block w-100 text-left" @click="setQuickRange('14Days')">
                <radio-button :checked="quickRange == '14Days'"></radio-button>Last 14 Days
              </button>
              <button class="btn radio p-0 d-block w-100 text-left" @click="setQuickRange('30Days')">
                <radio-button :checked="quickRange == '30Days'"></radio-button>Last 30 Days
              </button>
              <button class="btn radio p-0 d-block w-100 text-left" @click="setQuickRange('ThisWeek')">
                <radio-button :checked="quickRange == 'ThisWeek'"></radio-button>This Week
              </button>
              <button class="btn radio p-0 d-block w-100 text-left" @click="setQuickRange('LastWeek')">
                <radio-button :checked="quickRange == 'LastWeek'"></radio-button>Last Week
              </button>
              <button class="btn radio p-0 d-block w-100 text-left" @click="setQuickRange('ThisMonth')">
                <radio-button :checked="quickRange == 'ThisMonth'"></radio-button>This Month
              </button>
              <button class="btn radio p-0 d-block w-100 text-left mb-2" @click="setQuickRange('LastMonth')">
                <radio-button :checked="quickRange == 'LastMonth'"></radio-button>Last Month
              </button>
            </template>
          </DatePicker>
          <button class="btn-filter download-button d-none d-sm-inline-block" type="button" :disabled="pdfProcess"
            @click="exportPDF" v-if="false">
            <img @error="(e) => (e.target.src = $defaultCardAvatar)" src="@/assets/images/download.svg" alt="Download"
              v-if="!pdfProcess" />
            <Loading v-if="pdfProcess"></Loading>
          </button>
        </div>
        <!-- Right End -->
      </div>
    </div>
    <div v-if="xhrProcess === true && showAddAccount === false" class="row">
      <placeholder v-for="n in 7" :key="n" class="col-12 col-sm-4"></placeholder>
    </div>
    <div class="row" :class="{ 'no-data': data == null && !xhrProcess }" v-if="!showAddAccount && show">

      <div class="col-xs-12 col-lg-4">
        <chart :graph="data.chart" :totals="data.totals" :account="data.socialMediaAccount" :range="range" :start="start"
          :finish="finish" :type="getActiveSocialMedia" :key="1"></chart>
      </div>

      <div class="col-xs-12 col-lg-4" v-for="(most, key) in data.post" :key="key + 1">
        <general-most :data="most"></general-most>
      </div>

      <template v-if="data.follower && !isCompetitor()">
        <div class="col-xs-12 col-lg-4" v-for="(mostFollower, key) in data.follower" :key="key + 10">

          <general-follower :data="mostFollower" :account="data.socialMediaAccount"></general-follower>
        </div>
      </template>

      <!-- <muuri-grid id="draggable"
        class="drag"
        :options="dragOptions"
        v-if="show">
        <ItemMd>
          <chart :graph="data.chart"
            :totals="data.totals"
            :account="data.socialMediaAccount"
            :range="range"
            :start="start"
            :finish="finish"
            :type="getActiveSocialMedia"
            :key="1"></chart>
        </ItemMd>

        <template v-if="data.post">
          <ItemMd v-for="(most, key) in data.post"
            :key="key + 1">
            <general-most :data="most"></general-most>
          </ItemMd>
        </template>

        <template v-if="data.follower && !isCompetitor()">
          <ItemMd v-for="(mostFollower, key) in data.follower"
            :key="key + 10">
            <general-follower :data="mostFollower"
              :account="data.socialMediaAccount"></general-follower>
          </ItemMd>
        </template>
      </muuri-grid> -->
      <span class="placeholder" v-if="data == null && xhrProcess == false">
        <img @error="(e) => (e.target.src = $defaultCardAvatar)" src="@/assets/images/icon.svg" class="logo"
          alt="Socialays" />
        No Data Yet
        <br />
        <small>Check back soon</small>
      </span>

    </div>
    <div class="noaccount" v-if="showAddAccount">
      <router-link :to="{
        name: 'home',
        query: {
          addAccount: 'true',
        },
      }" @click.native="setActiveSocialMedia('')">
        <AddAccountBox :key="'add-account'" :class="{ 'animated shake': toggleEffect }"></AddAccountBox>
      </router-link>
    </div>
    <!-- Mobile Range Modal Start -->
    <mobileRangeFilter :mobileRangeModal="mobileRangeModal" :date="date" :f_range="range"
      @close="mobileRangeModalSetAndClose"></mobileRangeFilter>
    <!-- Mobile Order Modal -->
  </div>
</template>

<script>
import EventBus from "@/components/event-bus";
import chart from "@/components/facebook/general/Chart";
import generalmost from "@/components/facebook/general/Most";
import generalfollower from "@/components/facebook/general/Follower";
import { HTTP } from "@/main-source";
import { mapGetters, mapActions } from "vuex";
import rangeFilter from "@/components/filters/range";
import AddAccountBox from "@/components/accounts/AddAccountBox";
import { ItemMd } from "vue-muuri";
import placeholder from "@/components/placeholders/boxes";
import mobileRangeFilter from "@/components/filters/mobile/date";
import Loading from "@/components/Loading";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import radioButton from "@/components/radio-button";

import axios from "axios";
import { controllers } from "chart.js";
let request = axios.CancelToken.source();

export default {
  name: "ai-reports",
  data() {
    return {
      title: "General",
      data: [],
      range: localStorage.getItem("selectedRange") || "ALL",
      quickRange: localStorage.getItem("selectedQuickRange") || null,
      date: JSON.parse(localStorage.getItem("selectedDate")) || null,
      start: null,
      finish: null,
      show: false,
      showModal: false,
      list: null,
      dragOptions: this.$dragOptions,
      showAddAccount: false,
      toggleEffect: false,
      xhrProcess: false,
      mobileRangeModal: false,
      pdfProcess: false,
      controller: new AbortController(),
      activeAccount: null
    };
  },
  components: {
    chart,
    "general-most": generalmost,
    "general-follower": generalfollower,
    rangeFilter,
    AddAccountBox,
    ItemMd,
    placeholder,
    mobileRangeFilter,
    Loading,
    radioButton
  },
  methods: {
    ...mapActions(["setActiveSocialMedia"]),
    isCompetitor() {
      return this.getActiveSocialMediaAccountObj.type != 'OWN';
    },
    setQuickRange: function (which) {
      localStorage.setItem("selectedQuickRange", which);
      localStorage.removeItem("selectedRange");
      this.range = null;
      this.date = null;
      this.start = null;
      this.finish = null;
      let today = new Date();
      switch (which) {
        case '14Days':
          this.quickRange = '14Days';
          today = new Date();
          let f14 = new Date(today.setDate(new Date().getDate() - 14));
          this.date = [f14, new Date()];
          break;
        case '30Days':
          this.quickRange = '30Days';
          today = new Date();
          let f30 = new Date(today.setDate(new Date().getDate() - 30));
          this.date = [f30, new Date()];
          break;
        case 'ThisWeek':
          this.quickRange = 'ThisWeek';
          let curr = new Date();
          let first = curr.getDate() - curr.getDay() + 1;
          let last = first + 6;

          let firstday = new Date(curr.setDate(first));
          let lastday = new Date(curr.setDate(last));
          this.date = [firstday, lastday];
          break;
        case 'LastWeek':
          this.quickRange = 'LastWeek';
          today = new Date();
          let curl = new Date(today.setDate(new Date().getDate() - 7));
          let firstl = curl.getDate() - curl.getDay() + 1;
          let lastl = firstl + 6;

          let firstlday = new Date(curl.setDate(firstl));
          let lastlday = new Date(curl.setDate(lastl));
          this.date = [firstlday, lastlday];
          break;
        case 'ThisMonth':
          this.quickRange = 'ThisMonth';
          var date = new Date(), y = date.getFullYear(), m = date.getMonth();
          var firstDay = new Date(y, m, 1);
          var lastDay = new Date(y, m + 1, 0);
          this.date = [firstDay, lastDay];
          break;
        case 'LastMonth':
          this.quickRange = 'LastMonth';
          var date = new Date();
          var firstDayPrevMonth = new Date(date.getFullYear(), date.getMonth() - 1, 1);
          var lastDayPrevMonth = new Date(date.getFullYear(), date.getMonth(), 0);
          this.date = [firstDayPrevMonth, lastDayPrevMonth];
          break;
      }




      this.getDashboard();

      this.$refs.datepicker.closePopup();
    },
    getDashboard: function () {
      this.xhrProcess = true;
      this.show = false;
      this.data = null;
      this.showAddAccount = false;
      if (
        this.getActiveSocialMediaAccount != null &&
        this.getActiveSocialMedia != null
      ) {


        let uri =
          "social/" +
          this.getActiveSocialMediaAccount +
          "/" +
          this.getActiveSocialMedia +
          "/general";

        if (this.start && this.finish) {
          uri += "?start=" + this.start + "&finish=" + this.finish;
        } else {
          uri += "?range=" + this.range;
        }

        HTTP.get(uri, { cancelToken: request.token })
          .then(async (result) => {
            const { data } = result;
            const { response } = await this.$async(
              HTTP.get("/user/me", { cancelToken: request.token })
            );
            const { socialMediaAccounts } = response.data;
            const account = socialMediaAccounts.find(
              ({ accountId }) => accountId === data.socialMediaAccount.accountId
            );
            this.activeAccount = account;

            const getActiveAccountImageSource = () => {
              const img = document.querySelector(
                ".social-account-menu .social-account img"
              );
              return img ? img.src : null;
            };

            this.data = {
              ...result.data,
              socialMediaAccount: {
                ...result.data.socialMediaAccount,
                imageUrl: account
                  ? account.imageUrl
                  : getActiveAccountImageSource(),
              },
            };

            this.xhrProcess = false;
            this.show = true;
          })
          .catch((e) => {
            if (e.response.status == 400) {
              this.data = null;
            }
            this.xhrProcess = false;
          });
      } else {
        this.data = null;
        this.show = false;
        if (this.getActiveSocialMediaAccount == null) {
          this.showAddAccount = true;
        }
      }
    },
    setRange: function (value) {
      localStorage.setItem("selectedRange", value);
      localStorage.removeItem("selectedQuickRange");

      this.range = value;
      if (this.range != null) {
        this.date = null;
        this.start = null;
        this.quickRange = null;
        this.finish = null;
        this.getDashboard();
      }

    },
    disabledDates: function (date) {
      return this.$disabledDates(date);
    },
    mobileRangeModalSetAndClose: function (data) {
      if (data.range != "CUSTOM") {
        this.setRange(data.range);
      } else {
        this.range = "CUSTOM";
        this.date = [new Date(data.start), new Date(data.finish)];
      }

      this.mobileRangeModal = false;
    },
    exportPDF: function () {
      console.log("hüloo");
      window.html2canvas = html2canvas;
      var doc = new jsPDF("p", "pt", "a4");
      var width = doc.internal.pageSize.getWidth();
      var height = doc.internal.pageSize.getHeight();
      html2canvas(document.getElementById("draggable")).then((canvas) => {
        const imgProps = doc.getImageProperties(canvas);
        const pdfWidth = doc.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        doc.addImage(canvas, "JPEG", 0, 0, pdfWidth, pdfHeight);
        doc.save("t.pdf");
        // doc.html(canvas, {
        //   callback: function (pdf) {
        //     pdf.save("asd.pdf");
        //   },
        // });
      });
    },
  },
  computed: {
    ...mapGetters(["getActiveSocialMedia", "getActiveSocialMediaAccount", "getActiveSocialMediaAccountObj"]),
  },
  mounted() {
    let customDate = JSON.parse(localStorage.getItem("selectedDate")) || null;
    if (this.quickRange != null && customDate == null) {
      this.setQuickRange(this.quickRange);
    }

    if (customDate != null) {
      customDate[0] = new Date(customDate[0]);
      customDate[1] = new Date(customDate[1]);
      this.date = customDate;
    }
    EventBus.$on("setRange", (range) => {
      this.setRange(range);
    });

    EventBus.$on("toggleEffect", () => {
      this.toggleEffect = true;
      setTimeout(() => {
        this.toggleEffect = false;
      }, 1000);
    });

    this.getDashboard();
  },
  beforeDestroy() {
    EventBus.$off("setRange");
    EventBus.$off("toggleEffect");
  },
  watch: {
    getActiveSocialMediaAccount: function () {
      this.range = "ALL";
      request.cancel("Optional message");
      request = axios.CancelToken.source();
      this.getDashboard();
    },
    date: {
      deep: true,
      handler: function () {
        if (this.date[0]) {
          localStorage.setItem("selectedDate", JSON.stringify(this.date));
          this.range = null;
          this.start = this.date[0].getTime();
          this.finish = this.date[1].getTime() + 86399000;
          this.getDashboard();
        } else {
          this.range = "ALL";
          this.quickRange = null;
          this.start = null;
          this.finish = null;
          this.getDashboard();
        }
      },
    },
  },
};
</script>
<style lang="scss">
@import "@/scss/colors.scss";

.tab {
  a {
    color: inherit;
    padding: 10px 20px;
    border-bottom: 1px solid transparent;
    transition: all 0.2s ease;

    &:hover {
      border-bottom: 1px solid $brandcolor;
    }
  }

  .active {
    border-bottom: 1px solid $brandcolor;
  }
}

.no-data {
  display: flex;
  justify-content: center;
  align-items: center;
}

.noaccount {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
