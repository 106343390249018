<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="38.667"
    height="9.734"
    viewBox="0 0 38.667 9.734"
  >
    <g id="more" transform="translate(0 -170.666)">
      <g
        id="Group_1176"
        data-name="Group 1176"
        transform="translate(0 170.666)"
      >
        <g id="Group_1175" data-name="Group 1175" transform="translate(0 0)">
          <circle
            id="Ellipse_529"
            data-name="Ellipse 529"
            cx="4.867"
            cy="4.867"
            r="4.867"
            fill="#7b7b7b"
          />
        </g>
      </g>
      <g
        id="Group_1178"
        data-name="Group 1178"
        transform="translate(14.467 170.666)"
      >
        <g id="Group_1177" data-name="Group 1177" transform="translate(0 0)">
          <circle
            id="Ellipse_530"
            data-name="Ellipse 530"
            cx="4.867"
            cy="4.867"
            r="4.867"
            fill="#7b7b7b"
          />
        </g>
      </g>
      <g
        id="Group_1180"
        data-name="Group 1180"
        transform="translate(28.934 170.666)"
      >
        <g id="Group_1179" data-name="Group 1179" transform="translate(0 0)">
          <circle
            id="Ellipse_531"
            data-name="Ellipse 531"
            cx="4.867"
            cy="4.867"
            r="4.867"
            fill="#7b7b7b"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "dotMenu"
};
</script>
