<template>
  <div class="social-account">
    <div class="img">
      <facebookIcon
        class="socialmedia"
        :active="'true'"
        v-if="account.socialMediaApp.toLowerCase() == 'facebook'"
      ></facebookIcon>
      <instagramIcon
        class="socialmedia"
        :active="'true'"
        v-if="account.socialMediaApp.toLowerCase() == 'instagram'"
      ></instagramIcon>
      <twitterIcon
        class="socialmedia"
        :active="'true'"
        v-if="account.socialMediaApp.toLowerCase() == 'twitter'"
      ></twitterIcon>
      <youtubeIcon
        class="socialmedia"
        :active="'true'"
        v-if="account.socialMediaApp.toLowerCase() == 'youtube'"
      ></youtubeIcon>
      <img @error="(e) => (e.target.src = $defaultCardAvatar)" :src="profileImg" class="img-fluid" v-if="profileImg" />
      <img @error="(e) => (e.target.src = $defaultCardAvatar)" class="img-fluid" src="@/assets/images/profile.png" alt="Selected Account" v-if="!profileImg" />
    </div>
    <div class="info col-9">
      <span class="name">{{ account.accountName }}</span>
      <span class="slug i-status" v-if="account.isSynced"> Synchronizing </span>
      <div v-if="!authorizedItem && linked && subscriptionDetail">
        <span class="dates slug" v-if="subscriptionDetail ? (subscriptionDetail.canceledAt ? false : true) : true">
          Renewal Date: {{ renewalDate }}
        </span>
        <span class="dates slug" v-if="user.status == 'TRIAL'">
          Ending Date:
          {{ user.status == 'TRIAL' ? new Date(user.createdAt).addDays(14).toLocaleDateString() : '' }}
        </span>
        <span class="dates slug" v-if="user.status == 'ACTIVE' && subscriptionDetail.canceledAt">
          Ending Date:
          {{ new Date(subscriptionDetail.canceledAt * 1000).toLocaleDateString() }}
        </span>
      </div>
      <div v-if="authorizedItem && !linked">
        <span class="slug">
          given access by
          {{ account.systemUserName ? account.systemUserName : 'no-data' }} at
          {{ account.createdAt ? new Date(account.createdAt).toLocaleDateString() : 'no-data' }}
        </span>
      </div>
    </div>
    <button
      class="btn btn-primary reconnect"
      v-if="user.status == 'ACTIVE' && subscriptionDetail ? subscriptionDetail.isReSubscribable : false"
      @click="activateAgain"
    >
      Activate Again
    </button>
    <button class="btn btn-primary reconnect" v-if="account.hasError" @click="reConnect">Reconnect</button>
    <img @error="(e) => (e.target.src = $defaultCardAvatar)"
      src="@/assets/images/comments/delete.svg"
      class="delete"
      alt="Delete Account"
      v-if="!account.isSynced"
      @click="deleteAssignedPageTrigger"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import facebookIcon from '@/components/icons/facebook';
import instagramIcon from '@/components/icons/instagram';
import twitterIcon from '@/components/icons/twitter';
import youtubeIcon from '@/components/icons/youtube';
import EventBus from '@/components/event-bus';

export default {
  name: 'listitem',
  props: {
    account: {},
    disableClick: {},
    linked: null,
    authorizedItem: {
      type: Boolean,
      default: false,
    },
    user: null,
    subscriptionDetail: null,
  },
  methods: {
    ...mapActions(['setActiveSocialMediaAccount']),
    selectAccount: function (accountId) {
      this.setActiveSocialMediaAccount(accountId);
    },
    deleteAssignedPageTrigger: function () {
      let isForce = false;
      if (this.linked) {
        isForce =
          this.user.status == 'TRIAL'
            ? true
            : this.subscriptionDetail
            ? this.subscriptionDetail.isReSubscribable
              ? this.subscriptionDetail.isReSubscribable
              : false
            : true;
      }

      EventBus.$emit('deleteAssignedPage', this.linked, this.account, isForce, this.renewalDate);
    },
    reConnect: function () {
      EventBus.$emit('reConnect', this.account.socialMediaApp, this.account.id);
    },
    activateAgain: function () {
      EventBus.$emit('activateLinkedAccount', this.account.accountId);
    },
  },
  components: {
    facebookIcon,
    instagramIcon,
    twitterIcon,
    youtubeIcon,
  },
  computed: {
    ...mapGetters(['getActiveSocialMedia', 'getActiveSocialMediaAccount']),

    profileImg: function () {
      let uri = this.account.imageUrl;
      if (this.getActiveSocialMedia == 'facebook') {
        uri = 'https://graph.facebook.com/' + this.account.accountId + '/picture';
      }
      return uri;
    },

    renewalDate() {
      return this.user.status == 'TRIAL'
        ? 'TRIAL VERSION'
        : this.subscriptionDetail.periodFinish
        ? new Date(this.subscriptionDetail.periodFinish * 1000).toLocaleDateString()
        : 'not set';
    },
  },
};
</script>
<style lang="scss">
.social-account {
  display: flex;
  position: relative;
  .img {
    padding: 0;
    .socialmedia {
      position: absolute;
      left: 5px;
      top: 3px;
      width: 20px;
    }
    img {
      border-radius: 50%;
    }
  }
  .name {
    font-weight: bold;
  }
  .info {
    padding: 0px 15px;
    span {
      display: block;
    }
    .slug {
      font-size: 11px;
    }
  }
  .dates {
    width: 50%;
    display: inline-block !important;
  }
  .delete {
    position: absolute;
    right: 25px;
    top: 28px;
    opacity: 0;
    transition: all 0.2s ease;
  }
  &:hover {
    .delete {
      opacity: 1;
    }
  }
  .reconnect {
    position: absolute;
    right: 45px;
    top: 22px;
    padding: 4px 12px;
    line-height: 20px;
  }
}
</style>
