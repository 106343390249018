<template>
  <div class="setting wrap">
    <div class="head">
      <img @error="(e) => (e.target.src = $defaultCardAvatar)"
        src="@/assets/images/account-settings/mail.svg"
        alt="Email Settings"
      />
      <span>E-Mail Reporting</span>
      <span class="float-right c-badge">Coming Soon</span>
    </div>
    <div class="body blurred">
      <div>Report Type</div>
      <div>
        "Positive, Negative, Neutral, Questions, Deleted" Summary
        <label class="switch">
          <input
            type="checkbox"
            v-model="settings.emailByPolarity"
            class="default primary"
          />
          <span class="slider round"></span>
        </label>
      </div>
      <div class="sub">
        Daily
        <label class="switch">
          <input
            type="checkbox"
            v-model="settings.emailByPolarityDaily"
            class="default primary"
          />
          <span class="slider round"></span>
        </label>
      </div>
      <div class="sub">
        Weekly
        <label class="switch">
          <input
            type="checkbox"
            v-model="settings.emailByPolarityWeekly"
            class="default primary"
          />
          <span class="slider round"></span>
        </label>
      </div>
      <div class="sub">
        Monthly
        <label class="switch">
          <input
            type="checkbox"
            v-model="settings.emailByPolarityMonthly"
            class="default primary"
          />
          <span class="slider round"></span>
        </label>
      </div>
    </div>
  </div>
</template>
<script>
import EventBus from "@/components/event-bus";
export default {
  name: "email-reporting",
  props: ["settings"],
  watch: {
    "settings.emailByPolarity": function (value) {
      let data = {
        emailByPolarity: value,
      };
      EventBus.$emit("updateAccountNotification", data);
    },
    "settings.emailByPolarityDaily": function (value) {
      let data = {
        emailByPolarityDaily: value,
      };
      EventBus.$emit("updateAccountNotification", data);
    },
    "settings.emailByPolarityMonthly": function (value) {
      let data = {
        emailByPolarityMonthly: value,
      };
      EventBus.$emit("updateAccountNotification", data);
    },
    "settings.emailByPolarityWeekly": function (value) {
      let data = {
        emailByPolarityWeekly: value,
      };
      EventBus.$emit("updateAccountNotification", data);
    },
  },
};
</script>

<style lang="scss" scoped>
.setting {
  .body {
    div {
      padding-right: 30px !important;
    }
  }
}
.blurred{
  filter: blur(2px);
  pointer-events:none;
      opacity: 0.5;
}
</style>