<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="45"
    height="45"
    viewBox="0 0 45 45"
  >
    <defs>
      <linearGradient
        id="i-linear-gradient"
        x1="0.107"
        x2="0.761"
        y2="0.931"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="#db1472" />
        <stop offset="1" stop-color="#8b30ef" />
      </linearGradient>
      <filter
        id="Rectangle_Copy_4"
        x="0"
        y="0"
        width="45"
        height="45"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="20" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="7.5" result="blur" />
        <feFlood flood-opacity="0.039" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g id="Instagram" transform="translate(0 0)">
      <g filter="url(#Rectangle_Copy_4)">
        <rect
          id="Rectangle_Copy_4-2"
          data-name="Rectangle Copy 4"
          width="45"
          height="45"
          rx="13"
          :fill="[active == 'true' ? 'url(#i-linear-gradient)' : '']"
        />
      </g>
      <path
        id="Fill_8"
        data-name="Fill 8"
        d="M10.7,22a35.87,35.87,0,0,1-6.112-.324,5.5,5.5,0,0,1-4.379-4.8C-.066,15.136-.129,6.442.419,4.524A5.6,5.6,0,0,1,5.212.248,41.88,41.88,0,0,1,10.723,0c1.889,0,4,.049,5.372.124A6.066,6.066,0,0,1,20.11,1.768a5.807,5.807,0,0,1,1.756,3.755c.165,1.755.263,9.937-.24,11.832a5.623,5.623,0,0,1-4.856,4.4A53.27,53.27,0,0,1,10.7,22Zm.385-20c-2.323,0-4.414.064-5.328.164A3.776,3.776,0,0,0,2.245,5.473a75.028,75.028,0,0,0-.076,11.1,3.547,3.547,0,0,0,2.714,3.107A37.339,37.339,0,0,0,10.8,20c2.261,0,4.542-.07,5.424-.166a3.769,3.769,0,0,0,3.522-3.327,79.525,79.525,0,0,0,.135-10.8,3.714,3.714,0,0,0-3.293-3.5A48.826,48.826,0,0,0,11.087,2Z"
        transform="translate(12 12)"
        :fill="[active == 'true' ? '#fff' : '#B2B6C4']"
      />
      <path
        id="Fill_11"
        data-name="Fill 11"
        d="M5.949,11a6.217,6.217,0,0,1-4.4-1.78A5.209,5.209,0,0,1,0,5.5,5.823,5.823,0,0,1,6.033,0h.018a6.218,6.218,0,0,1,4.4,1.784A5.2,5.2,0,0,1,12,5.5,5.832,5.832,0,0,1,5.949,11Zm.084-9A3.834,3.834,0,0,0,2,5.467a3.224,3.224,0,0,0,.947,2.315A4.21,4.21,0,0,0,5.93,9h.036A3.832,3.832,0,0,0,10,5.534a3.226,3.226,0,0,0-.946-2.318A4.213,4.213,0,0,0,6.069,2H6.033Z"
        transform="translate(17 18)"
        :fill="[active == 'true' ? '#fff' : '#B2B6C4']"
      />
      <path
        id="Fill_6"
        data-name="Fill 6"
        d="M1.5,0h.019A1.487,1.487,0,0,1,3,1.5,1.515,1.515,0,0,1,1.483,3,1.5,1.5,0,0,1,1.5,0"
        transform="translate(27 16)"
        :fill="[active == 'true' ? '#fff' : '#B2B6C4']"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: ["active"]
};
</script>

<style lang="scss"></style>
