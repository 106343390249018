<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <g
      id="_002-surprised-1"
      data-name="002-surprised-1"
      transform="translate(0)"
    >
      <path
        id="Path_700"
        data-name="Path 700"
        d="M13.657,2.343A8,8,0,1,0,2.343,13.657,8,8,0,1,0,13.657,2.343Zm-.663,10.651A7.063,7.063,0,1,1,3.006,3.006a7.063,7.063,0,0,1,9.988,9.988Z"
        transform="translate(0)"
        fill="#5a5a5a"
      />
      <path
        id="Path_701"
        data-name="Path 701"
        d="M116.62,169.335a1.614,1.614,0,1,0-1.614,1.614A1.616,1.616,0,0,0,116.62,169.335Zm-1.614.676a.676.676,0,1,1,.676-.676A.677.677,0,0,1,115.006,170.011Z"
        transform="translate(-109.849 -162.479)"
        fill="#5a5a5a"
      />
      <path
        id="Path_702"
        data-name="Path 702"
        d="M295.335,167.721a1.614,1.614,0,1,0,1.614,1.614A1.616,1.616,0,0,0,295.335,167.721Zm0,2.29a.676.676,0,1,1,.676-.676A.677.677,0,0,1,295.335,170.011Z"
        transform="translate(-284.542 -162.479)"
        fill="#5a5a5a"
      />
      <path
        id="Path_703"
        data-name="Path 703"
        d="M182.462,296.348a2.372,2.372,0,1,0,2.372,2.372A2.375,2.375,0,0,0,182.462,296.348Zm0,3.807a1.435,1.435,0,1,1,1.435-1.435A1.436,1.436,0,0,1,182.462,300.155Z"
        transform="translate(-174.462 -287.087)"
        fill="#5a5a5a"
      />
      <path
        id="Path_704"
        data-name="Path 704"
        d="M114.37,80.611a.468.468,0,0,0,.658-.08,2.979,2.979,0,0,1,1.944-1.048.469.469,0,1,0-.044-.937,3.891,3.891,0,0,0-2.638,1.405A.469.469,0,0,0,114.37,80.611Z"
        transform="translate(-110.622 -76.092)"
        fill="#5a5a5a"
      />
      <path
        id="Path_705"
        data-name="Path 705"
        d="M295.015,78.547a.469.469,0,0,0-.044.937,2.979,2.979,0,0,1,1.944,1.048.469.469,0,0,0,.738-.579A3.891,3.891,0,0,0,295.015,78.547Z"
        transform="translate(-285.32 -76.092)"
        fill="#5a5a5a"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "surpise"
};
</script>
