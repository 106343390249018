<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
        <g id="people-svgrepo-com" transform="translate(-18 -18)">
            <g id="Group_1210" data-name="Group 1210" transform="translate(18 27.399)">
                <path id="Path_764" data-name="Path 764"
                    d="M32.875,60.987H20.125A2.127,2.127,0,0,1,18,58.862v-.744a3.005,3.005,0,0,1,.465-1.6A4.7,4.7,0,0,1,19.6,55.3a14.731,14.731,0,0,1,3.1-1.709l.022-.01.023-.008a.275.275,0,0,0,.086-.044l.028-.018.03-.015a1.06,1.06,0,0,1,.471-.113,1.017,1.017,0,0,1,.564.171,4.7,4.7,0,0,0,2.576.789,4.893,4.893,0,0,0,2.582-.766,1.017,1.017,0,0,1,.558-.167,1.057,1.057,0,0,1,.435.1,1.27,1.27,0,0,1,.262.128A14,14,0,0,1,33.4,55.305a4.671,4.671,0,0,1,1.134,1.216,3,3,0,0,1,.465,1.6v.744A2.127,2.127,0,0,1,32.875,60.987Zm-9.768-6.411a13.74,13.74,0,0,0-2.853,1.563,2.56,2.56,0,0,0-1.191,1.979v.744a1.067,1.067,0,0,0,1.063,1.063h12.75a1.067,1.067,0,0,0,1.063-1.062v-.744a2.547,2.547,0,0,0-1.19-1.975A13.215,13.215,0,0,0,29.878,54.6l-.046-.019-.041-.028a.276.276,0,0,0-.086-.044l-.036-.012-.024-.012a5.955,5.955,0,0,1-3.146.928,5.754,5.754,0,0,1-3.142-.952A1.259,1.259,0,0,1,23.107,54.576Z"
                    transform="translate(-18 -53.386)" fill="#bbc3e2" />
            </g>
            <g id="Group_1211" data-name="Group 1211" transform="translate(22.011 18)">
                <path id="Ellipse_536" data-name="Ellipse 536"
                    d="M2.489-2a4.134,4.134,0,0,1,1.766.395A4.477,4.477,0,0,1,5.682-.541a5.132,5.132,0,0,1,1.3,3.455,5.132,5.132,0,0,1-1.3,3.455A4.477,4.477,0,0,1,4.255,7.434a4.149,4.149,0,0,1-3.532,0A4.477,4.477,0,0,1-.7,6.369,5.132,5.132,0,0,1-2,2.914,5.132,5.132,0,0,1-.7-.541,4.477,4.477,0,0,1,.723-1.605,4.134,4.134,0,0,1,2.489-2Zm0,8.766A3.662,3.662,0,0,0,5.916,2.914,3.662,3.662,0,0,0,2.489-.938,3.662,3.662,0,0,0-.938,2.914,3.662,3.662,0,0,0,2.489,6.766Z"
                    transform="translate(2 2)" fill="#bbc3e2" />
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'profile',
    props: {
        color: {
            type: String,
            default: '#2e2e2e',
        },
    },
}
</script>