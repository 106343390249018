export default {
  inserted(el) {
    let watchers = [];
    let isOpen = false;
    const toggle = el.querySelector('.vs__dropdown-toggle');

    toggle.addEventListener('click', (e) => {
      e.stopPropagation();
      isOpen = !isOpen;
      const isPulledDown = Array.from(el.classList).includes('vs--open');

      const listbox = el.querySelector('[role="listbox"]');
      const selection = document.querySelector('.vs__selected')

      const closeDropdown = () => {
        listbox.classList.remove('vs__dropdown-menu');
        listbox.style.display = 'none';
        listbox.style.visibility = 'hidden';
        
        if (selection) {
          selection.style.position = 'static'
          selection.style.opacity = 1
        }
      };

      const openDropdown = () => {
        if (!isPulledDown) {
          return closeDropdown();
        }

        listbox.classList.add('vs__dropdown-menu');
        listbox.style.display = null;
        listbox.style.visibility = null;

        if (selection) {
          selection.style.position = 'absolute'
          selection.style.opacity = 0.4
        }
      };

      const clearWatchers = () => {
        watchers.forEach(clearInterval);
        watchers = [];
      };

      if (isOpen) {
        clearWatchers();

        watchers.push(
          setInterval(() => {
            const hasDropdownMenu = !!el.querySelector('.vs__dropdown-menu');
            if (!hasDropdownMenu) {
              clearWatchers();
              closeDropdown();
              isOpen = false;
            } else if (!isPulledDown) {
              clearWatchers();
              closeDropdown();
              isOpen = false;
            }
          }, 500)
        );
      }

      !isOpen ? closeDropdown() : openDropdown();
    });
  },
}