<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="45"
    height="45"
    viewBox="0 0 45 45"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="-0.01"
        x2="1"
        y2="1"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="#97abff" />
        <stop offset="1" stop-color="#123597" />
      </linearGradient>
      <filter
        id="Rectangle"
        x="0"
        y="0"
        width="45"
        height="45"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="20" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="7.5" result="blur" />
        <feFlood flood-opacity="0.039" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g id="Component_1_1" data-name="Component 1 – 1">
      <g filter="url(#Rectangle)">
        <path
          id="Rectangle-2"
          data-name="Rectangle"
          d="M13,0H32A13,13,0,0,1,45,13V32A13,13,0,0,1,32,45H13A13,13,0,0,1,0,32V13A13,13,0,0,1,13,0Z"
          :fill="[active == 'true' ? 'url(#linear-gradient)' : '']"
        />
      </g>
      <path
        id="Fill_1"
        data-name="Fill 1"
        d="M10.356,0c.7,0,1.408.037,2.109.067.457.018.913.081,1.342.126l-.032,4.07h-.337c-.464,0-.927-.005-1.391-.005-.348,0-.7,0-1.044.014-1.12.036-1.944.44-2,1.788-.036.97-.062,3.113-.063,3.225.058,0,1.165,0,2.28,0,.557,0,1.1,0,1.481,0h.9c-.226,1.564-.434,3.065-.661,4.574h-4L8.849,25.281H3.932l.091-11.439H0L.037,9.3h4.04l0-.342c.008-.917,0-1.833.031-2.749a10.958,10.958,0,0,1,.154-1.743,5.148,5.148,0,0,1,2.155-3.4A6.349,6.349,0,0,1,9.965,0q.195,0,.391,0"
        transform="translate(15.734 9.442)"
        :fill="[active == 'true' ? '#fff' : '#B2B6C4']"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: ["active"]
};
</script>

<style lang="scss"></style>
