<template>
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 223.4 223.4"
    style="enable-background: new 0 0 223.4 223.4"
    xml:space="preserve"
  >
    <path
      d="M63.6,175.3h96.2c2.3,0,4.5-1.1,5.9-2.9s1.9-4.2,1.4-6.4c-6.5-27-29.3-45.8-55.4-45.8c-26.1,0-48.9,18.8-55.4,45.8
	c-0.5,2.2,0,4.6,1.4,6.4S61.3,175.3,63.6,175.3z M111.7,135.2c16.1,0,30.5,9.9,37.6,25.1H74.1C81.2,145.2,95.6,135.2,111.7,135.2
	L111.7,135.2z"
    />
    <path
      d="M71.6,111.2c4.1,0,7.5-3.4,7.5-7.5v-3.9l5.2,2.6c1.1,0.5,2.2,0.8,3.3,0.8c2.8,0,5.4-1.5,6.7-4.1c1.9-3.7,0.4-8.2-3.4-10.1
	L59,72.9c-3.7-1.9-8.2-0.4-10.1,3.4c-1.9,3.7-0.4,8.2,3.4,10.1l11.9,5.9v11.4C64.1,107.8,67.5,111.2,71.6,111.2L71.6,111.2z"
    />
    <path
      d="M174.5,76.3c-1.9-3.7-6.4-5.2-10.1-3.4l-32.1,16c-3.7,1.9-5.2,6.4-3.4,10.1c1.3,2.6,4,4.1,6.7,4.1c1.1,0,2.3-0.3,3.3-0.8
	l5.2-2.6v3.9c0,4.1,3.4,7.5,7.5,7.5s7.5-3.4,7.5-7.5V92.3l11.9-5.9C174.9,84.5,176.4,80,174.5,76.3L174.5,76.3z"
    />
    <path
      d="M189.2,41.7c-3.3,2.6-3.8,7.3-1.3,10.5c13.4,17.1,20.5,37.7,20.5,59.5c0,53.3-43.4,96.7-96.7,96.7S15,165,15,111.7
	S58.4,15,111.7,15c18.5,0,36.5,5.3,52.1,15.2c3.5,2.2,8.1,1.2,10.4-2.3s1.2-8.1-2.3-10.4C153.9,6.1,133.1,0,111.7,0
	C50.1,0,0,50.1,0,111.7s50.1,111.7,111.7,111.7s111.7-50.1,111.7-111.7c0-25.2-8.2-48.9-23.6-68.7C197.2,39.7,192.5,39.1,189.2,41.7
	L189.2,41.7z"
    />
  </svg>
</template>

<script>
export default {
  name: "profanity",
};
</script>
