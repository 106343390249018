<template>
  <svg
    id="_001-worried-1"
    data-name="001-worried-1"
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <path
      id="Path_696"
      data-name="Path 696"
      d="M13.657,2.343A8,8,0,1,0,2.343,13.657,8,8,0,1,0,13.657,2.343Zm-.663,10.651A7.063,7.063,0,1,1,3.006,3.006a7.063,7.063,0,0,1,9.988,9.988Z"
      transform="translate(0)"
      fill="#5a5a5a"
    />
    <path
      id="Path_697"
      data-name="Path 697"
      d="M117.571,155.575a1.614,1.614,0,1,0-1.614,1.614A1.616,1.616,0,0,0,117.571,155.575Zm-2.29,0a.676.676,0,1,1,.676.676A.677.677,0,0,1,115.281,155.575Z"
      transform="translate(-110.77 -149.149)"
      fill="#5a5a5a"
    />
    <path
      id="Path_698"
      data-name="Path 698"
      d="M296.287,153.961a1.614,1.614,0,1,0,1.614,1.614A1.616,1.616,0,0,0,296.287,153.961Zm0,2.29a.676.676,0,1,1,.676-.676A.677.677,0,0,1,296.287,156.251Z"
      transform="translate(-285.464 -149.149)"
      fill="#5a5a5a"
    />
    <path
      id="Path_699"
      data-name="Path 699"
      d="M159.451,302.531a5.185,5.185,0,0,0-4.753.7,4.269,4.269,0,0,0-1.29,1.661.469.469,0,1,0,.875.336,3.387,3.387,0,0,1,1.029-1.287,4.34,4.34,0,0,1,3.9-.507.469.469,0,1,0,.238-.907Z"
      transform="translate(-148.583 -292.832)"
      fill="#5a5a5a"
    />
  </svg>
</template>

<script>
export default {
  name: "sadness"
};
</script>
