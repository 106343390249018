<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 13 13"
    class="m"
  >
    <g
      id="Blue.Ellipse"
      fill="none"
      :class="status"
      stroke="#e6e6e6"
      stroke-width="3"
    >
      <circle cx="6.5" cy="6.5" r="6.5" stroke="none" />
      <circle cx="6.5" cy="6.5" r="5" fill="none" />
    </g>
  </svg>
</template>

<script>
export default {
  name: "dot",
  props: ["status"]
};
</script>

<style lang="scss" scoped>
@import "@/scss/colors.scss";
.positive {
  stroke: $positive;
}
.neutral {
  stroke: $neatral;
}
.negative {
  stroke: $negative;
}
.question {
  stroke: $question;
}
.hidden {
  stroke: $hidden;
}
.notassigned {
  stroke: $notassigned;
}
.spam {
  stroke: $spam;
}
.m {
  margin: 2px 4px;
}
</style>
