<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="45"
    height="45"
    viewBox="0 0 45 45"
  >
    <defs>
      <radialGradient
        id="radial-gradient"
        cx="0.219"
        cy="0.254"
        r="1.382"
        gradientTransform="matrix(0.698, 0.716, -0.716, 0.698, 0.248, -0.081)"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="#f5835d" />
        <stop offset="1" stop-color="#ff4005" />
      </radialGradient>
    </defs>
    <g id="Group_1" data-name="Group 1" transform="translate(943 1507)">
      <g
        id="Component_3_1"
        data-name="Component 3 – 1"
        transform="translate(-943 -1507)"
      >
        <g id="Home">
          <rect
            id="Rectangle_Copy_2"
            data-name="Rectangle Copy 2"
            width="45"
            height="45"
            rx="13"
            :fill="[active == 'true' ? 'url(#radial-gradient)' : '']"
          />
          <path
            id="Path"
            d="M3.251,10.362H.554A.53.53,0,0,1,0,9.854a.567.567,0,0,1,.193-.415L11.007.138a.54.54,0,0,1,.723,0L22.664,9.462a.521.521,0,0,1,.048.738.561.561,0,0,1-.409.185H19.557v8.908a.524.524,0,0,1-.53.508H14.451a.53.53,0,0,1-.554-.508V13.546h-5.2v5.723a.53.53,0,0,1-.554.508H3.805a.53.53,0,0,1-.554-.508Z"
            transform="translate(11 12)"
            :fill="[active == 'true' ? '#fff' : '#B2B6C4']"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: ["active"]
};
</script>

<style lang="scss"></style>
