<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="11"
    viewBox="0 0 13 11"
    :class="{ 'rotate-180': !status }"
  >
    <path
      id="Polygon_16"
      data-name="Polygon 16"
      d="M6.5,0,13,11H0Z"
      :fill="status ? '#0eb280' : '#C40D3C'"
    />
  </svg>
</template>

<script>
export default {
  name: "triangle",
  props: {
    status: {
      type: Boolean,
      default: true, // It's mean up and yellow
    },
  },
};
</script>
