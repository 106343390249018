<template>
  <div></div>
</template>

<script>
/* eslint-disable*/
import { HTTP } from "@/main-source";
import router from "@/router";

export default {
  name: "confirmMail",
  created() {
    let token = this.$route.query ? this.$route.query.confirmation_token : null;
    if (token) {
      HTTP.post("auth/complete-register?confirmation_token=" + token)
        .then(res => {
          if (res.status == 200) {

            if (res.data.object) {
              window.location = res.data.object;
            } else {
              router.push({
                name: "home",
                query: {
                  mailstatus: 200
                }
              });
            }


          }
        })
        .catch(e => {
          if (e.response.data.status == 400) {
            router.push({
              name: "home",
              query: {
                mailstatus: 400
              }
            });
          }
        });
    } else {
      router.push({ name: "login" });
    }
  }
};
</script>