<template>
  <div class="d-inline-block">
    <span class="radio" @click="setRange('LAST24')">
      <radio-button :checked="range == 'LAST24'"></radio-button>Last 24 Hours
    </span>
    <span class="radio" @click="setRange('LAST7')" v-if="false">
      <radio-button :checked="range == 'LAST7'"></radio-button>Last 7 Days
    </span>
    <span class="radio" @click="setRange('ALL')">
      <radio-button :checked="range == 'ALL'"></radio-button>All Time
    </span>
  </div>
</template>

<script>
import EventBus from "@/components/event-bus";
import radioButton from "@/components/radio-button";

export default {
  name: "range-filter",
  props: ["range"],
  components: {
    radioButton,
  },
  methods: {
    setRange: function(range) {
      if (range) {
        EventBus.$emit("setRange", range);
        localStorage.removeItem("selectedDate");
      }
    },
  },
};
</script>
