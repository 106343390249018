<template>
  <div>
    <div class="row filter">
      <div class="col-12 left-filter">
        <!-- Left -->
        <div class="col-8 col-md-6 left">
          <div class="tab">
            <router-link :to="{
              name: 'ai-reports',
              params: {
                accountId: getActiveSocialMediaAccount,
              },
            }" :class="{ active: $route.name == 'general' }">AI Reports</router-link>
            <router-link :to="{
              name: 'insights',
              params: {
                accountId: getActiveSocialMediaAccount,
              },
            }" :class="{ active: $route.name == 'insights' }">Insights</router-link>
          </div>
        </div>
        <div class="col text-right d-sm-none mobile-filter">
          <span>
            <img @error="(e) => (e.target.src = $defaultCardAvatar)" src="@/assets/images/filter.svg" alt />
          </span>
          <span>
            <img @error="(e) => (e.target.src = $defaultCardAvatar)" src="@/assets/images/calander.svg" alt />
          </span>
        </div>
        <!-- Right -->
        <div class="col-12 col-md-6 text-right pr-0 topfilter d-none d-sm-block">
          <range-filter :range="range" v-if="false"></range-filter>
          <DatePicker class="datepicker" v-model="date" placeholder="Last 90 days" range></DatePicker>
        </div>
        <!-- Right End -->
      </div>
    </div>
    <!-- <div class="no-data" v-if="getActiveSocialMedia != 'instagram'">
      <span class="placeholder">
        <img @error="(e) => (e.target.src = $defaultCardAvatar)" src="@/assets/images/icon.svg" class="logo" alt="Socialays" />
        Coming soon
      </span>
    </div> -->
    <div class="row" v-if="xhrRequest">
      <placeholder v-for="n in 7" :key="n" class="col-12 col-sm-4"></placeholder>
    </div>

    <div class="row report-summary" v-if="!xhrRequest">
      <div class="col-6 col-sm-3" v-for="(topMetric, key) in getMetricsByType('card')" :key="key">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col summary">
                <span class="value" v-tooltip.bottom="topMetric.calculatedInsight.currentTotal">
                  {{
                    topMetric.calculatedInsight.currentTotal
                    ? abbreviate(topMetric.calculatedInsight.currentTotal)
                    : "~"
                  }}
                </span>
                <span class="sub-title">{{ topMetric.title }}
                </span>
                <span class="o-5">Total</span>

              </div>
              <div class="col-12 col-sm-6 graph d-none d-sm-flex">
                <span class="status text-right ">
                  <div v-if="topMetric.calculatedInsight.percentage">
                    <status-triangle :status="
                      topMetric.calculatedInsight.verticalMovement == 'UP'
                        ? true
                        : false
                    "></status-triangle>
                    <div class="desc" :class="[
                      topMetric.calculatedInsight.verticalMovement == 'UP'
                        ? 'color-green'
                        : 'color-red'
                    ]">
                      {{ topMetric.calculatedInsight.percentage + "%" }}
                    </div>
                    <div class="o-5" v-if="false">
                      vs date coming
                    </div>
                  </div>
                </span>

                <LineChartGraph :graph="topMetric.insightData" class="line-chart"
                  :class="{ transparented: topMetric.calculatedInsight.currentTotal == 0 }" :options="options"
                  :width="135" :height="50" v-if="topMetric.insightData.length > 0"></LineChartGraph>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="row chart-wrapper" v-if="!xhrRequest">
      <template v-for="(metric, index) in metrics">
        <div class="col-12 col-sm-4 wrap" :id="metric.insightName" :key="index">
          <component :is="whichComponent(metric.chartType)" :metric="metric"></component>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { HTTP } from "@/main-source";
import EventBus from "@/components/event-bus";
import { mapGetters } from "vuex";
import rangeFilter from "@/components/filters/range";
import LineChart from "@/components/charts/LineChart";
import BarChart from "@/components/charts/Bar";
import HorizantalBar from "@/components/charts/HorizantalBar";
import PieChart from "@/components/charts/PieChart";
import SimpleList from "@/components/charts/SimpleList";
import abbreviate from "number-abbreviate";

import LineChartGraph from "@/components/charts/components/LineChart";
// Icons
import statusTriangle from "@/components/icons/triangle";
import iconProfile from "@/components/icons/profile";

import placeholder from "@/components/placeholders/report-wrapper";


// Temporary time settings
let threeMonthAgo = new Date();
threeMonthAgo.setDate(threeMonthAgo.getDate() - 90);

export default {
  name: "Reports",
  data() {
    return {
      title: "Reports",
      data: dummyReportsDataFromJson,
      range: localStorage.getItem("selectedRange") || "ALL",
      start: (threeMonthAgo.getTime() / 1000).toFixed(),
      finish: (new Date().getTime() / 1000).toFixed(),
      date: [],
      xhrRequest: true,
      graph: [7, 10, 20, 30, 42, 20, 15],
      metrics: [],
      options: {

        showAllTooltips: false,
        tooltips: {
          enabled: false
        },
        responsive: true,
        legend: {
          display: false,
        },
        layout: {
          margin: 0,
        },
        scales: {
          yAxes: [
            {
              display: false,
              ticks: {
                fontColor: "rgba(0,0,0,0.5)",
                fontStyle: "bold",
                beginAtZero: true,
                maxTicksLimit: 7,
              },
              gridLines: {
                display: false,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: false,
              },
              display: false,
              ticks: {
                padding: 15,
                fontColor: "rgba(0,0,0,0.25)",
                fontStyle: "bold",
              },
            },
          ],
        },

      },
    };
  },
  components: {
    rangeFilter,
    LineChart,
    BarChart,
    HorizantalBar,
    PieChart,
    SimpleList,
    placeholder,
    statusTriangle,
    iconProfile,
    LineChartGraph
  },

  methods: {
    getTotal(metricName) {
      let total = 0;
      let selectedMetric = this.metrics.filter(
        (metric) => metric.insightName == metricName
      )[0];
      if (selectedMetric) {
        selectedMetric.insightData.forEach((metric) => {
          total += metric.value;
        });
      }
      return total;
    },
    abbreviate(value) {
      return value < 10000
        ? this.$intlNumber(value)
        : `${abbreviate(value)}`.toUpperCase();
    },
    getReports() {
      this.xhrRequest = true;
      this.metrics = [];
      if (
        this.getActiveSocialMedia != null &&
        this.getActiveSocialMediaAccount != null
      ) {
        HTTP.get("insight/metrics/" + this.getActiveSocialMedia)
          .then((res) => {
            this.metrics = res.data.map((metric) => {
              metric.insightData = [];
              metric.calculatedInsight = {};
              return metric;
            });
            this.getAndSetCharts().then(() => {
              this.metrics.sort(function (obj1, obj2) {
                return obj1.insightData.length - obj2.insightData.length;
              });
              this.xhrRequest = false;
            });
          })
          .catch((e) => {
            // this.$notify({
            //   group: "info",
            //   type: "error",
            //   text: e.response.data.message,
            // });
          });
      } else {
        this.xhrRequest = false;
      }
    },
    async getAndSetCharts() {
      let url;
      let accountId = this.$route.params.accountId;
      await Promise.all(
        this.metrics.map(async (metric) => {
          url =
            "/insight/" +
            this.getActiveSocialMedia +
            "/" +
            accountId +
            "?metric=" +
            metric.insightName +
            "&start=" +
            this.start +
            "&finish=" +
            this.finish;
          HTTP.get(url)
            .then((res) => {
              this.metrics.filter(
                (smetr) => smetr.insightName == metric.insightName
              )[0].insightData = res.data.insightData.map((d) => {
                // return { created: d.createdDate, value: d.value };
                return d;
              });

              this.metrics.filter(
                (smetr) => smetr.insightName == metric.insightName
              )[0].calculatedInsight = res.data.calculatedInsight;
            })
            .catch((e) => {
              // this.$notify({
              //   group: "info",
              //   type: "error",
              //   text: e.response.data.message,
              // });
            });
        })
      );
    },
    getMetricsByType(type) {
      return this.metrics.filter((metric) => metric.visualizationType == type);
    },
    whichComponent: function (type) {
      let componentName = null;
      switch (type) {
        case "bar":
          componentName = "BarChart";
          break;
        case "list":
          componentName = "SimpleList";
          break;
        case "line":
          componentName = "LineChart";
          break;
      }
      return componentName;
    },
    setRange: function (value) {
      localStorage.setItem("selectedRange", value);
      localStorage.removeItem("selectedQuickRange");
      this.range = value;
    },
  },
  computed: {
    ...mapGetters(["getActiveSocialMedia", "getActiveSocialMediaAccount"]),
  },
  mounted() {
    let customDate = JSON.parse(localStorage.getItem("selectedDate")) || null;
    if (this.quickRange != null && customDate == null) {
      this.setQuickRange(this.quickRange);
    }

    if (customDate != null) {
      customDate[0] = new Date(customDate[0]);
      customDate[1] = new Date(customDate[1]);
      this.date = customDate;
    }
    EventBus.$on("setRange", (range) => {
      this.setRange(range);
    });
    this.getReports();
  },
  watch: {
    "$route.params.accountId": function () {
      this.range = "ALL";
      this.getReports();
    },
    date: {
      deep: true,
      handler: function () {
        localStorage.setItem("selectedDate", JSON.stringify(this.date));
        if (this.date[0]) {
          this.range = null;
          this.start = this.date[0].getTime() / 1000;
          this.finish = this.date[1].getTime() / 1000 + 86399;
          this.getReports();
        } else {
          this.range = "ALL";
          (this.start = (threeMonthAgo.getTime() / 1000).toFixed()),
            (this.finish = (new Date().getTime() / 1000).toFixed()),
            this.getReports();
        }
      },
    },
  },
};
</script>
<style lang="scss">
@import "@/scss/colors.scss";

.reports {
  position: relative;
  width: 100%;
}
</style>
