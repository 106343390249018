<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="45"
    height="45"
    viewBox="0 0 45 45"
  >
    <defs>
      <linearGradient
        id="y-linear-gradient"
        y1="-0.092"
        x2="0.816"
        y2="1"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="#ff835c" />
        <stop offset="1" stop-color="#cc1829" />
      </linearGradient>
      <filter
        id="Rectangle_Copy_5"
        x="0"
        y="0"
        width="45"
        height="45"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="20" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="7.5" result="blur" />
        <feFlood flood-color="#f0a7a7" flood-opacity="0.039" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g id="Youtube" transform="translate(22.5 2.5)">
      <g
        transform="matrix(1, 0, 0, 1, -22.5, -2.5)"
        filter="url(#Rectangle_Copy_5)"
      >
        <rect
          id="Rectangle_Copy_5-2"
          data-name="Rectangle Copy 5"
          width="45"
          height="45"
          rx="13"
          :fill="[active == 'true' ? 'url(#y-linear-gradient)' : '']"
        />
      </g>
      <path
        id="Fill_13"
        data-name="Fill 13"
        d="M11.421,16.58c-3.8,0-8.086-.148-9.081-.479A2.731,2.731,0,0,1,.561,14.335c-.77-2.212-.9-11.236.588-12.993A2.878,2.878,0,0,1,3.1.292C4.748.112,8.063,0,11.752,0c3.984,0,8.373.136,9.2.44A2.774,2.774,0,0,1,22.7,2.15a22.162,22.162,0,0,1,.587,6.294,19.241,19.241,0,0,1-.789,6.232,2.833,2.833,0,0,1-1.268,1.3C20.427,16.392,15.726,16.58,11.421,16.58ZM8.808,4.21h0C8.789,6.767,8.77,9.3,8.751,11.862c2.014-1.065,4.063-2.14,6.044-3.179l1.215-.637L13.026,6.457l-.015-.008q-2.1-1.12-4.2-2.238Z"
        transform="translate(-11 12)"
        :fill="[active == 'true' ? '#fff' : '#B2B6C4']"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: ["active"]
};
</script>

<style lang="scss"></style>
