<template>
  <div>
    <!-- Upgrade Account Modal -->
    <modal v-if="upgradeAccountModal" @close="upgradeAccountModal = false">
      <div slot="title">Upgrade Your Account</div>
      <div slot="body" class="desc text-center">
        <img @error="(e) => (e.target.src = $defaultCardAvatar)" src="@/assets/images/icon.svg" class="modal-logo"
          alt="Socialays" />
        You can only add {{ $maxAddableAccountWhileTrial }} social accounts
        <br />at trial version.
      </div>
      <div slot="footer" class="content-center">
        <button class="btn btn-primary" @click="goToUpgrade">
          Upgrade Your Account
        </button>
      </div>
    </modal>
    <!-- Upgrade Account Modal -->

    <!-- Define Password Modal -->
    <modal v-if="definePasswordModal" class="define-password">
      <div slot="title">Define Password</div>
      <div slot="body" class="desc text-center">
        <img @error="(e) => (e.target.src = $defaultCardAvatar)" src="@/assets/images/icon.svg" class="modal-logo"
          alt="Socialays" />
        <p class="mt-4">
          We are using password verification for some important operations our
          panel. Please define a password.
        </p>
        <div class="form mt-3">
          <div class="form-group">
            <input maxLength="70" type="password" @blur="$v.newUser.password.$touch()" v-model="newUser.password"
              class="form-control" :class="{ 'invalid-b': $v.newUser.password.$error }" placeholder="Password" max="20" />
            <div v-if="$v.newUser.password.$error" class="text-left">
              <span class="invalid" v-if="!$v.newUser.password.required">Password required</span>
              <span class="invalid" v-if="!$v.newUser.password.minLength">Password min 8 charaters</span>
              <span class="invalid" v-if="!$v.newUser.password.maxLength">Password max 20 charaters</span>
              <span class="invalid" v-if="!$v.newUser.password.containsLowercase">Password must contain lowercase
                charater</span>
              <span class="invalid" v-if="!$v.newUser.password.containsUppercase">Password must contain uppercase
                charater</span>
              <span class="invalid" v-if="!$v.newUser.password.containsNumber">Password must contain one number</span>
              <span class="invalid" v-if="!$v.newUser.password.containsSpecial">Password must contain special
                character</span>
            </div>
          </div>
          <div class="form-group">
            <input maxLength="70" type="password" @blur="$v.newUser.rePassword.$touch()" @keyup.enter="definePassword"
              v-model="newUser.rePassword" class="form-control" :class="{ 'invalid-b': $v.newUser.rePassword.$error }"
              placeholder="Confirm Password" max="20" />
          </div>
          <div v-if="$v.newUser.password.$error || $v.newUser.rePassword.$error" class="text-left">
            <span class="invalid">Passwords doesn't match</span>
          </div>
        </div>
      </div>
      <div slot="footer" class="content-center">
        <button class="btn btn-primary" @click="definePassword">Save</button>
      </div>
    </modal>
    <!-- Define Password Modal END -->

    <div class="row filter" v-if="data.length != 0 && !showAddAccount">
      <div class="col-12 left-filter">
        <!-- Left -->
        <div class="col-6 col-md-6 left">
          <div class="account-filter-home">
            <div>
              Facebook
              <label class="switch">
                <input type="checkbox" v-model="showedAccounts.FACEBOOK" class="default primary" />
                <span class="slider round"></span>
              </label>
            </div>
            <div>
              Instagram
              <label class="switch">
                <input type="checkbox" v-model="showedAccounts.INSTAGRAM" class="default primary" />
                <span class="slider round"></span>
              </label>
            </div>
            <div v-if="false">
              Twitter
              <label class="switch">
                <input type="checkbox" v-model="showedAccounts.TWITTER" class="default primary" />
                <span class="slider round"></span>
              </label>
            </div>
            <div>
              Youtube
              <label class="switch">
                <input type="checkbox" v-model="showedAccounts.YOUTUBE" class="default primary" />
                <span class="slider round"></span>
              </label>
            </div>

          </div>
        </div>
        <div class="col text-right d-sm-none mobile-filter">
          <!-- <span>
            <img @error="(e) => (e.target.src = $defaultCardAvatar)" src="@/assets/images/filter.svg" alt />
          </span> -->

          <!-- {{ date }} -->

          <!-- <input type="date" name="End Date" v-model="mDateEnd" /> -->

          <span @click="openRangeModal">
            <img @error="(e) => (e.target.src = $defaultCardAvatar)" src="@/assets/images/calander.svg" alt />
          </span>
        </div>
        <!-- Right -->
        <div class="col-12 col-md-6 text-right pr-0 topfilter d-none d-sm-block">
          <rangeFilter :range="range"></rangeFilter>

          <DatePicker ref="datepicker" class="datepicker" v-model="date" placeholder="Select Date"
            :disabled-date="disabledDates" range>
            <template v-slot:header="{ emit }">
              <button class="btn radio p-0 d-block w-100 text-left" @click="setRange('ALL')">
                <radio-button :checked="range == 'ALL'"></radio-button>All Time
              </button>
              <button class="btn radio p-0 d-block w-100 text-left" @click="setQuickRange('14Days')">
                <radio-button :checked="quickRange == '14Days'"></radio-button>Last 14 Days
              </button>
              <button class="btn radio p-0 d-block w-100 text-left" @click="setQuickRange('30Days')">
                <radio-button :checked="quickRange == '30Days'"></radio-button>Last 30 Days
              </button>
              <button class="btn radio p-0 d-block w-100 text-left" @click="setQuickRange('ThisWeek')">
                <radio-button :checked="quickRange == 'ThisWeek'"></radio-button>This Week
              </button>
              <button class="btn radio p-0 d-block w-100 text-left" @click="setQuickRange('LastWeek')">
                <radio-button :checked="quickRange == 'LastWeek'"></radio-button>Last Week
              </button>
              <button class="btn radio p-0 d-block w-100 text-left" @click="setQuickRange('ThisMonth')">
                <radio-button :checked="quickRange == 'ThisMonth'"></radio-button>This Month
              </button>
              <button class="btn radio p-0 d-block w-100 text-left mb-2" @click="setQuickRange('LastMonth')">
                <radio-button :checked="quickRange == 'LastMonth'"></radio-button>Last Month
              </button>
            </template>
          </DatePicker>
        </div>
        <!-- Right End -->
      </div>
    </div>

    <div v-if="!show" class="row">
      <div v-for="i in 9" :key="i" class="col-12 col-lg-6 col-xl-4">
        <SkeletonLoader />
      </div>
    </div>

    <transition enter-active-class="animated fadeIn">
      <div v-if="show && data.length != 0 && !showAddAccount" class="account-charts row mt-4">
        <!-- Drag Area -->

        <div v-for="item in homeLayout" :key="item.i" class="col-12  col-lg-4 mb-4" v-if="isShowing(item)">
          <chart :graph="getAccountData(item.accountId).chartData" :account="getAccountData(item.accountId).object"
            :type="getAccountData(item.accountId).type" :totals="getAccountData(item.accountId).totals"
            :socialmedia="getAccountData(item.accountId).type.toLowerCase()" :range="range" :start="start"
            :finish="finish"></chart>
        </div>


        <!-- Drag Area -->
      </div>
    </transition>

    <transition enter-active-class="animated fadeInRight" leave-active-class="animated fadeOutRight" :delay="1500">
      <div v-if="(show && data.length == 0) || showAddAccount">
        <addAccount @close="showAddAccount = false" :accountCount="data.length" :showClose="true" :currentFlow="'OWN'"></addAccount>
      </div>
    </transition>
    <!-- Mobile Range Modal Start -->
    <mobileRangeFilter :mobileRangeModal="mobileRangeModal" :date="date" :f_range="range"
      @close="mobileRangeModalSetAndClose"></mobileRangeFilter>
    <!-- Mobile Order Modal -->
  </div>
</template>

<script>
import { HTTP } from "@/main-source";
import { mapActions, mapGetters } from "vuex";
import chart from "@/components/facebook/general/Chart";
import EventBus from "@/components/event-bus";
import rangeFilter from "@/components/filters/range";
import addAccount from "@/components/accounts/AddAccount";
import AddAccountBox from "@/components/accounts/AddAccountBox";
import modal from "@/components/modal";
import SkeletonLoader from "@/components/placeholders/boxes";
import mobileRangeFilter from "@/components/filters/mobile/date";
import radioButton from "@/components/radio-button";
import router from "@/router";
import {
  required,
  email,
  minLength,
  maxLength,
  sameAs,
} from "vuelidate/lib/validators";
export default {
  name: "Home",
  data() {
    return {
      title: "Home",
      data: [],
      date: JSON.parse(localStorage.getItem("selectedDate")) || null,
      mDateStart: null,
      mDateEnd: null,
      range: localStorage.getItem("selectedRange") || "ALL",
      quickRange: localStorage.getItem("selectedQuickRange") || null,
      show: false,
      showAddAccount: this.$route.query.addAccount
        ? this.$route.query.addAccount
        : false,
      dragOptions: this.$dragOptions,
      start: null,
      finish: null,
      intCover: null,
      upgradeAccountModal: false,
      syncIntCover: null,
      homeLayout:
        localStorage.getItem("home-order") && !this.mobileAndTabletCheck
          ? JSON.parse(localStorage.getItem("home-order"))
          : [],
      definePasswordModal: false,
      newUser: {
        password: null,
        rePassword: null,
      },
      mobileRangeModal: false,
      showedAccounts: {
        FACEBOOK: true,
        TWITTER: true,
        INSTAGRAM: true,
        YOUTUBE: true
      }
    };
  },
  validations: {
    newUser: {
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(20),
        containsUppercase: function (value) {
          return /[A-Z]/.test(value);
        },
        containsLowercase: function (value) {
          return /[a-z]/.test(value);
        },
        containsNumber: function (value) {
          return /[0-9]/.test(value);
        },
        containsSpecial: function (value) {
          return /[#?!@$%^&*-.]/.test(value);
        },
      },
      rePassword: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(20),
        sameAs: sameAs("password"),
      },
    },
  },
  components: {
    chart,
    rangeFilter,
    addAccount,
    AddAccountBox,
    modal,
    SkeletonLoader,
    mobileRangeFilter,
    radioButton
  },
  created() {
    this.$setMaxCount();
    this.getDashboard();
    this.setActiveSocialMedia("");
  },
  watch: {
    date: {
      deep: true,
      handler: function () {
        localStorage.setItem("selectedDate", JSON.stringify(this.date));
        if (this.date[0]) {
          this.range = null;
          this.start = this.date[0].getTime();
          this.finish = this.date[1].getTime() + 86399000;
          this.getDashboard();
        } else {
          this.range = "ALL";
          this.start = null;
          this.finish = null;
          this.getDashboard();
        }
      },
    },
    mDateStart: function () {
      if (this.mDateEnd) {
        this.date = [new Date(this.mDateStart), new Date(this.mDateEnd)];
      }
    },
    mDateEnd: function () {
      if (this.mDateStart) {
        this.date = [new Date(this.mDateStart), new Date(this.mDateEnd)];
      }
    },
  },
  methods: {
    isShowing(account) {
      console.log("🚀 ~ file: Home.vue:316 ~ isShowing ~ account:", account.type)

      return this.showedAccounts[account.type];
    },
    ...mapActions(["setActiveSocialMedia"]),
    saveLastSort: function () {
      localStorage.setItem("home-order", JSON.stringify(this.homeLayout));
    },
    setRange: function (value) {
      localStorage.setItem("selectedRange", value);
      localStorage.removeItem("selectedQuickRange");
      this.range = value;
      this.quickRange = null;
      this.date = null;
      this.start = null;
      this.finish = null;
      this.getDashboard();
    },
    setQuickRange: function (which) {
      localStorage.setItem("selectedQuickRange", which);
      localStorage.removeItem("selectedRange");
      this.range = null;

      this.date = null;
      this.start = null;
      this.finish = null;
      let today = new Date();
      switch (which) {
        case '14Days':
          this.quickRange = '14Days';
          today = new Date();
          let f14 = new Date(today.setDate(new Date().getDate() - 14));
          this.date = [f14, new Date()];
          break;
        case '30Days':
          this.quickRange = '30Days';
          today = new Date();
          let f30 = new Date(today.setDate(new Date().getDate() - 30));
          this.date = [f30, new Date()];
          break;
        case 'ThisWeek':
          this.quickRange = 'ThisWeek';
          let curr = new Date();
          let first = curr.getDate() - curr.getDay() + 1;
          let last = first + 6;

          let firstday = new Date(curr.setDate(first));
          let lastday = new Date(curr.setDate(last));
          this.date = [firstday, lastday];
          break;
        case 'LastWeek':
          this.quickRange = 'LastWeek';
          today = new Date();
          let curl = new Date(today.setDate(new Date().getDate() - 7));
          let firstl = curl.getDate() - curl.getDay() + 1;
          let lastl = firstl + 6;

          let firstlday = new Date(curl.setDate(firstl));
          let lastlday = new Date(curl.setDate(lastl));
          this.date = [firstlday, lastlday];
          break;
        case 'ThisMonth':
          this.quickRange = 'ThisMonth';
          var date = new Date(), y = date.getFullYear(), m = date.getMonth();
          var firstDay = new Date(y, m, 1);
          var lastDay = new Date(y, m + 1, 0);
          this.date = [firstDay, lastDay];
          break;
        case 'LastMonth':
          this.quickRange = 'LastMonth';
          var date = new Date();
          var firstDayPrevMonth = new Date(date.getFullYear(), date.getMonth() - 1, 1);
          var lastDayPrevMonth = new Date(date.getFullYear(), date.getMonth(), 0);
          this.date = [firstDayPrevMonth, lastDayPrevMonth];
          break;
      }

      this.getDashboard();

      this.$refs.datepicker.closePopup();
    },
    getDashboard: function () {
      let uri = "dashboard";
      if (this.start && this.finish) {
        uri += "?start=" + this.start + "&finish=" + this.finish;
      } else {
        uri += "?range=" + this.range;
      }
      HTTP.get(uri)
        .then((result) => {
          // // this.data = result.data.sort((a, b) => (a.chartData.Positive < b.chartData.Positive ? 1 : -1));
          this.data = result.data;
          if (this.data.length != this.homeLayout.length) {
            this.data.forEach((item, index) => {
              let count = this.homeLayout.filter(
                (layoutItem) => layoutItem.accountId == item.object.accountId
              ).length;
              if (count == 0) {
                this.homeLayout.push({
                  accountId: item.object.accountId,
                  type: item.type,
                  x: this.homeLayout.length % 3,
                  y: this.homeLayout.length,
                  w: 1,
                  h: this.mobileAndTabletCheck() ? 19 : 14,
                  i: item.object.accountId,
                  draggable: true,
                });
              }
            });
            this.homeLayout.forEach((item, index) => {
              let isExists = this.data.filter(
                (dAccount) => dAccount.object.accountId == item.accountId
              );
              if (isExists == 0) {
                this.homeLayout = this.homeLayout.filter(
                  (h) => h.accountId != item.accountId
                );
              }
            });
          }
          let hasError = result.data.filter((a) => a.object.hasError == true);
          if (hasError.length > 0) {
            EventBus.$emit("hasErrorAccount", true);
          }
          let hasSync = result.data.filter((a) => a.object.isSynced == true);
          if (hasSync.length > 0 && this.syncIntCover == null) {
            this.syncIntCover = setInterval(() => {
              this.getDashboard();
            }, 10000);
          } else {
            clearInterval(this.syncIntCover);
          }
          this.show = true;
        })
        .catch((e) => {
          if (e.response.status == 428) {
            this.definePasswordModal = false;
          }
        });
    },
    getAccountData: function (accountId) {
      return this.data.filter(
        (account) => account.object.accountId == accountId
      )[0];
    },
    disabledDates: function (date) {
      return this.$disabledDates(date);
    },
    showAddAccountBox: function (value) {
      let accountStatus = JSON.parse(localStorage.getItem("accountStatus"));
      if (accountStatus.status == "TRIAL") {
        HTTP.get("user/me").then((result) => {
          if (result.data.trialCount >= this.$maxAddableAccountWhileTrial) {
            this.upgradeAccountModal = true;
          } else {
            this.showAddAccount = true;
          }
        });
      } else {
        this.showAddAccount = value;
      }
    },
    goToUpgrade: function () {
      this.$goToUpgrade();
    },
    definePassword: function () {
      HTTP.post("auth/set-password", this.newUser)
        .then(() => {
          this.definePasswordModal = false;
          // this.getDashboard();
          location.reload();
        })
        .catch((e) => {
          this.$notify({
            group: "info",
            type: "error",
            text: e.response.data.message,
          });
        });
    },
    /*eslint-disable*/
    mobileAndTabletCheck: function () {
      let check = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      if (window.innerWidth < 768) {
        check = true;
      }
      return check;
    },
    /*eslint-disable*/
    openRangeModal: function () {
      this.mobileRangeModal = true;
      EventBus.$emit("mobileRangeFilter", true);
    },
    mobileRangeModalSetAndClose: function (data) {
      if (data.range != "CUSTOM") {
        this.setRange(data.range);
      } else {
        this.range = "CUSTOM";
        this.date = [new Date(data.start), new Date(data.finish)];
      }
      this.mobileRangeModal = false;
      EventBus.$emit("mobileRangeFilter", false);
    },
  },
  computed: {
    ...mapGetters(["getUser"])
  },
  mounted() {

    EventBus.$on("setRange", (range) => {
      this.setRange(range);
    });
    EventBus.$on("showAddAccountBox", (value) => {
      this.showAddAccountBox(value);
    });
    HTTP.get("user/me").then((res) => {
      let accountStatus = {
        status: res.data.status,
        count: res.data.socialMediaAccounts.length,
      };
      localStorage.accountStatus = JSON.stringify(accountStatus);
      
      // Temporary Disabled - Trial Period
      // EventBus.$emit("notify", true, res.data.createdAt, res.data.status);

    });
    this.intCover = setInterval(() => {
      this.getDashboard();
    }, 120000);
    setTimeout(() => {
      if (this.$route.query.mailstatus) {
        switch (this.$route.query.mailstatus) {
          case "200":
            this.$notify({
              group: "info",
              text: "E-mail verified",
            });
            break;
        }
      }
    }, 2000);
  },
  beforeDestroy: function () {
    clearInterval(this.intCover);
    EventBus.$off("showAddAccountBox");
    EventBus.$off("setRange");
  },
};
</script>
<style lang="scss">
@import "@/scss/colors.scss";

.home-chart {
  #item-md {
    max-width: calc(33% - 15px) !important;
    width: calc(33% - 15px) !important;
  }
}

.home {
  column-count: 3;
  transition: all 0.5s ease-in-out;
  column-gap: 15px;

  .wrap {
    margin-bottom: 15px;
    width: 100%;
    display: inline-block;
    // position: absolute;
  }

  .head {
    // cursor: grab;
  }
}

.content-center {
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
}

.account-charts {
  margin: -10px -15px;

  .profile,
  .name,
  .slug,
  .icon {
    cursor: pointer;
  }
}

// .accounts-enter-active {
// 	transform: translateX(100vw);
// 	transition: all 1.3s ease;
// }
// .accounts-enter,
// .accounts-leave-to {
// 	transform: translateX(0px);
// 	opacity: 0;
// }
// .add-account-enter-active {
// 	transform: translateX(100vw);
// 	transition: all 1.3s ease;
// }
// .add-account-enter,
// .add-account-leave-to {
// 	transform: translateX(0px);
// 	opacity: 0;
// }
</style>

