<template>
  <div>
    <div class="setting account profile-wrap">
      <div class="head">
        <img @error="(e) => (e.target.src = $defaultCardAvatar)" src="@/assets/images/settings/profile.svg" alt="Email Settings" />
        <span>Profile</span>
      </div>
      <div class="body">
        <div class="row">
          <div class="col-12 info">
            <file-upload
              ref="upload"
              class="file profile-img"
              v-model="image"
              accept="image/png, image/gif, image/jpeg"
              extensions="gif,jpg,jpeg,png"
            >
              <img @error="(e) => (e.target.src = $defaultCardAvatar)"
                class="profile"
                src="@/assets/images/profile.png"
                v-if="!user.profileImageUrl"
                alt="Profile"
              />
              <img @error="(e) => (e.target.src = $defaultCardAvatar)"
                class="profile"
                :src="profilePhoto(user.profileImageUrl)"
                v-if="user.profileImageUrl"
                alt="Profile"
              />
              <img @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/add-image.jpg"
                class="profile add"
                alt
              />
            </file-upload>
            <span class="name">{{ user.firstName }} {{ user.lastName }}</span>
            {{ user.email }}
            <span> Membership Date: {{ createdAt }} </span>
          </div>
        </div>
        <div class="row period" v-if="false">
          <div class="col">
            <span class="bold">Start Date</span>
            asdsd
          </div>
          <div class="col">
            <span class="bold">Start Date</span>
            asdsd
          </div>
          <div class="col">
            <span class="bold">Start Date</span>
            asdsd
          </div>
        </div>
        <div class="btn-wrap">
          <button
            class="btn btn-warning"
            @click="showModal('freeze')"
            v-if="false"
          >
            Freeze My Account
          </button>
          <button class="btn btn-danger" @click="deleteMyAccountTrigger">
            Delete My Account and All Data
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EventBus from "@/components/event-bus";

export default {
  name: "Profile",
  data() {
    return {
      process: null,
      password: null,
      image: []
    };
  },
  props: ["user"],
  methods: {
    deleteMyAccountTrigger: function() {
      EventBus.$emit("deleteMyAccount");
    },
    profilePhoto: function(url) {
      return url + "?token=" + localStorage.getItem("token");
    }
  },
  computed: {
    createdAt: function() {
      return new Date(this.user.createdAt).toLocaleDateString("tr-TR");
    }
  },
  watch: {
    image: function() {
      EventBus.$emit("uploadPhoto", this.image);
    }
  }
};
</script>

<style lang="scss">
@import "../../scss/colors.scss";
.profile-wrap {
  .info {
    padding-top: 10px !important;
  }
}
.account {
  .profile {
    width: 90px;
    height: 90px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    float: left;
    margin-right: 10px;
  }
  .body {
    width: 100%;
    padding: 15px;
  }
  .info {
    .name {
      font-weight: bold;
    }
    span {
      display: block;
    }
  }
  .period {
    font-size: 12px;
    text-align: center;
    margin: 15px auto;
    .bold {
      font-weight: bold;
      display: block;
    }
    .col {
      &:nth-child(2) {
        border-right: 1px solid lighten($black, 90%);
        border-left: 1px solid lighten($black, 90%);
      }
    }
  }
  .btn-wrap {
    display: flex;
    .btn {
      width: calc(100% - 5px);
      flex: 1;
      margin-right: 10px;
      font-size: 12px;
      font-weight: bold;
      color: $white;
      padding: 10px 15px;
      border-radius: 30px;
    }
    .btn-danger {
      margin-right: 0px;
    }
  }
  .profile-img {
    position: relative;
    margin-bottom: 10px;
    float: left;
    label {
      z-index: 999 !important;
      cursor: pointer;
    }
    .add {
      position: absolute;
      z-index: 9;
      left: 0;
      top: 0;
      opacity: 0;
      transition: all 0.3s ease;
      cursor: pointer;
    }
    &:hover {
      .add {
        opacity: 1;
      }
    }
  }
}
</style>
