<template>
  <div class="col second">
    <div v-if="getActiveSocialMedia != ''">
      <div class="social-account-menu add-new-account"
        v-if="accountList.length == 0">
        <router-link :to="{
          name: 'home',
          query: {
            addAccount: 'true',
          },
        }"
          @click.native="
            setActiveSocialMedia('');
          closeOnMobile();
            ">
          <div class="row">
            <div class="social-account">
              <div class="col-2 col-sm-3 img">
                <img @error="(e) => (e.target.src = $defaultCardAvatar)"
                  class="img-fluid"
                  src="@/assets/images/sidebar/new-account.svg"
                  alt="Selected Account" />
              </div>
              <div class="info col-9">
                <span class="name">Add Account</span>
              </div>
            </div>
          </div>
        </router-link>
      </div>
      <div class="social-account-menu"
        data-toggle="dropdown"
        v-if="accountList.length > 0">
        <list-item class="row drop"
          :account="selectedAccount"
          :disableClick="true"
          v-if="selectedAccount != null">
        </list-item>
        <img @error="(e) => (e.target.src = $defaultCardAvatar)"
          src="@/assets/images/sidebar/down.svg"
          class="down"
          alt="Down" />
      </div>
      <div class="dropdown-menu accounts animate slideIn"
        v-if="accountList.length > 0">
        <list-item v-for="account in accountList"
          :key="account.id"
          :account="account"></list-item>
        <div class="add text-center"
          @click="addAccount">
          <img @error="(e) => (e.target.src = $defaultCardAvatar)"
            src="@/assets/images/sidebar/add.svg"
            alt="Down" /> Add Account
        </div>
      </div>
      <div class="sub-menu"
        :class="{ 'disable-events': accountList.length == 0 }"
        @click="toggleEffect">
        <router-link :to="{
          name: 'ai-reports',
          params: setParams,
        }"
          :class="
            $route.name == 'insights'
              ? 'router-link-exact-active router-link-active'
              : ''
          "
          @click.native="closeOnMobile">Reports</router-link>
        <router-link :to="{
          name: 'comments',
          params: setParams,
        }"
          @click.native="closeOnMobile">
          {{ getActiveSocialMedia != "twitter" ? "Comment" : "Reply" }} Analysis
        </router-link>
        <router-link :to="{
          name: 'posts',
          params: setParams,
        }"
          @click.native="closeOnMobile">
          {{ postsText }}
        </router-link>
        <!-- Disable Direct messages for youtube after enable it -->
        <!-- v-if="getActiveSocialMedia != 'youtube'" -->
        <router-link :to="{
          name: 'directmessages',
          params: setParams,
        }"
          v-if="false"
          @click.native="closeOnMobile">Direct Messages</router-link>
        <router-link :to="{
          name: 'mentions',
          params: setParams,
        }"
          v-if="getActiveSocialMedia != 'youtube' && !isCompetitor()"
          @click.native="closeOnMobile">Mentions
        </router-link>
        <router-link v-if="!isCompetitor()"
          :to="{
            name: 'ai-actions',
            params: setParams,
          }"
          @click.native="closeOnMobile">AI Actions</router-link>
        <router-link v-if="!isCompetitor()" :to="{
          name: 'account-settings',
          params: setParams,
        }"
          @click.native="closeOnMobile">Account Settings</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from "@/main-source";
import { mapGetters, mapActions } from "vuex";
import listitem from "./account/list-item.vue";
import router from "@/router";
import EventBus from "@/components/event-bus";

export default {
  name: "second",
  data() {
    return {
      data: [],
      accountList: [],
      selectedAccount: null,
      redirectURL: this.$redirectURL,
    };
  },
  created() {
    this.create();
  },
  components: {
    "list-item": listitem,
  },
  methods: {
    ...mapActions([
      "setActiveSocialMedia",
      "setActiveSocialMediaAccount",
      "setActiveSocialMediaAccountObj",
      "setUsername",
    ]),
    isCompetitor() {
      if (!this.selectedAccount) {
        return false;
      }
      return this.selectedAccount.type == "COMPETITOR";
    },
    create: function () {
      if (this.$route.params.socialmedia) {
        this.setActiveSocialMedia(this.$route.params.socialmedia);
      }
      if (this.$route.params.accountId) {
        this.setActiveSocialMediaAccount(this.$route.params.accountId);
      }
      HTTP.get("/user/me")
        .then((result) => {
          this.data = result.data;
          let userName = this.data.firstName + " " + this.data.lastName;

          if (this.data.firstName == null) {
            userName = result.data.email;
          }

          localStorage.setItem("profileImg", this.data.profileImageUrl);

          // if (this.data.provider[0].provider == "local") {
          //   localStorage.setItem(
          //     "profileImg",
          //     this.data.profileImageUrl +
          //       "?token=" +
          //       localStorage.getItem("token")
          //   );
          // } else {
          //   localStorage.setItem("profileImg", this.data.profileImageUrl);
          // }

          if (result.data.socialMediaAccounts) {
            let hasErrorAccounts = result.data.socialMediaAccounts.filter(
              (a) => a.hasError == true
            );

            if (hasErrorAccounts.length > 0) {
              EventBus.$emit("brokenAccount", hasErrorAccounts.length);
            }
          }
          EventBus.$emit("photoUpdated");

          this.setUsername(userName);
        })
        .then(() => {
          this.setAccountList();
        });
    },
    addAccount: function () {
      let uri =
        "accounts/" +
        this.getActiveSocialMedia +
        "/url?redirect_url=" +
        this.redirectURL +
        "/social/" +
        this.getActiveSocialMedia +
        "/save";

      HTTP.get(uri).then((result) => {
        if (result.status == 200) {
          window.location = result.data.url;
        }
      });
    },
    setAccountList: function () {
      if (this.data.socialMediaAccounts) {
        this.accountList = this.data.socialMediaAccounts.filter(
          (account) =>
            account.socialMediaApp == this.getActiveSocialMedia.toUpperCase()
        );
      }

      if (this.data.authorizedAccounts) {
        let authorizedAccountList = this.data.authorizedAccounts.filter(
          (account) =>
            account.socialMediaApp == this.getActiveSocialMedia.toUpperCase()
        );

        authorizedAccountList.forEach((account) => {
          let isContains = false;

          this.accountList.forEach((l_account) => {
            if (l_account.accountId == account.accountId) {
              isContains = true;
            }
          });
          if (!isContains) {
            this.accountList.push(account);
          }
        });

        // Set Last Selected Account as selected
        if (
          Object.keys(this.$route.query).length === 0 &&
          this.$route.query.constructor === Object
        ) {
          if (
            localStorage.getItem(
              "lastSelected" + this.getActiveSocialMedia + "Account"
            ) != null
          ) {
            let account = localStorage.getItem(
              "lastSelected" + this.getActiveSocialMedia + "Account"
            );
            let selectedAccount = this.accountList.filter(
              (a) => a.accountId == account
            )[0];
            if (selectedAccount) {
              this.selectedAccount = selectedAccount;
              this.setActiveSocialMediaAccount(account);
              this.setActiveSocialMediaAccountObj(selectedAccount);
            }
          }
        }
        //
      }

      if (this.accountList.length == 0) {
        this.selectedAccount = null;
        this.setActiveSocialMediaAccount(null);
      }
    },
    toggleEffect: function () {
      if (this.accountList.length == 0) {
        EventBus.$emit("toggleEffect", true);
      }
    },
    closeOnMobile: function () {
      /*eslint-disable*/
      let mobileAndTabletCheck = function () {
        let check = false;
        (function (a) {
          if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
              a
            ) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
              a.substr(0, 4)
            )
          )
            check = true;
        })(navigator.userAgent || navigator.vendor || window.opera);
        return check;
      };
      /*eslint-disable*/
      if (mobileAndTabletCheck()) {
        EventBus.$emit("menu", false);
      }
    },
  },
  computed: {
    ...mapGetters(["getActiveSocialMedia", "getActiveSocialMediaAccount", "getActiveSocialMediaAccountObj"]),
    setParams: function () {
      return {
        socialmedia: this.getActiveSocialMedia,
        accountId: this.$route.params.accountId
          ? this.$route.params.accountId
          : this.getActiveSocialMediaAccount,
      };
    },

    postsText() {
      const postsTexts = {
        twitter: "Tweet",
        youtube: "Videos",
      };

      return (postsTexts[this.getActiveSocialMedia] || "Post") + " Analysis";
    },
  },
  watch: {
    getActiveSocialMedia: function () {
      this.create();
      this.setAccountList();
    },
    accountList: function () {
      if (this.$route.params.accountId) {
        this.selectedAccount = this.accountList.filter(
          (account) => account.accountId == this.$route.params.accountId
        )[0];
      } else {
        this.selectedAccount = this.accountList[0];
      }
      // if (this.selectedAccount) {

      // }
    },
    getActiveSocialMediaAccount: function (accountId) {
      if (this.selectedAccount) {
        let route = {
          name: this.$route.name,
          params: {
            socialmedia: this.$route.params.socialmedia,
            accountId: this.selectedAccount.accountId,
          },
          query: null,
        };

        if (accountId != this.selectedAccount.accountId) {
          this.selectedAccount = this.accountList.filter(
            (account) => account.accountId == accountId
          )[0];
          route.params.accountId = this.selectedAccount.accountId;
        }

        if (
          Object.keys(this.$route.query).length === 0 &&
          this.$route.query.constructor === Object
        ) {
          router.replace(route);
        }
      }
    },
    selectedAccount: function () {
      if (this.selectedAccount) {
        this.setActiveSocialMediaAccount(this.selectedAccount.accountId);
        localStorage.setItem(
          "lastSelected" + this.getActiveSocialMedia + "Account",
          this.selectedAccount.accountId
        );
        this.setActiveSocialMediaAccountObj(this.selectedAccount);
      } else {
        this.setActiveSocialMediaAccount(null);
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../scss/colors.scss";

.second {
  height: 100%;
  padding-top: 20px;

  .accounts {
    width: calc(100% - 30px);
    // padding: 10px;
    max-height: 60vh;
    overflow: hidden;
    padding: 0;
    // overflow-x: hidden;
    border-radius: 30px;
    border: 0;
    margin-left: 15px;
    position: relative;
    box-shadow: 0 0px 15px rgba(0, 0, 0, 0.1);

    &:after {
      top: -20px;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(255, 255, 255, 0);
      border-bottom-color: #fff;
      border-width: 10px;
    }

    .social-account {
      padding: 12px;
      border-bottom: 1px solid lighten($black, 90%);
      transition: all 0.2s ease;

      &:first-child {
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        overflow: hidden;
      }

      cursor: pointer;

      &:hover {
        background: lighten($black, 90%);
      }

      &:last-child {
        border-bottom: 0;
        margin-bottom: 0px;
        padding-bottom: 0px;
      }
    }

    .add {
      padding: 15px;
      transition: all 0.2s ease;
      border-bottom-left-radius: 30px;
      border-bottom-right-radius: 30px;
      overflow: hidden;
      cursor: pointer;

      &:hover {
        background: lighten($black, 90%);
      }
    }
  }

  .add-new-account {
    cursor: pointer;
  }
}

.text {
  width: 100%;
}

.social-account-menu {
  margin-top: 20px;
  padding: 15px;
  position: relative;
}

.sub-menu {
  margin-top: 10px;

  a {
    display: block;
    padding: 10px 15px;
    color: $white;
    margin-bottom: 10px;
    border-radius: 30px;
    transition: all 0.2s linear;
    font-weight: bold;

    img {
      margin-top: -3px;
      margin-right: 5px;
    }

    &:hover {
      background: rgba($main-bg-color, 0.95);
      color: lighten($black, 30%);
    }
  }

  .router-link-exact-active,
  .router-link-active {
    background: $main-bg-color;
    color: lighten($black, 30%);
  }
}

.down {
  position: absolute;
  right: 2px;
  top: 27px;
}

.drop {
  cursor: pointer;
}

@media (min-width: 992px) {
  .animate {
    animation-duration: 0.3s;
    -webkit-animation-duration: 0.3s;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(170px);
    opacity: 0;
  }

  100% {
    transform: translateY(115px);
    opacity: 1;
  }

  0% {
    transform: translateY(170px);
    opacity: 0;
  }
}

@-webkit-keyframes slideIn {
  0% {
    -webkit-transform: transform;
    -webkit-opacity: 0;
  }

  100% {
    -webkit-transform: translateY(115px);
    -webkit-opacity: 1;
  }

  0% {
    -webkit-transform: translateY(170px);
    -webkit-opacity: 0;
  }
}

.slideIn {
  -webkit-animation-name: slideIn;
  animation-name: slideIn;
}</style>
