var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.data.map)?_c('div',{staticClass:"wrap"},[_c('div',{staticClass:"row head"},[_c('img',{staticClass:"profile",attrs:{"src":_vm.commenterPhoto,"alt":"Profile"},on:{"error":(e) => (e.target.src = _vm.$defaultCardAvatar)}}),_c('div',{staticClass:"info"},[_c('div',[_c('span',{staticClass:"name"},[_vm._v(_vm._s(_vm.name))])])])]),_c('div',{staticClass:"row info"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col"},[_vm._v(_vm._s(_vm.data.map.Positive))])])]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"row"},[_vm._m(1),_c('div',{staticClass:"col"},[_vm._v(_vm._s(_vm.data.map.Neutral))])])]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"row"},[_vm._m(2),_c('div',{staticClass:"col"},[_vm._v(_vm._s(_vm.data.map.Negative))])])]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"row"},[_vm._m(3),_c('div',{staticClass:"col"},[_vm._v(_vm._s(_vm.data.map.Questioned))])])]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"row"},[_vm._m(4),_c('div',{staticClass:"col"},[_vm._v(_vm._s(_vm.data.map.Hidden))])])]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"row"},[_vm._m(5),_c('div',{staticClass:"col"},[_vm._v(_vm._s(_vm.data.map.Unassigned))])])])]),_c('div',{staticClass:"desc",on:{"click":_vm.goToProfile}},[_vm._v(" "+_vm._s(_vm.data.description)+" "),_c('img',{attrs:{"src":require("@/assets/images/link.svg"),"alt":"Detail"},on:{"error":(e) => (e.target.src = _vm.$defaultCardAvatar)}}),_c('span',{staticClass:"float-right"},[_vm._v(_vm._s(_vm.data.count))])])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-8 pr-0"},[_c('i',{staticClass:"dot positive"}),_vm._v(" Positive ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-8 pr-0"},[_c('i',{staticClass:"dot neutral"}),_vm._v(" Neutral ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-8 pr-0"},[_c('i',{staticClass:"dot negative"}),_vm._v(" Negative ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-8 pr-0"},[_c('i',{staticClass:"dot question"}),_vm._v(" Questions ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-8 pr-0"},[_c('i',{staticClass:"dot hidden"}),_vm._v(" Hidden ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-8 pr-0"},[_c('i',{staticClass:"dot"}),_vm._v(" Not Assigned ")])
}]

export { render, staticRenderFns }