<template>
  <svg
    id="_005-happy-1"
    data-name="005-happy-1"
    xmlns="http://www.w3.org/2000/svg"
    width="16.001"
    height="15.997"
    viewBox="0 0 16.001 15.997"
  >
    <path
      id="Path_715"
      data-name="Path 715"
      d="M75.914,201.132c.037,0,.074,0,.111,0a1.66,1.66,0,1,0-1.008-.343,3.915,3.915,0,0,1-6.488-.025,1.657,1.657,0,1,0-1.04.368c.053,0,.106,0,.157-.008a4.915,4.915,0,0,0,1.589,1.6,4.833,4.833,0,0,0,2.545.716A4.758,4.758,0,0,0,75.914,201.132Zm.111-2.374a.72.72,0,0,1,0,1.441.706.706,0,0,1-.72-.72A.721.721,0,0,1,76.025,198.758Zm-9.255.72a.72.72,0,1,1,.72.72A.721.721,0,0,1,66.769,199.479Z"
      transform="translate(-63.775 -191.641)"
      fill="#5a5a5a"
    />
    <path
      id="Path_716"
      data-name="Path 716"
      d="M114.7,106.9a.469.469,0,0,0,.469-.469.676.676,0,0,1,1.352,0,.469.469,0,1,0,.937,0,1.613,1.613,0,0,0-3.227,0A.469.469,0,0,0,114.7,106.9Z"
      transform="translate(-110.659 -101.545)"
      fill="#5a5a5a"
    />
    <path
      id="Path_717"
      data-name="Path 717"
      d="M294.984,106.9a.469.469,0,0,0,.469-.469.676.676,0,0,1,1.352,0,.469.469,0,1,0,.937,0,1.613,1.613,0,1,0-3.227,0A.469.469,0,0,0,294.984,106.9Z"
      transform="translate(-285.311 -101.545)"
      fill="#5a5a5a"
    />
    <path
      id="Path_718"
      data-name="Path 718"
      d="M15.719,10.113A7.36,7.36,0,0,0,16,8.065,8,8,0,0,0,2.345,2.41,7.946,7.946,0,0,0,0,8.065a8.625,8.625,0,0,0,.3,2.027A1.419,1.419,0,0,0,.157,11.61l.4.795a4.383,4.383,0,0,1,.472,1.973v1.216a.469.469,0,0,0,.469.469H3.444a.469.469,0,0,0,.469-.469v-.643A7.748,7.748,0,0,0,8,16.064a7.892,7.892,0,0,0,4.089-1.118v.648a.469.469,0,0,0,.469.469H14.5a.469.469,0,0,0,.469-.469V14.378a4.385,4.385,0,0,1,.472-1.973l.4-.795a1.419,1.419,0,0,0-.126-1.5ZM2.975,15.125H1.969v-.747a5.326,5.326,0,0,0-.573-2.4l-.4-.795a.489.489,0,0,1,.862-.461l.557.988a4.372,4.372,0,0,1,.563,2.144Zm.937-1.3a5.311,5.311,0,0,0-.683-2.574l-.557-.988A1.434,1.434,0,0,0,1.133,9.57a7.529,7.529,0,0,1-.194-1.5A7.061,7.061,0,0,1,12.994,3.073a7.015,7.015,0,0,1,2.068,4.993,6.15,6.15,0,0,1-.173,1.509,1.434,1.434,0,0,0-1.558.689l-.557.988a5.311,5.311,0,0,0-.683,2.572A6.939,6.939,0,0,1,8,15.126a6.8,6.8,0,0,1-4.089-1.3Zm11.1-2.64-.4.795a5.326,5.326,0,0,0-.573,2.4v.747H13.027V13.856a4.372,4.372,0,0,1,.563-2.144l.557-.988a.489.489,0,0,1,.862.461Z"
      transform="translate(0 -0.067)"
      fill="#5a5a5a"
    />
  </svg>
</template>

<script>
export default {
  name: "happiness"
};
</script>
