<template>
  <div class="wrap">
    <div class="row head">
      <img
        class="profile"
        alt="Profile"
        :src="account.imageUrl || profilePhoto || $defaultCardAvatar"
        @click="goToProfile"
        @error="(e) => (e.target.src = $defaultCardAvatar)"
      />
      <div class="info">
        <div>
          <span class="name" @click="goToProfile">{{
            account.accountName
          }}</span>
        </div>
        <div>
          <span class="slug" @click="goToProfile">
            {{ account.accountName | username }}
          </span>
        </div>
      </div>
      <div class="icon" @click="goToProfile">
        <facebookIcon
          :active="'true'"
          v-if="
            getActiveSocialMedia
              ? getActiveSocialMedia == 'facebook'
              : socialmedia == 'facebook'
          "
        ></facebookIcon>
        <instagramIcon
          :active="'true'"
          v-if="
            getActiveSocialMedia
              ? getActiveSocialMedia == 'instagram'
              : socialmedia == 'instagram'
          "
        ></instagramIcon>
        <twitterIcon
          :active="'true'"
          v-if="
            getActiveSocialMedia
              ? getActiveSocialMedia == 'twitter'
              : socialmedia == 'twitter'
          "
        ></twitterIcon>
        <youtubeIcon
          :active="'true'"
          v-if="
            getActiveSocialMedia
              ? getActiveSocialMedia == 'youtube'
              : socialmedia == 'youtube'
          "
        ></youtubeIcon>
      </div>
    </div>
    <div class="body">
      <div class="row info">
        <div class="col">
          <div class="row">
            <div class="col-12 mb-2">
              <span class="h6">Blocked Users</span>
            </div>
          </div>
          <div class="row details">
            <div class="col-9 pr-0">
              <img
                class="mr-1"
                src="@/assets/images/negative-comment-user.svg"
                height="20"
                width="20"
              />
              Hate speech
            </div>
            <div class="col p-0">
              {{ intToString(graph.NEGATIVE_COMMENT_USER) }}
            </div>
          </div>
          <div class="row details">
            <div class="col-9 pr-0">
              <img
                class="mr-1"
                src="@/assets/images/abusive-user.svg"
                height="20"
                width="20"
              />
              Profanity
            </div>
            <div class="col p-0">{{ intToString(graph.ABUSIVE_USER) }}</div>
          </div>
          <div class="row details">
            <div class="col-9 pr-0">
              <img
                class="mr-1"
                src="@/assets/images/ads-content-user.svg"
                height="20"
                width="20"
              />
              Ad content
            </div>
            <div class="col p-0">
              {{ graph.AD_CONTENT_USER }}
            </div>
          </div>
<div class="break"></div>
          <div class="row">
            <div class="col-12 mb-2">
              <span class="h6">Hidden Comments</span>
            </div>
          </div>
          <div class="row details">
            <div class="col-9 pr-0">
              <img
                class="mr-1"
                src="@/assets/images/ads-content-comment.svg"
                height="20"
                width="20"
              />
              Hate speech
            </div>
            <div class="col p-0">
              {{ intToString(graph.NEGATIVE_COMMENT) }}
            </div>
          </div>
          <div class="row details">
            <div class="col-9 pr-0">
              <img
                class="mr-1"
                src="@/assets/images/abusive-comment.svg"
                height="20"
                width="20"
              />
              Profanity
            </div>
            <div class="col p-0">{{ intToString(graph.ABUSIVE_COMMENT) }}</div>
          </div>
          <div class="row details">
            <div class="col-9 pr-0">
              <img
                class="mr-1"
                src="@/assets/images/ads-content-comment.svg"
                height="20"
                width="20"
              />
              Ad Content
            </div>
            <div class="col p-0">
              {{ intToString(graph.AD_CONTENT_COMMENT) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import status from "@/components/dot";
import { mapGetters, mapActions } from "vuex";
import facebookIcon from "@/components/icons/facebook";
import instagramIcon from "@/components/icons/instagram";
import twitterIcon from "@/components/icons/twitter";
import youtubeIcon from "@/components/icons/youtube";
import router from "@/router";
import { HTTP } from "@/main-source";

export default {
  name: "general-item",
  data() {
    return {
      title: "general-item",
      chartData: null,
      hasData: false,
      profilePhoto: this.$defaultCardAvatar,
    };
  },
  props: [
    "graph",
    "account",
    "type",
    "socialmedia",
    "totals",
    "range",
    "start",
    "finish",
  ],
  components: {
    status,
    facebookIcon,
    instagramIcon,
    twitterIcon,
    youtubeIcon,
  },

  computed: {
    ...mapGetters(["getActiveSocialMedia"]),
  },
  methods: {
    ...mapActions(["setActiveSocialMedia", "setActiveSocialMediaAccount"]),
    goToProfile: function () {
      if (this.$route.name == "home") {
        localStorage.removeItem(
          "lastSelected" + this.type.toLowerCase() + "Account"
        );
        this.setActiveSocialMedia(this.type.toLowerCase());
        this.setActiveSocialMediaAccount(this.account.accountId);

        router.push({
          name: "ai-reports",
          params: {
            socialmedia: this.type.toLowerCase(),
            accountId: this.account.accountId,
          },
        });
      }
    },
    intToString: function (value) {
      return this.$intToString(value);
    },
    dateFormat: function () {
      let syncDate = new Date(this.account.commentLastSyncDate);
      let result;
      switch (this.range) {
        case "ALL":
          result = syncDate;
          break;
        case "LAST7":
          result = new Date(Date.now() - 6 * 24 * 60 * 60 * 1000);
          break;
        case "LAST24":
          result = new Date(Date.now() - 24 * 60 * 60 * 1000);
          break;
      }

      if (this.start != null && this.finish != null) {
        result =
          new Date(this.start).toLocaleDateString("tr-TR") +
          " - " +
          new Date(this.finish).toLocaleDateString("tr-TR");
      } else {
        result = "Since " + result.toLocaleDateString("tr-TR");
      }

      return result;
    },

    setData: function () {
      var cData = [];
      cData.push(this.graph.NEGATIVE_COMMENT_USER);
      cData.push(this.graph.ABUSIVE_USER);
      cData.push(this.graph.AD_CONTENT_COMMENT);
      cData.push(this.graph.ABUSIVE_COMMENT);
      cData.push(this.graph.AD_CONTENT_USER);

      let labels = [
        "Negative Comment User",
        "Abusive User",
        "Ad Content Comment",
        "Abusive Comment",
        "Ad Content User",
      ];

      this.chartData = {
        labels: labels,
        datasets: [
          {
            backgroundColor: [
              "#00a1ed",
              "#ffd800",
              "#f00",
              "#a54cff",
              "#edc7a2",
              "#a2a2a2",
            ],
            data: cData,
          },
        ],
      };
    },
  },
  created() {
    this.setData();
    console.log(this.graph);
    if (this.$route.name == "home") {
      if (
        this.type == "facebook" ||
        this.type == "FACEBOOK" ||
        this.type == "twitter" ||
        this.type == "TWITTER"
      ) {
        // Set Comment Author Photo

        HTTP.get(
          "/picture/" +
            this.type.toLowerCase() +
            "?accountid=" +
            this.account.accountId +
            "&authorId=" +
            this.account.accountId
        ).then((result) => {
          if (result.status == 200) {
            this.profilePhoto = result.data.url;
          }
        });

        // Set Comment Author Photo End
      }
    } else {
      if (
        this.getActiveSocialMedia == "facebook" ||
        this.getActiveSocialMedia == "FACEBOOK" ||
        this.getActiveSocialMedia == "twitter" ||
        this.getActiveSocialMedia == "TWITTER"
      ) {
        // Set Comment Author Photo

        HTTP.get(
          "/picture/" +
            this.getActiveSocialMedia +
            "?accountid=" +
            this.account.accountId +
            "&authorId=" +
            this.account.accountId
        ).then((result) => {
          if (result.status == 200) {
            this.profilePhoto = result.data.url;
          }
        });

        // Set Comment Author Photo End
      }
    }
  },
  watch: {
    graph: function () {
      this.setData();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/colors.scss";
.wrap {
  background: $white;
  min-height: 40px;
  border-radius: 30px;
  padding: 10px 15px;
}
.head {
  padding: 5px 15px 5px 15px;
  // border-bottom: 1px solid darken($white, 20%);
  display: block;
  position: relative;
  .profile {
    width: 45px;
    height: 45px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    float: left;
    margin-right: 10px;
  }
  .name {
    font-weight: bold;
  }
  .city {
    font-size: 12px;
    position: relative;
    top: -5px;
  }
  .icon {
    position: absolute;
    right: 20px;
    top: 10px;
    width: auto;
    height: 40px;
    svg {
      width: 30px;
      height: auto;
      display: block;
      overflow: visible;
    }
  }
}
.body {
  padding: 10px 0px 0px;
  font-size: 13px;
  .title {
    display: block;
    color: darken($white, 40%);
    font-weight: bold;
    text-align: center;
  }
  .subtitle {
    display: block;
    color: darken($white, 40%);
    text-align: center;
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
  }
  .details {
    margin-bottom: 10px;
    color: $darker;
    i {
      float: left;
      margin-right: 5px;
    }
    line-height: 20px;
  }
  .info {
    padding-top: 10px;
    min-height: 200px;
    .col {
      padding-right: 0;
    }
  }
}
.graph {
  width: 180px;
  height: auto;
  margin: -5px auto 0px auto;
}
@media (max-width: 767.98px) {
  .body {
    .info {
      flex-wrap: wrap-reverse;
    }
    .graph {
      margin: auto;
    }
  }
}
@media screen and (max-device-width: 1450px) {
  .graph {
    width: 140px;
    height: auto;
    margin-top: 20px;
  }
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
#sync {
  position: absolute;
  width: 30px;
  height: 30px;
  right: 12px;
  bottom: 0px;
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}
#hasError {
  color: $brand-orange;
  position: absolute;
  right: 20px;
  bottom: 10px;
}
</style>