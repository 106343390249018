var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col second"},[(_vm.getActiveSocialMedia != '')?_c('div',[(_vm.accountList.length == 0)?_c('div',{staticClass:"social-account-menu add-new-account"},[_c('router-link',{attrs:{"to":{
        name: 'home',
        query: {
          addAccount: 'true',
        },
      }},nativeOn:{"click":function($event){_vm.setActiveSocialMedia('');
        _vm.closeOnMobile();}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"social-account"},[_c('div',{staticClass:"col-2 col-sm-3 img"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/images/sidebar/new-account.svg"),"alt":"Selected Account"},on:{"error":(e) => (e.target.src = _vm.$defaultCardAvatar)}})]),_c('div',{staticClass:"info col-9"},[_c('span',{staticClass:"name"},[_vm._v("Add Account")])])])])])],1):_vm._e(),(_vm.accountList.length > 0)?_c('div',{staticClass:"social-account-menu",attrs:{"data-toggle":"dropdown"}},[(_vm.selectedAccount != null)?_c('list-item',{staticClass:"row drop",attrs:{"account":_vm.selectedAccount,"disableClick":true}}):_vm._e(),_c('img',{staticClass:"down",attrs:{"src":require("@/assets/images/sidebar/down.svg"),"alt":"Down"},on:{"error":(e) => (e.target.src = _vm.$defaultCardAvatar)}})],1):_vm._e(),(_vm.accountList.length > 0)?_c('div',{staticClass:"dropdown-menu accounts animate slideIn"},[_vm._l((_vm.accountList),function(account){return _c('list-item',{key:account.id,attrs:{"account":account}})}),_c('div',{staticClass:"add text-center",on:{"click":_vm.addAccount}},[_c('img',{attrs:{"src":require("@/assets/images/sidebar/add.svg"),"alt":"Down"},on:{"error":(e) => (e.target.src = _vm.$defaultCardAvatar)}}),_vm._v(" Add Account ")])],2):_vm._e(),_c('div',{staticClass:"sub-menu",class:{ 'disable-events': _vm.accountList.length == 0 },on:{"click":_vm.toggleEffect}},[_c('router-link',{class:_vm.$route.name == 'insights'
            ? 'router-link-exact-active router-link-active'
            : '',attrs:{"to":{
        name: 'ai-reports',
        params: _vm.setParams,
      }},nativeOn:{"click":function($event){return _vm.closeOnMobile.apply(null, arguments)}}},[_vm._v("Reports")]),_c('router-link',{attrs:{"to":{
        name: 'comments',
        params: _vm.setParams,
      }},nativeOn:{"click":function($event){return _vm.closeOnMobile.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.getActiveSocialMedia != "twitter" ? "Comment" : "Reply")+" Analysis ")]),_c('router-link',{attrs:{"to":{
        name: 'posts',
        params: _vm.setParams,
      }},nativeOn:{"click":function($event){return _vm.closeOnMobile.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.postsText)+" ")]),(false)?_c('router-link',{attrs:{"to":{
        name: 'directmessages',
        params: _vm.setParams,
      }},nativeOn:{"click":function($event){return _vm.closeOnMobile.apply(null, arguments)}}},[_vm._v("Direct Messages")]):_vm._e(),(_vm.getActiveSocialMedia != 'youtube' && !_vm.isCompetitor())?_c('router-link',{attrs:{"to":{
        name: 'mentions',
        params: _vm.setParams,
      }},nativeOn:{"click":function($event){return _vm.closeOnMobile.apply(null, arguments)}}},[_vm._v("Mentions ")]):_vm._e(),(!_vm.isCompetitor())?_c('router-link',{attrs:{"to":{
          name: 'ai-actions',
          params: _vm.setParams,
        }},nativeOn:{"click":function($event){return _vm.closeOnMobile.apply(null, arguments)}}},[_vm._v("AI Actions")]):_vm._e(),(!_vm.isCompetitor())?_c('router-link',{attrs:{"to":{
        name: 'account-settings',
        params: _vm.setParams,
      }},nativeOn:{"click":function($event){return _vm.closeOnMobile.apply(null, arguments)}}},[_vm._v("Account Settings")]):_vm._e()],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }