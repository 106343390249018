<template>
  <div id="support--index">
    <div class="row justify-content-center">
      <div v-show="!loaded" class="col-12 col-xl-8">
        <SkeletonLoader />
      </div>
      <div v-show="loaded" class="col-12 col-xl-8">
        <div class="drag ac-settings">
          <div class="setting">
            <div class="head">
              <img @error="(e) => (e.target.src = $defaultCardAvatar)" src="@/assets/images/settings/support.svg" alt="Support" />
              <span>Submit Ticket</span>
            </div>
            <div class="body p-3">
              <div class="support-frame w-full px-2 lg:px-8">
                <iframe v-if="user"
                  class="border-0"
                  @load="iframe.loaded = true"
                  id="liveagent"
                  :src="`https://socialays.ladesk.com/submit_ticket?name=${user.firstName + ' ' + user.lastName}&email=${user.email}`"
                  height="100%"
                  width="100%"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SkeletonLoader from '@/components/placeholders/boxes';
import { HTTP } from '@/main-source';

export default {
  components: {
    SkeletonLoader,
  },

  data() {
    return {
      iframe: {
        loaded: false,
      },
      user: null,
    };
  },

  computed: {
    loaded() {
      return this.user && this.iframe.loaded;
    },
  },

  async mounted() {
    const { response } = await this.$async(HTTP.get('/user/me'));
    this.user = response.data;
  },
};
</script>

<style lang="scss">
#support--index .support-frame {
  height: 500px;
}
</style>
