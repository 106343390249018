import axios from 'axios';
import router from '@/router';
import EventBus from '@/components/event-bus';
// baseURL: 'http://192.168.1.108:8080/api/',
// baseURL: "https://api.socialays.com/api"
// baseURL: 'http://1e31a5f417e6.ngrok.io',
export const HTTP = axios.create({
  baseURL: 'https://api.socialays.com/api-v2/',
});

let token = localStorage.token;

if (token) {
  HTTP.defaults.headers.common.Authorization = 'Bearer ' + token;
}

HTTP.interceptors.response.use(
  function (response) {
    if (response.data.socialMediaAccounts || response.data.authorizedAccounts) {
      let sum =
        response.data.socialMediaAccounts.length +
        response.data.authorizedAccounts.length;
      localStorage.setItem("accountStatus", JSON.stringify({ status: response.data.status, count: sum }));
    }
    return response;
  },
  function (error) {
    if (error.response.status == 401) {
      localStorage.removeItem('token');
      HTTP.defaults.headers.common.Authorization = '';

      router.push({
        name: 'login',
      });
    }

    return Promise.reject(error);
  }
);
