<template>
  <div class="chart-card">
    <div class="chart-header">
      <span class="count"> 120 </span>
      <span class="sub-title">Post Interactions</span>
      <span class="d-block chart-desc">
        <status></status> %20 Toplam Sayfa Görüntülenmeleri
      </span>
    </div>

    <bar-chart
      :graph="graph"
      class="Bar-chart"
      :options="options"
      :height="180"
    ></bar-chart>
  </div>
</template>
<script>
import BarChart from "@/components/charts/components/HorizantalBar";
import statusTriangle from "@/components/icons/triangle";

export default {
  name: "Bar",
  props: ["graph"],
  components: {
    "bar-chart": BarChart,
    status: statusTriangle,
  },
  data() {
    return {
      options: {
        showAllTooltips: false,
        responsive: true,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              ticks: {
                beginAtZero: true,
                // callback: (value) => numeral(value).format("$0,0"),
              },
            },
          ],
        },
      },
    };
  },
};
</script>
