<template>
  <div>
    <div class="col-12 wrap cursor-default">
      <div class="mention pb-0">
        <div class="d-flex justify-content-between">
          <div class="d-flex" style="align-items: start">
            <i class="mr-1" style="margin-top: 3px" :class="iconClass"></i>
            <span class="d-flex flex-column">
              <span class="name">
                <span v-if="action.data.refCollection !== 'COMMENT'">
                  {{ action.data.content }}
                </span>
                <span
                  :class="{
                    'text-lowercase': action.data.refCollection !== 'COMMENT',
                  }"
                >
                  {{ operationText }}
                </span>
                due to {{ causeText }}
              </span>
              <small class="text-muted"> on {{ actionDate }} </small>
            </span>
          </div>
          <div class="d-flex justify-content-end" style="min-width: 220px">
            <div>
              <button
                style="min-width: 110px"
                class="btn btn-default btn-ai-action mr-2 btn-danger--hovered"
                @click="cancelAction(action.data)"
              >
                Cancel AI-Action
              </button>
            </div>
            <div>
              <button
                v-if="action.data.refCollection !== 'AUTHOR'"
                class="btn btn-default btn-ai-action btn-danger--hovered"
                @click="$emit('delete', action.data)"
              >
                Delete Now
              </button>
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="events-none">
          <comment
            v-if="action.relatedSocialMediaDocument"
            :id="action.relatedSocialMediaDocument.id"
            :commentId="action.relatedSocialMediaDocument.id"
            :comment="action.relatedSocialMediaDocument"
            :name="action.relatedSocialMediaDocument.authorName"
            :profilePic="action.relatedSocialMediaDocument.authorProfilePicUrl"
            :postImageUrl="action.relatedSocialMediaDocument.postImageUrl"
            :location="action.relatedSocialMediaDocument.locations"
            :content="action.relatedSocialMediaDocument.content"
            :countOfReplies="action.relatedSocialMediaDocument.numOfReplies"
            :hidden="action.relatedSocialMediaDocument.hidden"
            :isQuestion="action.relatedSocialMediaDocument.isQuestion"
            :isSpam="action.relatedSocialMediaDocument.isSpam"
            :polarity="action.relatedSocialMediaDocument.polarity"
            :polarityStrings="
              action.relatedSocialMediaDocument.polarityStringsGpt
                ? action.relatedSocialMediaDocument.polarityStringsGpt
                : action.relatedSocialMediaDocument.polarityStrings
            "
            :commentImgs="action.relatedSocialMediaDocument.imageUrl"
            :isLiked="action.relatedSocialMediaDocument.liked"
            :activeAccountDetails="activeAccountDetails"
            :likeCount="action.relatedSocialMediaDocument.numOfLikes"
            :authorId="action.relatedSocialMediaDocument.authorId"
            :date="action.relatedSocialMediaDocument.createdTime"
            class="comment-item"
            :isManual="action.relatedSocialMediaDocument.isManual"
            :disableAction="true"
          >
          </comment>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import comment from "@/components/facebook/comment.vue";
import EventBus from "@/components/event-bus";
import { fromUnixTime, format } from "date-fns";

export default {
  name: "action",
  props: ["action", "activeAccountDetails"],
  data() {
    return {};
  },
  components: {
    comment,
  },
  methods: {
    cancelAction: function(aiaction) {
      EventBus.$emit("cancelAction", aiaction);
    },
  },
  computed: {
    ...mapGetters(["getActiveSocialMedia"]),
    actionDate() {
      return format(fromUnixTime(this.action.data.actionDate), "MMM d H:mmaaa");
    },

    iconClass() {
      const colorClasses = {
        WILL_BE_BLOCKED: "fas fa-ban text-warning",
        BLOCKED: "fas fa-ban text-danger",
        WILL_BE_HIDDEN: "fas fa-eye-slash text-warning font-weight-normal",
        HIDDEN: "fas fa-eye-slash text-danger font-weight-normal",
      };

      return colorClasses[this.action.data.type];
    },

    operationText: function() {
      let operationName = "";
      switch (this.action.data.type) {
        case "DELETED":
          operationName = "Deleted";
          break;
        case "WILL_BE_BLOCKED":
          operationName = "Will be blocked";
          break;
        case "BLOCKED":
          operationName = "Blocked";
          break;
        case "WILL_BE_HIDDEN":
          operationName = "Will be hidden";
          break;
        case "HIDDEN":
          operationName = "Hidden";
          break;
        case "ROLL_BACKED":
          operationName = "Roll backed";
          break;
        case "CANCELED":
          operationName = "Canceled";
          break;
      }
      return operationName;
    },
    causeText: function() {
      let causeText = "";
      switch (this.action.data.cause) {
        case "ABUSIVE_COMMENT":
          causeText = "profanity";
          break;
        case "AD_CONTENT_COMMENT":
          causeText = "ad content";
          break;
        case "HATE_SPEECH_COMMENT":
          causeText = "hate speech";
          break;
        case "NEGATIVE_COMMENT":
          causeText = "negative content";
          break;
        case "ABUSIVE_USER":
          causeText = "profanity";
          break;
        case "AD_CONTENT_USER":
          causeText = "ad content";
          break;
        case "NEGATIVE_COMMENT_USER":
          causeText = "hate speech";
          break;
      }
      // Multiple Cause
      if (this.action.data.causeList) {
        if (this.action.data.causeList.length == 2) {
          causeText = "profanity and hate speech";
        }
      }
      return causeText;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/colors.scss";

.wrap {
  border-radius: 30px;
  background: $white;

  &:hover {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
  }

  padding: 0;
  margin-bottom: 20px;
  transition: all 0.25s ease;
  cursor: pointer;

  &:hover {
    transform: scale(1.01);
    // box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  }

  .mention {
    min-height: 80px;
    padding: 15px;

    img {
      width: 15px;
      height: 15px;
      margin-right: 5px;
      margin-top: -4px;
    }

    .info {
      float: left;
    }

    .profile {
      width: 45px;
      height: 45px;
      object-fit: cover;
      object-position: center;
      border-radius: 50%;
      float: left;
      margin-right: 10px;
    }

    .name {
      font-weight: bold;
    }

    .city {
      font-size: 12px;
      position: relative;
      top: -5px;
    }

    .imgs {
      width: 100%;
      clear: both;
      position: relative;
      margin-top: 20px;

      img {
        width: 100px;
        height: 100px;
        object-fit: cover;
        object-position: center;
        border-radius: 5px;
      }
    }
  }

  .bottom {
    width: 100%;
    padding: 10px 17px;
    border-top: 1px solid lighten($black, 95%);
    display: flex;
    flex-wrap: wrap;
    font-size: 12px;
    position: relative;
    justify-content: space-between;
  }

  .text {
    padding: 0;
    margin-top: 5px;
  }
}

.status {
  .dot {
    float: left;
    margin-right: 2px;
    transform: scale(0.8);
  }
}

.statuses {
  display: flex;
  padding: 0;
  justify-content: space-around;
  margin: auto;

  &:after {
    clear: both;
  }

  .status {
    margin-right: 10px;
    line-height: 19px;
  }
}

.quick-actions {
  img {
    margin-left: 10px;
    transition: all 0.25s ease;

    &:hover {
      transform: scale(1.1);
    }
  }
}

.reply-wrap {
  margin: 0;
  height: 0;
  transition: all 0.3s ease;
  opacity: 0;
}

.reply-wrap.show {
  opacity: 1;
  height: auto;
}

.reply {
  border-radius: 15px;
  background: $white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
  min-height: 40px;
  margin-bottom: 20px;
  padding: 10px;

  .info {
    float: left;

    div {
      display: block;
    }
  }

  .profile {
    width: 45px;
    height: 45px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    float: left;
    margin-right: 10px;
  }

  .name {
    font-weight: bold;
  }

  .city {
    font-size: 12px;
    position: relative;
    top: -5px;
  }

  textarea {
    border: 0;
    resize: none;
    margin: 0;
    margin: 0px -10px;

    &:focus {
      box-shadow: none;
    }
  }

  .close {
    margin-top: -5px;

    i {
      font-size: 14px;
    }
  }
}

.showReplies {
  transition: all 0.25s ease;
}

.hide {
  opacity: 0;
  z-index: -1;
}

.attention {
  text-align: center;
  font-size: 12px;
  background: $white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
  margin: 0;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  border-top: 1px solid lighten($black, 95%);
  padding: 0px;
  height: 0px;
  transition: all 0.2s ease;
  overflow: hidden;
}

.show {
  padding: 5px;
  height: auto;
}

.saveStatus {
  opacity: 0;
  z-index: -1;
  position: absolute;
  top: 7px;
  right: 0px;
  text-align: right;

  .btn {
    padding: 0px 15px;
    margin-right: 5px;
    border-radius: 15px;
    transition: all 0.2s ease;

    &:hover {
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
    }
  }

  .btn-default {
    background: darken($white, 5%);
  }
}

.showStatusEdit {
  opacity: 1;
  z-index: 9;
}

@media (max-width: 767.98px) {
  .status {
    .dot {
      float: none !important;
    }
  }
}

@media screen and (max-device-width: 1450px) {
  .bottom {
    font-size: 10px !important;
  }
}

.btn-ai-action {
  font-size: 12px;
  padding: 2px 10px;
}

.events-none {
  pointer-events: none !important;
}
</style>
