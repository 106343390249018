<script>
import { HorizontalBar } from "vue-chartjs";

export default {
  name: "Bar",
  extends: HorizontalBar,

  props: ["graph", "options"],

  mounted() {
    this.renderChart(
      {
        labels: ["1", "2"],
        datasets: [
          {
            data: this.graph,
            borderColor: ["rgba(2, 150, 60, .3)"],
            backgroundColor: [
              "rgba(0, 100, 155, 0.8)",
              "rgba(255, 108, 55, 0.8)",
              "rgba(196, 13, 60, 0.8)",
              "rgba(237, 199, 162, 0.8)",
              "rgba(2, 150, 60, 0.8)",
              "rgba(2, 150, 60, 0.8)",
              "rgba(2, 150, 60, 0.8)",
            ],
          },
        ],
      },
      this.options
    );
  },
};
</script>
