<template>
  <div class="contracts-link" :class="{ 'mt-100': $route.name != 'signup' }">
    <router-link :to="{ name: 'privacy-policy' }"> Privacy Policy</router-link>|
    <router-link :to="{ name: 'terms-of-use' }"
      >Terms and Conditions</router-link
    >|
    <router-link :to="{ name: 'contact' }">Contact Us</router-link>
  </div>
</template>

<script>
export default {
  name: "contracts",
};
</script>
