var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"first"},[_c('div',{staticClass:"social-media"},[_c('router-link',{attrs:{"to":{
      name: 'home'
    }},nativeOn:{"click":function($event){_vm.setActiveSocialMedia('');
      _vm.closeOnMobile();}}},[_c('home-icon',{attrs:{"active":[
        _vm.getActiveSocialMedia == '' && _vm.activeRouteName == 'home'
          ? 'true'
          : 'false'
      ]}})],1),_c('router-link',{class:{ selectedSocialMedia: _vm.getActiveSocialMedia == 'facebook' },attrs:{"to":{
      name: 'ai-reports',
      params: {
        socialmedia: 'facebook'
      }
    }},nativeOn:{"click":function($event){return _vm.setActiveSocialMedia('facebook')}}},[_c('facebook-icon',{attrs:{"active":[_vm.getActiveSocialMedia == 'facebook' ? 'true' : 'false']}})],1),_c('router-link',{class:{ selectedSocialMedia: _vm.getActiveSocialMedia == 'instagram' },attrs:{"to":{
      name: 'ai-reports',
      params: {
        socialmedia: 'instagram'
      }
    }},nativeOn:{"click":function($event){return _vm.setActiveSocialMedia('instagram')}}},[_c('instagram-icon',{attrs:{"active":[_vm.getActiveSocialMedia == 'instagram' ? 'true' : 'false']}})],1),(_vm.$testAccounts.includes(_vm.getUser.name))?_c('router-link',{class:{ selectedSocialMedia: _vm.getActiveSocialMedia == 'twitter' },attrs:{"to":{
        name: 'ai-reports',
        params: {
          socialmedia: 'twitter'
        }
      }},nativeOn:{"click":function($event){return _vm.setActiveSocialMedia('twitter')}}},[_c('twitter-icon',{attrs:{"active":[_vm.getActiveSocialMedia == 'twitter' ? 'true' : 'false']}})],1):_vm._e(),_c('router-link',{class:{ selectedSocialMedia: _vm.getActiveSocialMedia == 'youtube' },attrs:{"to":{
      name: 'ai-reports',
      params: {
        socialmedia: 'youtube'
      }
    }},nativeOn:{"click":function($event){return _vm.setActiveSocialMedia('youtube')}}},[_c('youtube-icon',{attrs:{"active":[_vm.getActiveSocialMedia == 'youtube' ? 'true' : 'false']}})],1)],1),_c('img',{staticClass:"vertical-logo",attrs:{"src":require("@/assets/images/logo-vertical.svg"),"alt":"Socialays Logo"},on:{"error":(e) => (e.target.src = _vm.$defaultCardAvatar)}})])
}
var staticRenderFns = []

export { render, staticRenderFns }