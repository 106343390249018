import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    activeSocialMedia: "",
    selectedSocialMediaAccount: null,
    selectedSocialMediaAccountObj: null,
    user: {
      name: ""
    },
    blockedUserList: [],
  },
  mutations: {
    setActiveSocialMedia(state, selectedMedia) {
      state.activeSocialMedia = selectedMedia;
    },
    setActiveSocialMediaAccount(state, selectedAccount) {
      state.selectedSocialMediaAccount = selectedAccount;
    },
    setSlectedSocialMediaAccountObj(state, selectedAccount) {
      state.selectedSocialMediaAccountObj = selectedAccount;
    },
    setUsername(state, userName) {
      state.user.name = userName;
    },
    setBlockedUsers(state, userList) {
      state.blockedUserList = state.blockedUserList.concat(userList);
    },
    clearList(state) {
      state.blockedUserList = [];
    },
    removeUserFromList(state, userId) {
      state.blockedUserList = state.blockedUserList.filter(user => user != userId);
    }
  },
  actions: {
    setActiveSocialMedia(context, selectedMedia) {
      context.commit("setActiveSocialMedia", selectedMedia);
    },
    setActiveSocialMediaAccount(context, selectedAccount) {
      context.commit("setActiveSocialMediaAccount", selectedAccount);
    },
    setActiveSocialMediaAccountObj(context, selectedAccount) {
      context.commit("setSlectedSocialMediaAccountObj", selectedAccount);
    },
    setUsername(context, userName) {
      context.commit("setUsername", userName);
    },
    setBlockedUserList(context, userList) {
      context.commit("setBlockedUsers", userList);
    },
    clearBlockedUserList(context) {
      context.commit("clearList", true);
    },
    removeUserFromList(context, userId) {
      context.commit("removeUserFromList", userId);
    },
  },
  modules: {},
  getters: {
    getActiveSocialMedia: state => {
      return state.activeSocialMedia;
    },
    getActiveSocialMediaAccount: state => {
      return state.selectedSocialMediaAccount;
    },
    getActiveSocialMediaAccountObj: state => {
      return state.selectedSocialMediaAccountObj;
    },
    getUsername: state => {
      return state.user.name;
    },
    getUser: state => {
      return state.user;
    },
    getBlockedUserList: state => {
      return state.blockedUserList;
    }
  }
});
