<template>
  <div class="setting account">
    <div class="head">
      <img @error="(e) => (e.target.src = $defaultCardAvatar)" src="@/assets/images/settings/socialaccounts.svg" alt="Accounts" />
      <span>Authorized Accounts</span>
    </div>
    <div class="body l-accounts" :class="{ 'no-data': accounts.length == 0 }">
      <span v-if="accounts.length == 0">
        No authorized accounts
      </span>
      <account
        v-for="(account, index) in accounts"
        :key="index"
        :account="account"
        :user="user"
        :authorizedItem="true"
        :linked="false"
      ></account>
    </div>
  </div>
</template>
<script>
import ListItem from "@/components/settings/Linked-Account-Item";

export default {
  name: "Authorized-Accounts",
  props: ["accounts","user"],
  components: {
    account: ListItem
  }
};
</script>

<style lang="scss">
@import "../../scss/colors.scss";

.l-accounts {
  width: calc(100% - 30px);
  // padding: 10px;
  top: -7px;
  padding: 0;
  overflow: hidden;
  .img {
    img {
      width: 50px;
      height: 50px;
    }
  }
  .info {
    padding-top: 0 !important;
  }
  .social-account {
    padding: 10px;

    transition: all 0.2s ease;
    border-radius: 30px;
    margin-bottom: 10px;
    cursor: pointer;
    &:hover {
      background: lighten($black, 90%);
    }
    &:last-child {
      border-bottom: 0;
      margin-bottom: 0px;
    }
  }
}
</style>
