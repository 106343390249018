<template>
  <div>
    <div class="container login p-0" v-if="true">
      <span class="h1">Create an account</span>
      <div class="form">
        <div class="form-group">
          <input maxLength="70" type="text" @blur="$v.user.email.$touch()" v-model="user.email" class="form-control"
            :class="{ 'invalid-b': $v.user.email.$error }" placeholder="e-mail" />
          <div v-if="$v.user.email.$error">
            <span class="invalid" v-if="!$v.user.email.required">Email required</span>
            <span class="invalid" v-if="!$v.user.email.email">Please provide a valid email.</span>
          </div>
        </div>
        <div class="form-group">
          <input maxLength="70" type="password" @blur="$v.user.password.$touch()" v-model="user.password"
            class="form-control" :class="{ 'invalid-b': $v.user.password.$error }" placeholder="Password" max="20" />
          <div v-if="$v.user.password.$error">
            <span class="invalid" v-if="!$v.user.password.required">Password required</span>
            <span class="invalid" v-if="!$v.user.password.minLength">Password min 5 charaters</span>
            <span class="invalid" v-if="!$v.user.password.maxLength">Password max 20 charaters</span>
            <!-- <span class="invalid" v-if="!$v.user.password.containsLowercase"
              >Password must contain lowercase charater</span
            >
            <span class="invalid" v-if="!$v.user.password.containsUppercase"
              >Password must contain uppercase charater</span
            >
            <span class="invalid" v-if="!$v.user.password.containsNumber"
              >Password must contain one number</span
            >
            <span class="invalid" v-if="!$v.user.password.containsSpecial"
              >Password must contain special character</span
            > -->
          </div>
        </div>

        <div class="col mt-4" v-if="serverFault">
          <span class="invalid">{{ serverFault }}</span>
        </div>

        <button class="btn btn-primary btn-block" @click="create" :disabled="isLoading">
          Create <Loading :white="true" v-if="isLoading"></Loading>
        </button>
      </div>

      <social-login></social-login>
    </div>
    <div class="container login p-0" v-if="false">Coming Soon</div>
    <div class="col authAction">
      Already have an account?
      <router-link :to="{
        name: 'login',
      }">
        Sign in.</router-link>
      <p class="m-0 mt-4 accepted-contacts">
        By creating account I accept Socialays's
        <router-link :to="{ name: 'terms-of-use' }">Terms and Conditions
        </router-link>
        and
        <router-link :to="{ name: 'privacy-policy' }">Privacy Policy</router-link>
      </p>
      <Contracts></Contracts>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import { HTTP } from "@/main-source";
import Loading from "@/components/Loading";
import {
  required,
  email,
  minLength,
  maxLength,
  sameAs,
} from "vuelidate/lib/validators";

import Contracts from "@/components/contracts";
import SocialLogin from "@/components/Social-Login.vue";

export default {
  name: "signup",
  data() {
    return {
      user: {
        email: "",
        password: "",
        repassword: "",
      },
      serverFault: "",
      redirectURL: this.$redirectURL,
      isLoading: false,
    };
  },
  validations: {
    user: {
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(20),
        // containsUppercase: function (value) {
        //   return /[A-Z]/.test(value);
        // },
        // containsLowercase: function (value) {
        //   return /[a-z]/.test(value);
        // },
        // containsNumber: function (value) {
        //   return /[0-9]/.test(value);
        // },
        // containsSpecial: function (value) {
        //   return /[#?!@$%^&*-.]/.test(value);
        // },
      },
    },
  },
  components: {
    Loading,
    Contracts,
    SocialLogin,
  },
  methods: {
    create: function () {
      this.$v.user.$touch();

      if (!this.$v.user.$error) {
        this.isLoading = true;
        HTTP.post("/auth/signup", this.user)
          .then((result) => {
            HTTP.defaults.headers.common["Authorization"] =
              "Bearer " + result.data.accessToken;
            localStorage.token = result.data.accessToken;
            this.isLoading = false;
            setTimeout(() => {
              window.location = this.$redirectURL + "welcome";
            }, 400);
          })
          .catch((e) => {
            this.isLoading = false;
            this.serverFault = e.response.data.message;
          });
      }
    },
    checkOnlyTextOrSpace: function (event) {
      return /[a-zA-ZğüşöçİĞÜŞÖÇ]+$/i.test(event.key) || event.keyCode == 32
        ? true
        : event.preventDefault();
    },
    loginWith: function (socialApp) {
      window.location =
        "https://api.socialays.com/api-v2/oauth2/authorize/" +
        socialApp +
        "?redirect_uri=" +
        this.redirectURL +
        "oauth2/callback";
    },
  },
};
</script>
