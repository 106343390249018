<template>
  <div class="wrap" :class="{ text: data.data.imageUrl ? false : true }">
    <div class="cover-over placeholder-animate" v-if="imageProcess">
      <Loading></Loading>
    </div>
    <img @error="(e) => (e.target.src = $defaultCardAvatar)" class="post-image" :src="featuredImage"
      v-if="featuredImage" />

    <div class="bg" v-if="featuredImage"></div>
    <div class="t" v-if="!featuredImage">" {{ data.data.content }} "</div>

    <div class="desc" :class="{ black: !featuredImage }" @click="goToDetail">
      {{ data.description }}
      <img @error="(e) => (e.target.src = $defaultCardAvatar)" src="@/assets/images/link-w.svg" v-if="data.data.imageUrl"
        alt="Detail" @click="goToDetail" />
      <img @error="(e) => (e.target.src = $defaultCardAvatar)" src="@/assets/images/link.svg" v-if="!data.data.imageUrl"
        alt="Detail" @click="goToDetail" />
      <span class="float-right">{{ data.count }}</span>
    </div>
  </div>
</template>

<script>
import { HTTP } from "@/main-source";
import { mapGetters } from "vuex";
import router from "@/router";
import Loading from "@/components/Loading";

export default {
  name: "general-most",
  data() {
    return {
      title: "general-most",
      featuredImage: null,
      imageProcess: true,
    };
  },
  components: {
    Loading,
  },
  props: ["data"],
  created() {
    if (this.data.data.imageUrl) {
      this.featuredImage = this.data.data.imageUrl;
    }

    let result = this.data.data.imageUrl ? this.data.data.imageUrl[0] : null;
    if (
      this.getActiveSocialMedia == "instagram" ||
      this.getActiveSocialMedia == "facebook" || (this.isCompetitor() && this.getActiveSocialMedia == "youtube")
    ) {
      HTTP.get(
        "/media/" +
        this.getActiveSocialMedia +
        "?accountid=" +
        this.getActiveSocialMediaAccount +
        "&uniqueid=" +
        this.data.data.id
      )
        .then((xhrResult) => {
          if (xhrResult.status == 200) {
            result =
              xhrResult.data[0].type == "VIDEO"
                ? xhrResult.data[0].videoThumbImage
                : xhrResult.data[0].url;
            if (xhrResult.data[0].type == "VIDEO") {
              this.videoSource = xhrResult.data[0].url;
            }
          }
        })
        .then(() => {
          this.featuredImage = result;
          this.imageProcess = false;
        })
        .catch(() => {
          this.featuredImage = this.$defaultCardImage;
          this.imageProcess = false;
        });
    } else {
      this.imageProcess = false;
    }
  },
  methods: {
    isCompetitor() {
      return this.getActiveSocialMediaAccountObj.type != 'OWN';
    },
    goToDetail: function () {
      router.push({
        name: "posts",
        params: {
          accountId: this.getActiveSocialMediaAccount,
        },
        query: {
          post: this.data.data.id,
        },
      });
    },
  },
  computed: {
    ...mapGetters(["getActiveSocialMediaAccount", "getActiveSocialMedia", "getActiveSocialMediaAccountObj"]),
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/colors.scss";
@import "@/scss/placeholder.scss";

.wrap {
  height: 337px;
  padding: 0;
  position: relative !important;
  overflow: hidden;
  border-radius: 30px;
  background: $white;
  color: $darker;
  margin-bottom: 25px;
}

.post-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  z-index: 1;
}

.bg {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 3;
  background: linear-gradient(rgba(0, 66, 118, 0) 0%, #02223b 100%);
}

.cover-over {
  position: absolute;
  width: 100%;
  height: 240px !important;
  left: 0;
  top: 0;
  z-index: 999;
  z-index: 4;
}

.desc {
  position: absolute;
  width: calc(100% - 40px);
  left: 20px;
  bottom: 15px;
  z-index: 4;
  color: $white;
  font-weight: bold;
  cursor: pointer;

  img {
    cursor: pointer;
  }
}

.black {
  color: $black !important;
}

.text {
  padding: 20px;
  height: auto !important;
}

.t {
  margin-bottom: 20px;
}

.goToDetail {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 999;
  cursor: pointer;
}
</style>
