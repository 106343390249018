<template>
  <div>
    <!-- Filter Desktop -->
    <div class="row filter d-none d-md-block">
      <div class="col-12 left-filter">
        <!-- Left -->
        <div class="col-7 left">
          <div class="col-6 has-icon p-0">
            <i class="fas fa-search"></i>
            <input
              type="text"
              class="search"
              v-model="search"
              placeholder="Search User or Word..."
            />
          </div>

          <div class="col-5 filters p-0 has-icon">
            <i
              class="dot main"
              :class="[
                { all: selectedFilter == 'All Messages' },
                { positive: selectedFilter == 'Positive' },
                { negative: selectedFilter == 'Negative' },
                { neutral: selectedFilter == 'Neutral' },
                { question: selectedFilter == 'Question' },
                { hidden: selectedFilter == 'Hidden' }
              ]"
            ></i>
            <v-select
              v-model="selectedFilter"
              :options="filters"
              :clearable="true"
              :searchable="false"
              :closeOnSelect="false"
              multiple
            >
              <template
                slot="selected-option-container"
                slot-scope="selectedFilter"
              >
                <div>
                  <i
                    class="dot main positive"
                    v-if="selectedFilter.option.label == 'Positive'"
                  ></i>
                  <i
                    class="dot main negative"
                    v-if="selectedFilter.option.label == 'Negative'"
                  ></i>
                  <i
                    class="dot main neutral"
                    v-if="selectedFilter.option.label == 'Neutral'"
                  ></i>
                  <i
                    class="dot main question"
                    v-if="selectedFilter.option.label == 'Question'"
                  ></i>
                  <i
                    class="dot main hidden"
                    v-if="selectedFilter.option.label == 'Hidden'"
                  ></i>
                  <div
                    class="all"
                    v-if="
                      selectedFilter.option.label == 'All Messages' &&
                        selectedFilterCount == 1
                    "
                  >
                    All Messages
                  </div>
                </div>
              </template>

              <template slot="option" slot-scope="option">
                <i
                  class="dot"
                  :class="[
                    { positive: option.label == 'Positive' },
                    { negative: option.label == 'Negative' },
                    { neutral: option.label == 'Neutral' },
                    { question: option.label == 'Question' },
                    { hidden: option.label == 'Hidden' }
                  ]"
                ></i>
                {{ option.label }}
                <!-- <small
									v-if="option.label == 'Positive'"
								>( {{ polarityCounts.positive }} )</small>
								<small v-if="option.label == 'All Messages'">( {{ polarityCounts.totalComments }} )</small>
								<small v-if="option.label == 'Negative'">( {{ polarityCounts.negative }} )</small>
								<small v-if="option.label == 'Neutral'">( {{ polarityCounts.neutral }} )</small>
								<small v-if="option.label == 'Hidden'">( {{ polarityCounts.hidden }} )</small>
								<small v-if="option.label == 'Question'">( {{ polarityCounts.question }} )</small>
                <small v-if="option.label == 'Not Assigned'">( {{ polarityCounts.notassigned }} )</small>-->

                <template v-for="(filter, index) in selectedFilter">
                  <span
                    :key="index"
                    @click.stop.prevent=""
                    class="remove"
                    v-if="
                      option.label == filter && option.label != 'All Messages'
                    "
                  >
                    <i class="fas fa-times" @click="removeFilter(option)"></i>
                  </span>
                </template>
              </template>
            </v-select>
          </div>

          <button
            class="btn-filter"
            type="button"
            id="dropdownMenu"
            data-toggle="dropdown"
          >
            <!-- <img @error="(e) => (e.target.src = $defaultCardAvatar)" src="../../assets/images/filter.svg" alt /> -->
            <i
              class="fas fa-sort-amount-down"
              v-if="order == 'DESC'"
              @click="order = 'ASC'"
            ></i>
            <i
              class="fas fa-sort-amount-up"
              v-if="order == 'ASC'"
              @click="order = 'DESC'"
            ></i>
          </button>
        </div>
        <!-- Right -->
        <div class="col-5 text-right pr-0 topfilter">
          <rangeFilter :range="range"></rangeFilter>
          <DatePicker
            class="datepicker"
            v-model="date"
            placeholder="Select Date"
            range
          ></DatePicker>
        </div>
        <!-- Right End -->
      </div>
    </div>
    <!-- Filter Desktop End -->
    <!-- Filter mobile -->
    <div class="row mobile-filter p-0 d-sm-none">
      <div class="col-4 p-0">
        <span>
          <img @error="(e) => (e.target.src = $defaultCardAvatar)" src="@/assets/images/search.svg" alt />
        </span>
      </div>
      <div class="col-4 text-center">Filter</div>
      <div class="col-4 text-right mobile">
        <span>
          <img @error="(e) => (e.target.src = $defaultCardAvatar)" src="@/assets/images/filter.svg" alt />
        </span>
        <span>
          <img @error="(e) => (e.target.src = $defaultCardAvatar)" src="@/assets/images/calander.svg" alt />
        </span>
      </div>
    </div>
    <!-- Filter mobile -->
    <div id="messages" class="col-12">
      <div class="row">
        <div class="col-12 col-sm-3 list">
          <user
            v-for="(conversation, index) in conversations"
            :key="index"
            :data="conversation"
            :profilePic="
              profileImg(
                conversation.authorProfilePicUrl,
                conversation.authorId
              )
            "
            :selected="selectedConversationId == conversation.conversationId"
            @click.native="selectedConversationId = conversation.conversationId"
          ></user>
        </div>
        <div class="col-9 details" id="details">
          <conversation
            :conversation="selectedConversationData"
            id="conversations"
          ></conversation>
          <div class="reply cant-reply" v-if="oneDayPassed">
            <span>
              <i class="fas fa-exclamation-triangle"></i> <br />
              Unfortunally cant send to message this conversation. 24 hours have
              passed since last message.
            </span>
          </div>
          <div class="reply" ref="replyBox" v-if="!oneDayPassed">
            <ResizeAuto :disabled="xhrReply">
              <template v-slot:default="{ resize }">
                <textarea
                  rows="1"
                  class="form-control text"
                  ref="textarea"
                  v-model="reply"
                  placeholder="Type a message.."
                  @input="resize"
                ></textarea>
              </template>
            </ResizeAuto>
            <img @error="(e) => (e.target.src = $defaultCardAvatar)"
              src="@/assets/images/send.svg"
              class="send"
              alt="Send"
              @click="sendMessage"
            />
            <img @error="(e) => (e.target.src = $defaultCardAvatar)"
              src="@/assets/images/emoji.svg"
              class="emojiTrigger"
              @click="emojiTrigger"
              alt="Send"
            />
            <picker
              @select="addEmoji"
              class="emoji"
              title="Pick your emoji.."
              v-show="showEmojiPicker"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { HTTP } from "@/main-source";
import EventBus from "@/components/event-bus";
import { mapGetters } from "vuex";
import { Picker } from "emoji-mart-vue";
import rangeFilter from "@/components/filters/range";
import user from "@/components/facebook/messages/List-Item";
import conversation from "@/components/facebook/messages/Conversation";
import ResizeAuto from "@/components/facebook/messages/resizedTextarea";

export default {
  name: "messages",
  data() {
    return {
      filters: [
        "All Messages",
        "Positive",
        "Neutral",
        "Negative",
        "Hidden",
        "Question",
        "Not Assigned"
      ],
      selectedFilter: ["All Messages"],
      page: 0,
      date: JSON.parse(localStorage.getItem("selectedDate")) || null,
      start: null,
      order: "DESC",
      search: "",
      range: localStorage.getItem("selectedRange") || "ALL",
      reply: "",
      showEmojiPicker: false,
      conversations: [],
      selectedConversationId: "",
      selectedConversationData: [],
      conversationPage: 0,
      conversationPageEnd: false,
      facebookAccessToken: "",
      activeAccountName: null,
      xhrReply: false,
      oneDayPassed: false
    };
  },

  components: {
    user,
    Picker,
    conversation,
    rangeFilter,
    ResizeAuto
  },
  methods: {
    getConversations: function() {
      let uri =
        "social/" +
        this.getActiveSocialMediaAccount +
        "/" +
        this.getActiveSocialMedia +
        "/conversations";

      HTTP.get(uri).then(result => {
        this.conversations = result.data;
        this.selectedConversationId = result.data[0].conversationId;
        var conversation = document.getElementById("conversations");
        conversation.scrollTop = conversation.scrollHeight;
      });
    },
    profileImg: function(img, authorId) {
      let imgUrl = img ? img : null;
      if (
        (this.getActiveSocialMedia == "facebook" && authorId) ||
        this.getActiveSocialMedia == "instagram"
      ) {
        imgUrl =
          "https://graph.facebook.com/" +
          authorId +
          "/picture?access_token=" +
          this.facebookAccessToken;
      }
      return imgUrl;
    },
    addEmoji: function(emoji) {
      // this.reply += emoji.native;

      const textarea = this.$refs.textarea;

      const start = this.reply.substring(0, textarea.selectionStart);
      const end = this.reply.substring(textarea.selectionStart);
      const text = start + emoji.native + end;
      this.reply = text;
      this.showEmojiPicker = false;
    },
    emojiTrigger: function() {
      this.showEmojiPicker = !this.showEmojiPicker;
    },
    setRange: function(value) {
      this.range = value;
    },
    removeFilter: function(option) {
      this.selectedFilter = this.selectedFilter.filter(
        filter => filter != option.label
      );
    },
    getConversation: function(next) {
      if (next && !this.conversationPageEnd) {
        this.conversationPage += 1;
      }

      let uri =
        "social/" +
        this.getActiveSocialMediaAccount +
        "/" +
        this.getActiveSocialMedia +
        "/conversation?conversation_id=" +
        this.selectedConversationId +
        "&offset=30&order=DESC&orderby=createdTime&page=" +
        this.conversationPage;

      HTTP.get(uri)
        .then(result => {
          this.selectedConversationData = result.data.content
            .reverse()
            .concat(this.selectedConversationData);
        })
        .then(() => {
          var conversation = document.getElementById("conversations");
          if (next) {
            conversation.scrollTop =
              conversation.scrollHeight / (this.conversationPage + 1);
          } else {
            conversation.scrollTop = conversation.scrollHeight;

            let cData = [...this.selectedConversationData];
            cData = cData.reverse();
            let lastUserReply = cData.filter(
              message => message.authorId != this.getActiveSocialMediaAccount
            )[0];
            console.log("son gelen mesage", lastUserReply);
            const today = new Date();
            const lastMessageDateTime = new Date(lastUserReply.createdTime);
            const diffTime = Math.abs(today - lastMessageDateTime);
            // const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            console.log("diff", diffTime);
            if (diffTime > 86400000) {
              this.oneDayPassed = true;
            } else {
              this.oneDayPassed = false;
            }
          }
        })
        .catch(e => {
          if (e.response.status == 400) {
            this.conversationPageEnd = true;
          }
        });
    },
    sendMessage: function() {
      if (this.reply != "" && this.reply != null && this.xhrReply == false) {
        this.xhrReply = true;
        let recipent = this.conversations.filter(
          conversation =>
            conversation.conversationId == this.selectedConversationId
        )[0];

        let data = {
          author: "string",
          content: this.reply,
          contentType: "facebook_conversation",
          conversationId: this.selectedConversationId,
          originalUserId: this.getActiveSocialMediaAccount,
          pageId: this.getActiveSocialMediaAccount,
          recipientId: recipent.authorId,
          recipientName: recipent.authorName,
          authorId: this.getActiveSocialMediaAccount,
          authorName: this.activeAccountName
        };

        HTTP.post("social/createmessage", data)
          .then(result => {
            console.log(result.data);

            this.selectedConversationData.push(data);
            this.reply = "";
            setTimeout(() => {
              let detailsView = document.getElementById("conversation");
              detailsView.scrollTop = detailsView.scrollHeight;
            }, 200);
            this.xhrReply = false;
          })
          .catch(() => {
            this.xhrReply = false;
          });
      }
    },
    scroll: function() {
      let messages = document.getElementById("conversation");
      messages.onscroll = () => {
        if (messages.scrollTop < 20 && !this.conversationPageEnd) {
          this.getConversation(true);
        }
      };
    }
  },
  mounted() {
    setTimeout(() => {
      if (this.getActiveSocialMedia == "facebook") {
        HTTP.get("user/me").then(result => {
          let data = result.data.socialMediaAccounts.filter(
            account => account.accountId == this.getActiveSocialMediaAccount
          )[0];
          this.facebookAccessToken = data.accessToken;
          this.activeAccountName = data.accountName;
        });
      }
      this.getConversations();
    }, 500);
    this.scroll();
    EventBus.$on("setRange", range => {
      this.setRange(range);
    });
  },
  computed: {
    ...mapGetters(["getActiveSocialMedia", "getActiveSocialMediaAccount"]),
    selectedFilterCount: function() {
      return this.selectedFilter.length;
    }
  },
  watch: {
    selectedConversationId: function() {
      this.reply = "";
      this.selectedConversationData = [];
      this.conversationPage = 0;
      this.conversationPageEnd = false;
      this.getConversation();
    },
    reply: function() {
      let messageBoxHeight = this.$refs.replyBox.clientHeight;
      let coverHeight = document.getElementById("details").clientHeight - 50;
      let conversationHeight = coverHeight - messageBoxHeight;
      document
        .getElementById("conversations")
        .setAttribute("style", "height:" + conversationHeight + "px");

      var conversation = document.getElementById("conversations");
      conversation.scrollTop = conversation.scrollHeight;
    }
  }
};
</script>
<style lang="scss">
@import "@/scss/colors.scss";
.mobile-filter {
  margin-bottom: 15px !important;
}
#messages {
  border-radius: 15px;
  background: $white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
  padding: 0;
  transition: all 0.25s ease;
  // height: calc(100% - 220px);
  // overflow: hidden;
  .list {
    border-right: 1px solid darken($white, 3%);
  }
  .details {
    padding: 15px 30px 0px 15px;
    min-height: calc(100vh - 210px);
    max-height: calc(100vh - 210px);
    overflow: hidden;
    position: relative;
    // display: flex;
    // flex-direction: column;
  }
  .conversation {
    height: calc(100vh - 280px);
    overflow-y: scroll;
    overscroll-behavior: contain;
  }
  .message {
    margin-bottom: 10px;
    span {
      max-width: 70%;
      background: darken($white, 3%);
      padding: 10px 15px;
      border-radius: 15px;
      display: inline-block;
    }
  }
  .reply {
    clear: both;
    // height: 95px;
    // position: absolute;
    // bottom: 10px;
    width: 100%;
    position: relative;
    margin-bottom: 10px;
    textarea {
      resize: none;
      border-radius: 15px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      &:focus {
        outline: none;
        box-shadow: none;
        // border: 1px solid rgba(0, 0, 0, 0.1);
      }
    }
    .send {
      position: absolute;
      right: 20px;
      bottom: 10px;
      width: 20px;
      height: auto;
      cursor: pointer;
    }
    .emojiTrigger {
      position: absolute;
      position: absolute;
      right: 50px;
      bottom: 10px;
      width: 20px;
      height: auto;
      cursor: pointer;
    }
    .emoji {
      position: absolute;
      right: 50px;
      bottom: 35px;
    }
  }
  .right {
    clear: right;

    &:after {
      content: "";
      position: relative;
      clear: both;
      display: block;
    }
    span {
      float: right;
      background: $brandSecondColor;
      color: $white;
      &:after {
        clear: both;
      }
    }
  }
}
.cant-reply {
  i {
    color: $brand-orange;
  }
  font-size: 12px;
  background: darken($white, 3%);
  border-radius: 30px;
  padding: 5px 20px;
  text-align: center;
}
</style>
