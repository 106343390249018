<template>
  <div class="chart-card">
    <div class="chart-header">
      <span class="count"> {{ sumValues == 0 ? "~" : abbreviate(sumValues) }} </span>
      <span class="sub-title">{{ metric.title }}</span>
    </div>
    <div class="row location-list" :class="{ 'not-enough': metric.insightData.length == 0 }">

      <div class="col-12" v-if="data">
        <div class="d-flex list-item" :class="getActiveSocialMedia()" v-for="(item, index) in data" :key="index">
          <div class="col p-0">{{ getCountryName(item.key) }}</div>
          <div class="col text-right">{{ abbreviate(item.sum) }}</div>
          <div class="percentage" :style="{ 'width': calculatePercentage(item.sum) + '%' }">
          </div>
        </div>
      </div>

      <div class="col-12 transparented" v-if="metric.insightData.length == 0">
        Not enough data
      </div>
    </div>
  </div>
</template>
<script>
import statusTriangle from "@/components/icons/triangle";
import circle from "@/components/icons/circle";
import abbreviate from "number-abbreviate";
import { mapGetters } from "vuex";

// Sources
import countries from "@/sources/countries.min.json";
import countries2digit from "@/sources/countries-2digit.min.json";

export default {
  name: "SimpleList",
  props: ["metric"],
  components: {
    status: statusTriangle,
    "location-circle": circle,
  },
  data() {
    return {
      data: [],
      sumValues: 0,
      countries,
      countries2digit
    };
  },
  methods: {
    ...mapGetters(['getActiveSocialMedia']),
    setData() {
      let keys = this.metric.insightData.map(item => item.key);
      let uniqueKeys = [...new Set(keys)];
      let data = [];

      uniqueKeys.forEach(key => {
        let sum = this.metric.insightData.filter(item => item.key == key)
          .map(item => item.value)
          .reduce((a, b) => a + b, 0);
        this.sumValues += sum;
        data.push({ key, sum })
      });

      this.data = data;
      this.data.sort((a, b) => b.sum - a.sum);
    },
    calculatePercentage(value) {
      return Math.round((value / this.sumValues) * 100);
    },
    getCountryName(key) {
      let mergedCountries = [...this.countries2digit, ...this.countries];
      let country = mergedCountries.find(item => item.code == key);
      return country ? country.name : key;
    },
    setSum: function () {
      let sum = 0;

      if (this.metric.insightData) {
        this.metric.insightData.forEach((d) => {
          sum += d.value;
        });
      }
      this.sumValues = this.$intToString(sum);
    },
    abbreviate(value) {
      return value < 10000
        ? this.$intlNumber(value)
        : `${abbreviate(value)}`.toUpperCase();
    },
  },
  watch: {
    metric: {
      deep: true,
      handler: function () {
        localStorage.setItem("selectedDate", JSON.stringify(this.date));
        this.setData();
      },
    },
  },
};
</script>
