<template>
  <div id="page--ai-actions">
    <!-- Filter Desktop -->
    <div class="row filter d-none d-md-block">
      <div class="col-12 left-filter">
        <!-- Left -->
        <div class="col-7 left">
          <!-- Search -->
          <div
            class="search-area has-icon"
            :class="{ 'animated shake': shakeSearch, active: searchActive }"
          >
            <i class="fas fa-search"></i>

            <input-tag
              v-model="search"
              class="search taggable"
              placeholder="Search comment, @user or word..."
              :before-adding="checkSearchValue"
            ></input-tag>
          </div>
          <!-- Search -->

          <div class="d-flex w-100" style="padding-right:5px;">
            <div
              class="w-60 filters p-0 has-icon elem"
              :class="{ disappear: searchActive }"
            >
              <v-select
                class="filter--status"
                v-model="selectedFilter"
                :options="filters"
                :clearable="false"
                :searchable="false"
                :tabindex="10"
              >
                <template
                  slot="selected-option-container"
                  slot-scope="selectedFilter"
                >
                  <div>
                    <template v-for="(item, i) in filters">
                      <div
                        class="all ml-0"
                        :key="i"
                        v-if="selectedFilter.option.value == item.value"
                      >
                        {{ item.label }}
                      </div>
                    </template>
                  </div>
                </template>

                <template slot="option" slot-scope="option">
                  <div
                    @click="
                      option.value == 'ALL'
                        ? (selectedFilter = filters[0])
                        : null
                    "
                    :class="{ selected: selectedFilter.value === option.value }"
                    v-html="option.markup"
                  ></div>
                </template>
              </v-select>
            </div>
            <!-- Emotions Filter -->
            <div
              class="w-40 filters p-0 has-icon elem emotions-filter"
              :class="{ disappear: searchActive }"
            >
              <v-select
                v-model="selectedCause"
                :options="causes"
                :clearable="true"
                :searchable="false"
                :closeOnSelect="false"
                multiple
                :tabindex="10"
                :class="{
                  selectedCausePosition: selectedCause.length > 1,
                }"
              >
                <template
                  slot="selected-option-container"
                  slot-scope="selectedCause"
                >
                  <div>
                    <div
                      class="all ml-0"
                      v-if="
                        selectedCause.option.label.trim() == 'All Cause' &&
                          selectedCauseCount == 1
                      "
                    >
                      All Cause
                    </div>
                  </div>
                </template>

                <template slot="option" slot-scope="option">
                  <div
                    @click="
                      option.label == 'All Cause'
                        ? (selectedCause = ['All Cause'])
                        : ''
                    "
                  >
                    {{ option.label }}

                    <template v-for="(filter, index) in selectedCause">
                      <span
                        :key="index"
                        class="remove"
                        v-if="
                          option.label.toLowerCase() == filter.toLowerCase() &&
                            option.label != 'All Cause'
                        "
                      >
                        <i
                          class="fas fa-times"
                          @click.stop.prevent=""
                          @click="removeFeeling(option)"
                        ></i>
                      </span>
                    </template>
                  </div>
                </template>
              </v-select>
            </div>
          </div>
          <!-- Emotions Filter End -->
          <button
            class="btn-filter elem search-trigger badge-wrapper"
            type="button"
            @click="searchActive = !searchActive"
          >
            <span class="badge" v-if="search.length > 0"
              >{{ search.length }}
            </span>
            <span class="icon--search">
              <i class="fas fa-search" v-if="!searchActive"></i>
            </span>
            <close :dark="true" v-if="searchActive"></close>
          </button>
          <button
            class="btn-filter elem"
            type="button"
            id="dropdownMenu"
            data-toggle="dropdown"
          >
            <img
              @error="(e) => (e.target.src = $defaultCardAvatar)"
              src="@/assets/images/comment.svg"
              alt
              v-if="orderBy == 'numOfReplies'"
            />
            <img
              @error="(e) => (e.target.src = $defaultCardAvatar)"
              src="@/assets/images/sort/desc.svg"
              alt
              v-if="order == 'DESC' && orderBy == 'actionDate'"
            />
            <img
              @error="(e) => (e.target.src = $defaultCardAvatar)"
              src="@/assets/images/sort/asc.svg"
              alt
              v-if="order == 'ASC' && orderBy == 'actionDate'"
            />
            <img
              @error="(e) => (e.target.src = $defaultCardAvatar)"
              src="@/assets/images/sort/heart.svg"
              alt
              v-if="orderBy == 'numOfLikes'"
            />
          </button>
          <div class="dropdown-menu order animate slideIn">
            <button
              class="dropdown-item"
              :class="{ selected: order == 'DESC' && orderBy == 'actionDate' }"
              type="button"
              @click="
                order = 'DESC';
                orderBy = 'actionDate';
              "
            >
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/sort/desc.svg"
                @click="mobileSearchActive = true"
                alt
              />
              Newest
            </button>
            <button
              class="dropdown-item"
              :class="{ selected: order == 'ASC' && orderBy == 'actionDate' }"
              type="button"
              @click="
                order = 'ASC';
                orderBy = 'actionDate';
              "
            >
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/sort/asc.svg"
                @click="mobileSearchActive = true"
                alt
              />
              Oldest
            </button>
          </div>
          <!-- Left End -->
        </div>
        <!-- Right -->
        <div class="col-5 text-right pr-0 topfilter">
          <range-filter :range="range"></range-filter>
          <DatePicker
            ref="datepicker"
            class="datepicker"
            v-model="date"
            placeholder="Select Date"
            range
            :disabled-date="disabledDates"
          >
            <template v-slot:header="{ emit }">
               <!-- <button
                class="btn radio p-0 d-block w-100 text-left"
                @click="setRange('ALL')"
              >
                <radio-button :checked="range == 'ALL'"></radio-button>All Time
              </button> -->
              <button
                class="btn radio p-0 d-block w-100 text-left"
                @click="setQuickRange('14Days')"
              >
                <radio-button :checked="quickRange == '14Days'"></radio-button
                >Last 14 Days
              </button>
              <button
                class="btn radio p-0 d-block w-100 text-left"
                @click="setQuickRange('30Days')"
              >
                <radio-button :checked="quickRange == '30Days'"></radio-button
                >Last 30 Days
              </button>
              <button
                class="btn radio p-0 d-block w-100 text-left"
                @click="setQuickRange('ThisWeek')"
              >
                <radio-button :checked="quickRange == 'ThisWeek'"></radio-button
                >This Week
              </button>
              <button
                class="btn radio p-0 d-block w-100 text-left"
                @click="setQuickRange('LastWeek')"
              >
                <radio-button :checked="quickRange == 'LastWeek'"></radio-button
                >Last Week
              </button>
              <button
                class="btn radio p-0 d-block w-100 text-left"
                @click="setQuickRange('ThisMonth')"
              >
                <radio-button
                  :checked="quickRange == 'ThisMonth'"
                ></radio-button
                >This Month
              </button>
              <button
                class="btn radio p-0 d-block w-100 text-left mb-2"
                @click="setQuickRange('LastMonth')"
              >
                <radio-button
                  :checked="quickRange == 'LastMonth'"
                ></radio-button
                >Last Month
              </button>
            </template>
          </DatePicker>
        </div>
        <!-- Right End -->
      </div>
    </div>
    <!-- Filter Desktop End -->
    <!-- Filter mobile -->
    <div class="row mobile-filter p-0 d-sm-none">
      <div class="p-0" :class="[mobileSearchActive ? 'col-5' : 'col-4']">
        <span>
          <img
            @error="(e) => (e.target.src = $defaultCardAvatar)"
            src="@/assets/images/search.svg"
            @click="mobileSearchActive = true"
            alt
          />
        </span>
        <input-tag
          v-model="search"
          class="taggable mobile-search"
          :class="{ active: mobileSearchActive }"
          placeholder="Search comment, @user or word..."
        ></input-tag>
      </div>
      <div
        class="text-center group-select"
        :class="[mobileSearchActive ? 'col-3 p-0' : 'col-4']"
      >
        <span @click="showFilter">
          <status :status="'all'"></status>
          <span
            class="badge"
            v-if="selectedFilterCount > 1 && selectedFilter[1] != 'All Status'"
            >{{ selectedFilterCount - 1 }}
          </span>
        </span>
        <span @click="showEmotionFilter">
          <component :is="'neutral'"></component>
          <span
            class="badge"
            v-if="selectedCauseCount > 1 && selectedCause[1] != 'All Cause'"
            >{{ selectedCauseCount - 1 }}
          </span>
        </span>
      </div>
      <div
        class="text-right mobile"
        :class="[mobileSearchActive ? 'col ' : 'col-4']"
      >
        <span @click="mobileOrderModal = true">
          <img
            @error="(e) => (e.target.src = $defaultCardAvatar)"
            src="@/assets/images/filter.svg"
            alt="Filter"
          />
        </span>
        <span @click="mobileRangeModal = true">
          <img
            @error="(e) => (e.target.src = $defaultCardAvatar)"
            src="@/assets/images/calander.svg"
            alt="Set"
          />
        </span>
      </div>

      <div class="col-12 tags" v-if="search.length > 0">
        <span
          v-for="(q, index) in search"
          :key="index"
          @click.stop.prevent=""
          @click="removeTag(q)"
        >
          {{ q }}
          <i class="remove">x</i>
        </span>
      </div>
    </div>
    <!-- Filter mobile -->

    <!-- Result Info -->
    <div class="row result-info">
      <div class="col-12 col-sm-7">
        <div class="row" v-if="resultCount > 0">
          <div class="col-10 col-sm-6">Listed {{ resultCount }} actions</div>
          <div class="col-2 col-sm-6 text-right p-0">
            <!-- <button
              class="btn-filter download-button d-none d-sm-inline-block"
              type="button"
              :disabled="xhrDownload"
              @click="exportExcel"
            >
              <img
                src="@/assets/images/download.svg"
                alt="Download"
                v-if="!xhrDownload"
              />
              <span v-if="!xhrDownload">Export</span>
              <Loading v-if="xhrDownload"></Loading>
            </button> -->
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-5"></div>
    </div>
    <!-- Result Info -->

    <!-- Comments -->
    <div class="row">
      <!-- has active add when select some elements -->
      <div
        id="comments"
        class="col-12 col-md-8 list"
        :class="[
          { 'no-data': comments.length == 0 && show },
          { 'has-selected': selectedComment.id != null },
        ]"
      >
        <span class="placeholder" v-if="comments.length == 0 && show">
          <img
            @error="(e) => (e.target.src = $defaultCardAvatar)"
            src="@/assets/images/icon.svg"
            class="logo"
            alt="Socialays"
          />
          No AI-Actions</span
        >
        <div v-if="!show && page == 0">
          <comment-placeholder v-for="n in 5" :key="n"></comment-placeholder>
        </div>
        <action
          @delete="confirmDeletion"
          v-for="(action, index) in comments"
          :action="action"
          :activeAccountDetails="activeAccountDetails"
          :key="index"
        ></action>
        <div class="end" v-if="comingMore">
          <comment-placeholder v-for="n in 2" :key="n"></comment-placeholder>
        </div>
        <div
          class="end placeholder"
          v-if="endOfComments && comments.length != 0"
        >
          <img
            @error="(e) => (e.target.src = $defaultCardAvatar)"
            src="@/assets/images/icon.svg"
            class="logo"
            alt="Socialays"
          />
          No More Comments
        </div>
      </div>

      <!-- add Not selected class if not selected element  -->
      <div id="details" class="col-12 col-lg-4 list">
        <chart
          v-if="causeGraphData && comments.length"
          :graph="causeGraphData"
          :totals="20"
          :account="activeAccountDetails"
          :range="range"
          :start="start ? start : ''"
          :finish="finish ? finish : ''"
          :type="getActiveSocialMedia"
          :key="1"
        ></chart>
      </div>
    </div>
    <!-- Comments End -->
    <!-- Mobile Status Filter Start -->
    <div class="mobile-ai-filter" :class="{ show: mobileAiFilter }">
      <div class="close-trigger" @click="filtersDone"></div>
      <div class="wrap">
        <div class="head">
          <div class="row">
            <div class="col-9">
              <span class="h5">Filter</span>
            </div>
            <div class="col text-right done" @click="filtersDone">Done</div>
          </div>
        </div>
        <div class="body">
          <div class="row" @click="setFilter('positive')">
            <div class="col-9">
              <status :status="'positive'"></status>Positive
            </div>
            <div class="col text-right">
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/checked.svg"
                v-if="selectedFilter === 'positive'"
              />
            </div>
          </div>
          <div class="row" @click="setFilter('negative')">
            <div class="col-9">
              <status :status="'negative'"></status>Negative
            </div>
            <div class="col text-right">
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/checked.svg"
                v-if="selectedFilter === 'negative'"
              />
            </div>
          </div>
          <div class="row" @click="setFilter('neutral')">
            <div class="col-9">
              <status :status="'neutral'"></status>Neutral
            </div>
            <div class="col text-right">
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/checked.svg"
                v-if="selectedFilter === 'neutral'"
              />
            </div>
          </div>
          <div class="row" @click="setFilter('hidden')">
            <div class="col-9"><status :status="'hidden'"></status>Hidden</div>
            <div class="col text-right">
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/checked.svg"
                v-if="selectedFilter === 'hidden'"
              />
            </div>
          </div>
          <div class="row" @click="setFilter('question')">
            <div class="col-9">
              <status :status="'question'"></status>Question
            </div>
            <div class="col text-right">
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/checked.svg"
                v-if="selectedFilter === 'question'"
              />
            </div>
          </div>
          <div class="row" @click="setFilter('notassigned')">
            <div class="col-9"><status></status>Not Assigned</div>
            <div class="col text-right">
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/checked.svg"
                v-if="selectedFilter === 'notassigned'"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Mobile Status Filter End -->
    <!-- Mobile Emotion Filter Start -->
    <div class="mobile-ai-filter" :class="{ show: mobileEmotionFilter }">
      <div class="close-trigger" @click="filtersDone"></div>
      <div class="wrap">
        <div class="head">
          <div class="row">
            <div class="col-9">
              <span class="h5">Filter</span>
            </div>
            <div class="col text-right done" @click="filtersDone">Done</div>
          </div>
        </div>
        <div class="body">
          <div
            class="row"
            v-for="(cause, index) in causes.slice(1)"
            @click="setEmotion(emotion.toLowerCase())"
            :key="index"
          >
            <div class="col-9">
              {{ cause }}
            </div>
            <div class="col text-right">
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/checked.svg"
                v-if="selectedCause.includes(cause.toLowerCase())"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Mobile Emotion Filter End -->
    <!-- Mobile Order Modal Start -->
    <div class="mobile-ai-filter" :class="{ show: mobileOrderModal }">
      <div class="close-trigger" @click="mobileOrderModal = false"></div>
      <div class="wrap">
        <div class="head">
          <div class="row">
            <div class="col-9">
              <span class="h5">Order</span>
            </div>
            <div class="col text-right done" @click="mobileOrderModal = false">
              Done
            </div>
          </div>
        </div>
        <div class="body">
          <div
            class="row"
            @click="
              order = 'DESC';
              orderBy = 'actionDate';
              mobileOrderModal = false;
            "
          >
            <div class="col-9">
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/sort/desc.svg"
                @click="mobileSearchActive = true"
                alt
              />
              Newest
            </div>
            <div class="col text-right">
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/checked.svg"
                v-if="order == 'DESC' && orderBy == 'actionDate'"
              />
            </div>
          </div>

          <div
            class="row"
            @click="
              order = 'ASC';
              orderBy = 'actionDate';
              mobileOrderModal = false;
            "
          >
            <div class="col-9">
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/sort/asc.svg"
                @click="mobileSearchActive = true"
                alt
              />
              Oldest
            </div>
            <div class="col text-right">
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/checked.svg"
                v-if="order == 'ASC' && orderBy == 'actionDate'"
              />
            </div>
          </div>

          <div
            class="row"
            @click="
              order = 'DESC';
              orderBy = 'numOfLikes';
              mobileOrderModal = false;
            "
          >
            <div class="col-9">Most Liked</div>
            <div class="col text-right">
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/checked.svg"
                v-if="order == 'DESC' && orderBy == 'numOfLikes'"
              />
            </div>
          </div>
          <div
            class="row"
            @click="
              order = 'DESC';
              orderBy = 'numOfReplies';
              mobileOrderModal = false;
            "
          >
            <div class="col-9">Most Replies</div>
            <div class="col text-right">
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/checked.svg"
                v-if="order == 'DESC' && orderBy == 'numOfReplies'"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Mobile Order Modal End -->
    <!-- Mobile Range Modal Start -->
    <div class="mobile-ai-filter" :class="{ show: mobileRangeModal }">
      <div class="close-trigger" @click="mobileRangeModal = false"></div>
      <div class="wrap">
        <div class="head">
          <div class="row">
            <div class="col">
              <span class="h5">Select Date</span>
            </div>
            <div class="col text-right done" @click="mobileRangeModal = false">
              Done
            </div>
          </div>
        </div>
        <div class="body">
          <div
            class="row"
            @click="
              range = 'LAST24';
              mobileRangeModal = false;
            "
          >
            <div class="col">Last 24 Hours</div>
            <div class="col text-right">
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/checked.svg"
                v-if="range == 'LAST24'"
              />
            </div>
          </div>

          <div
            class="row"
            @click="
              range = 'LAST7';
              mobileRangeModal = false;
            "
          >
            <div class="col">Last 7 Days</div>
            <div class="col text-right">
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/checked.svg"
                v-if="range == 'LAST7'"
              />
            </div>
          </div>

          <div
            class="row"
            @click="
              range = 'ALL';
              mobileRangeModal = false;
            "
          >
            <div class="col">All Time</div>
            <div class="col text-right">
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/checked.svg"
                v-if="range == 'ALL'"
              />
            </div>
          </div>
          <div
            class="row"
            @click="
              range = 'LAST7';
              mobileRangeModal = false;
            "
          >
            <div class="col">Custom Date</div>
            <div class="col text-right">
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/checked.svg"
                v-if="date != null"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Mobile Order Modal -->
    <ConfirmationModal id="delete-ai-action-modal" @confirm="deleteAction">
      <template slot="title"> Delete Comment </template>
      <template slot="body">
        You are deleting a comment.
        <br />Comment will be deleted from the linked account.
      </template>
    </ConfirmationModal>
  </div>
</template>
<script>
/* eslint-disable */
import { HTTP } from "@/main-source";
import EventBus from "@/components/event-bus";
import comment from "@/components/facebook/comment.vue";
import Action from "@/components/facebook/ai-actions/List-Item";
import commentHolder from "@/components/placeholders/comment.vue";
import rightPostView from "@/components/facebook/right-post-view.vue";
import rightCommentView from "@/components/facebook/right-comment-view.vue";
import rightAuthorView from "@/components/facebook/right-author-view.vue";
import { mapGetters } from "vuex";
import rangeFilter from "@/components/filters/range";
import subcommentfilter from "@/components/filters/subcomments";
import InputTag from "vue-input-tag";
import status from "@/components/dot";
import router from "@/router";
import close from "@/components/close";
import chart from "@/components/charts/cause";
import radioButton from "@/components/radio-button";
// Emotions - Will be improved
import fun from "@/components/icons/emotions/fun";
import happiness from "@/components/icons/emotions/happiness";
import hate from "@/components/icons/emotions/hate";
import love from "@/components/icons/emotions/love";
import neutral from "@/components/icons/emotions/neutral";
import relief from "@/components/icons/emotions/relief";
import sadness from "@/components/icons/emotions/sadness";
import surprise from "@/components/icons/emotions/surprise";
import worry from "@/components/icons/emotions/worry";
import ConfirmationModal from "@/components/partials/ConfirmationModal";

const filters = [
  {
    value: "ALL",
    markup: "All Status",
    label: "All Status",
  },
  {
    value: "WILL_BE",
    markup:
      'will be Blocked <i class="relative--force fas fa-ban text-warning"></i> / Hidden <i class="relative--force fas fa-eye-slash text-warning font-weight-normal"></i>',
    label: "will be Blocked / Hidden",
  },
  {
    value: "ALREADY",
    markup:
      'already Blocked <i class="relative--force fas fa-ban text-danger"></i> / Hidden <i class="relative--force fas fa-eye-slash text-danger font-weight-normal"></i>',
    label: "already Blocked / Hidden",
  },
];

export default {
  name: "comments",
  data() {
    return {
      comments: [],
      subcomments: [],
      resultCount: 0,
      replies: [],
      selectedComment: {
        id: null,
      },
      filters,
      selectedFilter: filters[0],
      causes: [
        "Abusive Comment",
        "Ad Content Comment",
        "Negative Comment",
        "Abusive User",
        "Ad Content User",
        "Negative Comment User",
      ],
      selectedCause: ["All Cause"],
      searchActive: false,
      selectedCommentPost: null,
      isShowSubComments: false,
      commentPost: [],
      page: 0,
      subPage: 0,
      date: JSON.parse(localStorage.getItem("selectedDate")) || null,
      start: null,
      finish: null,
      order: "DESC",
      search: [],
      range: localStorage.getItem("selectedRange") || "ALL",
      quickRange: localStorage.getItem("selectedQuickRange") || null,
      view: null,
      polarityCounts: {
        totalComments: null,
      },
      pocket: {
        polarityStrings: null,
        hidden: null,
        isQuestion: null,
        isSpam: null,
        emotion: [],
      },
      facebookAccessToken: "",
      activeAccountDetails: {
        accountId: null,
      },
      show: false,
      showSubComments: false,
      isShowDetailsOnMobile: false,
      commentUniqueId: null,
      isDeletedCommentSub: false,
      orderBy: "actionDate",
      mobileSearchActive: false,
      mobileAiFilter: false,
      mobileEmotionFilter: false,
      mobileOrderModal: false,
      mobileRangeModal: false,
      comingMore: false,
      endOfComments: false,
      selectedAuthor: {
        authorId: null,
        polarityCounts: null,
        show: false,
      },
      subOrder: "DESC",
      subOrderBy: "actionDate",
      subFilter: null,
      searchQuery: null,
      shakeSearch: false,
      causeGraphData: null,
      typeGraphData: null,
      target: null,
      xhrDownload: false,
    };
  },
  created() {
    // this.getComments();
    // this.setActiveSocialMediaAccount();
  },
  components: {
    comment,
    rightCommentView,
    "right-post-view": rightPostView,
    "range-filter": rangeFilter,
    "comment-placeholder": commentHolder,
    "right-author-view": rightAuthorView,
    subcommentfilter,
    InputTag,
    close,
    status,
    fun,
    happiness,
    hate,
    love,
    neutral,
    relief,
    sadness,
    surprise,
    worry,
    Action,
    chart,
    ConfirmationModal,
    radioButton,
  },
  methods: {
    confirmDeletion(aiaction) {
      this.target = aiaction;
      this.$eventBus.$emit("toggle-delete-ai-action-modal", { active: true });
    },
    getComments: function(next = false) {
      let emotionFilterText = "";

      if (next && !this.endOfComments) {
        this.comingMore = true;
        this.page++;
      }

      let uri =
        "aiaction/" +
        this.getActiveSocialMediaAccount +
        "/?page=" +
        this.page +
        "&order=" +
        this.order +
        "&orderby=" +
        this.orderBy;

      if (this.range) {
        uri += "&range=" + this.range;
      }

      if (this.start && this.finish) {
        uri +=
          "&page=" +
          this.page +
          "&start=" +
          this.start +
          "&finish=" +
          this.finish;
      }

      if (this.searchQuery) {
        uri += "&text=" + this.searchQuery;
      }

      // Set query selected filters
      // this.selectedFilter.forEach((filter) => {
      //   if (filter != 'All Status') {
      //     if (filterText != '') {
      //       filterText += ',';
      //     }
      //     filter = filter.split(' ').join('_');
      //     filterText += filter.toUpperCase();
      //   }
      // });

      this.selectedCause.forEach((filter) => {
        if (filter != "All Cause") {
          if (emotionFilterText != "") {
            emotionFilterText += ",";
          }
          filter = filter.split(" ").join("_");

          emotionFilterText += filter.toUpperCase();
        }
      });
      // Set query selected filters end
      // Add Query filters
      uri += `&type=${this.selectedFilter.value}`;

      if (emotionFilterText.length > 0) {
        uri += "&cause=" + emotionFilterText;
      }
      // Add Query filters End

      if (
        this.getActiveSocialMediaAccount &&
        !this.endOfComments &&
        !uri.includes("null")
      ) {
        this.show = false;

        HTTP.get(uri)
          .then((result) => {
            if (this.page == 0) {
              this.causeGraphData = result.data.causeGraphData;
              this.typeGraphData = result.data.typeGraphData;
              this.resultCount = result.data.totalElements;
            }

            if (!next) {
              this.comments = [];
              // this.page = 0;
            }
            result.data.data.forEach((comment) => {
              this.comments.push(comment);
            });
            this.show = true;
            this.comingMore = false;
          })
          .catch((e) => {
            if (e.response.status == 400) {
              if (this.comments.length == 0) {
                this.polarityCounts = {
                  hidden: 0,
                  negative: 0,
                  neutral: 0,
                  notassigned: 0,
                  positive: 0,
                  question: 0,
                  spam: 0,
                  totalComments: 0,
                };
              }
              if (this.page == 0) {
                this.comments = [];
                this.resultCount = 0;
              }
              this.show = true;
              this.comingMore = false;
              this.endOfComments = true;
            }
          });
      } else {
        this.show = true;
      }
    },

    clear: function() {
      this.close();
      this.page = 0;
      this.comments = [];
      this.subcomments = [];
      this.selectedCommentPost = null;
      this.selectedComment = { id: null };
      this.isShowSubComments = false;
      this.view = null;
      this.endOfComments = false;
    },
    setRange: function(value) {
      localStorage.setItem("selectedRange", value);
      localStorage.removeItem("selectedQuickRange");
      this.range = value;
      this.quickRange = null;
      if (this.range != null) {
        this.clear();
        this.date = null;
        this.start = null;
        this.finish = null;
        this.close();
        this.getComments();
      }
      this.$refs.datepicker.closePopup();
    },
    setQuickRange: function(which) {
      localStorage.setItem("selectedQuickRange", which);
      localStorage.removeItem("selectedRange");
      this.range = null;

      this.clear();
      this.close();
      this.date = null;
      this.start = null;
      this.finish = null;
      let today = new Date();
      switch (which) {
        case "14Days":
          this.quickRange = "14Days";
          today = new Date();
          let f14 = new Date(today.setDate(new Date().getDate() - 14));
          this.date = [f14, new Date()];
          break;
        case "30Days":
          this.quickRange = "30Days";
          today = new Date();
          let f30 = new Date(today.setDate(new Date().getDate() - 30));
          this.date = [f30, new Date()];
          break;
        case "ThisWeek":
          this.quickRange = "ThisWeek";
          let curr = new Date();
          let first = curr.getDate() - curr.getDay() + 1;
          let last = first + 6;

          let firstday = new Date(curr.setDate(first));
          let lastday = new Date(curr.setDate(last));
          this.date = [firstday, lastday];
          break;
        case "LastWeek":
          this.quickRange = "LastWeek";
          today = new Date();
          let curl = new Date(today.setDate(new Date().getDate() - 7));
          let firstl = curl.getDate() - curl.getDay() + 1;
          let lastl = firstl + 6;

          let firstlday = new Date(curl.setDate(firstl));
          let lastlday = new Date(curl.setDate(lastl));
          this.date = [firstlday, lastlday];
          break;
        case "ThisMonth":
          this.quickRange = "ThisMonth";
          var date = new Date(),
            y = date.getFullYear(),
            m = date.getMonth();
          var firstDay = new Date(y, m, 1);
          var lastDay = new Date(y, m + 1, 0);
          this.date = [firstDay, lastDay];
          break;
        case "LastMonth":
          this.quickRange = "LastMonth";
          var date = new Date();
          var firstDayPrevMonth = new Date(
            date.getFullYear(),
            date.getMonth() - 1,
            1
          );
          var lastDayPrevMonth = new Date(
            date.getFullYear(),
            date.getMonth(),
            0
          );
          this.date = [firstDayPrevMonth, lastDayPrevMonth];
          break;
      }

      this.getComments();

      this.$refs.datepicker.closePopup();
    },
    removeFeeling: function(option) {
      this.selectedCause = this.selectedCause.filter(
        (filter) => filter != option.label
      );
    },
    scroll: function() {
      let commentListView = document.getElementById("comments");
      // let lastItem = document.querySelector("#comments .col-12:last-child");

      commentListView.onscroll = () => {
        let position = commentListView.scrollTop - 200;
        let end =
          commentListView.scrollHeight - commentListView.offsetHeight - 200;
        if (position.toFixed() >= end) {
          this.getComments(true);
        }
      };
    },
    detailScroll: function() {
      let commentListView = document.getElementById("details");
      // let lastItem = document.querySelector("#comments .col-12:last-child");

      commentListView.onscroll = () => {
        let position = commentListView.scrollTop - 200;
        let end =
          commentListView.scrollHeight - commentListView.offsetHeight - 200;
        if (position == end) {
          this.getSubComments(true);
        }
      };
    },

    close: function() {
      this.view = null;
      this.selectedCommentPost = null;
      this.selectedComment = {
        id: null,
      };
      this.subcomments = [];
      this.isShowDetailsOnMobile = false;
      // this.selectedAuthor = null;
      this.subPage = 0;
    },

    profileImg: function(img, authorId) {
      let imgUrl = img ? img : null;
      if (
        (this.getActiveSocialMedia == "facebook" && authorId) ||
        this.getActiveSocialMedia == "instagram"
      ) {
        // "https://graph.facebook.com/v3.2/101698591437761/picture?type=large&access_token=512465416305145|19fee8c38b6b550dfa469b8875f7fa37"
        // imgUrl =
        //   "https://graph.facebook.com/" + authorId + "/picture?type=square";
        imgUrl =
          "https://via.placeholder.com/200/ff6c37/c40d3c/?text=" + authorId;
      }
      // http://graph.facebook.com/101698591437761/picture?type=large
      return imgUrl;
    },

    setActiveSocialMediaAccount: function() {
      return new Promise((resolve, reject) => {
        let accountId = "";
        accountId = this.$route.params.accountId
          ? this.$route.params.accountId
          : this.getActiveSocialMediaAccount;

        HTTP.get("user/me")
          .then((result) => {
            this.activeAccountDetails = result.data.socialMediaAccounts.filter(
              (account) => account.accountId == accountId
            )[0];

            if (this.activeAccountDetails == undefined) {
              this.activeAccountDetails = result.data.authorizedAccounts.filter(
                (account) => account.accountId == accountId
              )[0];
            }
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    deleteComment: function() {
      /*eslint-disable*/
      let payLoad = {
        uniqueId: this.commentUniqueId,
        originalUserId: this.getActiveSocialMediaAccount,
        pageId: this.getActiveSocialMediaAccount,
      };
      /*eslint-disable*/

      HTTP.delete("social", { params: payLoad }).then((result) => {
        if (result.status == 200) {
          this.deleteModal = false;

          if (this.isDeletedCommentSub) {
            this.subcomments = this.subcomments.filter(
              (subcomment) => subcomment.id != this.commentUniqueId
            );
          } else {
            this.comments = this.comments.filter(
              (comment) => comment.id != this.commentUniqueId
            );
          }

          this.replies = this.replies.filter(
            (reply) => reply.id != this.commentUniqueId
          );

          // this.getComments();
        }
      });
    },

    /*eslint-disable*/
    mobileAndTabletCheck: function() {
      let check = false;
      (function(a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    },
    /*eslint-disable*/
    showDetailsOnMobile: function() {
      if (this.mobileAndTabletCheck()) {
        this.isShowDetailsOnMobile = true;
      }
    },
    setFilter: function(option) {
      this.selectedFilter = option;
    },
    setEmotion: function(option) {
      if (this.selectedCause.includes(option)) {
        this.selectedCause = this.selectedCause.filter(
          (filter) => filter != option
        );
      } else {
        this.selectedCause.push(option);
      }
    },
    filtersDone: function() {
      this.mobileAiFilter = false;
      this.mobileEmotionFilter = false;
      EventBus.$emit("mobileAiFilter", false);
      this.page = 0;
      this.comments = [];
      this.close();
      this.getComments();
    },
    showFilter: function() {
      this.mobileAiFilter = true;
      EventBus.$emit("mobileAiFilter", true);
    },
    showEmotionFilter: function() {
      this.mobileEmotionFilter = true;
      EventBus.$emit("mobileAiFilter", true);
    },
    removeTag: function(t) {
      this.search = this.search.filter((q) => q != t);
    },

    disabledDates: function(date) {
      return this.$disabledDates(date);
    },
    checkSearchValue: function(query) {
      if (query.length < 3) {
        this.$notify({
          group: "info",
          text: "Min. 3 character needed",
        });
        this.shakeSearch = true;
        setTimeout(() => {
          this.shakeSearch = false;
        }, 500);

        return false;
      } else {
        return query;
      }
    },
    cancelAction: function(aiaction) {
      HTTP.put(
        "/aiaction/" +
          this.getActiveSocialMediaAccount +
          "?uniqueid=" +
          aiaction.id
      )
        .then(() => {
          this.removeFromList(aiaction.id);

          this.$notify({
            group: "info",
            text: "Success",
          });
        })
        .catch((e) => {
          this.$notify({
            group: "info",
            type: "error",
            text: e.response.data.message,
          });
        });
    },
    deleteAction() {
      const { target: aiaction } = this;
      HTTP.delete(
        "/aiaction/" +
          this.getActiveSocialMediaAccount +
          "?uniqueid=" +
          aiaction.id
      )
        .then(() => {
          this.removeFromList(aiaction.id);
          this.$notify({
            group: "info",
            text: "Success",
          });
        })
        .catch((e) => {
          this.$notify({
            group: "info",
            type: "error",
            text: e.response.data.message,
          });
        });
    },

    removeFromList(id) {
      const index = this.comments.findIndex(({ data }) => data.id === id);
      if (index >= 0) {
        this.comments.splice(index, 1);
      }
    },
  },

  mounted() {
    let customDate = JSON.parse(localStorage.getItem("selectedDate")) || null;
    if (this.quickRange != null && customDate == null) {
      this.setQuickRange(this.quickRange);
    }

    if (customDate != null) {
      customDate[0] = new Date(customDate[0]);
      customDate[1] = new Date(customDate[1]);
      this.date = customDate;
    }

    this.setActiveSocialMediaAccount().then(this.getComments());

    this.scroll();
    this.detailScroll();

    EventBus.$on("cancelAction", (aiaction) => {
      this.cancelAction(aiaction);
    });

    EventBus.$on("setRange", (range) => {
      this.setRange(range);
    });
  },
  beforeDestroy: function() {
    EventBus.$off("cancelAction");
    EventBus.$off("setRange");
  },
  computed: {
    ...mapGetters(["getActiveSocialMedia", "getActiveSocialMediaAccount"]),
    selectedFilterCount: function() {
      return this.selectedFilter.length;
    },
    selectedCauseCount: function() {
      return this.selectedCause.length;
    },
  },
  watch: {
    search: function() {
      this.clear();
      let searchQuery = "";
      let i = 0;

      this.search.forEach((q) => {
        if (i != 0) {
          searchQuery += ",";
        }
        if (q.length > 2) {
          searchQuery += q;
          i++;
        }
      });

      this.searchQuery = searchQuery;
    },
    searchQuery: function() {
      if (!this.searchQuery) {
        this.page = 0;
        this.comments = [];
        this.getComments();
        this.mobileSearchActive = false;
      } else {
        this.getComments();
      }
    },
    order: function() {
      this.page = 0;
      this.endOfComments = false;
      this.comments = [];
      this.getComments();
    },
    orderBy: function() {
      this.page = 0;
      this.endOfComments = false;
      this.comments = [];
      this.getComments();
    },
    selectedFilter: function() {
      if (this.mobileAndTabletCheck != false) {
        this.page = 0;
        this.endOfComments = 0;
        this.comments = [];
        this.close();
        this.getComments();
      }
    },
    selectedCause: function() {
      if (this.mobileAndTabletCheck != false) {
        this.page = 0;
        this.comments = [];
        this.endOfComments = 0;
        this.close();
        this.getComments();
      }
    },
    date: {
      deep: true,
      handler: function() {
        localStorage.setItem("selectedDate", JSON.stringify(this.date));
        this.clear();
        if (this.date[0]) {
          this.range = null;
          this.start = this.date[0].getTime() / 1000;
          this.finish = this.date[1].getTime() / 1000 + 86399000;
          this.comments = [];
          this.subcomments = [];
          this.page = 0;
          this.getComments();
        } else {
          this.range = "ALL";
          this.quickRange = null;
          this.start = null;
          this.finish = null;
          this.comments = [];
          this.subcomments = [];
          this.page = 0;
          // this.getComments();
        }
      },
    },
    "$route.params.accountId": function() {
      if (
        Object.keys(this.$route.query).length === 0 &&
        this.$route.query.constructor === Object
      ) {
        this.page = 0;
        this.comments = [];
        this.subcomments = [];
        this.selectedComment = {
          id: null,
        };
        this.selectedCommentPost = null;
        this.endOfComments = false;
        this.selectedFilter = ["All Status"];
        this.selectedCause = ["All Cause"];

        this.setActiveSocialMediaAccount(this.$route.params.accountId);
        this.getComments();
      }
    },
    mobileOrderModal: function() {
      EventBus.$emit("mobileAiFilter", this.mobileOrderModal);
    },
    mobileRangeModal: function() {
      EventBus.$emit("mobileAiFilter", this.mobileRangeModal);
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/colors.scss";

#page--ai-actions {
  .filter--status {
    .selected {
      font-weight: 700;
    }
  }
}

.mobile-filter {
  margin-bottom: 15px !important;

  * {
    transition: all 0.3s ease;
  }
}

@media (max-width: 767.98px) {
  .mobile-search {
    width: 1px;
    margin-top: -29px;
    margin-left: 10px;
    height: 29px;
    line-height: 29px;
    opacity: 0;

    .input-tag {
      display: none !important;
    }
  }

  .mobile-search.active {
    opacity: 1;
    width: 100%;
    padding-left: 30px;
  }

  .group-select {
    padding: 0;

    span {
      width: 30px !important;
      height: 30px !important;
      border-radius: 30px;
      width: auto;
      line-height: 30px !important;
      position: relative;
    }

    .badge {
      position: absolute;
      top: -3px;
      right: -3px;
      background: $brand-orange;
      width: 15px !important;
      height: 15px !important;
      line-height: 11px !important;
      border-radius: 50%;
      color: $white;
      z-index: 9 !important;
    }

    svg,
    img {
      width: 18px;
      height: auto;
      margin: 0px;
    }
  }

  .mobile {
    .dropdown-menu {
      margin-left: 10px;
      margin-top: 90px;
    }
  }

  .mobile-filter {
    .tags {
      margin-top: 10px;

      span {
        &:first-child {
          margin-left: 0;
        }

        background: $brand-orange;
        width: auto;
        border-radius: 30px;
        padding: 0px 5px;
        color: $white;
      }

      i {
        font-style: normal;
        font-weight: bold;
      }
    }
  }
}
</style>
