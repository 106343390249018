<template>
  <svg
    id="_008-angry"
    data-name="008-angry"
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <path
      id="Path_729"
      data-name="Path 729"
      d="M119.794,102.1a1.613,1.613,0,0,0,1.192-2.7l-.012-.015-1.045-1.115a.469.469,0,1,0-.684.641l.04.043a1.614,1.614,0,0,0,.509,3.145Zm0-2.29a.676.676,0,1,1-.676.676A.677.677,0,0,1,119.794,99.806Z"
      transform="translate(-114.487 -95.052)"
      :fill="filled ? '#c40d3c' : '#5a5a5a'"
    />
    <path
      id="Path_730"
      data-name="Path 730"
      transform="translate(0)"
      :fill="filled ? '#c40d3c' : '#5a5a5a'"
    />
    <path
      id="Path_731"
      data-name="Path 731"
      d="M292.123,102.158h.015a1.614,1.614,0,0,0,.523-3.141l.04-.042a.469.469,0,1,0-.678-.647l-1.055,1.1,0,.005a1.614,1.614,0,0,0,1.159,2.721Zm.489-1.131a.676.676,0,0,1-1.15-.489h0a.676.676,0,0,1,.676-.67h.006a.676.676,0,0,1,.468,1.159Z"
      transform="translate(-281.444 -95.114)"
      :fill="filled ? '#c40d3c' : '#5a5a5a'"
    />
    <path
      id="Path_732"
      data-name="Path 732"
      d="M196.363,298.165a.469.469,0,0,0,0,.938h0v.1a.469.469,0,0,0,.467.471h0a.469.469,0,0,0,.469-.467V299.1h.935v.106a.469.469,0,0,0,.469.466h0a.469.469,0,0,0,.466-.471v-.1h0a.469.469,0,1,0,0-.938h-.01l0-.732h.014a.469.469,0,1,0,0-.938h-.019v-.106a.469.469,0,0,0-.469-.466h0a.469.469,0,0,0-.466.471v.1h-.909v-.061a.469.469,0,0,0-.467-.471h0a.469.469,0,0,0-.469.467v.064h-.016a.469.469,0,1,0,0,.938h.012l0,.732Zm.949-.732h.918l0,.732h-.926Z"
      transform="translate(-189.772 -286.675)"
      :fill="filled ? '#c40d3c' : '#5a5a5a'"
    />
    <path
      id="Path_733"
      data-name="Path 733"
      d="M124.807,295.475a.469.469,0,0,0,.469-.469v-1.332a.469.469,0,0,0-.938,0v1.332A.469.469,0,0,0,124.807,295.475Z"
      transform="translate(-120.452 -284.042)"
      :fill="filled ? '#c40d3c' : '#5a5a5a'"
    />
    <ellipse
      id="Ellipse_532"
      data-name="Ellipse 532"
      cx="0.469"
      cy="0.469"
      rx="0.469"
      ry="0.469"
      transform="translate(3.864 12.063)"
      :fill="filled ? '#c40d3c' : '#5a5a5a'"
    />
    <path
      id="Path_734"
      data-name="Path 734"
      d="M355.891,345.614a.469.469,0,0,0,.469-.469v-1.332a.469.469,0,1,0-.938,0v1.332A.469.469,0,0,0,355.891,345.614Z"
      transform="translate(-344.315 -332.614)"
      :fill="filled ? '#c40d3c' : '#5a5a5a'"
    />
    <ellipse
      id="Ellipse_533"
      data-name="Ellipse 533"
      cx="0.469"
      cy="0.469"
      rx="0.469"
      ry="0.469"
      transform="translate(11.13 9.163)"
      :fill="filled ? '#c40d3c' : '#5a5a5a'"
    />
    <path
      id="Path_735"
      data-name="Path 735"
      d="M16,8.007A8,8,0,0,0,2.343,2.35,7.948,7.948,0,0,0,0,8.007a8.058,8.058,0,0,0,1.653,4.87v1.135a.469.469,0,0,0,.469.469H3.3A7.667,7.667,0,0,0,8,16.007a7.686,7.686,0,0,0,4.7-1.526h1.175a.469.469,0,0,0,.469-.469V12.88A8.347,8.347,0,0,0,16,8.007Zm-15.063,0A7.063,7.063,0,0,1,12.994,3.013a7.016,7.016,0,0,1,2.069,4.994,7.2,7.2,0,0,1-.715,3.059V8.287a.469.469,0,0,0-.469-.469H2.121a.469.469,0,0,0-.469.469V11.1a7.089,7.089,0,0,1-.715-3.09ZM8,15.069a6.964,6.964,0,0,1-2.869-.588h5.734A6.961,6.961,0,0,1,8,15.069Zm5.41-1.526H2.59V8.756H13.41Z"
      transform="translate(0 -0.007)"
      :fill="filled ? '#c40d3c' : '#5a5a5a'"
    />
  </svg>
</template>

<script>
export default {
  name: "profanity",
  props: ["filled"],
};
</script>
