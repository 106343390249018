<template>
  <div class="setting wrap">
    <div class="head">
      <img @error="(e) => (e.target.src = $defaultCardAvatar)"
        src="@/assets/images/account-settings/negative.svg"
        alt="Email Settings" />
      <span>Auto Hide</span>
    </div>
    <div class="body">
      <div>
        Auto hide ad/spam comments
        <label class="switch">
          <input type="checkbox"
            v-model="settings.deleteAdContent"
            class="default primary" />
          <span class="slider round"></span>
        </label>
      </div>
      <div>
        Auto hide profanity comments
        <label class="switch">
          <input type="checkbox"
            v-model="settings.deleteAbusive"
            class="default primary" />
          <span class="slider round"></span>
        </label>
      </div>
      <div>
        Auto hide negative comments
        <label class="switch">
          <input type="checkbox"
            v-model="settings.deleteNegative"
            class="default primary" />
          <span class="slider round"></span>
        </label>
      </div>
      <div>
        Auto hide hate speech comments
        <label class="switch">
          <input type="checkbox"
            v-model="settings.deleteHateSpeech"
            class="default primary" />
          <span class="slider round"></span>
        </label>
      </div>
      <div class="sub"
        v-show="false"
        :class="{ disabled: !settings.deleteHateSpeech }">
        Immediately
        <label class="switch">
          <input type="checkbox"
            v-model="settings.negativeDelImmediately"
            class="default primary" />
          <span class="slider round"></span>
        </label>
      </div>
      <div class="sub"
        :class="{ disabled: !settings.deleteHateSpeech }"
        v-if="false">
        After
        <select name="#"
          v-model="settings.negativeDelNumber">
          <option v-for="i in 30"
            :value="i"
            :key="i">{{ i }}</option>
        </select>
        &nbsp;
        <select name="#"
          v-model="settings.negativeDelFreq">
          <option value="DAYS">Days</option>
          <option value="HOURS">Hours</option>
          <option value="MINUTES">Minutes</option>
        </select>
        <label class="switch">
          <input type="checkbox"
            v-model="settings.negativeDelLater"
            class="default primary" />
          <span class="slider round"></span>
        </label>
      </div>
    </div>
  </div>
</template>
<script>
import EventBus from "@/components/event-bus";
export default {
  name: "negative-comments",
  props: ["settings"],
  created() {
    // console.log(this.settings);
  },
  watch: {
    "settings.negativeDelNumber": function (value) {
      let data = {
        negativeDelNumber: value
      };
      EventBus.$emit("updateAccountSetting", data);
    },
    "settings.negativeDelImmediately": function (value) {
      if (value == true) {
        this.settings.negativeDelLater = false;
      }
      let data = {
        negativeDelImmediately: value
      };
      EventBus.$emit("updateAccountSetting", data);
    },
    "settings.negativeDelLater": function (value) {
      if (value == true) {
        this.settings.negativeDelImmediately = false;
      }
      let data = {
        negativeDelLater: value
      };
      EventBus.$emit("updateAccountSetting", data);
    },
    "settings.deleteAbusive": function (value) {
      let data = {
        deleteAbusive: value
      };
      EventBus.$emit("updateAccountSetting", data);
    },
    "settings.deleteAdContent": function (value) {
      let data = {
        deleteAdContent: value
      };
      EventBus.$emit("updateAccountSetting", data);
    },
    "settings.negativeDelFreq": function (value) {
      let data = {
        negativeDelFreq: value
      };
      EventBus.$emit("updateAccountSetting", data);
    },
    "settings.deleteNegative": function (value) {
      let data = {
        deleteNegative: value
      };
      EventBus.$emit("updateAccountSetting", data);
    },
    "settings.deleteHateSpeech": function (value) {
      let data = {
        deleteHateSpeech: value
      };

      if (value) {
        this.settings.negativeDelImmediately = value;
      } else {
        this.settings.negativeDelImmediately = false;
        this.settings.negativeDelLater = false;
      }

      EventBus.$emit("updateAccountSetting", data);
    }
  }
};
</script>
