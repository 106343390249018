<template>
  <div class="chart-card">
    <div class="chart-header">
      <span class="count"> 12,5 K </span>
      <span class="sub-title">Post Interactions</span>
      <span class="d-block chart-desc">
        <status :status="false"></status> %50 Toplam Sayfa Görüntülenmeleri
      </span>
    </div>
    <pie-chart
      :graph="graph"
      class="pie-chart"
      :options="options"
      :height="180"
    ></pie-chart>
  </div>
</template>
<script>
import PieChart from "@/components/charts/components/PieChart";
import statusTriangle from "@/components/icons/triangle";

export default {
  name: "PieChart",
  props: ["graph"],
  components: {
    "pie-chart": PieChart,
    status: statusTriangle,
  },
  data() {
    return {
      options: {
        showAllTooltips: false,
        cutoutPercentage: 50,
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              return (
                data["labels"][tooltipItem["index"]] +
                ": " +
                (
                  (chartData.length != 1
                    ? data["datasets"][0]["data"][tooltipItem["index"]] * 100
                    : "100") / sum
                ).toFixed() +
                "%"
              );
            },
          },
        },
        responsive: true,
        legend: {
          display: false,
        },
      },
    };
  },
};
</script>


<style lang="scss" scoped>
@import "@/scss/colors.scss";
.wrap {
  background: $white;
  min-height: 40px;
  border-radius: 30px;
  padding: 15px;
  .head {
    font-weight: bold;
    color: $darker;
  }
}
.report {
  .pie-chart {
    margin: 15px;
  }
}
</style>
