<template>
  <div>
    <div class="col-12 wrap">
      <div class="close" @click="close">
        <close :dark="true"></close>
      </div>

      <div class="comment">
        <div class="col p-0">
          <img @error="(e) => (e.target.src = $defaultCardAvatar)" class="profile" src="@/assets/images/profile.png" alt="Profile" v-if="!selectedAuthor.profileImg" />
          <img @error="(e) => (e.target.src = $defaultCardAvatar)" class="profile" :src="selectedAuthor.profileImg" alt="Profile" v-if="selectedAuthor.profileImg" />
          <div class="info">
            <span class="name">{{ selectedAuthor.name }}</span>
          </div>
        </div>
      </div>
      <div class="row mr-0 ml-0 mb-3 mt-0">
        <div class="col-6">
          <div class="row">
            <div class="col-8 pr-0">
              <status :status="'positive'"></status>
              Positive
            </div>
            <div class="col">{{ selectedAuthor.polarityCounts.positive }}</div>
          </div>
        </div>
        <div class="col-6">
          <div class="row">
            <div class="col-8 pr-0">
              <status :status="'neutral'"></status>
              Neutral
            </div>
            <div class="col">{{ selectedAuthor.polarityCounts.neutral }}</div>
          </div>
        </div>
        <div class="col-6">
          <div class="row">
            <div class="col-8 pr-0">
              <status :status="'negative'"></status>

              Negative
            </div>
            <div class="col">{{ selectedAuthor.polarityCounts.negative }}</div>
          </div>
        </div>
        <div class="col-6">
          <div class="row">
            <div class="col-8 pr-0">
              <status :status="'question'"></status>

              Questions
            </div>
            <div class="col">{{ selectedAuthor.polarityCounts.question }}</div>
          </div>
        </div>
        <div class="col-6">
          <div class="row">
            <div class="col-8 pr-0">
              <status :status="'hidden'"></status>

              Hidden
            </div>
            <div class="col">{{ selectedAuthor.polarityCounts.hidden }}</div>
          </div>
        </div>
        <div class="col-6">
          <div class="row">
            <div class="col-8 pr-0">
              <status status="notassigned"></status>

              Not Assigned
            </div>
            <div class="col">{{ selectedAuthor.polarityCounts.notassigned }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import close from '@/components/close';
import status from '@/components/dot';
import { mapGetters } from 'vuex';

export default {
  name: 'Right-Author-View',
  props: ['selectedAuthor'],
  data() {
    return {
      selectedFilters: [],
      order: 'DESC',
    };
  },
  components: {
    close,
    status,
  },
  methods: {
    filterSubComments: function (filter) {
      if (this.selectedFilters.includes(filter)) {
        this.selectedFilters = this.selectedFilters.filter((item) => item != filter);
      } else {
        this.selectedFilters.push(filter);
      }
      let text = '';

      this.selectedFilters.forEach((f, index) => {
        if (index > 0) {
          text += ',';
        }
        text += f;
      });

      this.$emit('filterSubComment', text, this.order);
    },
    close: function () {
      this.$emit('close');
    },
    switchOrder: function () {
      if (this.order == 'ASC') {
        this.order = 'DESC';
      } else {
        this.order = 'ASC';
      }
      this.$emit('filterSubComment', this.filterText, this.order);
    },
  },
  computed: {
    ...mapGetters(['getActiveSocialMedia']),
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/colors.scss';
.wrap {
  border-radius: 30px;
  background: $white;
  padding: 0;
  margin-bottom: 20px;
  transition: all 0.25s ease;
  overflow: hidden;
  .comment {
    min-height: 80px;
    padding: 15px 15px 10px;
    position: relative;
    .info {
      float: left;
      div {
        display: block;
      }
    }
    .profile {
      width: 45px;
      height: 45px;
      object-fit: cover;
      object-position: center;
      border-radius: 50%;
      float: left;
      margin-right: 10px;
    }
    .name {
      font-weight: bold;
    }
    .city {
      font-size: 12px;
      position: relative;
      top: -5px;
    }
    .imgs {
      width: 100%;
      clear: both;
      position: relative;
      margin-top: 20px;
      img {
        width: 100px;
        height: 100px;
        object-fit: cover;
        object-position: center;
        border-radius: 15px;
      }
    }
  }

  .text {
    padding: 0;
    clear: both;
    margin: 10px 0px;
  }
  .media {
    margin: 10px 0px;
    img {
      margin: auto;
    }
  }
  .status {
    .dot {
      margin-bottom: -5px;
      margin-right: 2px;
      transform: scale(0.8);
    }
  }
  .statuses {
    display: flex;
    padding: 10px 0px 0px;
    font-size: 14px;
    margin: 5px 0px -3px 0px;
    justify-content: space-around;
    border-top: 1px solid lighten($black, 95%);
    .status {
      position: relative;
      flex: 1;
      padding-bottom: 2px;
      border-right: 1px solid lighten($black, 95%);
      border-bottom: 7px solid;
      text-align: center;
      cursor: pointer;
      &:last-child {
        border-right: 0px;
      }
    }
    .has-selected {
      .status {
        opacity: 0.2;
      }
      .active {
        opacity: 1 !important;
      }
    }
  }
  .positive {
    border-bottom-color: $positive !important;
  }
  .neutral {
    border-bottom-color: $neatral !important;
  }
  .negative {
    border-bottom-color: $negative !important;
  }
  .question {
    border-bottom-color: $question !important;
  }
  .hidden {
    border-bottom-color: $hidden !important;
  }
  .notassigned {
    border-bottom-color: $notassigned !important;
  }
}
.has-selected {
  .status {
    opacity: 0.2;
  }
  .active {
    opacity: 1 !important;
  }
}
.close {
  color: lighten($black, 30%);
  position: absolute;
  right: 20px;
  top: 10px;
  z-index: 99;
  cursor: pointer;
}
</style>
