<template>
  <!-- Filter -->
  <div class="row filter d-md-block subcomments-filter">
    <!-- Mobile Emotion Filter Start -->
    <div
      class="mobile-ai-filter mvd"
      :class="{ show: mobileAiFilter }"
      :style="{ top: cposition }"
    >
      <div class="close-trigger" @click="filtersDone"></div>
      <div class="wrap">
        <div class="head">
          <div class="row">
            <div class="col">
              <span class="h5">Filter</span>
            </div>
            <div class="col text-right done" @click="filtersDone">Done</div>
          </div>
        </div>
        <div class="body">
          <div class="row" @click="setFilter('positive')">
            <div class="col-8">
              <status :status="'positive'"></status>Positive
            </div>
            <div class="col text-right">
              <img @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/checked.svg"
                v-if="selectedFilter.includes('positive')"
              />
            </div>
          </div>
          <div class="row" @click="setFilter('negative')">
            <div class="col-8">
              <status :status="'negative'"></status>Negative
            </div>
            <div class="col text-right">
              <img @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/checked.svg"
                v-if="selectedFilter.includes('negative')"
              />
            </div>
          </div>
          <div class="row" @click="setFilter('neutral')">
            <div class="col-8">
              <status :status="'neutral'"></status>Neutral
            </div>
            <div class="col text-right">
              <img @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/checked.svg"
                v-if="selectedFilter.includes('neutral')"
              />
            </div>
          </div>
          <div class="row" @click="setFilter('hidden')">
            <div class="col-8"><status :status="'hidden'"></status>Hidden</div>
            <div class="col text-right">
              <img @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/checked.svg"
                v-if="selectedFilter.includes('hidden')"
              />
            </div>
          </div>
          <div class="row" @click="setFilter('question')">
            <div class="col-8">
              <status :status="'question'"></status>Question
            </div>
            <div class="col text-right">
              <img @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/checked.svg"
                v-if="selectedFilter.includes('question')"
              />
            </div>
          </div>
          <div class="row" @click="setFilter('notassigned')">
            <div class="col-8"><status></status>Not Assigned</div>
            <div class="col text-right">
              <img @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/checked.svg"
                v-if="selectedFilter.includes('notassigned')"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <div
      class="mobile-ai-filter mvd"
      :class="[{ show: mobileEmotionFilter }]"
      :style="{ top: cposition }"
    >
      <div class="close-trigger" @click="filtersDone"></div>
      <div class="wrap">
        <div class="head">
          <div class="row">
            <div class="col-9">
              <span class="h5">Filter</span>
            </div>
            <div class="col text-right done" @click="filtersDone">Done</div>
          </div>
        </div>
        <div class="body">
          <div
            class="row"
            v-for="(emotion, index) in feelings.slice(1)"
            @click="setEmotion(emotion.toLowerCase())"
            :key="index"
          >
            <div class="col-9">>{{ emotion }}</div>
            <div class="col text-right">
              <img @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/checked.svg"
                v-if="selectedEmotion.includes(emotion.toLowerCase())"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Mobile Emotion Filter End -->
    <!-- Mobile Order Modal Start -->
    <mobileSortFilter
      :mobileOrderModal="mobileOrderModal"
      :f_order="order"
      :f_orderBy="orderBy"
      :style="{ top: cposition }"
      class="mvd"
      @close="mobileSortModalSetAndClose"
    ></mobileSortFilter>
    <!-- Mobile Order Modal End -->
    <div class="col-12 left-filter">
      <!-- Mobile Filter -->
      <div class="col-12 left d-flex d-sm-none mobile-filter">
        <div class="group-select col-4">
          <span @click="showFilter">
            <status :status="'all'"></status>

            <span
              class="badge"
              v-if="selectedFilterCount > 1 && selectedFilter[1] != 'All'"
              >{{ selectedFilterCount - 1 }}
            </span>
          </span>
          <span @click="showEmotionFilter">
            <component :is="'neutral'"></component>
            <span
              class="badge"
              v-if="
                selectedEmotionCount > 1 && selectedEmotion[1] != 'Emotions'
              "
              >{{ selectedEmotionCount - 1 }}
            </span>
          </span>
        </div>
        <div class="text-right mobile col-4">
          <span @click="mobileOrderModal = true">
            <img @error="(e) => (e.target.src = $defaultCardAvatar)" src="@/assets/images/filter.svg" alt="Filter" />
          </span>
        </div>
      </div>
      <!-- Mobile Filter -->

      <!-- Left -->
      <div class="col-12 left d-none d-sm-flex">
        <div class="col filters p-0 has-icon elem">
          <i
            class="dot main all"
            v-if="selectedFilter[0] == 'All' && selectedFilter.length == 1"
          ></i>
          <v-select
            v-model="selectedFilter"
            :options="filters"
            :class="{
              selectedFilterPosition: selectedFilter.length > 1,
            }"
            :clearable="true"
            :searchable="false"
            :closeOnSelect="false"
            multiple
            :tabindex="10"
          >
            <template
              slot="selected-option-container"
              slot-scope="selectedFilter"
            >
              <div>
                <i
                  class="dot main positive"
                  v-if="selectedFilter.option.label == 'Positive'"
                ></i>
                <i
                  class="dot main negative"
                  v-if="selectedFilter.option.label == 'Negative'"
                ></i>
                <i
                  class="dot main neutral"
                  v-if="selectedFilter.option.label == 'Neutral'"
                ></i>
                <i
                  class="dot main question"
                  v-if="selectedFilter.option.label == 'Question'"
                ></i>
                <i
                  class="dot main hidden"
                  v-if="selectedFilter.option.label == 'Hidden'"
                ></i>
                <i
                  class="dot main spam"
                  v-if="selectedFilter.option.label == 'Spam'"
                ></i>
                <i
                  class="dot main notassigned"
                  v-if="selectedFilter.option.label == 'Not Assigned'"
                ></i>

                <div
                  class="all"
                  v-if="
                    selectedFilter.option.label.trim() == 'All' &&
                    selectedFilterCount == 1
                  "
                >
                  Sentiments
                </div>
              </div>
            </template>

            <template slot="option" slot-scope="option">
              <div
                @click="option.label == 'All' ? (selectedFilter = ['All']) : ''"
              >
                <i
                  class="dot"
                  :class="[
                    { all: option.label == 'All' },
                    { positive: option.label == 'Positive' },
                    { negative: option.label == 'Negative' },
                    { neutral: option.label == 'Neutral' },
                    { question: option.label == 'Question' },
                    { hidden: option.label == 'Hidden' },
                    { spam: option.label == 'Spam' },
                  ]"
                ></i>
                {{ option.label }}
                <small v-if="option.label == 'Positive'"
                  >( {{ polarityCounts.positive }} )</small
                >
                <small v-if="option.label == 'All'"
                  >( {{ totalComments }} )</small
                >
                <small v-if="option.label == 'Negative'"
                  >( {{ polarityCounts.negative }} )</small
                >
                <small v-if="option.label == 'Neutral'"
                  >( {{ polarityCounts.neutral }} )</small
                >
                <small v-if="option.label == 'Hidden'"
                  >( {{ polarityCounts.hidden }} )</small
                >
                <small v-if="option.label == 'Question'"
                  >( {{ polarityCounts.question }} )</small
                >
                <small v-if="option.label == 'Not Assigned'"
                  >( {{ polarityCounts.notassigned }} )</small
                >
                <small v-if="option.label == 'Spam'"
                  >( {{ polarityCounts.spam }} )</small
                >

                <template v-for="(filter, index) in selectedFilter">
                  <span
                  @click.stop.prevent=""
                    :key="index"
                    class="remove"
                    @click="removeFilter(option)"
                    v-if="
                      option.label.toLowerCase() == filter.toLowerCase() &&
                      option.label != 'All'
                    "
                  >
                    <i class="fas fa-times" ></i>
                  </span>
                </template>
              </div>
            </template>
          </v-select>
        </div>
        <!-- Emotions Filter -->
        <div class="col filters p-0 has-icon elem emotions-filter">
          <!-- <component :is="'neutral'" class="main-emoji"></component> -->
          <i
            class="dot main all"
            v-if="selectedEmotion[0] == 'All' && selectedEmotion.length == 1"
          ></i>
          <v-select
            v-model="selectedEmotion"
            :options="feelings"
            :clearable="true"
            :searchable="false"
            :closeOnSelect="false"
            multiple
            :tabindex="10"
            :class="{
              selectedEmotionPosition: selectedEmotion.length > 1,
            }"
          >
            <template
              slot="selected-option-container"
              slot-scope="selectedEmotion"
            >
              <div>
                <div
                  class="all"
                  v-if="
                    selectedEmotion.option.label.trim() == 'All' &&
                    selectedEmotionCount == 1
                  "
                >
                  Offensive
                </div>
              </div>
            </template>

            <template slot="option" slot-scope="option">
              <div
                @click="
                  option.label == 'All' ? (selectedEmotion = ['All']) : ''
                "
              >
                {{ option.label }}

                <template v-for="(filter, index) in selectedEmotion">
                  <span
                    :key="index"
                    class="remove"
                    @click.stop.prevent=""
                    @click="removeFeeling(option)"
                    v-if="
                      option.label.toLowerCase() == filter.toLowerCase() &&
                      option.label != 'All'
                    "
                  >
                    <i class="fas fa-times" ></i>
                  </span>
                </template>
              </div>
            </template>
          </v-select>
        </div>
        <!-- Emotions Filter End -->

        <button
          class="btn-filter elem"
          type="button"
          id="dropdownMenu"
          data-toggle="dropdown"
        >
          <img @error="(e) => (e.target.src = $defaultCardAvatar)"
            src="@/assets/images/comment.svg"
            alt
            v-if="orderBy == 'numOfReplies'"
          />
          <img @error="(e) => (e.target.src = $defaultCardAvatar)"
            src="@/assets/images/sort/desc.svg"
            alt
            v-if="order == 'DESC' && orderBy == 'createdTime'"
          />
          <img @error="(e) => (e.target.src = $defaultCardAvatar)"
            src="@/assets/images/sort/asc.svg"
            alt
            v-if="order == 'ASC' && orderBy == 'createdTime'"
          />
          <img @error="(e) => (e.target.src = $defaultCardAvatar)"
            src="@/assets/images/sort/heart.svg"
            alt
            v-if="orderBy == 'numOfLikes'"
          />
        </button>
        <div class="dropdown-menu order animate slideIn">
          <button
            class="dropdown-item"
            :class="{
              selected: order == 'DESC' && orderBy == 'createdTime',
            }"
            type="button"
            @click="
              order = 'DESC';
              orderBy = 'createdTime';
            "
          >
            <img @error="(e) => (e.target.src = $defaultCardAvatar)"
              src="@/assets/images/sort/desc.svg"
              @click="mobileSearchActive = true"
              alt
            />
            Newest
          </button>
          <button
            class="dropdown-item"
            :class="{
              selected: order == 'ASC' && orderBy == 'createdTime',
            }"
            type="button"
            @click="
              order = 'ASC';
              orderBy = 'createdTime';
            "
          >
            <img @error="(e) => (e.target.src = $defaultCardAvatar)"
              src="@/assets/images/sort/asc.svg"
              @click="mobileSearchActive = true"
              alt
            />
            Oldest
          </button>
          <button
            class="dropdown-item"
            :class="{
              selected: order == 'DESC' && orderBy == 'numOfLikes',
            }"
            type="button"
            @click="
              order = 'DESC';
              orderBy = 'numOfLikes';
            "
          >
            <img @error="(e) => (e.target.src = $defaultCardAvatar)"
              src="@/assets/images/sort/heart.svg"
              @click="mobileSearchActive = true"
              alt
            />
            Most Liked
          </button>
          <button
            class="dropdown-item"
            :class="{
              selected: order == 'DESC' && orderBy == 'numOfReplies',
            }"
            type="button"
            @click="
              order = 'DESC';
              orderBy = 'numOfReplies';
            "
          >
            <img @error="(e) => (e.target.src = $defaultCardAvatar)"
              src="@/assets/images/comment.svg"
              @click="mobileSearchActive = true"
              alt
            />
            Most Replies
          </button>
        </div>
        <!-- Left End -->
      </div>
    </div>
  </div>
  <!-- Filter End -->
</template>
<script>
import EventBus from "@/components/event-bus";
import status from "@/components/dot";
// Emotions - Will be improved
import heart from "@/components/icons/heart";
import fun from "@/components/icons/emotions/fun";
import happiness from "@/components/icons/emotions/happiness";
import hate from "@/components/icons/emotions/hate";
import love from "@/components/icons/emotions/love";
import neutral from "@/components/icons/emotions/neutral";
import relief from "@/components/icons/emotions/relief";
import sadness from "@/components/icons/emotions/sadness";
import surprise from "@/components/icons/emotions/surprise";
import worry from "@/components/icons/emotions/worry";
// Filter
import mobileSortFilter from "@/components/filters/mobile/sort";

export default {
  name: "subfilters",
  props: ["polarityCounts", "totalComments"],
  data() {
    return {
      filters: [
        "All",
        "Positive",
        "Neutral",
        "Negative",
        "Hidden",
        "Question",
        "Spam",
        "Not Assigned",
      ],
      feelings: ["All", "Hate Speech", "Profanity"],
      selectedFilter: ["All"],
      selectedEmotion: ["All"],
      order: "DESC",
      orderBy: "createdTime",
      activeAccountPhoto: "/images/no-image.jpg",
      mobileAiFilter: false,
      mobileEmotionFilter: false,
      mobileOrderModal: false,
      isPinned: false,
      position: 0,
    };
  },
  components: {
    heart,
    status,
    fun,
    happiness,
    hate,
    love,
    neutral,
    relief,
    sadness,
    surprise,
    worry,
    mobileSortFilter,
  },
  created() {},
  mounted() {
    let detailsView = document.getElementById("rdetails");

    detailsView.onscroll = () => {
      this.isPinned = detailsView.scrollTop > 150 ? true : false;
      this.position = detailsView.scrollTop;
    };
  },
  methods: {
    removeFilter: function (option) {
      this.selectedFilter = this.selectedFilter.filter(
        (filter) => filter != option.label
      );
    },
    removeFeeling: function (option) {
      this.selectedEmotion = this.selectedEmotion.filter(
        (filter) => filter != option.label
      );
    },
    showFilter: function () {
      this.mobileAiFilter = true;
      EventBus.$emit("mobileAiFilter", true);
    },
    showEmotionFilter: function () {
      this.mobileEmotionFilter = true;
      EventBus.$emit("mobileAiFilter", true);
    },
    filtersDone: function () {
      this.mobileAiFilter = false;
      this.mobileEmotionFilter = false;
      EventBus.$emit("mobileAiFilter", false);
    },
    setFilter: function (option) {
      if (this.selectedFilter.includes(option)) {
        this.selectedFilter = this.selectedFilter.filter(
          (filter) => filter != option
        );
      } else {
        this.selectedFilter.push(option);
      }
    },
    setEmotion: function (option) {
      if (this.selectedEmotion.includes(option)) {
        this.selectedEmotion = this.selectedEmotion.filter(
          (filter) => filter != option
        );
      } else {
        this.selectedEmotion.push(option);
      }
    },
    mobileSortModalSetAndClose: function (data) {
      this.order = data.order;
      this.orderBy = data.orderBy;
      this.mobileOrderModal = false;
    },
  },
  computed: {
    filterText: function () {
      let filterText = "";

      this.selectedFilter.forEach((item, index) => {
        if (item != "All") {
          filterText += item.toLowerCase().split(" ").join("");
          if (index != this.selectedFilterCount - 1) {
            filterText += ",";
          }
        }
      });

      // Hate Speech Filter
      if (this.selectedEmotion.includes("Hate Speech")) {
        filterText += "&emotion=hate";
      }

      // Profanity Filter
      if (this.selectedEmotion.includes("Profanity")) {
        filterText += "&profanity=true";
      }

      

      // let emotionLength = this.selectedEmotion.length;

      // filterText += "&emotion=";
      // if (emotionLength > 0) {
      //   this.selectedEmotion.forEach((item, index) => {
      //     if (item != "All") {
      //       filterText += item.toLowerCase();
      //       if (index != emotionLength - 1) {
      //         filterText += ",";
      //       }
      //     }
      //   });
      // }

      return filterText;
    },
    selectedFilterCount: function () {
      return this.selectedFilter.length;
    },
    selectedEmotionCount: function () {
      return this.selectedEmotion.length;
    },
    cposition: function () {
      let contentHeight = document.getElementById("right-content").scrollHeight;
      let diff = contentHeight - this.position + 17;
      return (this.position < 142 ? "-" + diff : "-11") + "px";
    },
  },
  watch: {
    selectedFilter: function () {
      this.$emit("filterSubComment", this.filterText, this.order, this.orderBy);
    },
    selectedEmotion: function () {
      this.$emit("filterSubComment", this.filterText, this.order, this.orderBy);
    },
    order: function () {
      this.$emit("filterSubComment", this.filterText, this.order, this.orderBy);
    },
    orderBy: function () {
      this.$emit("filterSubComment", this.filterText, this.order, this.orderBy);
    },
  },
};
</script>

<style >
.mobile-ai-filter.mvd {
  position: absolute;
  /* top: -165px; */
}
.pinned {
  /* top: 0px !important; */
}
</style>