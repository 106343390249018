<template>
  <div :class="`comments--${$route.name}`">
    <div
      class="col-12 wrap"
      :class="[{ hiddenComment: hidden }, `wrap--${$route.name}`]"
    >
      <div class="manual" v-if="isManual && false"></div>
      <div
        class="comment-wrap"
        @click="!disableSelect ? selectComment(commentId) : ''"
      >
        <div class="comment col-12">
          <div class="col p-0">
            <img
              @error="(e) => (e.target.src = $defaultCardAvatar)"
              class="profile"
              :src="commenterPhoto"
              alt="Profile"
              @click.stop.prevent="selectComment"
              @click="show"
            />
            <div class="info">
              <div
                v-if="!isCompetitor() || getActiveSocialMedia == 'youtube'"
                @click.stop.prevent="selectComment"
                @click="show"
              >
                <span class="name"> {{ name ? name : "Author Name" }}</span>
                <span class="badge orange" v-if="isUserBlocked()"
                  >Blocked User</span
                >
              </div>
              <div>
                <span class="city" @click.stop.prevent="selectComment">{{
                  location
                }}</span>
              </div>
              <div>
                <span class="likeCount">
                  <heart :isLiked="likeCount > 0 ? true : false"></heart>
                  {{ likeCount }}
                </span>
                <span class="date">
                  {{ timeSince(date) }}
                </span>
              </div>
            </div>
            <div class="clearfix"></div>
          </div>
          <div
            class="col text"
            :class="{
              'has-post-thumb-text':
                !isSub && (postImageUrl || postImageUrlForFacebookAndInstagram),
            }"
            @click="showpost"
          >
            {{ content }}
          </div>

          <div class="imgs" v-if="commentImgs">
            <img
              @error="(e) => (e.target.src = $defaultCardAvatar)"
              v-for="(img, index) in commentImgs"
              :src="img"
              :key="index"
              alt="Comment Image"
            />
          </div>
        </div>

        <div
          class="post-thumb"
          v-if="!isSub && (postImageUrl || postImageUrlForFacebookAndInstagram)"
        >
          <svg
            id="play"
            xmlns="http://www.w3.org/2000/svg"
            width="11"
            height="12.222"
            viewBox="0 0 11 12.222"
            v-if="comment.videoUrl"
          >
            <path
              id="play"
              d="M26.688,5.579,16.911.079A.611.611,0,0,0,16,.612v11a.611.611,0,0,0,.911.533l9.778-5.5a.611.611,0,0,0,0-1.066Z"
              transform="translate(-16 0)"
              fill="#fcfcfc"
            />
          </svg>

          <img
            @error="(e) => (e.target.src = $defaultCardAvatar)"
            v-if="postImageUrl || postImageUrlForFacebookAndInstagram"
            :src="
              getActiveSocialMedia == 'facebook' ||
              getActiveSocialMedia == 'instagram' ||
              getActiveSocialMedia == 'youtube' ||
              $route.name == 'search'
                ? postImageUrlForFacebookAndInstagram
                : postImageUrl
            "
            alt="POST"
          />
        </div>
      </div>

      <div
        class="bottom"
        :class="[
          { hideaction: disableAction == true },
          `bottom--${$route.name}`,
        ]"
      >
        <div
          class="statuses col-8 col-sm-5 col-md-7 d-sm-flex p-0"
          @click="!isCompetitor() ? (editStatus = true) : null"
          v-if="!editStatus"
        >
          <span
            class="status black"
            v-for="(polarity, index) in polarityStringsC"
            :key="index"
          >
            <status :status="polarity.toLowerCase()"></status>
            <span class="text" v-if="statusCount <= 4">{{
              polarity.toLowerCase()
            }}</span>
          </span>

          <span v-if="isQuestion" class="status black">
            <status status="question"></status>
            <span class="text" v-if="statusCount <= 4">Question</span>
          </span>
          <span v-if="isSpam" class="status black">
            <status status="spam"></status>
            <span class="text" v-if="statusCount <= 4">Ad Content</span>
          </span>

          <!-- <span class="status black" v-if="isQuestion">
            
          </span> -->
          <!-- Emotions -->
          <!-- <div class="emotions black">
            <template v-for="(emotion, n) in comment.emotion">
              <span v-if="emotion != 'notassigned'" :key="n">
                <component
                  :is="emotion.toLowerCase()"
                  :class="{ margined: statusCount > 4 }"
                ></component>
                <span class="text" v-if="statusCount <= 4">{{
                  emotion.toLowerCase()
                }}</span>
              </span>
            </template> 
          </div> -->
          <!-- Emotions -->
          <div class="emotions black offensive-wrap">
            <span v-if="comment.emotionGpt.includes('hate')">
              <component :is="'hate'" :filled="true"></component
              ><span v-if="statusCount <= 3">Hate Speech</span>
            </span>

            <span v-if="comment.isSwearing || comment.isProfanityGpt">
              <component :is="'profanity'" :filled="true"></component
              ><span v-if="statusCount <= 3">Profanity</span>
            </span>
          </div>
        </div>

        <div class="col-10 d-none d-sm-flex row" v-if="editStatus">
          <div class="col-12 p-0">
            <span
              class="status"
              :class="{
                'black font-weight-bold': polarityStrings.includes('positive'),
              }"
              @click="updateClassification(commentId, 'positive', false)"
            >
              <status
                :status="{ positive: polarityStrings.includes('positive') }"
              ></status
              >Positive
            </span>
            <span
              class="status"
              :class="{
                'black font-weight-bold': polarityStrings.includes('neutral'),
              }"
              @click="updateClassification(commentId, 'neutral', false)"
            >
              <status
                :status="{ neutral: polarityStrings.includes('neutral') }"
              ></status
              >Neutral
            </span>
            <span
              class="status"
              :class="{
                'black font-weight-bold': polarityStrings.includes('negative'),
              }"
              @click="updateClassification(commentId, 'negative', false)"
            >
              <status
                :status="{ negative: polarityStrings.includes('negative') }"
              ></status
              >Negative
            </span>
            <span
              class="status"
              :class="{ 'black font-weight-bold': isQuestion }"
              @click="updateClassification(commentId, 'question', false)"
            >
              <status :status="{ question: isQuestion }"></status>Question
            </span>
            <span
              class="status"
              :class="{ 'black font-weight-bold': isSpam }"
              @click="updateClassification(commentId, 'spam', false)"
            >
              <status :status="{ spam: isSpam }"></status>Spam
            </span>
            <!-- <span class="status">
              <component :is="'hate'" :filled="false"></component><span>Hate Speech</span>
            </span> -->
          </div>

          <!-- <hr />
          <div class="col-12 statuses p-0 pl-1 edit-emotion-list">
            <span
              v-for="(emotion, n) in emotions"
              :key="n"
              :class="{
                'black font-weight-bold': comment.emotion.includes(
                  emotion.toLowerCase()
                ),
              }"
              @click="updateEmotions(commentId, emotion.toLowerCase(), false)"
            >
              <component :is="emotion.toLowerCase()"></component>
              v-tooltip.top-center="emotion.toLowerCase()"
              {{ emotion }}
            </span>
          </div> -->
        </div>

        <div
          class="col-3 saveStatus"
          :class="{ showStatusEdit: editStatus == true }"
        >
          <button class="btn btn-default" @click="exitEdit(id)">
            <img
              @error="(e) => (e.target.src = $defaultCardAvatar)"
              src="@/assets/images/close.svg"
              alt="Ok"
            />
          </button>
          <button
            class="btn btn-primary"
            @click="updateClassification(commentId, '', true)"
            @click.stop.prevent="selectComment"
          >
            <img
              @error="(e) => (e.target.src = $defaultCardAvatar)"
              src="@/assets/images/tick.svg"
              alt="Ok"
            />
          </button>
        </div>
        <!--  -->
        <div
          class="pr-0 showReplies"
          v-if="!editStatus && !isSub && !hidden && $route.name !== 'search'"
          :class="{ hide: countOfReplies == 0 }"
          @click="getSubComments(commentId)"
        >
          <img
            @error="(e) => (e.target.src = $defaultCardAvatar)"
            src="@/assets/images/comment.svg"
            alt="Replies"
          />
          Replies ({{ countOfReplies }})
        </div>
        <div class="d-flex align-items-center" v-if="$route.name == 'search'">
          <span class="d-block ">
            {{
              socialMediaAccounts.filter(
                (account) => account.accountId == comment.originalUserId
              ) &&
              socialMediaAccounts.filter(
                (account) => account.accountId == comment.originalUserId
              ).length > 0
                ? (activeAccountDetails = socialMediaAccounts.filter(
                    (account) => account.accountId == comment.originalUserId
                  )[0]).accountName
                : ""
            }}
          </span>
          <div v-if="$route.name == 'search'">
            <facebookIcon
              :active="'true'"
              class="social-small-badge"
              v-if="comment.contentType == 'facebook_comment'"
            ></facebookIcon>
            <instagramIcon
              :active="'true'"
              class="social-small-badge"
              v-if="comment.contentType == 'instagram_comment'"
            ></instagramIcon>
            <twitterIcon
              :active="'true'"
              class="social-small-badge"
              v-if="comment.contentType == 'twitter_comment'"
            ></twitterIcon>
            <youtubeIcon
              :active="'true'"
              class="social-small-badge"
              v-if="comment.contentType == 'youtube_comment'"
            ></youtubeIcon>
          </div>
        </div>
        <div
          class="quick-actions mobile-actions d-sm-none m-0 text-right"
          v-if="!isCompetitor()"
        >
          <button
            class="btn-filter btn-unhide mobile"
            @click="updateClassification(commentId, 'hidden', true)"
            v-if="hidden"
          >
            Unhide
          </button>
          <dotMenu
            class="dotmenu dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
          </dotMenu>

          <div class="dropdown-menu dropMenu animate slideInDropMenu text-left">
            <!-- Mobile Dropdown Menu-->

            <button
              class="dropdown-item p-15"
              type="button"
              @click="reply = true"
              v-if="!hidden"
            >
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/comments/reply.svg"
                alt="Reply"
              />
              Reply
            </button>
            <button
              class="dropdown-item p-15"
              type="button"
              v-if="getActiveSocialMedia == 'facebook' && !hidden"
              @click="likeTrigger(id)"
            >
              <like :isLiked="isLiked"> </like>
              Like
            </button>

            <button
              class="dropdown-item p-15"
              type="button"
              @click="updateClassification(commentId, 'hidden', true)"
              v-if="!hidden"
            >
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/comments/hide.svg"
                alt="Hide"
              />
              Hide
            </button>
            <button
              class="dropdown-item p-15"
              type="button"
              @click="deleteComment(id)"
            >
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/comments/delete.svg"
                alt="Delete"
              />
              Delete
            </button>
            <button
              class="dropdown-item p-15"
              type="button"
              @click="deleteAllCommentByUser()"
            >
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/item.svg"
                alt="Hide"
              />
              Delete all comments from this user
            </button>
            <button
              class="dropdown-item p-15"
              type="button"
              @click="blockUser()"
            >
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/item.svg"
                alt="Hide"
              />
              {{ isUserBlocked() ? "Unblock" : "Block" }} this user
            </button>
          </div>
        </div>
        <!--  -->
        <div
          class="quick-actions d-none d-sm-flex"
          :class="{ hide: editStatus == true }"
          v-if="!isCompetitor()"
        >
          <template v-if="hidden">
            <button
              class="btn-filter btn-unhide"
              @click="updateClassification(commentId, 'hidden', true)"
            >
              Unhide
            </button>
          </template>
          <!-- IsNot Hidden -->
          <div
            v-if="!hidden"
            class="flex"
            :class="[
              { 'c-pr': getActiveSocialMedia == 'instagram' },
              { 'g-1': getActiveSocialMedia == 'youtube' },
            ]"
          >
            <img
              @error="(e) => (e.target.src = $defaultCardAvatar)"
              src="@/assets/images/comments/reply.svg"
              @click="reply = true"
              class="reply-image"
              alt="Reply"
            />
            <like
              @click.native="likeTrigger(id)"
              class="like-btn"
              v-if="getActiveSocialMedia == 'facebook'"
              :isLiked="isLiked"
            ></like>

            <heart
              v-if="
                getActiveSocialMedia != 'facebook' &&
                  getActiveSocialMedia != 'instagram'
              "
              :isLiked="isLiked"
              @click.native="likeTrigger(id)"
            >
            </heart>
          </div>
          <dotMenu
            class="dotmenu dropdown-toggle"
            :class="{ 'ml-0': hidden }"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
          </dotMenu>

          <div class="dropdown-menu dropMenu animate slideInDropMenu text-left">
            <button
              class="dropdown-item p-15"
              v-if="!hidden"
              type="button"
              @click="updateClassification(commentId, 'hidden', true)"
            >
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/comments/hide.svg"
                alt="Hide"
              />
              Hide
            </button>
            <button
              class="dropdown-item p-15"
              type="button"
              @click="deleteComment(id)"
            >
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/comments/delete.svg"
                alt="Delete"
              />
              Delete
            </button>
            <button
              class="dropdown-item p-15"
              type="button"
              @click="deleteAllCommentByUser()"
            >
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/item.svg"
                alt="Hide"
              />
              Delete all comments from this user
            </button>
            <button
              class="dropdown-item p-15"
              type="button"
              @click="blockUser()"
            >
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/item.svg"
                alt="Hide"
              />
              {{ isUserBlocked() ? "Unblock" : "Block" }} this user
            </button>
          </div>
          <!-- IsNot Hidden -->
        </div>
        <!-- Mobile comment menu dot -->
      </div>

      <div class="attention" :class="{ showEdit: editStatus == true }">
        <img
          @error="(e) => (e.target.src = $defaultCardAvatar)"
          src="@/assets/images/comments/alert.svg"
          alt
        />
        <br />You are changing a comment status. <br />
      </div>
    </div>
    <div
      class="row reply-wrap"
      v-if="$route.name != 'search'"
      :class="{ show: reply }"
    >
      <div class="col-10 offset-md-2 reply">
        <img
          @error="(e) => (e.target.src = $defaultCardAvatar)"
          :src="activeAccountPhoto"
          class="profile"
          alt="Profile Photo"
        />
        <div class="info">
          <div>
            <span class="name">{{ activeAccountDetails.accountName }}</span>
          </div>
          <div>
            <!-- <span class="city">@tutkalbilisim</span> -->
          </div>
        </div>
        <div
          class="close"
          @click="reply = false"
          @click.stop.prevent="selectComment"
        >
          <i class="fa fa-times"></i>
        </div>
        <Loading class="ml-1" v-if="xhrReply"></Loading>
        <ResizeAuto>
          <template v-slot:default="{ resize }">
            <textarea
              :disabled="xhrReply"
              rows="1"
              :ref="'replyTextarea' + commentId"
              class="form-control text"
              v-model="replyText"
              :placeholder="'Comment as ' + activeAccountDetails.accountName"
              @input="resize"
            ></textarea>
          </template>
        </ResizeAuto>

        <img
          @error="(e) => (e.target.src = $defaultCardAvatar)"
          src="@/assets/images/emoji.svg"
          class="emojiTrigger send"
          @click="showEmojiPicker = !showEmojiPicker"
          alt="Send"
        />

        <picker
          @select="addEmoji"
          :data="emojiIndex"
          class="emoji picker"
          title="Pick your emoji.."
          v-if="showEmojiPicker"
          :disabled="xhrReply"
          :class="{ 'o-5': xhrReply }"
        />

        <img
          @error="(e) => (e.target.src = $defaultCardAvatar)"
          src="@/assets/images/send.svg"
          class="send"
          alt="Send"
          :class="{ 'o-5': xhrReply }"
          @click="replyComment(id)"
        />
      </div>
    </div>
    <!-- Replies -->
  </div>
</template>
<script>
import EventBus from "@/components/event-bus";
import { mapGetters } from "vuex";
import { HTTP } from "@/main-source";
import status from "@/components/dot";
import heart from "@/components/icons/heart";
import ResizeAuto from "@/components/facebook/messages/resizedTextarea";
import dotMenu from "@/components/icons/dot-menu";
import like from "@/components/icons/like";
import data from "emoji-mart-vue-fast/data/all.json";
import { Picker, EmojiIndex } from "emoji-mart-vue-fast";
import Loading from "@/components/Loading";
// Emotions
import fun from "@/components/icons/emotions/fun";
import happiness from "@/components/icons/emotions/happiness";
// import hate from "@/components/icons/emotions/hate";
import love from "@/components/icons/emotions/love";
import neutral from "@/components/icons/emotions/neutral";
import relief from "@/components/icons/emotions/relief";
import sadness from "@/components/icons/emotions/sadness";
import surprise from "@/components/icons/emotions/surprise";
import worry from "@/components/icons/emotions/worry";
// Offensive
import hate from "@/components/icons/offensive/hate";
import offensive from "@/components/icons/offensive/offensive";
import profanity from "@/components/icons/offensive/profanity";

//Icons

import facebookIcon from "@/components/icons/facebook";
import instagramIcon from "@/components/icons/instagram";
import twitterIcon from "@/components/icons/twitter";
import youtubeIcon from "@/components/icons/youtube";

export default {
  name: "comment",
  props: [
    "comment",
    "socialMediaAccounts",
    "id",
    "commentId",
    "name",
    "profilePic",
    "location",
    "content",
    "countOfReplies",
    "hidden",
    "isQuestion",
    "isSpam",
    "polarity",
    "polarityStrings",
    "commentImgs",
    "disableSelect",
    "isLiked",
    "activeAccountDetails",
    "isSub",
    "likeCount",
    "date",
    "authorId",
    "isManual",
    "postImageUrl",
    "isReply",
    "disableAction",
    "isReplyView",
  ],
  components: {
    status,
    ResizeAuto,
    Picker,
    dotMenu,
    like,
    heart,
    fun,
    happiness,
    hate,
    love,
    neutral,
    relief,
    sadness,
    surprise,
    worry,
    offensive,
    profanity,
    Loading,
    facebookIcon,
    instagramIcon,
    twitterIcon,
    youtubeIcon,
  },
  data() {
    return {
      reply: false,
      editStatus: false,
      c_hidden: null,
      c_isQuestion: null,
      c_polarityStrings: [],
      replyText: "",
      showMoreMenu: false,
      activeAccountPhoto: "/images/no-image.jpg",
      replies: [],
      repliesPage: 0,
      repliesEnd: false,
      commenterPhoto: "/images/no-image.jpg",
      emotions: [
        "Fun",
        "Happiness",
        "Hate",
        "Love",
        "Neutral",
        "Relief",
        "Sadness",
        "Surprise",
        "Worry",
      ],
      postImageUrlForFacebookAndInstagram: "/images/no-image.jpg",
      showEmojiPicker: false,
      emojiIndex: new EmojiIndex(data),
      xhrReply: false,
    };
  },
  created() {
    // Nullcheck
    if (this.polarityStrings == null) {
      this.polarityStrings = [];
    }
  },
  methods: {
    isCompetitor() {
      if (this.$route.name == "search") {
        return false;
      }
      return this.getActiveSocialMediaAccountObj.type != "OWN";
    },
    isUserBlocked() {
      return this.getBlockedUserList.includes(this.authorId);
    },
    getReplies: function(next = false) {
      if (next) {
        this.repliesPage++;
      }
      HTTP.get(
        "social/" +
          this.getActiveSocialMediaAccount +
          "/" +
          this.getActiveSocialMedia +
          "/comment/" +
          this.commentId +
          "/subcomments" +
          "?page=" +
          this.repliesPage
      )
        .then((result) => {
          result.data.data.forEach((comment) => {
            this.replies.push(comment.data);
          });
        })
        .catch((e) => {
          if (e.response.status == 400) {
            this.repliesEnd = true;
          }
        });
    },
    /*eslint-disable*/
    replyComment: function(id) {
      this.xhrReply = true;
      EventBus.$emit("replyTrigger", id, this.replyText);
    },
    /*eslint-disable*/
    show: function() {
      let data = {
        authorId: this.authorId,
        name: this.name,
        profileImg: this.commenterPhoto,
      };
      EventBus.$emit("byAuthor", data);
    },
    showpost: function() {
      console.log("comment");
    },
    exitEdit: function(id) {
      this.editStatus = false;
      EventBus.$emit("getBackCommentStatus", id, this.isSub, this.isReply);
    },
    selectComment: function(commentId) {
      if (typeof commentId == "string") {
        EventBus.$emit("getComments", commentId);
      }
    },
    getSubComments: function(commentId) {
      EventBus.$emit("getSubComments", commentId);
    },
    deleteComment: function(id) {
      EventBus.$emit("deleteComment", id, this.isSub, this.isReply);
    },
    updateClassification: function(id, field, isSave) {
      if (this.editStatus == true || isSave == true) {
        EventBus.$emit(
          "updateClassification",
          id,
          field,
          isSave,
          this.isSub,
          this.isReply
        );
      }
    },
    updateEmotions: function(id, field, isSave) {
      if (this.editStatus == true || isSave == true) {
        EventBus.$emit(
          "updateEmotions",
          id,
          field,
          isSave,
          this.isSub,
          this.isReply
        );
      }
    },
    clearCommentStatus: function(id) {
      EventBus.$emit("clearCommentStatus", id, this.isSub, this.isReply);
    },
    likeTrigger: function(id) {
      EventBus.$emit("likeTrigger", id);
    },
    timeSince: function(timeStamp) {
      return this.$timeSince(timeStamp);
    },
    deleteAllCommentByUser() {
      EventBus.$emit("deleteAllCommentByUser", this.authorId);
    },
    blockUser() {
      let isUserBlocked = this.isUserBlocked();
      EventBus.$emit("blockUser", this.authorId, isUserBlocked);
    },
    setPhotos: function() {
      if (typeof this.getActiveSocialMediaAccount == "string") {
        // Set Post Image Url
        if (
          this.getActiveSocialMedia == "instagram" ||
          this.getActiveSocialMedia == "facebook" ||
          this.getActiveSocialMedia == "youtube"
        ) {
          if (this.getActiveSocialMediaAccount != null) {
            HTTP.get(
              "/media/" +
                this.getActiveSocialMedia +
                "?accountid=" +
                this.getActiveSocialMediaAccount +
                "&uniqueid=" +
                this.comment.postId
            ).then((result) => {
              if (result.status == 200) {
                if (result.data[0]) {
                  this.postImageUrlForFacebookAndInstagram =
                    result.data[0].type == "VIDEO"
                      ? result.data[0].videoThumbImage
                      : result.data[0].url;
                }
              }
            });
          }
        }

        // Set Author
        if (
          this.getActiveSocialMedia == "facebook" &&
          this.getActiveSocialMediaAccount != null
        ) {
          let accountId = this.getActiveSocialMediaAccount;
          let url =
            "/picture/" +
            this.getActiveSocialMedia +
            "?accountid=" +
            accountId +
            "&authorId=" +
            accountId;

          HTTP.get(url).then((result) => {
            if (result.status == 200) {
              this.activeAccountPhoto = result.data.url;
            }
          });
        } else {
          if (this.activeAccountDetails.imageUrl) {
            this.activeAccountPhoto = this.activeAccountDetails.imageUrl;
          }
        }
        // Set Comment Author Photo
        if (this.getActiveSocialMedia == "facebook") {
          HTTP.get(
            "/picture/" +
              this.getActiveSocialMedia +
              "?accountid=" +
              this.getActiveSocialMediaAccount +
              "&authorId=" +
              this.authorId
          ).then((result) => {
            if (result.status == 200) {
              this.commenterPhoto = result.data.url;
            }
          });
        } else {
          if (this.profilePic && this.isReplyView != true) {
            this.commenterPhoto = this.profilePic;
          }
        }
        // If Commenter as account owner
        if (this.comment.authorId == this.getActiveSocialMediaAccount) {
          this.commenterPhoto = this.activeAccountDetails.imageUrl
            ? this.activeAccountDetails.imageUrl
            : this.commenterPhoto;
        }
        // end
      }

      if (this.$route.name == "search") {
        let platform;

        switch (this.comment.contentType) {
          case "facebook_comment":
            platform = "facebook";
            break;
          case "instagram_comment":
            platform = "instagram";
            break;
          case "youtube_comment":
            platform = "youtube";
            break;
          case "twitter_comment":
            platform = "twitter";
            break;
        }

        HTTP.get(
          "/media/" +
            platform +
            "?accountid=" +
            this.comment.originalUserId +
            "&uniqueid=" +
            this.comment.postId
        ).then((result) => {
          if (result.status == 200) {
            if (result.data[0]) {
              this.postImageUrlForFacebookAndInstagram =
                result.data[0].type == "VIDEO"
                  ? result.data[0].videoThumbImage
                  : result.data[0].url;
            }
          }
        });
      }
    },
    addEmoji: function(emoji) {
      // this.reply += emoji.native;

      const textarea = this.$refs["replyTextarea" + this.commentId];

      const start = this.replyText.substring(0, textarea.selectionStart);
      const end = this.replyText.substring(textarea.selectionStart);
      const text = start + " " + emoji.native + " " + end;
      this.replyText = text;
      this.showEmojiPicker = false;
    },
  },
  watch: {
    editStatus: function() {
      if (this.editStatus == true) {
        this.clearCommentStatus(this.id);
      }
    },
    getActiveSocialMediaAccount: function() {
      this.setPhotos();
    },
    comment: function() {
      if (this.isReplyView == true) {
        this.setPhotos();
      }
    },
    activeAccountDetails: function() {
      if (this.$route.name != "search") {
        // If Commenter as account owner
        if (this.comment.authorId == this.getActiveSocialMediaAccount) {
          this.commenterPhoto = this.activeAccountDetails.imageUrl;
        }
        // end

        // Active account photo
        if (this.getActiveSocialMedia != "facebook") {
          this.activeAccountPhoto = this.activeAccountDetails.imageUrl;
        }
      }
    },
  },
  mounted() {
    EventBus.$on("commentEditSuccess", () => {
      this.editStatus = false;
    });
    EventBus.$on("replySent", () => {
      this.xhrReply = false;
      this.reply = false;
      this.replyText = null;
    });
    this.setPhotos();
  },
  computed: {
    ...mapGetters([
      "getActiveSocialMedia",
      "getActiveSocialMediaAccount",
      "getBlockedUserList",
      "getActiveSocialMediaAccountObj",
    ]),
    polarityStringsC: function() {
      if (!this.polarityStrings) {
        return [];
      }
      let polarityStrings = this.polarityStrings.filter(
        (polarity) => polarity != "notassigned"
      );

      if (
        polarityStrings.length == 0 &&
        this.isQuestion == false &&
        this.isSpam == false
      ) {
        polarityStrings.push("notassigned");
      }
      return polarityStrings;
    },
    statusCount: function() {
      let count = this.polarityStringsC.length + this.comment.emotion.length;
      this.isSpam ? count++ : null;
      this.isQuestion ? count++ : null;
      this.comment.isSwearing ? count++ : null;
      // if (window.innerWidth < 1300) {
      //   count = 5;
      // }
      return count;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/colors.scss";

.wrap {
  border-radius: 30px;
  background: $white;
  padding: 0;

  // margin-bottom: 20px;
  transition: all 0.25s ease;
  min-height: 130px;

  &:hover {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
    // z-index: 99;
    // transform: scale(1.01);
  }

  cursor: pointer;

  .emotions {
    margin-left: 5px;

    span {
      margin-right: 2px;
    }

    svg {
      margin-top: -2px;
      margin-right: 3px;
      width: 15px;
      height: 15px;
    }

    .margined {
      margin-right: 6px;
    }
  }

  .comment {
    min-height: 80px;
    padding: 15px 15px 10px;

    .info {
      float: left;
      width: 80%;

      div {
        display: block;
      }
    }

    .profile {
      width: 45px;
      height: 45px;
      object-fit: cover;
      object-position: center;
      border-radius: 50%;
      float: left;
      margin-right: 10px;
    }

    .name {
      font-weight: bold;
    }

    .city {
      font-size: 12px;
      position: relative;
      top: -5px;
    }

    .imgs {
      width: 100%;
      clear: both;
      position: relative;
      margin-top: 20px;

      img {
        width: 100px;
        height: 100px;
        object-fit: cover;
        object-position: center;
        border-radius: 15px;
      }
    }
  }

  .likeCount {
    color: $light-gray;
    font-size: 12px;
    margin-right: 5px;
  }

  .date {
    // width: 60%;
    display: inline-block;
    color: $light-gray;
    font-size: 12px;
  }
  .social-small-badge {
    max-height: 18px;
    max-width: 28px;
  }
  .bottom {
    width: 100%;
    padding: 10px 17px;
    border-top: 1px solid lighten($black, 95%);
    display: flex;
    flex-wrap: wrap;
    font-size: 12px;
    position: relative;
    justify-content: space-between;
    color: $light-gray;

    .status {
      // margin-top: 2px;
      svg {
        margin-top: 0;
      }
    }
  }

  .text {
    padding: 0;
    margin-top: 5px;
    min-height: 35px;
    line-break: anywhere;
  }
}

.offensive-wrap {
  margin-left: 15px !important;

  svg {
    margin-left: 3px;
  }
}

.quick-actions {
  position: relative;
  z-index: 9;
  // width: 85px;
  margin-right: 0px;
  margin-left: 5px;

  .reply-image {
    width: 12px;
    height: auto;
  }

  .like-btn {
    margin-left: 10px;
  }

  .flex.c-pr {
    img {
      width: 12px;
      height: auto;
    }
  }

  .flex {
    display: flex !important;
    justify-content: space-around;
    margin-top: 3px;
  }

  img,
  svg {
    flex-basis: 0;
    transition: all 0.25s ease;

    &:hover {
      transform: scale(1.18);
    }
  }
}

.mobile-actions {
  width: 30px;
}

.reply-wrap {
  margin: 0;
  height: 0;
  transition: all 0.3s ease;
  opacity: 0;
}

.reply-wrap.show {
  margin: 15px 0px 0px 0px;
  opacity: 1;
  height: auto;
}

.reply {
  border-radius: 15px;

  background: $white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
  min-height: 40px;
  // margin-bottom: 20px;
  padding: 10px;

  img {
    width: 12px;
    height: auto;
  }

  .info {
    float: left;

    div {
      display: block;
    }
  }

  .profile {
    width: 45px;
    height: 45px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    float: left;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .name {
    font-weight: bold;
  }

  .city {
    font-size: 12px;
    position: relative;
    top: -5px;
  }

  textarea {
    margin: 0;
    width: 100%;
    resize: none;
    max-height: 200px !important;
    border-radius: 15px;
    border: 1px solid rgba(0, 0, 0, 0.1);

    &:focus {
      box-shadow: none;
    }
  }

  .send.emojiTrigger {
    right: 50px !important;
  }

  .emoji.picker {
    position: absolute;
    z-index: 999;
    right: 0;
    margin-top: 15px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    color: #fff;
  }

  .send {
    position: absolute;
    right: 20px;
    bottom: 18px;
    width: 20px;
    height: auto;
    cursor: pointer;
  }

  .close {
    margin-top: -5px;

    i {
      font-size: 14px;
    }
  }
}

.showReplies {
  transition: all 0.25s ease;
  margin-top: 1px;

  img {
    margin-top: -2px;
  }
}

.hide {
  opacity: 0;
  z-index: -1;
}

.o-1 {
  opacity: 0.4;
}

.attention {
  text-align: center;
  font-size: 12px;
  background: $white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
  margin: 0;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  padding: 0px;
  height: 0px;
  transition: all 0.2s ease;
  overflow: hidden;
  visibility: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0);
  opacity: 0;
}

.showEdit {
  opacity: 1;
  padding: 5px;
  height: auto;
  visibility: visible;
  border-top: 1px solid lighten($black, 95%);
}

.saveStatus {
  opacity: 0;
  z-index: -1;
  position: absolute;
  top: 7px;
  right: 0px;
  text-align: right;

  .btn {
    padding: 0px 15px;
    margin-right: 5px;
    border-radius: 15px;
    transition: all 0.2s ease;

    &:hover {
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
    }
  }

  .btn-default {
    background: darken($white, 5%);
  }
}

.showStatusEdit {
  opacity: 1;
  z-index: 9;
}

.btn-unhide.mobile {
  display: none;
}

@media (max-width: 767.98px) {
  .btn-unhide.mobile {
    display: block;
    position: absolute;
    right: 20px;
    bottom: -2px;
  }

  .comment {
    .info {
      width: 65% !important;
    }
  }

  .statuses {
    pointer-events: none;

    span {
      margin-right: 5px;
    }

    .text {
      display: none !important;
    }

    .emotions {
      display: inline-block;
      margin: 0;
    }
  }

  .post-thumb {
    right: 15px !important;

    img {
      width: 60px !important;
      height: auto !important;
    }
  }

  .status {
    .dot {
      float: none !important;
    }
  }

  .quick-actions {
    .dropdown-menu {
      left: -3px !important;
    }
  }

  .reply-wrap {
    justify-content: flex-end;
  }

  .hiddenComment {
    opacity: 0.3;

    &:hover {
      opacity: 0.3 !important;
    }
  }
}

@media screen and (max-device-width: 1450px) {
  .bottom {
    font-size: 10px !important;
  }
}

.manual {
  position: absolute;
  width: 5px;
  height: 5px;
  right: 10px;
  top: 10px;
  background: #f00;
  border-radius: 50%;
}

.black {
  color: $black;
  text-transform: capitalize !important;
}

.post-thumb {
  height: 70px;
  position: absolute;
  top: 20px;
  right: 20px;

  img {
    width: 80px;
    height: 80px;
    border-radius: 10px;
    margin: 0;
    border: 1px solid #ccc;
    display: inline-block;
    line-height: 80px;
    text-align: center;
  }

  #play {
    position: absolute;
    top: calc(50% - 10.5px);
    left: calc(50% - 12px);
    width: 25px;
    height: auto;
  }
}

.comment-wrap {
  display: flex;
}

.dotmenu {
  width: 20px;
  margin: 5px 10px;
  padding: 2px;

  // width: 24px;
  &:hover {
    // transform: scale(1) !important;
  }
}

.dropMenu {
  position: absolute;
  background: $white;
  -webkit-box-shadow: 0px 10px 20px -7px rgba(32, 56, 117, 0.35) !important;
  box-shadow: 0px 10px 20px -7px rgba(32, 56, 117, 0.35) !important;
  border-radius: 20px;
  top: 20px;
  left: -160px !important;
  overflow: hidden;
  z-index: 9999;
  border: none;

  button {
    border: none;
    border-radius: 20px;
    cursor: pointer;
  }

  img,
  svg {
    width: 20px;
    height: 15px;
    object-fit: contain;
    object-position: center;
  }
}

.p-15 {
  padding: 5px 10px;
}

.btn-unhide {
  // margin-left: 10px;
  border: 1px solid darken($white, 20%);
  width: auto;
  height: auto;
  border-radius: 15px;
  font-size: 10px;
  padding: 4px 10px;
  line-height: 10px;
  transition: all 0.3s ease;
  margin-left: 3px;
  margin-right: 5px;

  &:hover {
    background-color: $main-bg-color;
  }
}

.hiddenComment {
  opacity: 0.3;

  &:hover {
    opacity: 1;
  }
}

.c-pr {
  padding-left: 35px;
}

.hideaction {
  pointer-events: none !important;

  .quick-actions {
    display: none !important;
  }
}

.comments--ai-actions .wrap--ai-actions {
  margin-bottom: 0;
}

.comments--ai-actions .bottom--ai-actions {
  padding: 10px 0px;
}

.subcomments .comments--comments,
.subcomments .comments--posts {
  margin-bottom: 20px;
}

.ml-0 {
  margin-left: 0px !important;
}
</style>
