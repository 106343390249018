<template>
  <div>
    <!-- New Card Modal -->
    <modal v-if="newCardModal" @close="newCardModal = false" class="newCardModal">
      <div slot="title">New Payment Method</div>
      <div class="row head">
        <div class="col">
          <i class="fas fa-exclamation-triangle"></i>
          Warning
        </div>
        <div class="col text-right">
          <i class="fas fa-times" @click="newCardModal = false"></i>
        </div>
      </div>
      <div slot="body" class="newcard-form">
        <div class="row" v-if="billingCustomers == null">
          <div class="col-12">
            <div class="form-group">
              <label for="cardnumber">Payment Profile Name</label>

              <input type="text" @blur="$v.newPaymentProfile.name.$touch()" v-model="newPaymentProfile.name"
                class="form-control underline" placeholder="Name"
                :class="{ 'invalid-b': $v.newPaymentProfile.name.$error }" />
              <div v-if="$v.newPaymentProfile.name.$error">
                <span class="invalid" v-if="!$v.newPaymentProfile.name.required">Payment Profile Name required</span>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label for="cardnumber">Email</label>
              <input type="text" v-model="newPaymentProfile.email" class="form-control underline" placeholder="email"
                :class="{ 'invalid-b': $v.newPaymentProfile.email.$error }" />
              <div v-if="$v.newCard.number.$error">
                <span class="invalid" v-if="!$v.newCard.number.required">Please enter valid email</span>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label for="cardnumber">Phone</label>
              <input type="text" v-model="newPaymentProfile.phone" class="form-control underline" placeholder="phone" />
            </div>
          </div>
          <hr />
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="cardnumber">Card Number</label>
              <the-mask mask="#### #### #### ####" type="text" name="cardnumber" @blur="$v.newCard.number.$touch()"
                v-model="newCard.number" class="form-control underline"
                :class="{ 'invalid-b': $v.newCard.number.$error }" placeholder="· · · ·  · · · ·  · · · ·  · · · · ">
              </the-mask>
              <div v-if="$v.newCard.number.$error">
                <span class="invalid" v-if="!$v.newCard.number.required">Card Number required</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <div class="form-group">
              <label for="cardnumber">MM</label>
              <the-mask mask="##" type="text" name="cardmonth" @blur="$v.newCard.expMonth.$touch()"
                v-model="newCard.expMonth" class="form-control underline"
                :class="{ 'invalid-b': $v.newCard.expMonth.$error }" placeholder="· ·"></the-mask>
              <div v-if="$v.newCard.expMonth.$error">
                <span class="invalid" v-if="!$v.newCard.expMonth.required">End Month required</span>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <label for="cardnumber">YY</label>
              <the-mask mask="##" type="text" name="cardmonth" @blur="$v.newCard.expYear.$touch()"
                v-model="newCard.expYear" class="form-control underline"
                :class="{ 'invalid-b': $v.newCard.expYear.$error }" placeholder="· ·"></the-mask>
              <div v-if="$v.newCard.expYear.$error">
                <span class="invalid" v-if="!$v.newCard.expYear.required">End Year required</span>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <label for="cardnumber">cvc</label>

              <the-mask mask="###" type="text" name="cardmonth" @blur="$v.newCard.cvc.$touch()" v-model="newCard.cvc"
                class="form-control underline" :class="{ 'invalid-b': $v.newCard.cvc.$error }" placeholder="· · ·">
              </the-mask>
              <div v-if="$v.newCard.cvc.$error">
                <span class="invalid" v-if="!$v.newCard.cvc.required">cvc required</span>
              </div>
            </div>
          </div>
        </div>
        <div class="w-100 d-block stripe-wrapper">
          <img @error="(e) => (e.target.src = $defaultCardAvatar)" src="@/assets/images/stripe-k.png" class="pwr-stripe" alt="Powered by Stripe" />
        </div>
      </div>
      <div slot="footer">
        <button class="btn btn-default" @click="newCardModal = false">
          CANCEL
        </button>
        <button class="btn btn-primary" @click="addNewPaymentMethodwithProfile">
          ADD
        </button>
      </div>
    </modal>
    <!-- New Card Modal End -->
    <modal v-if="modal" @close="modal = false" id="deleteModal">
      <div slot="title">
        {{ process == "freeze" ? "Freeze" : "Delete" }} My Account
      </div>
      <div slot="body" class="text-center">
        <img @error="(e) => (e.target.src = $defaultCardAvatar)" src="@/assets/images/icon.svg" class="logo" alt="Socialays" />
        <p>
          With this action, your account, all your linked accounts, and in this
          context all related data will be erased from our servers. This action
          cannot be undone.
        </p>
        <div class="w-100 text-left">
          <p>Enter the following to confirm:</p>
          <span class="confirm-text">
            {{ confirmText }}
          </span>
        </div>
        <input type="text" v-model="userConfirmationText" class="form-control underline"
          :disabled="deletingSocialaysAccountLoading" @keyup.enter="doAction" />
        <!-- <div v-if="$v.password.$error">
          <span class="invalid" v-if="!$v.password.required">Email required</span>
          <span class="invalid" v-if="!$v.password.email">Please provide a valid email.</span>
        </div>-->
        <span class="invalid" v-if="deleteError">
          {{ deleteError.response.data.message }}
        </span>
      </div>
      <div slot="footer">
        <button class="btn btn-primary" @click="modal = false">Cancel</button>
        <button class="btn btn-danger" @click="doAction">
          {{ process == "freeze" ? "Freeze" : "Delete" }}
          <Loading :white="true" v-if="deletingSocialaysAccountLoading"></Loading>
        </button>
      </div>
    </modal>
    <!-- Delete Modal End -->
    <!-- Delete Social Account Modal -->
    <modal v-if="modalSocialAccountDelete" @close="modalSocialAccountDelete = false" id="deleteModal">
      <div slot="title">Delete Social Account</div>
      <div slot="body" class="text-center">
        <img @error="(e) => (e.target.src = $defaultCardAvatar)" src="@/assets/images/icon.svg" class="logo" alt="Socialays" />
        <p>
          With this action, your are unsubscribing your
          {{ account.accountName | username }}. You can continue to use this
          account until {{ renewalDate }}. After this date all data linked to
          this account will be deleted.
        </p>

        <p class="slug i-status trial-delete-account-notify" v-if="user.status == 'TRIAL'">
          You will lose one uses right while trial version.
        </p>
        <div class="w-100 text-left">
          <p>Enter the following to confirm:</p>
          <span class="confirm-text">
            {{ confirmText }}
          </span>
        </div>
        <!-- @blur="$v.password.$touch()" -->
        <!-- :class="{ 'invalid-b': $v.password.$error }" -->
        <input type="text" v-model="userConfirmationText" class="form-control underline"
          @keyup.enter="deleteSocialAccount" />
        <!-- <div v-if="$v.password.$error">
          <span class="invalid" v-if="!$v.password.required">Email required</span>
          <span class="invalid" v-if="!$v.password.email">Please provide a valid email.</span>
        </div>-->
        <span class="invalid" v-if="deleteError">
          {{ deleteError.response.data.message }}
        </span>
      </div>
      <div slot="footer">
        <button class="btn btn-primary" @click="modalSocialAccountDelete = false">
          Cancel
        </button>
        <button class="btn btn-danger" @click="deleteSocialAccount">
          {{ process == "freeze" ? "Freeze" : "Delete" }}
          <Loading :white="true" v-if="xhrDeleteSocialAccount"></Loading>
        </button>
      </div>
    </modal>
    <!-- Delete Social Account Modal END -->
    <!-- Modal Card Default -->
    <modal v-if="modalDefaultCard" @close="modalDefaultCard = false" id="deleteModal">
      <div slot="title">Confirm</div>
      <div slot="body" class="text-center">
        <img @error="(e) => (e.target.src = $defaultCardAvatar)" src="@/assets/images/icon.svg" class="logo" alt="Socialays" />
        <p>
          With this action, all your subscription will be updated and charges
          from this card.
        </p>

        <!-- @blur="$v.password.$touch()" -->
        <!-- :class="{ 'invalid-b': $v.password.$error }" -->
        <input type="password" v-model="password" class="form-control underline" placeholder="Password"
          @blur="$v.password.$touch()" @keyup.enter="makeDefault" :class="{ 'invalid-b': $v.password.$error }" />
        <div v-if="$v.password.$error" class="text-left">
          <span class="invalid">Password required.</span>
        </div>
        <span class="invalid" v-if="defaultCardError">
          {{ defaultCardError.response.data.message }}
        </span>
      </div>
      <div slot="footer">
        <button class="btn btn-primary" @click="modalDefaultCard = false">
          Cancel
        </button>
        <button class="btn btn-danger" @click="makeDefault">
          Confirm
          <Loading :white="true" v-if="xhrDefaultCard"></Loading>
        </button>
      </div>
    </modal>

    <div v-if="!show" class="row">
      <div v-for="i in 9" :key="i" class="col-12 col-lg-6 col-xl-4">
        <SkeletonLoader />
      </div>
    </div>

    <!-- Modal Card Default End -->
    <div class="drag ac-settings" v-if="show">
      <grid-layout :layout.sync="layout" :margin="[10, 10]" :row-height="15"
        :cols="{ lg: 3, md: 3, sm: 2, xs: 1, xxs: 1 }" :is-draggable="true" :is-resizable="false"
        :use-css-transforms="true" :responsive="true" :vertical-compact="true" @layout-updated="saveLastSort">
        <grid-item v-for="item in layout" :key="item.i" :static="false" :x="item.x" :y="item.y" :w="item.w" :h="item.h"
          :i="item.i" :preserve-aspect-ratio="true">
          <component :laccounts="accounts" :accounts="authorizedAccountsList" :is="item.componentName"
            :billingCustomers="billingCustomers" :billingMethods="billingMethods" :movements="movements"
            :invitations="invitationsData" :isLoading="invitationsProgress" :user="user"
            :subscriptionDetails="subscriptionDetails"></component>
        </grid-item>
      </grid-layout>
    </div>
  </div>
</template>

<script>
/*eslint-disable */
import { HTTP } from "@/main-source";
import EventBus from "@/components/event-bus";
import { mapActions } from "vuex";
import Profile from "@/components/settings/Profile";
import ChangePassword from "@/components/settings/Change-Password";
import LinkedAccounts from "@/components/settings/Linked-Accounts";
import AuthorizedAccounts from "@/components/settings/Authorized-Accounts";
import Billing from "@/components/settings/Billing";

import Invitations from "@/components/settings/Invitations";
import VueGridLayout from "vue-grid-layout";
import router from "@/router";
import modal from "@/components/modal";
import Loading from "@/components/Loading";
import { required, email } from "vuelidate/lib/validators";
import SkeletonLoader from "@/components/placeholders/boxes";

let testLayout = [
  {
    x: 0,
    y: 0,
    w: 1,
    h: 10,
    i: "4",
    draggable: true,
    minW: 4,
    componentName: "Profile",
  },
  {
    x: 1,
    y: 0,
    w: 1,
    h: 12,
    i: "1",
    draggable: true,
    minW: 4,
    componentName: "change-password",
  },
  {
    x: 2,
    y: 0,
    w: 1,
    h: 11,
    i: "0",
    draggable: true,
    minW: 4,
    componentName: "linked-accounts",
  },
  {
    x: 1,
    y: 0,
    w: 1,
    h: 11,
    i: "3",
    draggable: true,
    minW: 4,
    componentName: "Billing",
  },

  {
    x: 0,
    y: 0,
    w: 1,
    h: 11,
    i: "5",
    draggable: true,
    minW: 4,
    componentName: "Invitations",
  },
  {
    x: 0,
    y: 0,
    w: 1,
    h: 11,
    i: "6",
    draggable: true,
    minW: 4,
    componentName: "authorized-accounts",
  },
];

if (localStorage.getItem("settings-order")) {
  if (testLayout.count) {
    testLayout = JSON.parse(localStorage.getItem("settings-order"));
  } else {
    localStorage.setItem("settings-order", JSON.stringify(testLayout));
  }
}

export default {
  name: "Settings",
  data() {
    return {
      layout: localStorage.getItem("settings-order")
        ? testLayout
        : JSON.parse(JSON.stringify(testLayout)),
      account: null,
      accounts: [],
      subscriptionDetails: [],
      show: false,
      billingCustomers: null,
      billingMethods: [],
      movements: [],
      user: [],
      invitationsData: [],
      dragOptions: this.$dragOptions,
      userId: null,
      invitationsProgress: false,
      modal: false,
      process: "delete",
      authorizedAccountsList: [],
      provider: [],
      redirectURL: this.$redirectURL,
      deletingSocialaysAccountLoading: false,
      deleteError: null,
      defaultCardError: null,
      newCardModal: false,
      password: null,
      newPaymentProfile: {
        userId: null,
        description: null,
        email: null,
        name: null,
        phone: null,
      },
      newCard: {
        userId: null,
        number: null,
        expMonth: null,
        expYear: null,
        cvc: null,
      },
      modalSocialAccountDelete: false,
      modalDefaultCard: false,
      xhrDeleteSocialAccount: false,
      xhrDefaultCard: false,
      willBeDeleteSocialAccountId: null,
      willBeDeleteSocialAccountIsLinked: null,
      willBeDeleteSocialAccountisCanceled: null,
      renewaldate: JSON.parse(localStorage.getItem("selectedDate")) || null,
      confirmText: null,
      userConfirmationText: null,
    };
  },
  validations: {
    newCard: {
      number: { required },
      expYear: { required },
      expMonth: { required },
      cvc: { required },
    },
    newPaymentProfile: {
      userId: required,
      name: required,
      email: email,
    },
    newDefaultPaymentMethodId: null,
    password: { required },
  },
  components: {
    Profile,
    "change-password": ChangePassword,
    "linked-accounts": LinkedAccounts,
    "authorized-accounts": AuthorizedAccounts,
    Billing,
    Invitations,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    modal,
    Loading,
    SkeletonLoader,
  },
  created() {
    this.getData();
  },
  methods: {
    ...mapActions[("setActiveSocialMedia", "setActiveSocialMediaAccount")],
    saveLastSort: function () {
      localStorage.setItem("settings-order", JSON.stringify(this.layout));
    },
    getData: function () {
      HTTP.get("user/me").then((result) => {
        this.accounts = result.data.socialMediaAccounts;
        this.provider = (result.data.provider || []).filter(
          (p) => p.provider == "local"
        );
        this.authorizedAccountsList = result.data.authorizedAccounts;

        this.userId = result.data.id;
        this.newPaymentProfile.userId = result.data.id;
        this.newPaymentProfile.email = result.data.email;
        this.newCard.userId = result.data.id;
        this.user = result.data;
        this.billingCustomers = result.data.customers;

        if (this.accounts.length > 0 && this.user.status == "ACTIVE") {
          this.subscriptionDetails = [];
          this.accounts.forEach((account) => {
            HTTP.get(
              "settings/subscription?userid=" +
              this.userId +
              "&accountid=" +
              account.accountId
            ).then((res) => {
              this.subscriptionDetails.push(res.data);
            });
          });
        }

        HTTP.get("useractions/" + this.userId).then((res) => {
          this.movements = res.data.content;
        });

        HTTP.get("settings/my-pending-invitations").then((result) => {
          this.invitationsData = result.data;
        });

        if (this.billingCustomers) {
          HTTP.get(
            "payment/payment-method?customerId=" +
            this.billingCustomers.customerId +
            "&userid=" +
            this.userId
          )
            .then((res) => {
              this.billingMethods = res.data;
              this.show = true;
            })
            .catch(() => {
              this.show = true;
              this.billingMethods = [];
            });
        } else {
          this.show = true;
        }
      });
    },
    changePassword: function (data) {
      HTTP.put("auth/change-password", data)
        .then((result) => {
          this.$notify({
            group: "info",
            text: "Password changed",
          });
        })
        .catch((e) => {
          this.$notify({
            group: "info",
            type: "error",
            text: e.response.data.message,
          });
        });
    },
    replyInvitation: function (id, desicion) {
      this.invitationsProgress = true;
      HTTP.put(
        "settings/approve-invitation/" + id + "?status=" + desicion
      ).then((res) => {
        console.log(res);
        this.invitationsData = this.invitationsData.filter(
          (i) => i.invitee.id != id
        );
        this.invitationsProgress = false;
        if (desicion) {
          this.$notify({
            group: "info",
            // title: "Important message",
            text: "Invitation accepted",
          });
        } else {
          this.$notify({
            group: "info",
            // title: "Important message",
            text: "Invitation rejected",
          });
        }
        this.getData();
      });
    },
    updateOrder(items) {
      console.log(items);
    },
    deleteSocialAccount: function () {
      this.deleteAssignedPage(
        this.willBeDeleteSocialAccountIsLinked,
        this.willBeDeleteSocialAccountId,
        this.willBeDeleteSocialAccountisCanceled
      );
    },
    deleteAssignedPage: function (isLinked, accountId, isCanceled) {
      let uri = "/settings/social/" + accountId;

      uri += "?force=" + (isCanceled ? true : false).toString();

      if (this.confirmText == this.userConfirmationText) {
        if (isLinked) {
          HTTP.put(uri, {})
            .then((res) => {
              if (isLinked) {
                this.accounts = this.accounts.filter((a) => a.id != accountId);
              } else {
                this.authorizedAccountsList =
                  this.authorizedAccountsList.filter((a) => a.id != accountId);
              }
              this.$notify({
                group: "info",
                // title: "Important message",
                text: "Page deleted",
              });

              this.getData();

              this.modalSocialAccountDelete = false;
              // TO FIXED
              localStorage.removeItem("home-order");
            })
            .catch((e) => {
              this.$notify({
                group: "info",
                type: "error",
                text: e.response.data.message,
              });
            });
        } else {
          uri =
            "/settings/delete-auth-account/" +
            accountId +
            "?email=" +
            this.user.email +
            "&force=true";

          HTTP.delete(uri, {})
            .then((res) => {
              if (isLinked) {
                this.accounts = this.accounts.filter((a) => a.id != accountId);
              } else {
                this.authorizedAccountsList =
                  this.authorizedAccountsList.filter((a) => a.id != accountId);
              }
              this.$notify({
                group: "info",
                // title: "Important message",
                text: "Page deleted",
              });
              this.getData();

              this.modalSocialAccountDelete = false;
              // TODO will be fixed
              localStorage.removeItem("home-order");
            })
            .catch((e) => {
              this.$notify({
                group: "info",
                type: "error",
                text: e.response.data.message,
              });
            });
        }
      } else {
        this.$notify({
          group: "topNotifications",
          type: "error",
          text: "Wrong confirmation text",
        });
      }
    },

    showModal: function (process) {
      this.modal = true;
      this.process = process;
    },
    doAction: function () {
      this.deletingSocialaysAccountLoading = true;
      let uri = "settings/";

      if (this.process == "freeze") {
        uri += "freeze";
        HTTP.put(uri, {})
          .then((res) => {
            if (res.status == 200) {
              EventBus.$emit("notify", false);
              this.setActiveSocialMedia("");
              this.setActiveSocialMediaAccount(null);
              localStorage.removeItem("token");
              localStorage.removeItem("profileImg");
              localStorage.removeItem("lastSelectedinstagramAccount");
              localStorage.removeItem("lastSelectedfacebookAccount");
              localStorage.removeItem("lastSelectedtwitterAccount");
              localStorage.removeItem("lastSelectedyoutubeAccount");
              router.push({
                name: "login",
              });
            }
          })
          .catch((e) => {
            this.$notify({
              group: "info",
              type: "error",
              text: e.response.data.message,
            });
          });
      } else {
        if (this.confirmText == this.userConfirmationText) {
          uri += "delete?force=true";

          HTTP.put(uri, {})
            .then((res) => {
              console.log(res);
              EventBus.$emit("notify", false);

              localStorage.removeItem("token");
              localStorage.removeItem("profileImg");
              localStorage.removeItem("lastSelectedinstagramAccount");
              localStorage.removeItem("lastSelectedfacebookAccount");
              localStorage.removeItem("lastSelectedtwitterAccount");
              localStorage.removeItem("lastSelectedyoutubeAccount");
              router.push({
                name: "login",
              });
            })
            .catch((e) => {
              this.$notify({
                group: "info",
                type: "error",
                text: e.response.data.message,
              });
              console.log("catched", e);
              this.deleteError = e;
              this.deletingSocialaysAccountLoading = false;
            });
        } else {
          this.deletingSocialaysAccountLoading = false;
          this.$notify({
            group: "topNotifications",
            type: "error",
            text: "Wrong confirmation text",
          });
        }
      }
    },
    reConnect: function (type, accountId) {
      console.log(type, accountId);
      let uri =
        "accounts/" +
        type.toLowerCase() +
        "/url?redirect_url=" +
        this.redirectURL +
        "social/" +
        type.toLowerCase() +
        "/update";

      HTTP.get(uri).then((result) => {
        if (result.status == 200) {
          window.location = result.data.url;
        }
      });
    },
    addNewPaymentMethodwithProfile: function () {
      console.log("profile", this.newPaymentProfile);
      console.log("card", this.newCard);

      if (this.billingCustomers == null) {
        HTTP.post("payment/customer", this.newPaymentProfile)
          .then((res) => {
            console.log(res);
            this.addPaymentMethod();
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        this.addPaymentMethod();
      }
    },
    addPaymentMethod: function () {
      HTTP.post("payment/payment-method", this.newCard)
        .then((res) => {
          console.log(res);
          this.newCardModal = false;
          this.getData();
        })
        .catch((e) => {
          this.$notify({
            group: "info",
            type: "error",
            text: e.response.data.message,
          });
        });
    },
    deletePaymentMethod: function (paymentId) {
      HTTP.delete(
        "payment/payment-method?paymentMethodId=" +
        paymentId +
        "&userid=" +
        this.userId,
        this.newCard
      )
        .then(() => {
          const index = this.billingMethods.findIndex(
            (method) => method.paymentId === paymentId
          );
          if (index >= 0) {
            this.billingMethods.splice(index, 1);
          }

          this.getData();
        })
        .catch((e) => {
          console.log(e);
          this.$notify({
            group: "info",
            type: "error",
            text: e.response.data.message,
          });
        });
    },
    activateLinkedAccount: function (accountId) {
      HTTP.put(
        "settings/subscription?userid=" +
        this.userId +
        "&accountid=" +
        accountId
      )
        .then(() => {
          this.$notify({
            group: "info",
            text: "Successfully activated",
          });

          this.getData();
        })
        .catch((e) => {
          this.$notify({
            group: "info",
            type: "error",
            text: e.response.data.message,
          });
        });
    },
    makeDefault: function () {
      let data = { password: this.password };
      if (!this.$v.password.$error) {
        this.xhrDefaultCard = true;
        HTTP.put(
          "payment/payment-method?userid=" +
          this.user.id +
          "&paymentMethodId=" +
          this.newDefaultPaymentMethodId,
          data
        )
          .then((result) => {
            this.modalDefaultCard = false;
            this.getData();
            this.xhrDefaultCard = false;
          })
          .catch((e) => {
            this.xhrDefaultCard = false;
            this.$notify({
              group: "info",
              type: "error",
              text: e.response.data.message,
            });
          });
      } else {
        this.$notify({
          group: "info",
          type: "error",
          text: "Password Required",
        });
      }
    },
  },
  mounted() {
    EventBus.$on("Change-Password", (data) => {
      this.changePassword(data);
    });
    EventBus.$on("openNewCardModal", () => {
      this.newCardModal = true;
    });
    EventBus.$on("makeDefault", (paymentId) => {
      this.modalDefaultCard = true;
      this.newDefaultPaymentMethodId = paymentId;
    });
    EventBus.$on("invitation-reply", (id, desicion) => {
      this.replyInvitation(id, desicion);
    });
    EventBus.$on(
      "deleteAssignedPage",
      (isLinked, account, isCanceled, renewalDate = null) => {
        this.confirmText = "delete " + account.accountName;
        this.userConfirmationText = null;
        this.account = account;
        this.renewalDate = renewalDate;
        // this.deleteAssignedPage(isLinked, accountId, isCanceled);
        this.willBeDeleteSocialAccountId = account.accountId;
        this.willBeDeleteSocialAccountIsLinked = isLinked;
        this.willBeDeleteSocialAccountisCanceled = isCanceled;
        this.modalSocialAccountDelete = true;
      }
    );
    EventBus.$on("activateLinkedAccount", (accountId) => {
      this.activateLinkedAccount(accountId);
    });
    EventBus.$on("deleteMyAccount", () => {
      this.confirmText = "confirm delete";
      this.userConfirmationText = null;
      this.showModal("delete");
    });
    EventBus.$on("reConnect", (type, accountId) => {
      this.reConnect(type, accountId);
    });
    EventBus.$on("deletePaymentMethod", (paymentId) => {
      this.deletePaymentMethod(paymentId);
    });

    EventBus.$on("uploadPhoto", (image) => {
      let fd = new FormData();
      fd.append("file", image[0].file, image[0].name);

      HTTP.post("/settings/profile/upload/", fd, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
        .then((res) => {
          localStorage.setItem(
            "profileImg",
            res.data.fileDownloadUri + "?token=" + localStorage.getItem("token")
          );
          this.user.profileImageUrl = res.data.fileDownloadUri;
          EventBus.$emit("photoUpdated", true);
        })
        .catch((e) => {
          console.log(e);
        });
    });
  },
  watch: {
    modal: function () {
      this.password = null;
    },
    modalSocialAccountDelete: function () {
      this.password = null;
    },
    newCardModal: function () {
      if (!this.newCardModal) {
        this.newCard = {
          number: null,
          expMonth: null,
          expYear: null,
          cvc: null,
        };
      }
    },
    modalDefaultCard: function () {
      if (this.modalDefaultCard) {
        this.$v.password.$reset();
        this.password = null;
      }
    },
  },
  destroyed() {
    EventBus.$off("Change-Password");
    EventBus.$off("openNewCardModal");
    EventBus.$off("invitation-reply");
    EventBus.$off("deleteAssignedPage");
    EventBus.$off("activateLinkedAccount");
    EventBus.$off("deleteMyAccount");
    EventBus.$off("reConnect");
    EventBus.$off("deletePaymentMethod");
    EventBus.$off("uploadPhoto");
  },
};
</script>

<style lang="scss">
@import "@/scss/colors.scss";

.ac-settings {
  // -webkit-column-count: 4 !important;
  // -moz-column-count: 4 !important;
  // column-count: 4 !important;

  .head {
    color: lighten($black, 50%);
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    padding: 15px;
    position: relative;

    .title {
      font-size: 15px;
      font-weight: bold;
    }

    img {
      margin-right: 10px;
      width: 20px;
      height: auto;
    }

    .btn {
      position: absolute;
      right: 15px;
      top: 12px;
      padding: 5px 10px;
      font-size: 12px;
    }
  }
}

@media (max-width: 767.98px) {
  .ac-settings {
    -webkit-column-count: 1 !important;
    -moz-column-count: 1 !important;
    column-count: 1 !important;
  }
}

#deleteModal {
  .logo {
    width: 60px;
    margin: auto;
    display: block;
    margin-bottom: 20px;
  }
}

#deleteModal {
  p {
    width: 100%;
    font-size: 12px;
  }
}

.trial-delete-account-notify {
  font-size: 12px !important;
  margin: 10px auto 15px auto !important;
  display: block !important;
  width: 80% !important;
}
</style>
