<template>
  <div class="setting account">
    <div class="head">
      <img @error="(e) => (e.target.src = $defaultCardAvatar)" src="@/assets/images/settings/payment.svg" alt="Billing Settings" />
      <span>Billing Options</span>
    </div>
    <div
      class="body billing-box"
      :class="{ 'no-data': billingMethods.length == 0 }"
    >
      <div class="row" v-if="billingMethods.length > 0">
        <div class="col-12" >
          <div
            class="social-account credit-card"
            v-for="card in billingMethods"
            :key="card.id"
          >
            <div class="img">
              <img @error="(e) => (e.target.src = $defaultCardAvatar)"
                :src="[
                  card.brand == 'visa'
                    ? require('@/assets/images/bank/visa.jpg')
                    : require('@/assets/images/bank/mastercard.jpg')
                ]"
                class="img-fluid"
              />
            </div>
            <div class="info">
              <span class="name"
                >**** **** **** {{ card.last4Digits }}
                <span class="c-badge" v-if="card.isDefault"
                  >Default Payment Method</span
                >
              </span>

              <small
                >valid until {{ card.expMonth }} / {{ card.expYear }}</small
              >
            </div>
             <button
      class="btn btn-primary btn-hovered"
      v-if="!card.isDefault"
      @click="makeDefault(card.paymentId)"
      >
     Make Default
    </button>
            <img @error="(e) => (e.target.src = $defaultCardAvatar)"
              src="@/assets/images/comments/delete.svg"
              class="delete"
              alt="Delete Account"
              @click="deletePaymentMethodTrigger(card.paymentId)"
            />
          </div>
        </div>
      </div>
      <div class="col-12 text-center mt-3">
        <button
          class="btn btn-primary add-credit-card"
          @click="openNewCardModal"
        >
          Add Credit Card
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import EventBus from "@/components/event-bus";
export default {
  name: "Billing",
  props: ["billingMethods", "billingCustomers"],
  methods: {
    openNewCardModal: function() {
      EventBus.$emit("openNewCardModal", true);
    },
    deletePaymentMethodTrigger: function(paymentId) {
      EventBus.$emit("deletePaymentMethod", paymentId);
    },
    makeDefault: function(paymentId){
      EventBus.$emit("makeDefault", paymentId);
    }
  }
};
</script>

<style lang="scss">
@import "../../scss/colors.scss";
.account {
  .profile {
    width: 90px;
    height: 90px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    float: left;
    margin-right: 10px;
  }
  .info {
    padding-top: 25px;
    .name {
      font-weight: bold;
      width: 100%;
    }
  }
  .period {
    font-size: 12px;
    text-align: center;
    .bold {
      font-weight: bold;
    }
    .col {
      &:nth-child(2) {
        border-right: 1px solid lighten($black, 90%);
        border-left: 1px solid lighten($black, 90%);
      }
    }
  }
  .btn-wrap {
    .btn {
      width: calc(50% - 5px);
      margin-right: 10px;
      font-size: 12px;
      font-weight: bold;
      color: $white;
    }
    .btn-danger {
      margin-right: 0px;
    }
  }
}
.btn.add-credit-card {
  font-size: 12px;
}
.billing-box {
  .social-account {
    padding: 15px 15px;
    margin: 15px 0px;
    font-weight: bold;
    position: relative;
    border-radius: 30px;
    position: relative;
    .socialmedia {
      position: absolute;
      left: 5px;
      top: 3px;
      width: 20px;
    }
    img {
      width: 50px;
      height: auto;
    }
    &:hover {
      background: $main-bg-color;
    }
    &:after {
      content: "";
      width: 100%;
      position: absolute;
      left: 0;
      bottom: -7.5px;
      border-bottom: 1px solid $main-bg-color;
      border-radius: 5px;
    }
    cursor: pointer;
    &:last-child {
      &:after {
        content: "";
        display: none;
      }
    }
    .delete {
      position: absolute;
      right: 25px;
      top: 35px;
      opacity: 0;
      transition: all 0.2s ease;
      width: 14px !important;
      height: auto;
    }
    &:hover {
      .delete {
        opacity: 1;
      }
      .btn-hovered{
     opacity: 1;
      pointer-events:all;
        
      }
    }
    input {
      position: absolute;
      top: 15px;
      right: 15px;
    }
  }
  .btn-hovered{
   position: absolute;
    right: 45px;
    top: 31px;
    padding: 4px 12px;
    line-height: 20px;
    font-size:12px;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s ease;
  }
}
  
</style>
