<template>
  <div>
    <a
      :href="'#social-account' + account.id"
      data-toggle="collapse"
      class="toggle"
    >
      <page :page="account" :noaction="true"></page>
      <i class="fas fa-angle-down"></i>
    </a>
    <div :id="'social-account' + account.id" class="collapse page-settings">
      <div class="switch-wrap">
        <strong>Only Screening</strong>
        By default, sub user can only view the screens.
        <label class="switch">
          <input
            type="checkbox"
            v-model="account.readOnly"
            class="default primary"
            disabled
          />
          <span class="slider round"></span>
        </label>
      </div>
      <div class="switch-wrap">
        <strong>Changing Account Settings</strong>
        Payment details will be shown only in the master user.
        <label class="switch">
          <input
            type="checkbox"
            v-model="account.changeSettings"
            class="default primary"
            @click="updateAccountSetting('changeSettings')"
          />
          <span class="slider round"></span>
        </label>
      </div>

      <div class="switch-wrap">
        <strong>Changing Comment Status</strong>
        Sub user can change status of comments.
        <label class="switch">
          <input
            type="checkbox"
            v-model="account.changeStatus"
            class="default primary"
            @click="updateAccountSetting('changeStatus')"
          />
          <span class="slider round"></span>
        </label>
      </div>
      <div class="switch-wrap">
        <strong>Replying Comments</strong>
        Sub user can reply the comments.
        <label class="switch">
          <input
            type="checkbox"
            v-model="account.replyComment"
            class="default primary"
            @click="updateAccountSetting('replyComment')"
          />
          <span class="slider round"></span>
        </label>
      </div>
      <div class="switch-wrap">
        <strong>Like Comments</strong>
        Sub user can like the comments.
        <label class="switch">
          <input
            type="checkbox"
            v-model="account.likeComment"
            class="default primary"
            @click="updateAccountSetting('likeComment')"
          />
          <span class="slider round"></span>
        </label>
      </div>
      <div class="switch-wrap">
        <strong>Block Commented User</strong>
        Sub user can block the user who made a comment to the connected account.
        <label class="switch">
          <input
            type="checkbox"
            v-model="account.blockUser"
            class="default primary"
            @click="updateAccountSetting('blockUser')"
          />
          <span class="slider round"></span>
        </label>
      </div>
      <div class="switch-wrap">
        <strong>Delete / Hide Comment</strong>
        Sub user can delete / hide the comments.
        <label class="switch">
          <input
            type="checkbox"
            v-model="account.deleteComment"
            class="default primary"
            @click="updateAccountSetting('deleteComment')"
          />
          <span class="slider round"></span>
        </label>
      </div>
      <!-- <button class="btn save">Save</button> -->
    </div>
  </div>
</template>

<script>
import EventBus from "@/components/event-bus";
import page from "@/components/subusers/page-list";

export default {
  name: "PageSettings",
  props: ["account"],
  data: function() {
    return {
      page: {}
    };
  },
  components: {
    page
  },
  created: function() {
    this.page = {
      accountId: this.account.accountId,
      accountName: this.account.accountName,
      socialMedia: this.account.socialMediaApp,
      imageUrl: this.account.imageUrl,
      id: this.account.id
    };
  },
  methods: {
    updateAccountSetting: function(field) {
      let accountId = this.account.id;
      let data = {};
      data[field] = !this.account[field];
      EventBus.$emit("updateAccountSetting", data, accountId);
    }
  }
};
</script>
<style lang="scss">
@import "@/scss/colors.scss";
.toggle {
  width: 100%;
  display: block;
  position: relative;
  margin-bottom: 10px;

  border-radius: 30px;
  &:hover {
    background: $main-bg-color;
  }

  .fa-angle-down {
    position: absolute;
    right: 30px;
    top: 27px;
    font-size: 21px;
    transition: all 0.25s ease;
  }
}
.page-settings {
  .switch-wrap {
    position: relative;
    padding: 7.5px 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    &:last-child {
      border-bottom: 0;
    }
    strong {
      display: block;
    }
    .switch {
      top: 11px;
      right: 0px;
    }
  }
}
.btn.save {
  display: block;
  border: 1px solid rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 100%;
  margin-top: 20px;
}
a,
a:hover {
  color: inherit;
}
a[aria-expanded="true"] {
  background: $main-bg-color;
  i {
    transform: rotate(180deg);
    margin-top: -3px;
  }
}
.toggle + div {
  padding: 10px 15px 20px 15px !important;
}
</style>
