var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sidebar",class:[
    { 'sidebar-collapsed': _vm.getActiveSocialMedia == '' },
    { closed: !_vm.menu },
    { 'facebook-bg': _vm.getActiveSocialMedia == 'facebook' },
    { 'instagram-bg': _vm.getActiveSocialMedia == 'instagram' },
    { 'twitter-bg': _vm.getActiveSocialMedia == 'twitter' },
    { 'youtube-bg': _vm.getActiveSocialMedia == 'youtube' },
  ],attrs:{"id":"sidebar"}},[_c('sidebar-left'),_c('sidebar-right')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }