<template>
  <div>
    <Login v-if="true"></Login>
    <div class="container login p-0" v-if="false">
      Coming Soon
    </div>
    <div class="col authAction" v-if="true">
      Don’t you have an account?
      <router-link
        :to="{
          name: 'signup'
        }"
      >
        Sign Up.
      </router-link>
      <Contracts></Contracts>
    </div>
  </div>
</template>

<script>
import Login from "@/components/user/login.vue";
import Contracts from "@/components/contracts";

export default {
  name: "home",
  components: {
    Login,
    Contracts
  }
};
</script>
