<template>
  <div class="wrap">
    <div class="row">
      <img @error="(e) => (e.target.src = $defaultCardAvatar)" src="@/assets/images/add-account.svg" alt="Down" />
      <span class="h1">ADD SOCIAL ACCOUNT</span>
      <p>
        Please add social accounts and 
        <br />watch our AI work.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "AddAccountBox"
};
</script>

<style lang="scss" scoped>
@import "@/scss/colors.scss";
.wrap {
  height: 330px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  text-align: center !important;
  opacity: 0.5;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    opacity: 1;
  }
  * {
    display: block;
  }
  img {
    margin: auto !important;
  }
  .h1 {
    font-family: "Fenomen Sans";
    font-weight: bold;
    color: lighten($black, 30%);
    margin: 15px 0px;
    font-size: 27px;
  }
}
</style>
