<template>
  <div>
    <div class="chart-card">
      <div class="chart-header">
        <span class="count"> </span>
        <span class="sub-title"></span>
      </div>
      <div class="ghost-chart"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "report-wrapper",
};
</script>
<style lang="scss" scoped>
@import "@/scss/colors.scss";
@-webkit-keyframes placeholderSkeleton {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@-webkit-keyframes skeletonAnimate {
  from {
    background-position: top left;
  }
  to {
    background-position: top right;
  }
}
.chart-card {
  border-radius: 30px;
  background: $white;
  &:hover {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
  }
  padding: 0px 0px 5px 0px;
  margin-bottom: 20px;
  transition: all 0.25s ease;

  .chart-header {
    width: 100%;
    padding: 15px 15px 10px;
    .count {
      width: 120px;
      height: 70px;
    }
    .sub-title {
      width: 100%;
      height: 25px;
      display: block;
      margin-top: 20px;
    }
  }
  .ghost-chart {
    width: 90%;
    height: 87px;
    margin: 15px auto;
    display: block;
  }

  .text {
    padding: 0;
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    div {
      background: $main-bg-color;
      height: 10px;
      margin-bottom: 5px;
    }
  }
  .chart-header .count,
  .ghost-chart,
  .chart-header .sub-title {
    -webkit-animation-duration: 1.5s;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: placeholderSkeleton;
    -webkit-animation-timing-function: linear;
    background: #f6f7f8;
    background-image: -webkit-gradient(
      linear,
      left center,
      right center,
      from(#f6f7f8),
      color-stop(0.2, #edeef1),
      color-stop(0.4, #f6f7f8),
      to(#f6f7f8)
    );
    background-image: -webkit-linear-gradient(
      left,
      #f6f7f8 0%,
      #edeef1 20%,
      #f6f7f8 40%,
      #f6f7f8 100%
    );
    background-repeat: no-repeat;
    border-radius: 5px;
  }
}

@media (max-width: 767.98px) {
  .status {
    .dot {
      float: none !important;
    }
  }
}
@media screen and (max-device-width: 1450px) {
  .bottom {
    font-size: 10px !important;
  }
}
</style>
