<template>
  <div class="fl-wrap">
    <!-- Add New Payment Method Modal -->
    <modal
      v-if="newCardModal"
      @close="newCardModal = false"
      class="newCardModal"
    >
      <div slot="title">New Payment Method</div>
      <div class="row head">
        <div class="col">
          <i class="fas fa-exclamation-triangle"></i>
          Warning
        </div>
        <div class="col text-right">
          <i class="fas fa-times" @click="newCardModal = false"></i>
        </div>
      </div>
      <div
        slot="body"
        class="newcard-form"
        :class="{ disabled: xhrNewCreditCard }"
      >
        <div class="row" v-if="billingCustomers == null">
          <div class="col-12">
            <div class="form-group">
              <label for="cardnumber">Payment Profile Name</label>

              <input
                type="text"
                @blur="$v.newPaymentProfile.name.$touch()"
                v-model="newPaymentProfile.name"
                class="form-control underline"
                placeholder="Name"
                :class="{ 'invalid-b': $v.newPaymentProfile.name.$error }"
              />
              <div v-if="$v.newPaymentProfile.name.$error">
                <span class="invalid" v-if="!$v.newPaymentProfile.name.required"
                  >Payment Profile Name required</span
                >
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label for="cardnumber">Email</label>
              <input
                type="text"
                v-model="newPaymentProfile.email"
                class="form-control underline"
                placeholder="email"
                :class="{ 'invalid-b': $v.newPaymentProfile.email.$error }"
              />
              <div v-if="$v.newCard.number.$error">
                <span class="invalid" v-if="!$v.newCard.number.required"
                  >Please enter valid email</span
                >
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label for="cardnumber">Phone</label>
              <input
                type="text"
                v-model="newPaymentProfile.phone"
                class="form-control underline"
                placeholder="Phone"
              />
            </div>
          </div>
          <hr />
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="cardnumber">Card Number</label>
              <the-mask
                mask="#### #### #### ####"
                type="text"
                name="cardnumber"
                @blur="$v.newCard.number.$touch()"
                v-model="newCard.number"
                class="form-control underline"
                :class="{ 'invalid-b': $v.newCard.number.$error }"
                placeholder="· · · ·  · · · ·  · · · ·  · · · · "
              >
              </the-mask>
              <div v-if="$v.newCard.number.$error">
                <span class="invalid" v-if="!$v.newCard.number.required"
                  >Card Number required</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <div class="form-group">
              <label for="cardnumber">MM</label>
              <the-mask
                mask="##"
                type="text"
                name="cardmonth"
                @blur="$v.newCard.expMonth.$touch()"
                v-model="newCard.expMonth"
                class="form-control underline"
                :class="{ 'invalid-b': $v.newCard.expMonth.$error }"
                placeholder="· ·"
              ></the-mask>
              <div v-if="$v.newCard.expMonth.$error">
                <span class="invalid" v-if="!$v.newCard.expMonth.required"
                  >End Month required</span
                >
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <label for="cardnumber">YY</label>
              <the-mask
                mask="##"
                type="text"
                name="cardmonth"
                @blur="$v.newCard.expYear.$touch()"
                v-model="newCard.expYear"
                class="form-control underline"
                :class="{ 'invalid-b': $v.newCard.expYear.$error }"
                placeholder="· ·"
              ></the-mask>
              <div v-if="$v.newCard.expYear.$error">
                <span class="invalid" v-if="!$v.newCard.expYear.required"
                  >End Year required</span
                >
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <label for="cardnumber">cvc</label>

              <the-mask
                mask="###"
                type="text"
                name="cardmonth"
                @blur="$v.newCard.cvc.$touch()"
                v-model="newCard.cvc"
                class="form-control underline"
                :class="{ 'invalid-b': $v.newCard.cvc.$error }"
                placeholder="· · ·"
              >
              </the-mask>
              <div v-if="$v.newCard.cvc.$error">
                <span class="invalid" v-if="!$v.newCard.cvc.required"
                  >cvc required</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="w-100 d-block stripe-wrapper">
          <img
            @error="(e) => (e.target.src = $defaultCardAvatar)"
            src="@/assets/images/stripe-k.png"
            class="pwr-stripe"
            alt="Powered by Stripe"
          />
        </div>
      </div>
      <div slot="footer">
        <button class="btn btn-primary" @click="addNewPaymentMethodwithProfile">
          ADD <Loading :white="true" v-if="xhrNewCreditCard"></Loading>
        </button>
      </div>
    </modal>
    <!-- Add New Payment Method Modal -->

    <div class="wrapper" :class="{ centered: !showAddNew }">
      <img
        @error="(e) => (e.target.src = $defaultCardAvatar)"
        src="@/assets/images/close.svg"
        class="close icon"
        @click="close"
        alt="Socialays"
        v-if="!showAddNew"
      />
      <img
        @error="(e) => (e.target.src = $defaultCardAvatar)"
        src="@/assets/images/close.svg"
        class="close icon"
        @click="showAddNew = false"
        alt="Socialays"
        v-if="showAddNew"
      />
      <transition enter-active-class="animated fadeIn">
        <div class="wrapper centered-content" v-if="paymentCheck">
          <div class="container text-center">
            <img
              @error="(e) => (e.target.src = $defaultCardAvatar)"
              src="@/assets/images/icon.svg"
              class="icon"
              alt="Socialays"
            />
            <Loading></Loading><br />
            Just a second, we processing your subscription.
          </div>
        </div>
      </transition>
      <transition enter-active-class="animated fadeIn" :delay="500">
        <div
          class="container add-account-list-scroll"
          v-if="!showAddNew && !paymentCheck"
        >
          <img
            @error="(e) => (e.target.src = $defaultCardAvatar)"
            src="@/assets/images/icon.svg"
            class="icon"
            alt="Socialays"
          />
          <span class="h1">Select Accounts</span>
          <div class="row">
            <div class="col-md-8 col-12 offset-md-2">
              <Loading class="p-centered" v-if="xhrCreditCard"></Loading>
              <div class="row mt-2 mb-2" :class="{ filtered: xhrCreditCard }">
                <div class="col-12 p-0">
                  <span class="h4 d-block mt-c ml-3">New Accounts</span>
                  <span
                    class="ml-3"
                    v-if="accountList.length == 0 && xhrProcess == false"
                    >You didn't select new account(s).</span
                  >
                  <div
                    class="accounts"
                    :class="{ 'centered-content': xhrProcess }"
                  >
                    <Loading v-if="xhrProcess"></Loading>
                    <div>
                      <div
                        class="social-account"
                        v-for="(account, index) in accountList"
                        :key="index"
                        :class="{ selected: account.selected }"
                        @click="setSelected(account.accountId)"
                      >
                        <div class="img">
                          <span
                            class="badge-primary"
                            v-if="
                              account.accountType == 'COMPETITOR' ||
                                account.type == 'COMPETITOR'
                            "
                          >
                            Competitor
                          </span>
                          <facebookIcon
                            class="socialmedia"
                            :active="'true'"
                            v-if="account.type.toLowerCase() == 'facebook'"
                          >
                          </facebookIcon>
                          <instagramIcon
                            class="socialmedia"
                            :active="'true'"
                            v-if="account.type.toLowerCase() == 'instagram'"
                          >
                          </instagramIcon>
                          <twitterIcon
                            class="socialmedia"
                            :active="'true'"
                            v-if="account.type.toLowerCase() == 'twitter'"
                          >
                          </twitterIcon>
                          <youtubeIcon
                            class="socialmedia"
                            :active="'true'"
                            v-if="account.type.toLowerCase() == 'youtube'"
                          >
                          </youtubeIcon>
                          <img
                            @error="(e) => (e.target.src = $defaultCardAvatar)"
                            :src="
                              account.profileImageUrl
                                ? account.profileImageUrl
                                : '/images/no-image.jpg'
                            "
                            class="img-fluid"
                          />
                        </div>
                        <div class="info">
                          <span class="name">{{ account.accountName }}</span>
                          <span class="account-id">{{
                            account.accountId
                          }}</span>
                        </div>
                        <input type="checkbox" v-model="account.selected" />
                      </div>
                    </div>

                    <button
                      class="btn btn-primary btn-block mt-3 d-sm-none"
                      @click="addAnother"
                    >
                      <img
                        @error="(e) => (e.target.src = $defaultCardAvatar)"
                        class="btn-icon"
                        src="@/assets/images/sidebar/new-account.svg"
                        alt="Add Account"
                      />
                      Add
                      {{ accountList.length == 0 ? "New" : "Another" }} Account
                    </button>
                  </div>
                </div>
                <div class="col-12 col-sm-6 upgrade d-none">
                  <span class="h4 d-block">Order Details</span>
                  <div class="plan">
                    <ul class="leaders">
                      <li v-if="count('facebook') > 0">
                        <span>Facebook Account</span>
                        <span class="account-total"
                          >$ {{ accountTotal("facebook") }}</span
                        >
                        <span> $ 29 x {{ count("facebook") }} </span>
                      </li>
                      <li v-if="count('instagram') > 0">
                        <span>Instagram Account</span>
                        <span class="account-total"
                          >$ {{ accountTotal("instagram") }}</span
                        >
                        <span> $ 29 x {{ count("instagram") }} </span>
                      </li>
                      <li v-if="count('twitter') > 0">
                        <span>Twitter Account</span>
                        <span class="account-total"
                          >$ {{ accountTotal("twitter") }}</span
                        >
                        <span> $ 29 x {{ count("twitter") }} </span>
                      </li>
                      <li v-if="count('youtube') > 0">
                        <span>Youtube Account</span>
                        <span class="account-total"
                          >$ {{ accountTotal("youtube") }}</span
                        >
                        <span> $ 29 x {{ count("youtube") }} </span>
                      </li>
                      <li v-if="count('competitor') > 0">
                        <span>Competitor Account</span>
                        <span class="account-total"
                          >$ {{ accountTotal("competitor") }}</span
                        >
                        <span> $ 29 x {{ count("competitor") }} </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-12 addAnother d-none d-sm-flex">
                  <button
                    class="btn btn-primary btn-block btn-success mt-3"
                    :class="{
                      'cta-bordered':
                        totalSelectedAccountsCount >
                          this.$maxAddableAccountWhileTrial &&
                        userAccountType == 'TRIAL',
                    }"
                    :disabled="
                      (totalSelectedAccountsCount >
                        this.$maxAddableAccountWhileTrial &&
                        userAccountType == 'TRIAL') ||
                        isLoading
                    "
                    @click="subscribe(false)"
                    v-if="userAccountType == 'TRIAL'"
                  >
                    <!-- until {{ lessDay }} days -->
                    <span v-if="!isLoading">
                      Save & Continue
                      <img
                        @error="(e) => (e.target.src = $defaultCardAvatar)"
                        src="@/assets/images/continue.svg"
                        alt="Continue"
                      />
                    </span>
                    <Loading :white="true" v-if="isLoading"></Loading>
                  </button>
                  <button
                    class="btn btn-border btn-block mt-3"
                    @click="addAnother"
                  >
                    <img
                      @error="(e) => (e.target.src = $defaultCardAvatar)"
                      class="btn-icon"
                      src="@/assets/images/sidebar/new-account-dark.svg"
                      alt="Add Account"
                    />
                    Add
                    {{ accountList.length == 0 ? "New" : "Another" }} Account
                  </button>
                </div>
                <div class="col-12 col-sm-6 p-0 d-none">
                  <div class="total">
                    <b>Total:</b> $ {{ grandTotal() }} / monthly
                  </div>
                  <div
                    class="alert info mt-2"
                    v-if="
                      formerlyLinked.length > 0 &&
                        selectedPaymentMethod.isDefault != true
                    "
                  >
                    <i class="fas fa-exclamation-triangle"></i>
                    All your subscription will be updated and charges from this
                    card.
                  </div>

                  <div class="payment-method dropup">
                    <div
                      class="social-account credit-card"
                      data-toggle="dropdown"
                      v-if="billingMethods.length > 0 && !xhrCreditCard"
                    >
                      <div class="img">
                        <img
                          @error="(e) => (e.target.src = $defaultCardAvatar)"
                          :src="[
                            selectedPaymentMethod.brand == 'visa'
                              ? require('@/assets/images/bank/visa.jpg')
                              : require('@/assets/images/bank/mastercard.jpg'),
                          ]"
                          class="img-fluid"
                        />
                      </div>
                      <div class="info">
                        <span class="name"
                          >**** **** ****
                          {{ selectedPaymentMethod.last4Digits }}</span
                        >
                        <small
                          >valid until {{ selectedPaymentMethod.expMonth }} /
                          {{ selectedPaymentMethod.expYear }}</small
                        >
                      </div>
                      <img
                        @error="(e) => (e.target.src = $defaultCardAvatar)"
                        src="@/assets/images/sidebar/down-b.svg"
                        class="down"
                        alt="Down"
                      />
                    </div>
                    <div
                      class="add text-center"
                      v-if="billingMethods.length == 0 && !xhrCreditCard"
                      @click="newCardModal = true"
                    >
                      <img
                        @error="(e) => (e.target.src = $defaultCardAvatar)"
                        src="@/assets/images/sidebar/add.svg"
                        alt="Add"
                      />
                      Add Payment Method
                    </div>
                    <!-- Dropdown -->
                    <div class="dropdown-menu" id="card-list">
                      <div
                        class="social-account credit-card"
                        v-for="card in billingMethods"
                        :key="card.id"
                        @click="setPaymentMethod(card.id)"
                      >
                        <div class="img">
                          <img
                            @error="(e) => (e.target.src = $defaultCardAvatar)"
                            :src="[
                              card.brand == 'visa'
                                ? require('@/assets/images/bank/visa.jpg')
                                : require('@/assets/images/bank/mastercard.jpg'),
                            ]"
                            class="img-fluid"
                          />
                        </div>
                        <div class="info">
                          <span class="name"
                            >**** **** **** {{ card.last4Digits }}</span
                          >
                          <small
                            >valid until {{ card.expMonth }} /
                            {{ card.expYear }}</small
                          >
                        </div>
                      </div>

                      <div
                        class="add text-center"
                        v-if="billingMethods.length > 0"
                        @click="newCardModal = true"
                      >
                        <img
                          @error="(e) => (e.target.src = $defaultCardAvatar)"
                          src="/img/add.73aa7dbf.svg"
                          alt="Add"
                        />
                        Add Another
                      </div>
                      <!-- Dropdown -->
                    </div>
                    <div class="fixed-buttons d-none">
                      <div class="row">
                        <!-- v-if="
                          totalSelectedAccountsCount <=
                            this.$maxAddableAccountWhileTrial && lessDay <= 14
                        " -->
                        <div class="col-12">
                          <button
                            class="btn btn-primary btn-block btn-success mt-3"
                            :class="{
                              'cta-bordered':
                                totalSelectedAccountsCount >
                                  this.$maxAddableAccountWhileTrial &&
                                userAccountType == 'TRIAL',
                            }"
                            :disabled="
                              (totalSelectedAccountsCount >
                                this.$maxAddableAccountWhileTrial &&
                                userAccountType == 'TRIAL') ||
                                isLoading
                            "
                            @click="subscribe(false)"
                            v-if="userAccountType == 'TRIAL'"
                          >
                            <!-- until {{ lessDay }} days -->
                            <span v-if="!isLoading">
                              Continue with free trial
                              <img
                                @error="
                                  (e) => (e.target.src = $defaultCardAvatar)
                                "
                                src="@/assets/images/continue.svg"
                                alt="Continue"
                              />
                            </span>
                            <Loading :white="true" v-if="isLoading"></Loading>
                          </button>
                        </div>
                      </div>

                      <button
                        class="btn btn-primary btn-block btn-success mt-3"
                        :class="{
                          'cta-bordered':
                            totalSelectedAccountsCount <=
                              this.$maxAddableAccountWhileTrial &&
                            lessDay <= 14,
                        }"
                        @click="subscribe(true)"
                        :disabled="
                          accountList.length == 0 ||
                            grandTotal() == 0 ||
                            (selectedPaymentMethod.length == 0 &&
                              userAccountType != 'TRIAL') ||
                            isLoading
                        "
                      >
                        <span v-if="!isLoading">
                          Subscribe Now ($ {{ grandTotal() }}
                          <small>/ Monthly</small>)
                        </span>
                        <Loading :white="true" v-if="isLoading"></Loading>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
      <transition enter-active-class="animated fadeInRight" :delay="1500">
        <div v-if="showAddNew">
          <addAccount
            @close="showAddNew = false"
            :currentFlow="'OWN'"
            :showClose="false"
          ></addAccount>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import { HTTP } from "@/main-source";
import router from "@/router";
import modal from "@/components/modal";
import addAccount from "@/components/accounts/AddAccount";
import facebookIcon from "@/components/icons/facebook";
import instagramIcon from "@/components/icons/instagram";
import twitterIcon from "@/components/icons/twitter";
import youtubeIcon from "@/components/icons/youtube";
import {
  required,
  email,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import Loading from "@/components/Loading";

export default {
  name: "AddAccountList",
  data() {
    return {
      accountList: [],
      user: null,
      redirectURL: this.$redirectURL,
      newCardModal: false,
      showAddNew: false,
      userId: null,
      billingCustomers: null,
      billingMethods: [],
      newPaymentProfile: {
        userId: null,
        description: null,
        email: null,
        name: null,
        phone: null,
      },
      newCard: {
        userId: null,
        number: null,
        expMonth: null,
        expYear: null,
        cvc: null,
      },
      selectedPaymentMethod: [],
      isLoading: false,
      xhrProcess: false,
      xhrCreditCard: false,
      xhrNewCreditCard: false,
      paymentCheck: false,
      userAccountType: null,
      paymentIntent: this.$route.query.payment_intent,
      formerlyLinked: [],
      lessDay: null,
      totalSelectedAccountsCount: 0,
    };
  },
  validations: {
    newCard: {
      number: { required },
      expYear: { required },
      expMonth: { required },
      cvc: { required },
    },
    newPaymentProfile: {
      userId: required,
      name: required,
      email: email,
    },
  },
  components: {
    modal,
    addAccount,
    facebookIcon,
    instagramIcon,
    twitterIcon,
    youtubeIcon,
    Loading,
  },
  created() {
    this.$setMaxCount();
    if (this.$route.name == "paymentCallback") {
      this.paymentCheck = true;
      this.checkPayment();
    }
  },
  mounted() {
    this.getAccountListAndPaymentInfo();
  },
  methods: {
    getAccountListAndPaymentInfo: function() {
      let socialmedia = this.$route.params.socialmedia;
      let code = this.$route.query.code;
      let oauth_token = this.$route.query.oauth_token;
      let oauth_verifier = this.$route.query.oauth_verifier;

      // Set Competitor Accounts
      let competitorAccounts = JSON.parse(
        localStorage.getItem("competitorURLs")
      );

      if (competitorAccounts != null) {
        competitorAccounts.forEach((account) => {
          let competitorAccount = {
            accountId: account.accountId,
            accountName: account.accountName,
            socialAppName: account.appName,
            accountType: "COMPETITOR",
            type: account.appName,
            selected: true,
          };
          this.accountList.push(competitorAccount);
        });
      }
      // End Competitor Accounts

      if (
        code == undefined &&
        oauth_verifier == undefined &&
        oauth_token == undefined &&
        this.$route.name != "upgrade" &&
        this.$route.name != "newPaymentMethod" &&
        this.$route.name != "paymentCallback" &&
        competitorAccounts == null
      ) {
        window.location = this.$redirectURL;
      }

      let redirect_url =
        this.redirectURL + "social/" + socialmedia + "/save&code=" + code;

      if (socialmedia == "twitter") {
        redirect_url =
          this.redirectURL +
          "social/" +
          socialmedia +
          "/save&oauth_token=" +
          oauth_token +
          "&oauth_verifier=" +
          oauth_verifier;
      }

      this.getPaymentInfos();
      if (socialmedia != "competitor" && this.$route.name != "upgrade") {
        HTTP.get("accounts/" + socialmedia + "?redirect_url=" + redirect_url)
          .then((result) => {
            if (result.data.accountList) {
              result.data.accountList.forEach((account) => {
                account.selected = true;
                account.type = socialmedia;
                this.accountList.push(account);
              });
              this.checkTotalSelected();
            }
            this.xhrProcess = false;
          })
          .catch((e) => {
            this.xhrProcess = false;
            this.$notify({
              group: "topNotifications",
              type: "error",
              text: e.response.data.message,
            });

            // if (this.accountList.length == 0) {
              window.location = this.redirectURL;
            // }
          });
      } else {
        this.xhrProcess = false;
      }
      if (localStorage.getItem("accountList")) {
        let pocketAccounts = JSON.parse(localStorage.getItem("accountList"));
        if (pocketAccounts) {
          pocketAccounts.forEach((account) => {
            if (
              !this.accountList.some(
                (laccount) => laccount.accountId == account.accountId
              )
            ) {
              this.accountList.push(account);
            }
            this.checkTotalSelected();
          });
        }
      }
    },
    getPaymentInfos: function(setCard) {
      HTTP.get("user/me").then((result) => {
        let userId = result.data.id;
        this.user = result.data;
        this.newPaymentProfile.email = result.data.email;
        this.newPaymentProfile.userId = userId;
        this.newCard.userId = userId;
        this.userAccountType = result.data.status;
        if (result.data.customers) {
          this.billingCustomers = result.data.customers;
        }
        this.userId = userId;

        let createdDateTime = new Date(result.data.createdAt);
        let today = new Date();
        let difference =
          14 - Math.round((today - createdDateTime) / (1000 * 60 * 60 * 24));
        this.lessDay = difference;

        if (this.billingCustomers) {
          HTTP.get(
            "payment/payment-method?customerId=" +
              this.billingCustomers.customerId +
              "&userid=" +
              userId
          )
            .then((res) => {
              this.billingMethods = res.data;
              if (setCard != false) {
                if (this.billingMethods.length > 0) {
                  this.selectedPaymentMethod = this.billingMethods.filter(
                    (m) => m.isDefault == true
                  )[0];
                }
              }
              this.xhrCreditCard = false;
            })
            .catch(() => {
              this.xhrCreditCard = false;
            });
        } else {
          this.xhrCreditCard = false;
        }

        // Upgrade

        this.formerlyLinked = result.data.socialMediaAccounts;
        // if (
        //   this.userAccountType == "TRIAL" ||
        //   this.userAccountType == "TRIAL_EXPIRED"
        // ) {
                    // biome-ignore lint/complexity/noForEach: <explanation>
          result?.data?.socialMediaAccounts.forEach((account) => {
            account.selected = true;
            account.profileImageUrl = account.imageUrl;
            account.type = account.socialMediaApp.toLowerCase();
            if (
              this.accountList.filter(
                (laccount) => laccount.accountId === account.accountId
              ).length === 0) {
              this.accountList.push(account);
            }
          });

          this.checkTotalSelected();
        // }

        // Upgrade
      });
    },
    count: function(which) {
      if (which == "competitor") {
        return this.accountList.filter(
          (account) =>
            account.accountType == "COMPETITOR" && account.selected == true
        ).length;
      }
      return this.accountList.filter(
        (account) =>
          account.type == which &&
          account.selected == true &&
          account.accountType != "COMPETITOR"
      ).length;
    },
    accountTotal: function(which) {
      return (this.count(which) * 29).toFixed(2);
    },
    grandTotal: function() {
      return (
        this.accountList.filter((account) => account.selected).length * 29
      ).toFixed(2);
    },
    addAnother: function() {
      this.showAddNew = true;
      localStorage.setItem("accountList", JSON.stringify(this.accountList));
    },
    setSelected: function(accountId) {
      var account = this.accountList.filter(
        (account) => account.accountId == accountId
      )[0];

      let formerlyCount = this.formerlyLinked.filter(
        (formerlyAccount) => formerlyAccount.accountId == account.accountId
      ).length;

      if (formerlyCount == 0) {
        account.selected = !account.selected;
      } else {
        this.$notify({
          group: "topNotifications",
          type: "error",
          text: "Formerly linked account can not be removed.",
        });
      }

      this.checkTotalSelected();
    },
    checkTotalSelected: function() {
      this.totalSelectedAccountsCount = this.accountList.filter(
        (account) => account.selected == true
      ).length;

      if (this.userAccountType == "TRIAL") {
        if (
          this.totalSelectedAccountsCount > this.$maxAddableAccountWhileTrial
        ) {
          this.$notify({
            group: "topNotifications",
            type: "warning",
            text:
              "You can only add " +
              this.$maxAddableAccountWhileTrial +
              " accounts at trial version.",
          });
        }
      }
    },
    subscribe: function(upgradeAccount) {
      // let uri = "http://localhost:8080/payment/callback";
      let uri = this.$redirectURL + "payment/callback";
      this.isLoading = true;
      let data = {
        paymentMethodId: this.selectedPaymentMethod.paymentId,
        returnUrl: uri,
        items: [],
      };

      const items = this.accountList
        .filter((account) => account.selected)
        .map((account) => {
          return {
            app: account.type.toUpperCase(),
            accountId: account.accountId,
          };
        });

      data.items = items;

      if (upgradeAccount) {
        if (!this.selectedPaymentMethod.paymentId) {
          // this.$notify({
          //   group: "topNotifications",
          //   // title: "Important message",
          //   type: "error",
          //   text: "Please add payment method.",
          //   duration: 2000,
          // });
          this.newCardModal = true;
          this.isLoading = false;
        } else {
          // if (this.userAccountType == "TRIAL") {
          //   const formerItems = this.formerlyLinked
          //     .filter(account => account.selected)
          //     .map(account => {
          //       return {
          //         app: account.type.toUpperCase(),
          //         accountId: account.accountId
          //       };
          //     });

          //   formerItems.forEach(formerAccount => {
          //     data.items.push(formerAccount);
          //   });
          // }

          HTTP.post("payment/subscription?userid=" + this.userId, data)
            .then((result) => {
              if (result.status == 200) {
                this.paymentIntent = result.data.object;
                if (this.checkPayment()) {
                  this.saveAccounts();
                }
              }
            })
            .catch((e) => {
              if (e.response.status == 428) {
                localStorage.setItem(
                  "accountList",
                  JSON.stringify(this.accountList.filter((a) => a.selected))
                );
                window.location = e.response.data.object;
              } else {
                this.isLoading = false;
                this.$notify({
                  group: "topNotifications",
                  // title: "Important message",
                  type: "error",
                  text: e.response.data.message,
                  duration: 5000,
                });
              }
            });
        }
      } else if (this.userAccountType == "TRIAL") {
        this.formerlyLinked.forEach((formerAccount) => {
          this.accountList.filter(
            (account) => account.accountId == formerAccount.accountId
          )[0].selected = false;
        });
        this.saveAccounts();
      }
    },
    saveAccounts: function() {
      /* eslint-disable */
      this.isLoading = true;
      var selectedAccounts = this.accountList.filter(
        (account) => account.selected
      );

      selectedAccounts.forEach((account) => {
        account.socialAppName = account.socialMediaApp
          ? account.socialMediaApp.toUpperCase()
          : account.type.toUpperCase();
      });

      let uri = "accounts/";

      if (this.paymentIntent) {
        uri += "?paymentIntent=" + this.paymentIntent;
      }
      if (selectedAccounts.length > 0) {
        HTTP.post(uri, selectedAccounts)
          .then((result) => {
            localStorage.removeItem("accountList");
            // localStorage.removeItem("competitorURLs");
            window.location = this.redirectURL;
          })
          .catch((e) => {
            this.isLoading = false;
            this.$notify({
              group: "topNotifications",
              type: "error",
              text: e.response.data.message,
            });
          });
      } else {
        localStorage.removeItem("accountList");
        // localStorage.removeItem("competitorURLs");
        window.location = this.redirectURL;
      }
    },
    close: function() {
      // localStorage.removeItem("accountList");
      window.location = this.redirectURL;
    },
    addNewPaymentMethod: function() {
      this.newCardModal = false;
    },

    setPaymentMethod: function(cardId) {
      this.selectedPaymentMethod = this.billingMethods.filter(
        (card) => card.id == cardId
      )[0];
    },
    addNewPaymentMethodwithProfile: function() {
      this.xhrNewCreditCard = true;
      if (this.billingCustomers == null) {
        HTTP.post("payment/customer", this.newPaymentProfile)
          .then((res) => {
            console.log(res);
            this.addPaymentMethod();
          })
          .catch((e) => {
            console.log(e);
            this.$notify({
              group: "topNotifications",
              type: "error",
              text: "Something went wrong. <br>Please try again later.",
            });
          });
      } else {
        this.addPaymentMethod();
      }
    },
    addPaymentMethod: function() {
      this.newCard.userId = this.userId;
      HTTP.post("payment/payment-method", this.newCard)
        .then((res) => {
          this.newCardModal = false;
          this.getPaymentInfos(false);
          this.xhrNewCreditCard = false;
          this.selectedPaymentMethod = res.data.object;
        })
        .catch((e) => {
          this.xhrNewCreditCard = false;
          this.$notify({
            group: "topNotifications",
            type: "error",
            text: e.response.data.message,
          });
        });
    },
    checkPayment: function() {
      let userId;
      HTTP.get("user/me")
        .then((result) => {
          userId = result.data.id;
        })
        .then(() => {
          HTTP.get(
            "payment/subscription/check?paymentIntent=" +
              this.paymentIntent +
              "&userid=" +
              userId
          ).then((res) => {
            if (res.data.success == true) {
              this.saveAccounts();
              return true;
            } else {
              this.paymentCheck = false;
              this.$notify({
                group: "topNotifications",
                // title: "Important message",
                type: "error",
                text:
                  "Payment failed, please select or add new payment method.",
                duration: 5000,
              });
            }
          });
        });
    },
  },
  computed: {
    activeProcess: function() {
      return this.$route.name;
    },
  },
  watch: {
    newCardModal: function() {
      if (!this.newCardModal) {
        this.newCard = {
          userId: null,
          number: null,
          expMonth: null,
          expYear: null,
          cvc: null,
        };
      }

      this.$v.$reset();
    },
  },
};
/* eslint-disable */
</script>

<style lang="scss">
@import "@/scss/colors.scss";

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper {
  background-color: $white;
  width: 93vw;
  height: 86vh;
  padding: 15px;
  border-radius: 5px;
  position: relative;

  .h1 {
    font-size: 27px;
    letter-spacing: 2px;
    text-transform: uppercase;
    display: block;
    text-align: center;
    font-weight: bold;
    font-family: "Fenomen Sans";
  }

  .icon {
    width: 90px;
    height: auto;
    margin-bottom: 15px;
  }

  .accounts {
    padding: 10px 15px;
  }

  .social-account {
    padding: 15px 15px;
    margin: 15px 0px;
    font-weight: bold;
    position: relative;
    border-radius: 30px;
    position: relative;

    input {
      pointer-events: none !important;
    }

    .socialmedia {
      position: absolute;
      left: 5px;
      top: 3px;
      width: 20px;
    }

    img {
      width: 50px;
      height: auto;
    }

    &:hover {
      background: $main-bg-color;
    }

    &:after {
      content: "";
      width: 100%;
      position: absolute;
      left: 0;
      bottom: -7.5px;
      border-bottom: 1px solid $main-bg-color;
      border-radius: 5px;
    }

    cursor: pointer;

    &:last-child {
      &:after {
        content: "";
        display: none;
      }
    }

    input {
      position: absolute;
      top: 15px;
      right: 15px;
    }
  }

  .selected {
    background: $main-bg-color;
  }

  .upgrade {
    padding: 15px 15px 0px 15px;
    color: $darker;
    position: relative;

    .h4 {
      color: $black;
    }

    .btn {
      // margin-top:
    }
  }

  .close {
    position: absolute;
    right: 25px;
    top: 25px;
    width: 20px;
    cursor: pointer;
    z-index: 99;
  }
}

.accounts,
.upgrade {
  height: 320px;
  // border:1px solid #f00;
  overflow: scroll;
}

.plan {
  margin: 10px 0px;
}

.total {
  text-align: right;
}

.btn-icon {
  width: 20px;
  margin-right: 5px;
}

.addAnother {
  display: flex;
  flex-direction: column-reverse;
}

.add.text-center {
  border-radius: 30px !important;
}

#card-list {
  width: 100%;

  .social-account,
  .add.text-center {
    transition: all 0.2s ease;

    &:hover {
      background: darken($white, 5%);
    }
  }
}

.total {
  margin-top: -95px;
}

.mt-c {
  margin-top: 15px;
}

.p-centered {
  position: absolute;
  left: 50%;
  top: 50%;
}

.filtered {
  filter: blur(7px);
}

@media (max-width: 767.98px) {
  .wrapper {
    margin-top: 25px;

    .container {
      margin-top: 20px;

      .icon {
        width: 70px;
      }
    }
  }

  .accounts {
    padding: 0 !important;
  }

  .wrapper,
  .accounts,
  .upgrade {
    height: auto;
  }

  .total {
    margin: 0;
  }
}
.btn-border {
  border: 1px solid #ccc !important;
}
</style>
