<template>
  <svg
    id="_006-worried"
    data-name="006-worried"
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <path
      id="Path_719"
      data-name="Path 719"
      d="M13.658,2.343A8,8,0,1,0,2.344,13.657,8,8,0,1,0,13.658,2.343ZM13,12.994A7.063,7.063,0,0,1,3.007,3.006,7.063,7.063,0,0,1,13,12.994Z"
      transform="translate(-0.001)"
      fill="#5a5a5a"
    />
    <path
      id="Path_720"
      data-name="Path 720"
      d="M116.621,150.675a1.614,1.614,0,1,0-1.614,1.614A1.616,1.616,0,0,0,116.621,150.675Zm-2.29,0a.676.676,0,1,1,.676.676A.677.677,0,0,1,114.331,150.675Z"
      transform="translate(-109.849 -144.403)"
      fill="#5a5a5a"
    />
    <path
      id="Path_721"
      data-name="Path 721"
      d="M295.336,149.062a1.614,1.614,0,1,0,1.614,1.614A1.616,1.616,0,0,0,295.336,149.062Zm0,2.29a.676.676,0,1,1,.676-.676A.677.677,0,0,1,295.336,151.352Z"
      transform="translate(-284.543 -144.404)"
      fill="#5a5a5a"
    />
    <path
      id="Path_722"
      data-name="Path 722"
      d="M158.967,303.858a4.517,4.517,0,0,0-2.52-.765h0a4.519,4.519,0,0,0-2.523.768,1.317,1.317,0,0,0-.565,1.716,1.341,1.341,0,0,0,1.208.79,1.425,1.425,0,0,0,.446-.074l.047-.017a3.606,3.606,0,0,1,2.776,0,.468.468,0,0,0,.071.023,1.316,1.316,0,0,0,1.636-.74A1.331,1.331,0,0,0,158.967,303.858Zm-.287,1.334a.381.381,0,0,1-.509.209,4.55,4.55,0,0,0-3.454,0,.394.394,0,0,1-.506-.215.384.384,0,0,1,.2-.525l.027-.017a3.585,3.585,0,0,1,2.011-.615h0a3.583,3.583,0,0,1,2.012.616l.037.022A.394.394,0,0,1,158.681,305.191Z"
      transform="translate(-148.445 -293.621)"
      fill="#5a5a5a"
    />
  </svg>
</template>

<script>
export default {
  name: "worry"
};
</script>
