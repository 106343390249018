<template>
  <div>
    <div class="container login p-0">
      <span class="h1">PASSWORD RESET</span>
      <div class="form">
        <div class="form-group">
          <input
            type="text"
            @blur="$v.email.$touch()"
            v-model="email"
            class="form-control"
            :class="{ 'invalid-b': $v.email.$error }"
            placeholder="e-mail"
            @keyup.enter="resetPassword"
          />
          <div v-if="$v.email.$error">
            <span class="invalid" v-if="!$v.email.required"
              >Email required</span
            >
            <span class="invalid" v-if="!$v.email.email"
              >Please provide a valid email.</span
            >
          </div>
        </div>

        <span class="valid" v-if="result ? result.status == 200 : false"
          >Email successfuly sent. Please check your inbox.</span
        >
        <span class="invalid" v-if="result ? result.status == 500 : false"
          >Server error. Please try again later.</span
        >
        <span class="invalid" v-if="result ? result.status == 404 : false"
          >User not found</span
        >
        <button class="btn btn-primary btn-block" @click="resetPassword">
          Reset Password <Loading :white="true" v-if="isLoading"></Loading>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import EventBus from "@/components/event-bus";
import { required, email } from "vuelidate/lib/validators";
import Loading from "@/components/Loading";

export default {
  name: "Forgot",
  props: ["isLoading", "result"],
  data() {
    return {
      title: "Forgot",
      email: null
    };
  },
  validations: {
    email: {
      required,
      email
    }
  },
  components: {
    Loading
  },
  methods: {
    resetPassword: function() {
      this.$v.email.$touch();
      if (!this.$v.email.$error) {
        EventBus.$emit("resetpassword", this.email);
      }
    }
  }
};
</script>
<style lang="scss">
// @import "../../scss/colors.scss";
// .login {
//   .h1 {
//     font-size: 27px;
//     font-weight: 900;
//     letter-spacing: 2px;
//     text-transform: uppercase;
//     display: block;
//     font-family: "Fenomen Sans";
//   }
//   .form {
//     margin-top: 30px;
//     input.underline {
//       background: transparent;
//       border: 0;
//       border-radius: 0;
//       border-bottom: 1px solid darken($white, 20%);
//       transition: all 0.2s ease;
//       &:focus {
//         box-shadow: none;
//       }
//     }
//   }
//   .form-group {
//     text-align: left;
//   }
//   .social {
//     margin: 0;
//     .col {
//       padding: 0;
//     }
//   }
//   .btn-primary {
//     margin-top: 20px;
//     transition: all 0.2s ease;
//     &:hover {
//       transform: scale(1.01);
//       box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05) !important;
//     }
//   }
//   .facebook {
//     background: #3b5998;
//     font-size: 12px;
//     width: calc(100% - 10px);
//   }
//   .google {
//     background: #db4437;
//     font-size: 12px;
//     float: right;
//     width: calc(100% - 10px);
//   }
//   .error {
//     border-color: $brand-red !important;
//   }
//   a {
//     color: $black;
//   }
// }
</style>
