<template>
  <div class="sub-user" @click="$emit('select-sub-user', user.id)">
    <div class="img">
      <img @error="(e) => (e.target.src = $defaultCardAvatar)"
        :src="user.inviteeProfilePic"
        class="img-fluid"
        v-if="user.inviteeProfilePic"
      />
      <img @error="(e) => (e.target.src = $defaultCardAvatar)"
        class="img-fluid"
        src="@/assets/images/profile.png"
        alt="Selected Account"
        v-if="!user.inviteeProfilePic"
      />
    </div>
    <div class="info col-10">
      <span class="name">{{
        user.authorizedUserName ? user.authorizedUserName : user.name
      }}</span>
      <span class="slug">
        {{ user.authorizedUserEmail ? user.authorizedUserEmail : "" }}
        {{ user.inviteeEmail ? user.inviteeEmail : "" }}
        <span class="i-status" v-if="user.inviteStatus">{{
          user.inviteStatus == "PENDING" ? "Invitaton Sent" : ""
        }}</span>
      </span>
      <img @error="(e) => (e.target.src = $defaultCardAvatar)"
        src="@/assets/images/reAction.svg"
        class="resend"
        alt="Resend Confirmation Mail"
        @click.prevent.stop
        @click="resendTrigger"
        v-if="actions"
      />
      <img @error="(e) => (e.target.src = $defaultCardAvatar)"
        src="@/assets/images/comments/delete.svg"
        class="delete"
        alt="Resend confirmation email"
        @click.prevent.stop
        @click="deleteTrigger"
        v-if="actions"
      />
    </div>
  </div>
</template>
<script>
import EventBus from "@/components/event-bus";

export default {
  name: "user",
  props: {
    user: null,
    actions: {
      default: true
    }
  },
  methods: {
    deleteTrigger: function() {
      EventBus.$emit("delete-invited-user", this.user);
    },
    resendTrigger: function() {
      EventBus.$emit("resend-mail-to-invited-user", this.user);
    }
  }
};
</script>
<style lang="scss">
@import "@/scss/colors.scss";
.sub-user {
  display: flex;
  padding: 10px;
  border-radius: 30px;

  .img {
    padding: 0;
    img {
      border-radius: 50%;
      width: 50px !important;
      height: auto !important;
    }
  }
  .info {
    padding: 0px 10px;
    position: relative;
    .name {
      font-weight: bold;
    }
    span {
      display: block;
    }
    .slug {
      font-size: 12px;
    }
    .delete {
      position: absolute;
      right: 0px;
      top: 20px;
      opacity: 0;
      transition: all 0.2s ease;
    }
    .resend {
      position: absolute;
      right: 20px;
      top: 20px;
      opacity: 0;
      transition: all 0.2s ease;
    }
    &:hover {
      .delete,
      .resend {
        opacity: 1;
      }
    }
  }
 
}
</style>
