<template>
  <div>
    <!-- Filter Desktop -->
    <div class="row filter d-none d-md-block">
      <div class="col-12 left-filter">
        <!-- Left -->
        <div class="col-7 left">
          <!-- Search -->
          <div
            class="search-area has-icon"
            :class="{ 'animated shake': shakeSearch, active: searchActive }"
          >
            <i class="fas fa-search"></i>

            <input-tag
              v-model="search"
              class="search taggable"
              placeholder="Search comment, @user or word..."
              :before-adding="checkSearchValue"
            ></input-tag>
          </div>
          <!-- Search -->

          <div
            class="col filters p-0 has-icon elem"
            :class="{ disappear: searchActive }"
          >
            <i
              class="dot main all"
              v-if="selectedFilter[0] == 'All' && selectedFilter.length == 1"
            ></i>
            <v-select
              v-model="selectedFilter"
              :options="filters"
              :class="{
                selectedFilterPosition: selectedFilter.length > 1,
              }"
              :clearable="true"
              :searchable="false"
              :closeOnSelect="closeOnSelect"
              multiple
              :tabindex="10"
            >
              <template
                slot="selected-option-container"
                slot-scope="selectedFilter"
              >
                <div>
                  <i
                    class="dot main positive"
                    v-if="selectedFilter.option.label == 'Positive'"
                  ></i>
                  <i
                    class="dot main negative"
                    v-if="selectedFilter.option.label == 'Negative'"
                  ></i>
                  <i
                    class="dot main neutral"
                    v-if="selectedFilter.option.label == 'Neutral'"
                  ></i>
                  <i
                    class="dot main question"
                    v-if="selectedFilter.option.label == 'Question'"
                  ></i>
                  <i
                    class="dot main hidden"
                    v-if="selectedFilter.option.label == 'Hidden'"
                  ></i>
                  <i
                    class="dot main spam"
                    v-if="selectedFilter.option.label == 'Spam/Ad Content'"
                  ></i>
                  <i
                    class="dot main notassigned"
                    v-if="selectedFilter.option.label == 'Not Assigned'"
                  ></i>

                  <div
                    class="all"
                    v-if="
                      selectedFilter.option.label.trim() == 'All' &&
                        selectedFilterCount == 1
                    "
                  >
                    Sentiments
                  </div>
                </div>
              </template>

              <template slot="option" slot-scope="option">
                <div
                  @click="
                    option.label == 'All' ? (selectedFilter = ['All']) : ''
                  "
                >
                  <i
                    class="dot"
                    :class="[
                      { all: option.label == 'All' },
                      { positive: option.label == 'Positive' },
                      { negative: option.label == 'Negative' },
                      { neutral: option.label == 'Neutral' },
                      { question: option.label == 'Question' },
                      { hidden: option.label == 'Hidden' },
                      { spam: option.label == 'Spam/Ad Content' },
                    ]"
                  ></i>
                  {{ option.label }}
                  <small v-if="option.label == 'Positive'"
                    >( {{ polarityCounts.positive }} )</small
                  >
                  <small v-if="option.label == 'All'"
                    >( {{ polarityCounts.totalComments }} )</small
                  >
                  <small v-if="option.label == 'Negative'"
                    >( {{ polarityCounts.negative }} )</small
                  >
                  <small v-if="option.label == 'Neutral'"
                    >( {{ polarityCounts.neutral }} )</small
                  >
                  <small v-if="option.label == 'Hidden'"
                    >( {{ polarityCounts.hidden }} )</small
                  >
                  <small v-if="option.label == 'Question'"
                    >( {{ polarityCounts.question }} )</small
                  >
                  <small v-if="option.label == 'Not Assigned'"
                    >( {{ polarityCounts.notassigned }} )</small
                  >
                  <small v-if="option.label == 'Spam/Ad Content'"
                    >( {{ polarityCounts.spam }} )</small
                  >

                  <template v-for="(filter, index) in selectedFilter">
                    <span
                      :key="index"
                      @click.stop.prevent=""
                      class="remove"
                      v-if="
                        option.label.toLowerCase() == filter.toLowerCase() &&
                          option.label != 'All'
                      "
                    >
                      <i class="fas fa-times" @click="removeFilter(option)"></i>
                    </span>
                  </template>
                </div>
              </template>
            </v-select>
          </div>
          <!-- Emotions Filter -->
          <div
            class="col filters p-0 has-icon elem emotions-filter"
            :class="{ disappear: searchActive }"
          >
            <!-- <component :is="'neutral'" class="main-emoji"></component> -->

            <component
              class="main-icon"
              :is="'offensive'"
              v-if="selectedEmotion[0] == 'All' && selectedEmotion.length == 1"
            ></component>
            <v-select
              v-model="selectedEmotion"
              :options="feelings"
              :clearable="true"
              :searchable="false"
              :closeOnSelect="false"
              multiple
              :tabindex="10"
              :class="{
                selectedEmotionPosition: selectedEmotion.length > 1,
              }"
            >
              <template
                slot="selected-option-container"
                slot-scope="selectedEmotion"
              >
                <div>
                  <component
                    :is="'profanity'"
                    v-if="
                      selectedEmotion.option.label.toLowerCase() == 'profanity'
                    "
                  ></component>
                  <component
                    :is="'hate'"
                    v-if="
                      selectedEmotion.option.label.toLowerCase() ==
                        'hate speech'
                    "
                  ></component>
                  <div
                    class="all"
                    v-if="
                      selectedEmotion.option.label.trim() == 'All' &&
                        selectedEmotionCount == 1
                    "
                  >
                    Offensive
                  </div>
                </div>
              </template>

              <template slot="option" slot-scope="option">
                <div
                  @click="
                    option.label == 'All' ? (selectedEmotion = ['All']) : ''
                  "
                >
                  <component
                    :is="'profanity'"
                    v-if="option.label.toLowerCase() == 'profanity'"
                  ></component>
                  <component
                    :is="'hate'"
                    v-if="option.label.toLowerCase() == 'hate speech'"
                  ></component>

                  {{ option.label }}
                  <small v-if="option.label.toLowerCase() != 'all'"
                    >(
                    {{
                      option.label.toLowerCase() == "profanity"
                        ? offensiveCounts.profanity
                        : offensiveCounts.hate
                    }}
                    )
                  </small>

                  <template v-for="(filter, index) in selectedEmotion">
                    <span
                      :key="index"
                      class="remove"
                      v-if="
                        option.label.toLowerCase() == filter.toLowerCase() &&
                          option.label != 'All'
                      "
                    >
                      <i
                        class="fas fa-times"
                        @click="removeFeeling(option)"
                      ></i>
                    </span>
                  </template>
                </div>
              </template>
            </v-select>
          </div>
          <!-- Emotions Filter End -->
          <button
            class="btn-filter elem search-trigger badge-wrapper"
            type="button"
            @click="searchActive = !searchActive"
          >
            <span class="badge" v-if="search.length > 0"
              >{{ search.length }}
            </span>
            <span class="icon--search">
              <i class="fas fa-search" v-if="!searchActive"></i>
            </span>
            <close :dark="true" v-if="searchActive"></close>
          </button>
          <button
            class="btn-filter elem mr-n-2"
            type="button"
            id="dropdownMenu"
            data-toggle="dropdown"
          >
            <img
              @error="(e) => (e.target.src = $defaultCardAvatar)"
              src="@/assets/images/comment.svg"
              alt
              v-if="orderBy == 'numOfReplies'"
            />
            <img
              @error="(e) => (e.target.src = $defaultCardAvatar)"
              src="@/assets/images/sort/desc.svg"
              alt
              v-if="order == 'DESC' && orderBy == 'createdTime'"
            />
            <img
              @error="(e) => (e.target.src = $defaultCardAvatar)"
              src="@/assets/images/sort/asc.svg"
              alt
              v-if="order == 'ASC' && orderBy == 'createdTime'"
            />
            <img
              @error="(e) => (e.target.src = $defaultCardAvatar)"
              src="@/assets/images/sort/heart.svg"
              alt
              v-if="orderBy == 'numOfLikes'"
            />
          </button>
          <div class="dropdown-menu order animate slideIn">
            <button
              class="dropdown-item"
              :class="{ selected: order == 'DESC' && orderBy == 'createdTime' }"
              type="button"
              @click="
                order = 'DESC';
                orderBy = 'createdTime';
              "
            >
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/sort/desc.svg"
                @click="mobileSearchActive = true"
                alt
              />
              Newest
            </button>
            <button
              class="dropdown-item"
              :class="{ selected: order == 'ASC' && orderBy == 'createdTime' }"
              type="button"
              @click="
                order = 'ASC';
                orderBy = 'createdTime';
              "
            >
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/sort/asc.svg"
                @click="mobileSearchActive = true"
                alt
              />
              Oldest
            </button>
            <!-- <button
              class="dropdown-item"
              :class="{ selected: order == 'DESC' && orderBy == 'numOfLikes' }"
              type="button"
              @click="
                order = 'DESC';
                orderBy = 'numOfLikes';
              "
            >
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/sort/heart.svg"
                @click="mobileSearchActive = true"
                alt
              />
              Most Liked
            </button>
            <button
              class="dropdown-item"
              :class="{
                selected: order == 'DESC' && orderBy == 'numOfReplies',
              }"
              type="button"
              @click="
                order = 'DESC';
                orderBy = 'numOfReplies';
              "
            >
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/comment.svg"
                @click="mobileSearchActive = true"
                alt
              />
              Most Replies
            </button> -->
          </div>
          <!-- Left End -->
        </div>
        <!-- Right -->
        <div class="col-5 text-right pr-0 topfilter">
          <range-filter :range="range"></range-filter>
          <DatePicker
            ref="datepicker"
            class="datepicker"
            v-model="date"
            placeholder="Select Date"
            range
            :disabled-date="disabledDates"
          >
            <template v-slot:header="{ emit }">
              <!-- <button
                class="btn radio p-0 d-block w-100 text-left"
                @click="setRange('ALL')"
              >
                <radio-button :checked="range == 'ALL'"></radio-button>All Time
              </button> -->
              <button
                class="btn radio p-0 d-block w-100 text-left"
                @click="setQuickRange('14Days')"
              >
                <radio-button :checked="quickRange == '14Days'"></radio-button
                >Last 14 Days
              </button>
              <button
                class="btn radio p-0 d-block w-100 text-left"
                @click="setQuickRange('30Days')"
              >
                <radio-button :checked="quickRange == '30Days'"></radio-button
                >Last 30 Days
              </button>
              <button
                class="btn radio p-0 d-block w-100 text-left"
                @click="setQuickRange('ThisWeek')"
              >
                <radio-button :checked="quickRange == 'ThisWeek'"></radio-button
                >This Week
              </button>
              <button
                class="btn radio p-0 d-block w-100 text-left"
                @click="setQuickRange('LastWeek')"
              >
                <radio-button :checked="quickRange == 'LastWeek'"></radio-button
                >Last Week
              </button>
              <button
                class="btn radio p-0 d-block w-100 text-left"
                @click="setQuickRange('ThisMonth')"
              >
                <radio-button
                  :checked="quickRange == 'ThisMonth'"
                ></radio-button
                >This Month
              </button>
              <button
                class="btn radio p-0 d-block w-100 text-left mb-2"
                @click="setQuickRange('LastMonth')"
              >
                <radio-button
                  :checked="quickRange == 'LastMonth'"
                ></radio-button
                >Last Month
              </button>
            </template>
          </DatePicker>
        </div>
        <!-- Right End -->
      </div>
    </div>
    <!-- Filter Desktop End -->
    <!-- Filter mobile -->
    <div class="row mobile-filter p-0 d-sm-none">
      <div class="p-0" :class="[mobileSearchActive ? 'col-5' : 'col-4']">
        <span>
          <img
            @error="(e) => (e.target.src = $defaultCardAvatar)"
            src="@/assets/images/search.svg"
            @click="mobileSearchActive = true"
            alt
          />
        </span>
        <input-tag
          v-model="search"
          class="taggable mobile-search"
          :class="{ active: mobileSearchActive }"
          placeholder="Search comment, @user or word..."
        ></input-tag>
      </div>
      <div
        class="text-center group-select"
        :class="[mobileSearchActive ? 'col-3 p-0' : 'col-4']"
      >
        <span @click="showFilter">
          <img
            @error="(e) => (e.target.src = $defaultCardAvatar)"
            src="@/assets/images/filter-outline.svg"
            class="filter-outline"
            alt="Filter"
          />
          <span
            class="badge"
            v-if="selectedFilterCount > 1 && selectedFilter[1] != 'All'"
            >{{ selectedFilterCount - 1 }}
          </span>
        </span>
        <span @click="showEmotionFilter">
          <component :is="'offensive'"></component>
          <span
            class="badge"
            v-if="selectedEmotionCount > 1 && selectedEmotion[1] != 'All'"
            >{{ selectedEmotionCount - 1 }}
          </span>
        </span>
      </div>
      <div
        class="text-right mobile"
        :class="[mobileSearchActive ? 'col ' : 'col-4']"
      >
        <span @click="mobileOrderModal = true">
          <img
            @error="(e) => (e.target.src = $defaultCardAvatar)"
            src="@/assets/images/filter.svg"
            alt="Filter"
          />
        </span>
        <span @click="mobileRangeModal = true">
          <img
            @error="(e) => (e.target.src = $defaultCardAvatar)"
            src="@/assets/images/calander.svg"
            alt="Set"
          />
        </span>
      </div>

      <div class="col-12 tags" v-if="search.length > 0">
        <span v-for="(q, index) in search" :key="index">
          {{ q }}
          <i class="remove" @click="removeTag(q)">x</i>
        </span>
      </div>
    </div>

    <!-- Result Info -->
    <div class="row result-info">
      <div class="col-12 col-sm-7">
        <div class="row" v-if="resultCount > 0">
          <div class="col-10 col-sm-6">
            Listed {{ resultCount }}
            {{ resultCount == 1 ? "mention" : "mentions" }}
          </div>
          <div class="col-2 col-sm-6 text-right p-0">
            <!-- <button
              class="btn-filter download-button d-none d-sm-inline-block"
              type="button"
              :disabled="xhrDownload"
              @click="exportExcel"
            >
              <img
                src="@/assets/images/download.svg"
                alt="Download"
                v-if="!xhrDownload"
              />
              <span v-if="!xhrDownload">Export</span>
              <Loading v-if="xhrDownload"></Loading>
            </button> -->
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-5"></div>
    </div>
    <!-- Result Info -->

    <!-- Filter mobile -->
    <div class="row">
      <!-- has active add when select some elements -->
      <div
        id="comments"
        class="col-12 col-md-7 list"
        :class="{ 'no-data': mentions.length == 0 && show }"
      >
        <template v-if="isLoading">
          <SkeletonLoader v-for="i in 5" :key="i" />
        </template>
        <span class="placeholder" v-if="mentions.length == 0 && show">
          <img
            @error="(e) => (e.target.src = $defaultCardAvatar)"
            src="@/assets/images/icon.svg"
            class="logo"
            alt="Socialays"
          />
          No Mentions</span
        >
        <Mention
          v-for="(comment, i) in mentions"
          :key="comment.id"
          :id="comment.id"
          :commentId="comment.id"
          :isSub="false"
          :comment="comment"
          :name="comment.authorName"
          :profilePic="comment.authorProfilePicUrl"
          :postImageUrl="comment.postImageUrl"
          :location="comment.locations"
          :content="comment.content"
          :countOfReplies="comment.numOfReplies"
          :hidden="comment.hidden"
          :isQuestion="comment.isQuestion"
          :isSpam="comment.isSpam"
          :polarity="comment.polarity"
          :polarityStrings="comment.polarityStrings"
          :commentImgs="comment.imageUrl"
          :isLiked="comment.liked"
          :activeAccountDetails="activeAccountDetails"
          :likeCount="comment.numOfLikes"
          :authorId="comment.authorId"
          :date="comment.createdTime"
          class="comment-item"
        ></Mention>
        <template v-if="comingMore">
          <SkeletonLoader v-for="i in 5" :key="i" />
        </template>
      </div>

      <div class="col-12 col-sm-4">
        <chart
          v-if="chartShow && data && mentions.length > 0"
          :graph="data.polarityCounts"
          :totals="data.totalElements"
          :account="activeAccountDetails"
          :range="range"
          :start="start ? start : ''"
          :finish="finish ? finish : ''"
          :type="getActiveSocialMedia"
        ></chart>
      </div>
    </div>
    <!-- Mobile Status Filter Start -->
    <div class="mobile-ai-filter" :class="{ show: mobileAiFilter }">
      <div class="close-trigger" @click="filtersDone"></div>
      <div class="wrap">
        <div class="head">
          <div class="row">
            <div class="col">
              <span class="h5">Filter</span>
            </div>
            <div class="col text-right done" @click="filtersDone">Done</div>
          </div>
        </div>
        <div class="body">
          <div class="row" @click="setFilter('positive')">
            <div class="col-9">
              <status :status="'positive'"></status>Positive
              <small>( {{ polarityCounts.positive }} )</small>
            </div>
            <div class="col text-right">
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/checked.svg"
                v-if="selectedFilter.includes('positive')"
              />
            </div>
          </div>
          <div class="row" @click="setFilter('neutral')">
            <div class="col-9">
              <status :status="'neutral'"></status>Neutral
              <small>( {{ polarityCounts.neutral }} )</small>
            </div>
            <div class="col text-right">
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/checked.svg"
                v-if="selectedFilter.includes('neutral')"
              />
            </div>
          </div>
          <div class="row" @click="setFilter('negative')">
            <div class="col-9">
              <status :status="'negative'"></status>Negative
              <small>( {{ polarityCounts.negative }} )</small>
            </div>
            <div class="col text-right">
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/checked.svg"
                v-if="selectedFilter.includes('negative')"
              />
            </div>
          </div>

          <div class="row" @click="setFilter('hidden')">
            <div class="col-9">
              <status :status="'hidden'"></status>Hidden
              <small>( {{ polarityCounts.hidden }} )</small>
            </div>
            <div class="col text-right">
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/checked.svg"
                v-if="selectedFilter.includes('hidden')"
              />
            </div>
          </div>
          <div class="row" @click="setFilter('question')">
            <div class="col-9">
              <status :status="'question'"></status>Question
              <small>( {{ polarityCounts.question }} )</small>
            </div>
            <div class="col text-right">
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/checked.svg"
                v-if="selectedFilter.includes('question')"
              />
            </div>
          </div>
          <div class="row" @click="setFilter('spam')">
            <div class="col-8">
              <status :status="'spam'"></status>Spam/Ad Content
              <small>( {{ polarityCounts.spam }} )</small>
            </div>
            <div class="col text-right">
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/checked.svg"
                v-if="selectedFilter.includes('spam')"
              />
            </div>
          </div>
          <div class="row" @click="setFilter('notassigned')">
            <div class="col-9">
              <status></status>Not Assigned
              <small>( {{ polarityCounts.notassigned }} )</small>
            </div>
            <div class="col text-right">
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/checked.svg"
                v-if="selectedFilter.includes('notassigned')"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Mobile Status Filter End -->
    <!-- Mobile Emotion Filter Start -->
    <div class="mobile-ai-filter" :class="{ show: mobileEmotionFilter }">
      <div class="close-trigger" @click="filtersDone"></div>
      <div class="wrap">
        <div class="head">
          <div class="row">
            <div class="col">
              <span class="h5">Filter</span>
            </div>
            <div class="col text-right done" @click="filtersDone">Done</div>
          </div>
        </div>
        <div class="body">
          <div
            class="row"
            v-for="(emotion, index) in feelings.slice(1)"
            @click="setEmotion(emotion)"
            :key="index"
          >
            <div class="col">
              <component
                :is="'profanity'"
                v-if="emotion.toLowerCase() == 'profanity'"
              ></component>
              <component
                :is="'hate'"
                v-if="emotion.toLowerCase() == 'hate speech'"
              ></component>

              {{ emotion }}
              <small v-if="emotion.toLowerCase() != 'all'"
                >(
                {{
                  emotion.toLowerCase() == "profanity"
                    ? offensiveCounts.profanity
                    : offensiveCounts.hate
                }}
                )
              </small>
            </div>
            <div class="col text-right">
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/checked.svg"
                v-if="selectedEmotion.includes(emotion)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Mobile Emotion Filter End -->
    <!-- Mobile Order Modal Start -->
    <mobileSortFilter
      :mobileOrderModal="mobileOrderModal"
      :f_order="order"
      :f_orderBy="orderBy"
      @close="mobileSortModalSetAndClose"
    ></mobileSortFilter>
    <!-- Mobile Order Modal End -->
    <!-- Mobile Range Modal Start -->
    <mobileRangeFilter
      :mobileRangeModal="mobileRangeModal"
      :date="date"
      :f_range="range"
      @close="mobileRangeModalSetAndClose"
    ></mobileRangeFilter>
    <!-- Mobile Order Modal -->
  </div>
</template>
<script>
import { HTTP } from "@/main-source";
import EventBus from "@/components/event-bus";
// import Mention from "@/components/facebook/mentions/List-Item";
import Mention from "@/components/facebook/comment.vue";
import rangeFilter from "@/components/filters/range";
import { mapGetters } from "vuex";
import InputTag from "vue-input-tag";
import chart from "@/components/facebook/general/Chart";
import status from "@/components/dot";
import radioButton from "@/components/radio-button";
import close from "@/components/close";
import SkeletonLoader from "@/components/placeholders/comment";
// Emotions - Will be improved
import hate from "@/components/icons/offensive/hate";
import offensive from "@/components/icons/offensive/offensive";
import profanity from "@/components/icons/offensive/profanity";
// Filter for mobile
import mobileRangeFilter from "@/components/filters/mobile/date";
import mobileSortFilter from "@/components/filters/mobile/sort";

export default {
  name: "mentions",

  components: {
    Mention,
    status,
    rangeFilter,
    InputTag,
    chart,
    hate,
    close,
    SkeletonLoader,
    mobileRangeFilter,
    mobileSortFilter,
    offensive,
    profanity,
    radioButton,
  },
  data() {
    return {
      selectedEmotion: ["All"],
      filters: [
        "All",
        "Positive",
        "Neutral",
        "Negative",
        "Hidden",
        "Question",
        "Spam/Ad Content",
        "Not Assigned",
      ],
      closeOnSelect: false,
      selectedFilter: ["All"],
      page: 0,
      polarityCounts: {},
      isLoading: false,
      activeAccountDetails: null,
      date: JSON.parse(localStorage.getItem("selectedDate")) || null,
      start: null,
      finish: null,
      order: "DESC",
      orderBy: "createdTime",
      search: [],
      range: localStorage.getItem("selectedRange") || "ALL",
      quickRange: localStorage.getItem("selectedQuickRange") || null,
      reply: "",
      graph: {
        Hidden: 0,
        Negative: 51,
        Neutral: 151,
        Positive: 229,
        Questioned: 4,
        Unassigned: 60,
      },
      offensiveCounts: {
        hate: 0,
        profanity: 0,
      },
      data: null,
      show: false,
      chartShow: false,
      comingMore: false,
      mentions: [],
      shakeSearch: false,
      searchActive: false,
      feelings: ["All", "Hate Speech", "Profanity"],
      searchQuery: null,
      pocket: {
        polarityStrings: null,
        hidden: null,
        isQuestion: null,
        isSpam: null,
        emotion: [],
      },
      mobileSearchActive: false,
      mobileAiFilter: false,
      mobileEmotionFilter: false,
      mobileOrderModal: false,
      mobileRangeModal: false,
      socialMediaAccounts: [],
      resultCount: 0,
    };
  },

  computed: {
    ...mapGetters(["getActiveSocialMedia", "getActiveSocialMediaAccount"]),

    selectedFilterCount() {
      return this.selectedFilter.length;
    },
    selectedEmotionCount() {
      return this.selectedEmotion.length;
    },
  },

  watch: {
    date() {
      if (!this.date) {
        return;
      }
      localStorage.setItem("selectedDate", JSON.stringify(this.date));

      this.range = "CUSTOM";

      this.start = this.date[0].getTime() / 1000;
      this.finish = this.date[1].getTime() / 1000;
      this.range = null;
      this.posts = [];
      this.getMentions();
    },

    selectedFilter() {
      if (this.mobileAndTabletCheck != false) {
        this.page = 0;
        this.endOfComments = 0;
        this.mentions = [];
        this.close();
        this.getMentions();
      }
    },

    selectedEmotion() {
      if (this.mobileAndTabletCheck != false) {
        this.page = 0;
        this.mentions = [];
        this.endOfComments = 0;
        this.close();
        this.getMentions();
      }
    },
    order() {
      this.page = 0;
      this.endOfComments = false;
      this.mentions = [];
      this.getMentions();
    },

    orderBy() {
      this.page = 0;
      this.endOfComments = false;
      this.mentions = [];
      this.getMentions();
    },

    search: function() {
      this.clear();
      let searchQuery = "";
      let i = 0;

      this.search.forEach((q) => {
        if (i != 0) {
          searchQuery += ",";
        }
        if (q.length > 2) {
          searchQuery += q;
          i++;
        }
      });

      this.searchQuery = searchQuery;
    },

    searchQuery: function() {
      if (!this.searchQuery) {
        this.page = 0;
        this.mentions = [];
        this.getMentions();
        this.mobileSearchActive = false;
      } else {
        this.getMentions();
      }
    },
    "$route.params.accountId": function() {
      if (
        Object.keys(this.$route.query).length === 0 &&
        this.$route.query.constructor === Object
      ) {
        this.setActiveAccountDetails();
        this.page = 0;
        this.mentions = [];
        this.getMentions();
        this.mobileSearchActive = false;
      }
    },
  },

  created() {
    HTTP.get("user/me").then((result) => {
      this.socialMediaAccounts = result.data.socialMediaAccounts;
      this.setActiveAccountDetails();
    });
    this.getMentions();
  },

  mounted() {
    EventBus.$on("setRange", (range) => {
      this.setRange(range);
    });

    const customDate = JSON.parse(localStorage.getItem("selectedDate")) || null;
    console.log("🚀 ~ mounted ~ customDate:", customDate);
    if (this.quickRange != null && customDate == null) {
      this.setQuickRange(this.quickRange);
    }

    if (customDate != null) {
      customDate[0] = new Date(customDate[0]);
      customDate[1] = new Date(customDate[1]);
      this.date = customDate;
    }
    this.scroll();

    EventBus.$on("updateClassification", (id, field, isSave) => {
      this.updateClassification(id, field, isSave);
    });

    EventBus.$on("updateEmotions", (id, field, isSave) => {
      this.updateEmotions(id, field, isSave);
    });

    EventBus.$on("getBackMentionStatus", (id) => {
      const mention = this.mentions.filter((mention) => mention.id == id)[0];
      mention.polarityStrings = this.pocket.polarityStrings;
      mention.hidden = this.pocket.hidden;
      mention.isQuestion = this.pocket.isQuestion;
      mention.isSpam = this.pocket.isSpam;
      mention.emotion = this.pocket.emotion;
    });

    EventBus.$on("clearMentionStatus", (id) => {
      const mention = this.mentions.filter((mention) => mention.id == id)[0];
      this.pocket.polarityStrings = mention.polarityStrings;
      this.pocket.hidden = mention.hidden;
      this.pocket.isQuestion = mention.isQuestion;
      this.pocket.isSpam = mention.isSpam;
      this.pocket.emotion = mention.emotion;
      mention.polarityStrings = [];
      mention.emotion = [];
      mention.hidden = false;
      mention.isQuestion = false;
      mention.isSpam = false;
    });
  },

  methods: {
    updateSideStats() {
      this.getMentions(false, true);
    },
    setActiveAccountDetails() {
      this.activeAccountDetails = this.socialMediaAccounts.filter(
        (account) => account.accountId == this.getActiveSocialMediaAccount
      )[0];
      this.chartShow = true;
    },
    updateClassification(id, field, isSave) {
      const mention = this.mentions.filter((item) => item.id == id)[0];

      switch (field) {
        case "positive":
        case "negative":
        case "neutral":
          if (mention.polarityStrings.includes(field)) {
            mention.polarityStrings = mention.polarityStrings.filter(
              (pol) => pol != field
            );
          } else {
            mention.polarityStrings.push(field);
          }
          break;

        case "hidden":
          mention.hidden = !mention.hidden;
          break;

        case "question":
          mention.isQuestion = !mention.isQuestion;
          break;

        case "spam":
          mention.isSpam = !mention.isSpam;
          break;
      }

      if (isSave) {
        let data = {
          id: mention.id,
          originalUserId: mention.originalUserId,
          polarityStrings: mention.polarityStrings,
          hidden: mention.hidden,
          isQuestion: mention.isQuestion,
          isSpam: mention.isSpam,
          emotion: mention.emotion,
          pageId: this.getActiveSocialMediaAccount,
        };

        if (field == "hidden") {
          // comment = pocket;
          HTTP.put("social/hide", data)
            .then((result) => {
              if (result.status == 200) {
                // if (!isSub && ) {
                //   this.close();
                // }
                EventBus.$emit("mentionEditSuccess");
                mention.isManual = true;
              }
            })
            .finally(() => this.updateSideStats());
        } else {
          // comment = pocket;
          HTTP.put("social/changestatus", data)
            .then((result) => {
              if (result.status == 200) {
                // if (!isSub && ) {
                //   this.close();
                // }
                EventBus.$emit("mentionEditSuccess");
                mention.isManual = true;
              }
            })
            .finally(() => this.updateSideStats());
        }
      }
    },
    updateEmotions: function(id, field, isSave) {
      const mention = this.mentions.filter((item) => item.id == id)[0];

      if (mention.emotion.includes(field)) {
        mention.emotion = mention.emotion.filter((pol) => pol != field);
      } else {
        mention.emotion.push(field);
      }

      if (isSave) {
        let data = {
          id: mention.id,
          originalUserId: mention.originalUserId,
          polarityStrings: mention.polarityStrings,
          hidden: mention.hidden,
          isQuestion: mention.isQuestion,
          isSpam: mention.isSpam,
          pageId: this.getActiveSocialMediaAccount,
        };

        // comment = pocket;
        HTTP.put("social/changestatus", data)
          .then((result) => {
            if (result.status == 200) {
              EventBus.$emit("mentionEditSuccess");
              mention.isManual = true;
            }
          })
          .finally(() => this.updateSideStats());
      }
    },
    scroll: function() {
      let commentListView = document.getElementById("comments");
      if (!commentListView) {
        return;
      }
      // let lastItem = document.querySelector("#comments .col-12:last-child");
      commentListView.onscroll = () => {
        let position = commentListView.scrollTop - 200;
        let end =
          commentListView.scrollHeight - commentListView.offsetHeight - 200;
        if (position.toFixed() >= end) {
          this.getMentions(true);
        }
      };
    },
    clear() {
      this.close();
      this.page = 0;
      this.mentions = [];
      this.selectedCommentPost = null;
      this.selectedComment = { id: null };
      this.view = null;
      this.endOfComments = false;
    },

    close() {
      this.view = null;
      this.selectedCommentPost = null;
      this.selectedComment = {
        id: null,
      };
      this.isShowDetailsOnMobile = false;
      // this.selectedAuthor = null;
      this.subPage = 0;
    },

    checkSearchValue(query) {
      if (query.length < 3) {
        this.$notify({
          group: "info",
          text: "Min. 3 character needed",
        });
        this.shakeSearch = true;
        setTimeout(() => {
          this.shakeSearch = false;
        }, 500);

        return false;
      } else {
        return query;
      }
    },

    disabledDates(date) {
      return this.$disabledDates(date);
    },

    getMentions(next = false, polarityCountsOnly = false) {
      if (!this.mentions.length) {
        this.isLoading = true;
      }

      let filterText = "";
      let emotionFilterText = "";
      this.show = false;

      if (next && !this.endOfComments) {
        this.comingMore = true;
        this.page++;
      }

      let uri =
        "social/" +
        this.getActiveSocialMediaAccount +
        "/" +
        this.getActiveSocialMedia +
        "/mention/?page=" +
        this.page +
        "&order=" +
        this.order +
        "&orderby=" +
        this.orderBy;

      if (this.range) {
        uri += "&range=" + this.range;
      }

      if (this.start && this.finish) {
        uri +=
          "&page=" +
          this.page +
          "&start=" +
          this.start +
          "&finish=" +
          this.finish;
      }

      if (this.searchQuery) {
        uri += "&text=" + this.searchQuery;
      }

      this.selectedFilter.forEach((filter) => {
        if (filter != "All") {
          if (filterText != "") {
            filterText += ",";
          }
          filter = filter.split(" ").join("");

          filterText += filter.toLowerCase();
        }
      });

      if (filterText.length > 0) {
        uri += "&polarity=" + filterText;
      }

      // Hate Speech Filter
      if (this.selectedEmotion.includes("Hate Speech")) {
        uri += "&emotion=hate";
      }

      // Profanity Filter
      if (this.selectedEmotion.includes("Profanity")) {
        uri += "&profanity=true";
      }
      if (
        (this.getActiveSocialMediaAccount && !this.endOfComments) ||
        (this.getActiveSocialMediaAccount && !next)
      ) {
        HTTP.get(uri)
          .then((result) => {
            if (this.page == 0) {
              this.polarityCounts = result.data.polarityCounts;
              this.polarityCounts.totalComments = result.data.totalElements;

              this.resultCount = result.data.totalElements;
            }

            if (polarityCountsOnly) {
              this.data.polarityCounts = this.polarityCounts;
              this.data.totalElements = this.polarityCounts.totalComments;
            } else {
              if (!next) {
                this.mentions = [];
              }
              if (!next) {
                this.data = result.data;
              }
              result.data.content.forEach((comment) =>
                this.mentions.push(comment)
              );
              this.show = true;
              this.comingMore = false;
            }
          })
          .catch((e) => {
            if (e.response.status == 400) {
              if (this.mentions.length == 0) {
                this.polarityCounts = {
                  hidden: 0,
                  negative: 0,
                  neutral: 0,
                  notassigned: 0,
                  positive: 0,
                  question: 0,
                  spam: 0,
                  totalComments: 0,
                };
              }
              if (this.page == 0) {
                this.mentions = [];
              }
              this.show = true;
              this.comingMore = false;
              this.endOfComments = true;
            }
          })
          .finally(() => (this.isLoading = false));
      }
    },
    setFilter: function(option) {
      if (this.selectedFilter.includes(option)) {
        this.selectedFilter = this.selectedFilter.filter(
          (filter) => filter != option
        );
      } else {
        this.selectedFilter.push(option);
      }
    },
    setEmotion: function(option) {
      if (this.selectedEmotion.includes(option)) {
        this.selectedEmotion = this.selectedEmotion.filter(
          (filter) => filter != option
        );
      } else {
        this.selectedEmotion.push(option);
      }
    },
    setRange(value) {
      console.log("🚀 ~ setRange ~ value:", value);
      localStorage.setItem("selectedRange", value);
      localStorage.removeItem("selectedQuickRange");
      this.range = value;
      this.quickRange = null;
      if (this.range != null) {
        this.date = null;
        this.start = null;
        this.finish = null;
        this.getMentions();
      }
      this.$refs.datepicker.closePopup();
    },
    setQuickRange: function(which) {
      localStorage.setItem("selectedQuickRange", which);
      localStorage.removeItem("selectedRange");
      this.range = null;

      this.clear();
      this.close();
      this.date = null;
      this.start = null;
      this.finish = null;
      let today = new Date();
      switch (which) {
        case "14Days":
          this.quickRange = "14Days";
          today = new Date();
          let f14 = new Date(today.setDate(new Date().getDate() - 14));
          this.date = [f14, new Date()];
          break;
        case "30Days":
          this.quickRange = "30Days";
          today = new Date();
          let f30 = new Date(today.setDate(new Date().getDate() - 30));
          this.date = [f30, new Date()];
          break;
        case "ThisWeek":
          this.quickRange = "ThisWeek";
          let curr = new Date();
          let first = curr.getDate() - curr.getDay() + 1;
          let last = first + 6;

          let firstday = new Date(curr.setDate(first));
          let lastday = new Date(curr.setDate(last));
          this.date = [firstday, lastday];
          break;
        case "LastWeek":
          this.quickRange = "LastWeek";
          today = new Date();
          let curl = new Date(today.setDate(new Date().getDate() - 7));
          let firstl = curl.getDate() - curl.getDay() + 1;
          let lastl = firstl + 6;

          let firstlday = new Date(curl.setDate(firstl));
          let lastlday = new Date(curl.setDate(lastl));
          this.date = [firstlday, lastlday];
          break;
        case "ThisMonth":
          this.quickRange = "ThisMonth";
          var date = new Date(),
            y = date.getFullYear(),
            m = date.getMonth();
          var firstDay = new Date(y, m, 1);
          var lastDay = new Date(y, m + 1, 0);
          this.date = [firstDay, lastDay];
          break;
        case "LastMonth":
          this.quickRange = "LastMonth";
          var date = new Date();
          var firstDayPrevMonth = new Date(
            date.getFullYear(),
            date.getMonth() - 1,
            1
          );
          var lastDayPrevMonth = new Date(
            date.getFullYear(),
            date.getMonth(),
            0
          );
          this.date = [firstDayPrevMonth, lastDayPrevMonth];
          break;
      }

      this.$refs.datepicker.closePopup();
    },
    removeFilter(option) {
      this.selectedFilter = this.selectedFilter.filter(
        (filter) => filter != option.label
      );
    },

    removeFeeling(option) {
      this.selectedEmotion = this.selectedEmotion.filter(
        (filter) => filter != option.label
      );
    },
    filtersDone: function() {
      this.mobileAiFilter = false;
      this.mobileEmotionFilter = false;
      EventBus.$emit("mobileAiFilter", false);
      this.page = 0;
      this.comments = [];
      this.close();
      this.getMentions();
    },
    showFilter: function() {
      this.mobileAiFilter = true;
      EventBus.$emit("mobileAiFilter", true);
    },
    showEmotionFilter: function() {
      this.mobileEmotionFilter = true;
      EventBus.$emit("mobileAiFilter", true);
    },
    removeTag: function(t) {
      this.search = this.search.filter((q) => q != t);
    },
    mobileRangeModalSetAndClose: function(data) {
      if (data.range != "CUSTOM") {
        this.setRange(data.range);
      } else {
        this.range = "CUSTOM";
        this.date = [new Date(data.start), new Date(data.finish)];
      }

      this.mobileRangeModal = false;
    },
    mobileSortModalSetAndClose: function(data) {
      this.order = data.order;
      this.orderBy = data.orderBy;
      this.mobileOrderModal = false;
    },
  },

  beforeDestroy() {
    EventBus.$off("setRange");
    EventBus.$off("updateClassification");
    EventBus.$off("updateEmotions");
    EventBus.$off("getBackMentionStatus");
  },
};
</script>
<style lang="scss">
@import "@/scss/colors.scss";

.mobile-filter {
  margin-bottom: 15px !important;
}
</style>
