<template>
  <svg height="7" width="7">
    <circle cx="3" cy="3" r="7" :fill="[color ? color : '#000']" />
  </svg>
</template>

<script>
export default {
  name: "circle",
  props: ["color"],
};
</script>
