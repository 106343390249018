<template>
  <!-- :class="{ 'has-notify': showNotify || hasErrorNotify }" -->
  <div id="app">
    
    <div
      class="btn-add"
      @click="showAddAccountBox"
      v-if="$route.name == 'home' && !mobileRangeFilter"
    >
      <div class="img">
        <img src="@/assets/images/sidebar/add-w.svg" alt="Down" />
      </div>
      <span class="text"> Add Account </span>
    </div>
    <notifications
      group="topNotifications"
      position="top center"
      classes="notify-info top"
    />
    <template v-if="!isPageAuth && !addAccount">
      <div
        class="col-12 notify"
        :class="[
          { active: showNotify },
          { 'bg-red': accountStatus == 'TRIAL_EXPIRED' },
        ]"
        v-if="!hasErrorNotify"
      >
        <div v-if="false">
          <span v-if="lessDay > 0">
            You are currently using the trial version, and it's ending in
            {{ lessDay }} day{{ lessDay > 1 ? "s" : "" }}.
          </span>
          <span v-if="lessDay <= 0"> Your trial period has expired. </span>
          <!-- <span class="underline" @click="goToUpgrade">Subscribe Now!</span> -->
          <span class="close">
            <close @click.native="closeNotify"></close>
          </span>
        </div>
      </div>
      <div
        class="col-12 notify bg-red"
        :class="[{ active: hasErrorNotify }]"
        v-if="hasErrorNotify"
      >
        <div>
          Some of your social media accounts are broken.
          <span class="underline">
            <router-link :to="{ name: 'settings' }">
              Please reconnect by clicking here.
            </router-link>
          </span>
          <span class="close">
            <close @click.native="closeNotify"></close>
          </span>
        </div>
      </div>
      <topbar></topbar>
      <sidebar></sidebar>
      <div class="main">
        <div
          class="page"
          :class="[
            { open: getActiveSocialMedia != '' },
            { 'sidebar-closed': !menu },
            { filteractive: mobileAiFilter },
          ]"
        >
          <router-view
            class="content"
            :class="{ 'disable-scroll': isCommentOrPost }"
          />
        </div>
      </div>
    </template>

    <template v-if="isPageAuth && !addAccount">
      <div class="row container-fluid p-0 loginSignup">
        <div class="col left">
          <div class="row m-0">
            <div class="col-12 col-sm-9">
              <img
                src="@/assets/images/logo-w-text.svg"
                class="logo"
                alt="Socialays"
              />
              <router-view></router-view>
            </div>
          </div>
        </div>
        <div class="col right d-none d-sm-block"></div>
      </div>
    </template>
    <template v-if="addAccount">
      <router-view></router-view>
    </template>
    <notifications
      group="info"
      position="bottom center"
      classes="notify-info"
    />
  </div>
</template>

<script>
import sidebar from "./components/sidebar/sidebar.vue";
import topbar from "./components/topbar.vue";
import EventBus from "@/components/event-bus";
import { mapGetters, mapActions } from "vuex";
import close from "@/components/close";
import router from "@/router";

export default {
  name: "Socialays",
  data() {
    return {
      menu: false,
      addAccount: false,
      showNotify: false,
      mobileAiFilter: false,
      registereddate: JSON.parse(localStorage.getItem("selectedDate")) || null,
      lessDay: null,
      accountStatus: null,
      hasErrorNotify: false,
      mobileRangeFilter: false,
    };
  },
  components: {
    sidebar,
    topbar,
    close,
  },
  created() {
    if (this.$route.params.socialmedia == "Facebook") {
      this.setActiveSocialMedia("Facebook");
    }
    if (this.$route.params.slug) {
      this.setActiveSocialMediaAccount(this.$route.params.slug);
    }

    // // TO BE FIXED
    // if (this.$route.query.code) {
    //   if (this.$route.query.code.length > 10) {
    //     this.addAccount = true;
    //   }
    // }

    if (
      this.$route.name == "upgrade" ||
      this.$route.name == "paymentCallback" ||
      this.$route.name == "addAccountList" ||
      this.$route.name == "paymentCallback" ||
      this.$route.name == "welcome"
    ) {
      this.addAccount = true;
    }

    if (this.$route.query.oauth_token) {
      if (this.$route.query.oauth_token.length > 10) {
        this.addAccount = true;
      }
    }
    this.setOverflow();
  },
  methods: {
    ...mapActions(["setActiveSocialMedia", "setActiveSocialMediaAccount"]),
    setOverflow: function () {
      this.isCommentOrPost = false;
      if (this.$route.name == "posts" || this.$route.name == "comments") {
        this.isCommentOrPost = true;
      }
    },
    closeNotify: function () {
      this.showNotify = false;
      this.hasErrorNotify = false;
    },
    goToUpgrade: function () {
      let accountStatus = JSON.parse(localStorage.getItem("accountStatus"));
      if (accountStatus.count == 0) {
        router
          .push({
            name: "home",
            query: {
              addAccount: "true",
            },
          })
          .catch(() => {
            this.$notify({
              group: "info",
              type: "error",
              text: "Please select social media",
            });
          });
        this.setActiveSocialMedia("");
        this.setActiveSocialMediaAccount("");
      } else {
        this.$goToUpgrade();
      }
    },
    showAddAccountBox: function () {
      this.mobileRangeFilter = true;
      EventBus.$emit("showAddAccountBox", true);
    },
    setBodyMargin: function () {
      if (this.showNotify || this.hasErrorNotify) {
        document.body.style.marginTop = "60px";
        document.getElementById("sidebar").style.top = "60px";
      } else {
        document.body.style.marginTop = "0px";
        document.getElementById("sidebar").style.top = "0px";
      }
    },
  },
  mounted() {
    EventBus.$on("menu", (showMenu) => {
      this.menu = showMenu;
    });
    EventBus.$on("mobileAiFilter", (val) => {
      this.mobileAiFilter = val;
    });
    EventBus.$on("notify", (val, createdAt, status) => {
      if (val && status != "ACTIVE") {
        setTimeout(() => {
          this.showNotify = val;
        }, 820);
      } else {
        this.showNotify = false;
      }
      this.accountStatus = status;

      let createdDateTime = new Date(createdAt);
      let today = new Date();
      let difference =
        14 - Math.round((today - createdDateTime) / (1000 * 60 * 60 * 24));
      this.lessDay = difference;
    });
    EventBus.$on("hasErrorAccount", () => {
      this.hasErrorNotify = true;
    });
    EventBus.$on("mobileRangeFilter", (mobileRangeFilter) => {
      this.mobileRangeFilter = mobileRangeFilter;
    });
  },
  watch: {
    getActiveSocialMedia: function () {},
    $route: function () {
      this.setOverflow();
    },
    showNotify: function () {
      this.setBodyMargin();
    },
    hasErrorNotify: function () {
      this.setBodyMargin();
    },
  },
  computed: {
    ...mapGetters(["getActiveSocialMedia", "getActiveSocialMediaAccount"]),
    isPageAuth: function () {
      let status = false;
      switch (this.$route.meta.title) {
        case "Login":
        case "Sign Up":
        case "loginWithCallback":
        case "Forgot":
        case "RenewPassword":
        case "PrivacyPolicy":
        case "TermsOfUse":
        case "MembershipAgreement":
        case "Contact":
          status = true;
          break;
      }
      return status;
    },
  },
};
</script>

<style lang="scss">
@import "scss/colors.scss";
@import "scss/main.scss";
@import "scss/adjust.scss";

@media (min-width: 768.98px) {
  #app {
    overflow: visible;
  }
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-wrap: wrap;
  transition: all 1s ease;
  overflow-y: visible;
  max-height: 100vh;
}
.notify {
  background: $positive;
  z-index: 9;
  transition: all 0.45s ease-in-out;
  transform: translateY(-60px) !important;
  position: absolute;
  height: 60px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white;
  opacity: 0;
  .underline {
    text-decoration: underline;
    margin: 0px 5px;
    cursor: pointer;
  }
  .close {
    position: absolute;
    top: 16.5px;
    right: 12px;
    color: $white;
    opacity: 1;
    border: 0;
    cursor: pointer;
  }
}
.notify.active {
  z-index: 9999 !important;
  transform: translateY(-60px) !important;
  opacity: 1;
}
.has-notify {
  transform: translateY(40px);
  overflow: visible !important;
}
#app > .main {
  width: 100%;
  transition: all 0.3s ease;
  max-height: 100vh;
  overflow: hidden scroll;
}
.page {
  width: calc(100% - 90px);
  margin-top: 90px;
  margin-left: 90px;
  // overflow: hidden scroll;
  // max-height: calc(100vh - 130px);
}
.open {
  width: calc(100% - 300px);
  margin-left: 300px;
  .content {
    // overflow: hidden;
    //  sonra kontrol et düzenle
  }
}
.content {
  padding: 30px 20px 0px 30px;
  // min-height: calc(100vh - 110px);
  // max-height: calc(100vh - 200px);
  // background: url("./assets/images/temp-bg.jpg");
}
.cover {
  width: calc(100% - 280px);
  padding: 30px;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  top: 60px;
  min-height: calc(100vh - 70px);
  -webkit-backdrop-filter: saturate(180%) blur(4px);
  backdrop-filter: saturate(180%) blur(4px);
  background-color: rgba(245, 245, 245, 0.8);
}
.dialog {
  min-width: 340px;
  border-radius: 5px;
  background: $white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
  .head {
    padding: 10px 5px;
    border-bottom: 1px solid darken($white, 5%);
    margin: 0;
    font-size: 14px;
    color: darken($white, 45%);
    .fa-times {
      cursor: pointer;
    }
  }
  .body {
    text-align: center;
    padding: 15px;
    min-height: 70px;
  }
  .actions {
    margin: 0px 15px 15px 15px;
    .btn {
      margin: 5px;
      font-size: 14px;
    }
    .btn-primary {
      // background: $brandcolor !important;
    }
  }
}
.loginSignup {
  height: 100vh;
  margin: 0;
  .right {
    // background: linear-gradient(#0f61ac 0%, #002445 100%);
    background: url("./assets/images/soci-logsign.jpg");
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .left {
    background: $white;
    max-height: 100vh;
    overflow: scroll;
    .row {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .logo {
      width: 300px;
      height: auto;
      margin: 90px auto;
    }
  }
}
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .loginSignup .right{
      max-width: 15%;

    }

}
@media (max-width: 767.98px) {
  #app > .main {
    min-width: 100vw;
  }
  .content {
    padding: 15px !important;
    max-height: calc(100vh - 200px);
    overflow: scroll;
  }
  .loginSignup {
    margin: 0;
  }
  .page {
    width: 100%;
    margin-left: 0;
    transform: translateX(90px);
    transition: all 0.3s ease;
  }
  .sidebar-closed {
    margin-left: 0;
    transform: translateX(0) !important;
  }
  .home-chart #item-md {
    max-width: 100% !important;
    width: 100% !important;
  }
  .filteractive {
    max-height: calc(100vh - 170px) !important;
    overflow: hidden !important;
  }
  .notify {
    // display: unset !important;
    padding: 0px 40px;
  }
  .notify.active {
    z-index: 9999;
    transform: translateY(-60px) !important;
  }
}
.disable-scroll {
  overflow: hidden !important;
}
.bg-red {
  background: $brand-red;
}
</style>
