<template>
  <div>
    <!-- Start Add Subuser Modal -->
    <modal v-if="frozenAccount"
      @close="frozenAccount = false">
      <div slot="title">Active My Account</div>
      <div slot="body">
        This account has been frozen. Are you sure to want to activate the
        account?
      </div>
      <div slot="footer">
        <button class="btn btn-danger"
          @click="frozenAccount = false">
          Cancel
        </button>
        <button class="btn btn-primary"
          @click="login(true)">
          Login And Active
        </button>
      </div>
    </modal>
    <!-- End Add Subuser Modal -->
    <div class="container login p-0">
      <span class="h1">Login</span>

      <div class="form maxwidth"
        v-if="loginWithConflict">
        <span class="linked-account-notify">
          <!-- Looks like you registered before with this email, please enter your
          password for link your social account and socialays account. -- -->
          {{  $route.query.error  }}
        </span>
        <div class="form-group">
          <input type="text"
            name="email"
            @blur="$v.newlinkAccount.email.$touch()"
            v-model="newlinkAccount.email"
            class="form-control"
            :class="{ 'invalid-b': $v.newlinkAccount.email.$error }"
            placeholder="e-mail"
            disabled />
          <div v-if="$v.newlinkAccount.email.$error">
            <span class="invalid"
              v-if="!$v.newlinkAccount.email.required">Email required</span>
            <span class="invalid"
              v-if="!$v.newlinkAccount.email.email">Please provide a valid email.</span>
          </div>
        </div>
        <div class="form-group">
          <input type="password"
            name="password"
            @blur="$v.newlinkAccount.password.$touch()"
            v-model="newlinkAccount.password"
            class="form-control"
            :class="{ 'invalid-b': $v.newlinkAccount.password.$error }"
            placeholder="password"
            :disabled="frozenAccount"
            @keyup.enter="conflictLogin" />
          <div v-if="$v.newlinkAccount.password.$error">
            <span class="invalid"
              v-if="!$v.newlinkAccount.password.required">Password required</span>
            <span class="invalid"
              v-if="
                !$v.newlinkAccount.password.minLength ||
                !$v.newlinkAccount.password.maxLength
              ">Password must be within min 5 - max 20 character length</span>
          </div>
        </div>
        <div v-if="resError">
          <span class="invalid">{{ resError }}</span>
        </div>
        <div class="col mt-4"
          v-if="badCredentials">
          <span class="invalid">Wrong password or email</span>
        </div>
        <button class="btn btn-primary btn-block"
          @click="conflictLogin">
          Login
        </button>
      </div>

      <div class="form"
        v-if="!loginWithConflict">
        <div class="form-group">
          <input type="text"
            name="email"
            @blur="$v.user.email.$touch()"
            v-model="user.email"
            class="form-control"
            :class="{ 'invalid-b': $v.user.email.$error }"
            placeholder="e-mail"
            :disabled="frozenAccount" />
          <div v-if="$v.user.email.$error">
            <span class="invalid"
              v-if="!$v.user.email.required">Email required</span>
            <span class="invalid"
              v-if="!$v.user.email.email">Please provide a valid email.</span>
          </div>
        </div>
        <div class="form-group">
          <input type="password"
            name="password"
            @blur="$v.user.password.$touch()"
            v-model="user.password"
            class="form-control"
            :class="{ 'invalid-b': $v.user.password.$error }"
            placeholder="password"
            :disabled="frozenAccount"
            @keyup.enter="login" />
          <div v-if="$v.user.password.$error">
            <!-- <span class="invalid" v-if="!$v.user.password.required"
              >Password required</span
            > -->
            <span class="invalid"
              v-if="$v.user.password.$anyError">Password min 5 charaters
            </span>
          </div>
        </div>
        <div class="d-flex">
          <div class="col pl-4">
            <div class="form-check p-0">
              <input type="checkbox"
                class="form-check-input"
                id="remember" />
              <label class="form-check-label"
                for="remember">Remember me</label>
            </div>
          </div>
          <div class="col text-right p-0">
            <router-link :to="{
              name: 'forgot-password'
            }">Forgot Password</router-link>
          </div>
        </div>
        <div v-if="resError">
          <span class="invalid">{{ resError }}</span>
        </div>
        <div class="col mt-4"
          v-if="badCredentials">
          <span class="invalid">Wrong password or email</span>
        </div>
        <button class="btn btn-primary btn-block"
          @click="login(false)">
          Login
        </button>

        <social-login></social-login>
      </div>
    </div>
  </div>
</template>
<script>
import { HTTP } from "@/main-source";
import router from "@/router";
import modal from "@/components/modal";
import SocialLogin from "@/components/Social-Login";

import {
  required,
  email,
  minLength,
  maxLength
} from "vuelidate/lib/validators";

export default {
  name: "Login",
  data() {
    return {
      title: "Login",
      user: {
        email: "",
        password: "",
        force: false
      },
      badCredentials: false,
      apiURL: this.$apiURL,
      redirectURL: this.$redirectURL,
      frozenAccount: false,
      resError: null,
      loginWithConflict: this.$route.query.error ? true : false,
      newlinkAccount: {
        email: this.$route.query ? this.$route.query.email : null,
        password: null,
        provider: this.$route.query ? this.$route.query.provider : null
      }
    };
  },
  validations: {
    user: {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(5),
        // maxLength: maxLength(20),
        // containsUppercase: function(value) {
        //   return /[A-Z]/.test(value);
        // },
        // containsLowercase: function(value) {
        //   return /[a-z]/.test(value);
        // },
        // containsNumber: function(value) {
        //   return /[0-9]/.test(value);
        // },
        // containsSpecial: function(value) {
        //   return /[#?!@$%^&*-]/.test(value);
        // }
      }
    },
    newlinkAccount: {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(20)
      }
    }
  },
  components: {
    modal,
    SocialLogin
  },

  methods: {
    login: function (force = false) {
      if (force) {
        this.user.force = true;
      }
      this.$v.user.$touch();
      if (!this.$v.user.$error) {
        this.badCredentials = false;
        this.resError = null;
        HTTP.post("/auth/login", this.user)
          .then(result => {
            if (result.status == 200) {
              HTTP.defaults.headers.common["Authorization"] =
                "Bearer " + result.data.accessToken;
              localStorage.token = result.data.accessToken;
              router.push({ name: "home" });
            }

            // console.log(result);
          })
          .catch(er => {
            if (er.response.status == 401) {
              this.badCredentials = true;
            }
            if (er.response.status == 400) {
              this.resError = er.response.data.message;
              console.log(er.response.data.message);
            }
            if (er.response.status == 409) {
              this.frozenAccount = true;
            }
            if (er.response.status == 404) {
              this.resError = er.response.data.message;
            }
            return false;
          });
      }
    },
    conflictLogin: function () {
      this.$v.newlinkAccount.$touch();
      if (!this.$v.newlinkAccount.$error) {
        HTTP.post("/auth/link", this.newlinkAccount)
          .then(result => {
            if (result.status == 200) {
              HTTP.defaults.headers.common["Authorization"] =
                "Bearer " + result.data.accessToken;
              localStorage.token = result.data.accessToken;
              router.push("/");
            }
            // console.log(result);
          })
          .catch(er => {
            if (er.response.status == 401) {
              this.badCredentials = true;
            }
            if (er.response.status == 409) {
              this.frozenAccount = true;
            }
            if (er.response.status == 404) {
              this.resError = er.response.data.message;
            }
            return false;
          });
      }
    }
  }
};
</script>
