<template>
  <div class="fl-wrap">
    <div class="wrapper centered content">
      <div class="container"
        v-if="!showAddNew && !paymentCheck">
        <img @error="(e) => (e.target.src = $defaultCardAvatar)"
          src="@/assets/images/icon.svg"
          class="icon"
          alt="Socialays" />
        <span class="h1">Welcome to Socialays</span>
        <p>
          The most advanced AI-Based Social Media Assistant is ready for you. To experience our amazing features...
        </p>
        <button @click="goToDashboard"
          class="btn btn-primary orange">
          Let’s start!
        </button>
      </div>
    </div>
  </div>
</template>
<script>
// import { HTTP } from "@/main-source";
// import router from "@/router";



export default {
  name: "Welcome",
  data() {
    return {};
  },
  created() { },
  mounted() {
    gtag("event", "sign_up", {
      method: "Google"
    });
  },
  methods: {
    goToDashboard: function () {
      window.location = this.$redirectURL;
    },
  },
};
</script>

<style lang="scss">
.content.centered {
  min-height: 400px;
  background: url("https://app.socialays.com/mail/images/soci-hair.png") no-repeat;
  background-color: #fff;
  background-position: bottom;
  background-size: 20%;
  border-bottom: 2px solid #f1f3f4;
  text-align: center;
  padding: 50px 0px 100px 0px;
}

.content h1 {
  font-size: 35px;

  font-weight: bold;
  margin: 20px auto 30px auto;
}

.content .icon {
  display: block;
  margin: auto;
}

.content p {
  width: 60%;
  line-height: 25px;
  margin: auto;
}

.wrapper.content .btn-primary {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  margin-top: 30px;
  padding: 15px 30px;
  display: inline-block;
  border-radius: 30px;
  letter-spacing: 1px;
}

.orange {
  background: #f05d25 !important;
}

.green {
  background: #159342;
}

.brown {
  background: #986957;
}

.red {
  background: #c4113c;
}

.blue {
  background: #00649b;
}

@media (max-width: 767.98px) {
  .wrapper.centered.content {
    margin-top: 25px;
    min-height: 90vh !important;
    background-size: 30%;

    .container {
      margin-top: 20px;

      .icon {
        width: 70px;
      }
    }
  }

  .accounts {
    padding: 0 !important;
  }

  .wrapper,
  .accounts,
  .upgrade {
    height: auto;
  }

  .total {
    margin: 0;
  }
}
</style>
