<template>
  <div class="setting wrap">
    <div class="head">
      <img @error="(e) => (e.target.src = $defaultCardAvatar)"
        src="@/assets/images/account-settings/mail.svg"
        alt="Email Settings"
      />
      <span>e-Mail Notification</span>
    </div>
    <div class="body">
      <div>
        Hate speech comment notification
        <label class="switch">
          <input
            type="checkbox"
            v-model="settings.emailNotificationNegative.notifyMeNegativeComment"
            class="default primary"
          />
          <span class="slider round"></span>
        </label>
      </div>
      <div
        class="sub"
        :class="{
          disabled: !settings.emailNotificationNegative.notifyMeNegativeComment
        }"
      >
        Notify me in every
        <select
          name="#"
          v-model="
            settings.emailNotificationNegative
              .notifyMeNumOfNegativeCommentReceived
          "
        >
          <option v-for="i in 24" :value="i" :key="i">{{ i }}</option>
        </select>
        comment(s) received during the day.
      </div>
      <div class="break"></div>

      <div>
        Question comment notification
        <label class="switch">
          <input
            type="checkbox"
            v-model="settings.emailNotificationQuestion.notifyMeQuestionComment"
            class="default primary"
          />
          <span class="slider round"></span>
        </label>
      </div>
      <div
        class="sub"
        :class="{
          disabled: !settings.emailNotificationQuestion.notifyMeQuestionComment
        }"
      >
        Notify me in every
        <select
          name="#"
          v-model="
            settings.emailNotificationQuestion
              .notifyMeNumOfQuestionCommentReceived
          "
        >
          <option v-for="i in 24" :value="i" :key="i">{{ i }}</option>
        </select>
        comment(s) received during the day.
      </div>

      <div class="break"></div>
      <div>
        Mention notification
        <label class="switch">
          <input
            type="checkbox"
            v-model="settings.emailNotificationMention.notifyMeMention"
            class="default primary"
          />
          <span class="slider round"></span>
        </label>
      </div>
      <div
        class="sub"
        :class="{
          disabled: !settings.emailNotificationMention.notifyMeMention
        }"
      >
        Notify me in every
        <select
          name="#"
          v-model="
            settings.emailNotificationMention.notifyMeNumOfMentionReceived
          "
        >
          <option v-for="i in 24" :value="i" :key="i">{{ i }}</option>
        </select>
        mentions during the day.
      </div>

      <div class="break"></div>

      <div>
        Profanity comment notification
        <label class="switch">
          <input
            type="checkbox"
            v-model="
              settings.emailNotificationBlasphemy.notifyMeDeleteBlasphemy
            "
            class="default primary"
          />
          <span class="slider round"></span>
        </label>
      </div>
      <div
        class="sub"
        :class="{
          disabled: !settings.emailNotificationBlasphemy.notifyMeDeleteBlasphemy
        }"
      >
        Notify me in every
        <select
          name="#"
          v-model="
            settings.emailNotificationBlasphemy
              .notifyMeNumOfBlasphemyCommentDeleted
          "
        >
          <option v-for="i in 24" :value="i" :key="i">{{ i }}</option>
        </select>
        abusive comment(s) during the day.
      </div>
    </div>
  </div>
</template>
<script>
import EventBus from "@/components/event-bus";
/* eslint-disable */
export default {
  name: "email-notification",
  props: {
    settings: {
      type: Object,
      default: function() {
        return {
          emailNotificationNegative: {
            notifyMeNegativeComment: false,
            notifyMeNumOfNegativeCommentReceived: 0
          },
          emailNotificationQuestion: {
            notifyMeQuestionComment: false,
            notifyMeNumOfQuestionCommentReceived: 0
          },
          emailReporting: {
            emailByPolarityWeekly: true,
            emailByPolarityDaily: true,
            emailByPolarity: true,
            emailByPolarityMonthly: true
          },
          emailNotificationBlasphemy: {
            notifyMeNumOfBlasphemyCommentDeleted: 0,
            notifyMeDeleteBlasphemy: false
          },
          emailNotificationMention: {
            notifyMeMention: false,
            notifyMeNumOfMentionReceived: 0
          }
        };
      }
    }
  },
  watch: {
    "settings.emailNotificationNegative.notifyMeNegativeComment": function(
      value
    ) {
      let data = {
        notifyMeNegativeComment: value
      };
      EventBus.$emit("updateAccountNotification", data);
    },
    "settings.emailNotificationNegative.notifyMeNumOfNegativeCommentReceived": function(
      value
    ) {
      let data = {
        notifyMeNumOfNegativeCommentReceived: value
      };
      EventBus.$emit("updateAccountNotification", data);
    },
    "settings.emailNotificationQuestion.notifyMeQuestionComment": function(
      value
    ) {
      let data = {
        notifyMeQuestionComment: value
      };
      EventBus.$emit("updateAccountNotification", data);
    },
    "settings.emailNotificationQuestion.notifyMeNumOfQuestionCommentReceived": function(
      value
    ) {
      let data = {
        notifyMeNumOfQuestionCommentReceived: value
      };
      EventBus.$emit("updateAccountNotification", data);
    },
    "settings.emailReporting.emailByPolarityWeekly": function(value) {
      let data = {
        emailByPolarityWeekly: value
      };
      EventBus.$emit("updateAccountNotification", data);
    },
    "settings.emailReporting.emailByPolarityDaily": function(value) {
      let data = {
        emailByPolarityDaily: value
      };
      EventBus.$emit("updateAccountNotification", data);
    },
    "settings.emailReporting.emailByPolarity": function(value) {
      let data = {
        emailByPolarity: value
      };
      EventBus.$emit("updateAccountNotification", data);
    },
    "settings.emailReporting.emailByPolarityMonthly": function(value) {
      let data = {
        emailByPolarityMonthly: value
      };
      EventBus.$emit("updateAccountNotification", data);
    },
    "settings.emailNotificationBlasphemy.notifyMeNumOfBlasphemyCommentDeleted": function(
      value
    ) {
      let data = {
        notifyMeNumOfBlasphemyCommentDeleted: value
      };
      EventBus.$emit("updateAccountNotification", data);
    },
    "settings.emailNotificationBlasphemy.notifyMeDeleteBlasphemy": function(
      value
    ) {
      let data = {
        notifyMeDeleteBlasphemy: value
      };
      EventBus.$emit("updateAccountNotification", data);
    },
    "settings.emailNotificationMention.notifyMeMention": function(value) {
      let data = {
        notifyMeMention: value
      };
      EventBus.$emit("updateAccountNotification", data);
    },
    "settings.emailNotificationMention.notifyMeNumOfMentionReceived": function(
      value
    ) {
      let data = {
        notifyMeNumOfMentionReceived: value
      };
      EventBus.$emit("updateAccountNotification", data);
    }
  }
};
/* eslint-disable */
</script>
