<template>
  <div class="social-account"
    @click="selectAccount(account.accountId)">
    <div class="col-2 col-sm-3 img">
      <img @error="(e) => (e.target.src = $defaultCardAvatar)"
        :src="profileImg()"
        class="img-fluid"
        v-if="profileImg()" />
      <img @error="(e) => (e.target.src = $defaultCardAvatar)"
        class="img-fluid"
        src="@/assets/images/profile.png"
        alt="Selected Account"
        v-if="!profileImg()" />
    </div>
    <div class="info col-9">
      <span class="name">{{ account.accountName.substr(0, 9)
      }}{{ account.accountName.length > 9 ? ".." : "" }}</span>
      <span class="badge orange" v-if="account.type == 'COMPETITOR'">Competitor</span>
      <!-- <span class="slug">
        @{{ account.accountName.split(" ").join("").toLowerCase().substr(0, 26)
        }}{{ account.accountName.length > 26 ? ".." : "" }}
      </span> -->
    </div>
  </div>
</template>
<script>
import router from "@/router";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "listitem",
  props: ["account", "disableClick"],
  created() { },
  methods: {
    ...mapActions(["setActiveSocialMediaAccount"]),
    selectAccount: function (accountId) {
      if (this.$route.params.accountId != accountId) {
        this.setActiveSocialMediaAccount(accountId);
        let route = {
          name: this.$route.name,
          params: {
            socialmedia: this.$route.params.socialmedia,
            accountId: accountId,
          },
          query: null,
        };
        router.replace(route);
      }
    },
    profileImg() {
      let uri = this.account.imageUrl;
      if (this.getActiveSocialMedia == "facebook") {
        uri =
          "https://graph.facebook.com/" + this.account.accountId + "/picture";
      }
      return uri;
    },
  },
  computed: {
    ...mapGetters(["getActiveSocialMedia", "getActiveSocialMediaAccount"]),
  },
};
</script>
