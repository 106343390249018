<template>
  <div class="setting account">
    <div class="head">
      <img @error="(e) => (e.target.src = $defaultCardAvatar)" src="@/assets/images/settings/security.svg" alt="Email Settings" />
      <span>Password</span>
    </div>
    <div class="body">
      <div class="form">
        <div class="form-group">
          <input
            type="password"
            class="form-control underline"
            placeholder="Old Password"
            @blur="$v.pass.oldPassword.$touch()"
            v-model="pass.oldPassword"
            autocomplete="new-password"
          />
          <div v-if="$v.pass.oldPassword.$error">
            <span class="invalid" v-if="!$v.pass.oldPassword.required"
              >Password required</span
            >
            <span
              class="invalid"
              v-if="
                !$v.pass.oldPassword.minLength || !$v.pass.oldPassword.maxLength
              "
              >Password must be within min 4 - max 20 character length</span
            >
          </div>
        </div>
        <div class="form-group">
          <input
            type="password"
            class="form-control underline"
            placeholder="New Password"
            @blur="$v.pass.newPassword.$touch()"
            v-model="pass.newPassword"
            @keyup.enter="changePassword"
          />
          <div v-if="$v.pass.newPassword.$error">
            <span class="invalid" v-if="!$v.pass.newPassword.required"
              >Password required</span
            >
            <span
              class="invalid"
              v-if="
                !$v.pass.newPassword.minLength || !$v.pass.newPassword.maxLength
              "
              >Password must be within min 4 - max 20 character length</span
            >
          </div>
        </div>
        <div class="form-group">
          <input
            type="password"
            class="form-control underline"
            placeholder="Confirm New Password"
            @blur="$v.pass.newPasswordCheck.$touch()"
            v-model="pass.newPasswordCheck"
            @keyup.enter="changePassword"
          />
          <div v-if="$v.pass.newPasswordCheck.$error">
            <span class="invalid" v-if="!$v.pass.newPasswordCheck.required"
              >Password required</span
            >
            <span
              class="invalid"
              v-if="
                !$v.pass.newPasswordCheck.minLength ||
                  !$v.pass.newPasswordCheck.maxLength
              "
              >Password must be within min 4 - max 20 character length</span
            >
          </div>
        </div>
        <div
          v-if="$v.pass.newPassword.$error || $v.pass.newPasswordCheck.$error"
        >
          <div class="col" v-if="!$v.pass.newPasswordCheck.sameAs">
            <span class="invalid">Passwords doesn't match</span>
          </div>
        </div>
        <div class="btn-wrap">
          <button class="btn btn-primary" @click="changePassword">
            Change Password
            <Loading :white="true" v-if="isLoading"></Loading>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import vuelidate from "vuelidate";
import Loading from "@/components/Loading";
import EventBus from "@/components/event-bus";
import {
  required,
  minLength,
  maxLength,
  sameAs
} from "vuelidate/lib/validators";

export default {
  name: "ChangePassword",
  data() {
    return {
      pass: {
        oldPassword: null,
        newPassword: null,
        newPasswordCheck: null
      },
      isLoading: false
    };
  },
  validations: {
    pass: {
      oldPassword: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(20)
      },
      newPassword: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(20)
      },
      newPasswordCheck: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(20),
        sameAs: sameAs("newPassword")
      }
    }
  },
  components: {
    Loading
  },
  methods: {
    changePassword: function() {
      this.$v.pass.$touch();
      if (!this.$v.pass.$error) {
        EventBus.$emit("Change-Password", this.pass);
      }
    }
  }
};
</script>

<style lang="scss">
@import "../../scss/colors.scss";
.account {
  .profile {
    width: 90px;
    height: 90px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    float: left;
    margin-right: 10px;
  }
  .info {
    padding-top: 25px;
    .name {
      font-weight: bold;
    }
  }
  .period {
    font-size: 12px;
    text-align: center;
    .bold {
      font-weight: bold;
      display: block;
    }
    .col {
      &:nth-child(2) {
        border-right: 1px solid lighten($black, 90%);
        border-left: 1px solid lighten($black, 90%);
      }
    }
  }

  .btn-wrap {
    .btn {
      width: calc(50% - 5px);
      margin-right: 10px;
      font-weight: bold;
      color: $white;
      padding: 10px 15px;
      border-radius: 30px;
    }
    .btn-danger {
      margin-right: 0px;
    }
  }
}
</style>
