<script>
import { Doughnut, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: Doughnut,
  mixins: [reactiveProp],
  props: ["type"],
  methods: {
    /*eslint-disable*/
    handle: function(point, event) {
      const item = event[0];
      if (typeof item._index != undefined) {
        this.$emit("clicked", item._index);
      }
    },
    reChart: function() {
      let sum = 0;
      let chartData = this.chartData.datasets[0].data;
      chartData.forEach(i => {
        sum += i;
      });
      if (chartData.length == 1) {
        sum = 1;
      }
      this.renderChart(this.chartData, {
        showAllTooltips: false,
        cutoutPercentage: 50,
        tooltips: {
          callbacks: {
            label: function(tooltipItem, data) {
              return (
                data["labels"][tooltipItem["index"]] +
                ": " +
                (
                  (chartData.length != 1
                    ? data["datasets"][0]["data"][tooltipItem["index"]] * 100
                    : "100") / sum
                ).toFixed() +
                "%"
              );
            }
          }
        },
        responsive: true,
        legend: {
          display: false
        },
        onClick: this.handle
      });
    }
  },
  mounted() {
    this.reChart();
  },
  watch: {
    chartData: function() {
      this.reChart();
    }
  }
};
</script>
