<template>
    <div></div>
</template>
<script>
export default {
    name: "Mobile-Add-Account",
    created() {
        let code = this.$route.query.code ? this.$route.query.code : '';
        let provider = this.$route.query.provider ? this.$route.query.provider : '';
        let oauth_token = this.$route.query.oauth_token ? this.$route.query.oauth_token : '';
        let oauth_verifier = this.$route.query.oauth_verifier ? this.$route.query.oauth_verifier : '';

        window.location =
            "socialays://social/update?provider=" + provider + "&code=" + code + "&oauth_token=" + oauth_token + "&oauth_verifier=" + oauth_verifier;
    },
};
</script>