<template>
  <div>
    <!-- Start Add Subuser Modal -->
    <modal v-if="inviteUserModal" @close="inviteUserModal = false">
      <div slot="title">Add Subuser</div>
      <div slot="body">
        <input
          type="text"
          @blur="$v.userInviteEmail.$touch()"
          v-model="userInviteEmail"
          class="form-control underline"
          :class="{ 'invalid-b': $v.userInviteEmail.$error }"
          placeholder="e-mail"
          @keyup.enter="addSubUser"
          :disabled="isLoading"
        />
        <div v-if="$v.userInviteEmail.$error">
          <span class="invalid" v-if="!$v.userInviteEmail.required"
            >Email required</span
          >
          <span class="invalid" v-if="!$v.userInviteEmail.email"
            >Please provide a valid email.</span
          >
        </div>
        <div class="invalid" v-if="serverError.status">
          {{ serverError.message }}
        </div>
      </div>
      <div slot="footer">
        <!-- <button class="btn btn-danger" @click="inviteUserModal = false">
          Cancel
        </button> -->
        <button
          class="btn btn-primary"
          @click="addSubUser"
          :disabled="isLoading"
        >
          Add <Loading :white="true" v-if="isLoading"></Loading>
        </button>
      </div>
    </modal>
    <!-- End Add Subuser Modal -->

    <!-- Start addPageToSubuserModal -->
    <modal v-if="addPageToSubuserModal" @close="addPageToSubuserModal = false">
      <div slot="title">Assign Social Account</div>
      <div
        slot="body"
        class="account-list addable-page"
        :class="{ 'loading-wrap': xhrAssignablePage }"
      >
        <div>
          <span
            class="error"
            v-if="addablePageList.length == 0 && !xhrAssignablePage"
          >
            You have add a account/page first
          </span>
          <Loading v-if="xhrAssignablePage"></Loading>

          <div
            class="social-account"
            v-for="(account, index) in addablePageList"
            :key="index"
            :class="{ selected: account.selected }"
            @click="account.selected = !account.selected"
          >
            <div class="img">
              <facebookIcon
                class="socialmedia"
                :active="'true'"
                v-if="account.socialMediaApp.toLowerCase() == 'facebook'"
              ></facebookIcon>
              <instagramIcon
                class="socialmedia"
                :active="'true'"
                v-if="account.socialMediaApp.toLowerCase() == 'instagram'"
              ></instagramIcon>
              <twitterIcon
                class="socialmedia"
                :active="'true'"
                v-if="account.socialMediaApp.toLowerCase() == 'twitter'"
              ></twitterIcon>
              <youtubeIcon
                class="socialmedia"
                :active="'true'"
                v-if="account.socialMediaApp.toLowerCase() == 'youtube'"
              ></youtubeIcon>
              <img @error="(e) => (e.target.src = $defaultCardAvatar)" :src="account.imageUrl" class="img-fluid" />
            </div>
            <div class="info">
              <span class="name">{{ account.accountName }}</span>
            </div>
            <input type="checkbox" v-model="account.selected" />
          </div>
        </div>
      </div>
      <div slot="footer">
        <button class="btn btn-danger" @click="addPageToSubuserModal = false">
          Cancel
        </button>
        <button
          class="btn btn-primary"
          @click="assignPages"
          v-if="!addablePageList.length == 0"
        >
          Add
        </button>
        <button
          class="btn btn-primary"
          v-if="addablePageList.length == 0"
          @click="navigateAddAccount"
          @click.native="setActiveSocialMedia('')"
        >
          Add Account
        </button>
      </div>
    </modal>
    <!-- End addPageToSubuserModal -->
    <div class="row">
      <div class="col-12 col-sm-3 wrap">
        <div class="inner">
          <div class="head col-12">
            <img @error="(e) => (e.target.src = $defaultCardAvatar)"
              src="@/assets/images/settings/profile.svg"
              alt="Email Settings"
            />
            <span class="title">Subusers</span>
            <button class="btn btn-default" @click="inviteUserModal = true">
              + New User
            </button>
          </div>
          <div class="body list" :class="{ 'no-data': subusers.length == 0 }">
            <span v-if="subusers.length == 0 && isSubuserRequestDone"
              >No subusers</span
            >
            <Loading
              v-if="subusers.length == 0 && !isSubuserRequestDone"
            ></Loading>
            <user
              v-for="(user, index) in subusers"
              :key="index"
              :user="user"
              :class="{
                active: user.id == selectedUser.authorizedUserId
              }"
              @click.native="selectUser(user.id)"
            ></user>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 wrap">
        <div
          class="inner no-data"
          v-if="selectedUser.length == 0 && isInvitaionsRequestDone"
        >
          <span>Please Select User</span>
        </div>
        <div
          class="inner no-data"
          v-if="selectedUser.length == 0 && !isInvitaionsRequestDone"
        >
          <Loading></Loading>
        </div>

        <div class="inner" v-if="selectedUser.length != 0">
          <div class="head col-12">
            <user
              class="selected-user"
              :user="selectedUser"
              :actions="false"
            ></user>
            <button class="btn btn-default add-page" @click="showPageList">
              + Assign Social Account
            </button>
          </div>
          <div class="body page-manage">
            <div class="switch-wrap">
              <strong>Like Master</strong>
              Can do anything like Master user inc. add account, add sub user
              <label class="switch">
                <input
                  type="checkbox"
                  v-model="selectedUser.likeMaster"
                  class="default primary"
                />
                <span class="slider round"></span>
              </label>
            </div>
            <h6 class="sub-title">Assigned Pages</h6>
            <input type="text" class="search" placeholder="Search" />
            <div class="page-list">
              <page-settings
                v-for="(page, index) in selectedUserAuthorizedPages"
                :key="index"
                :account="page"
              ></page-settings>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-3 wrap">
        <div class="inner">
          <div class="head col-12">
            <img @error="(e) => (e.target.src = $defaultCardAvatar)"
              src="@/assets/images/settings/movements.svg"
              alt="Billing Settings"
            />
            <span class="title">Recent Movements</span>
          </div>
          <div class="body" :class="{ 'no-data': userMovements.length == 0 }">
            <span v-if="userMovements.length == 0 && isMovementsRequestDone"
              >No user movements</span
            >

            <Loading
              v-if="userMovements.length == 0 && !isMovementsRequestDone"
            ></Loading>

            <movement
              v-for="(movement, index) in userMovements"
              :key="index"
              :movement="movement"
            ></movement>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import user from "@/components/subusers/user-list";
import pageSettings from "@/components/subusers/page-settings-item";
import { HTTP } from "@/main-source";
import modal from "@/components/modal";
import { required, email } from "vuelidate/lib/validators";
import movement from "@/components/settings/user-movements";
import EventBus from "@/components/event-bus";
import facebookIcon from "@/components/icons/facebook";
import instagramIcon from "@/components/icons/instagram";
import twitterIcon from "@/components/icons/twitter";
import youtubeIcon from "@/components/icons/youtube";
import Loading from "@/components/Loading";
import router from "@/router";

export default {
  name: "subuser",
  data: function() {
    return {
      subusers: [],
      selectedUser: [],
      selectedUserAuthorizedPages: [],
      inviteUserModal: false,
      userInviteEmail: null,
      userMovements: [],
      addPageToSubuserModal: false,
      addablePageList: [],
      isLoading: false,
      isSubuserRequestDone: false,
      isInvitaionsRequestDone: false,
      isMovementsRequestDone: false,
      xhrAssignablePage: false,
      serverError: {
        status: false,
        message: null
      }
    };
  },
  validations: {
    userInviteEmail: {
      required,
      email
    }
  },
  components: {
    user,
    pageSettings,
    modal,
    movement,
    Loading,
    facebookIcon,
    instagramIcon,
    twitterIcon,
    youtubeIcon
  },
  created: function() {
    this.getSubusers();

    // HTTP.put("settings/approve-invitation/1?status=true").then(a => {
    //   console.log(a);
    // });
  },
  methods: {
    navigateAddAccount: function() {
      router.push({
        name: "home",
        query: {
          addAccount: "true"
        }
      });
    },
    getSubusers: function(hasSelectedUser) {
      HTTP.get("user/me").then(result => {
        this.subusers = result.data.authorizedUsers;
        this.isSubuserRequestDone = true;
        if (this.subusers.length == 0) {
          this.isInvitaionsRequestDone = true;
          this.isMovementsRequestDone = true;
        }
        this.getPendingInvitations(hasSelectedUser);
      });
    },
    getPendingInvitations: function(hasSelectedUser) {
      this.isInvitaionsRequestDone = false;
      HTTP.get("settings/pending-invitations")
        .then(result => {
          if (result.data.length > 0) {
            result.data.forEach(invitation => {
              this.subusers.push(invitation);
            });
          }
          if (hasSelectedUser) {
            this.selectUser(this.selectedUser.id);
          } else {
            this.selectUser(this.subusers[0].id);
          }
          this.isInvitaionsRequestDone = true;
        })
        .catch(() => {
          this.isInvitaionsRequestDone = true;
        });
    },
    selectUser: function(userId) {
      let data = this.subusers.filter(user => user.id == userId)[0];

      this.selectedUser = {
        authorizedUserId: data.id,
        authorizedUserName: data.authorizedUserName
          ? data.authorizedUserName
          : null,
        likeMaster: data.likeMaster,
        id: data.authorizedUserId,
        email: data.authorizedUserEmail
      };
      this.selectedUserAuthorizedPages = [];
      this.selectedUserAuthorizedPages = data.authorizedAccounts
        ? data.authorizedAccounts
        : data.accountAuthInvites;
      console.log(this.selectedUserAuthorizedPages);
    },
    getMovements: function() {
      this.isMovementsRequestDone = false;
      console.log(this.selectedUser);
      HTTP.get("useractions/" + this.selectedUser.id)
        .then(result => {
          this.userMovements = result.data.content;
          this.isMovementsRequestDone = true;
        })
        .catch(() => {
          this.isMovementsRequestDone = true;
        });
    },
    addSubUser: function() {
      let data = {
        inviteeEmail: this.userInviteEmail
      };
      this.isLoading = true;
      HTTP.post("settings/invite-user", data)
        .then(res => {
          if (res.status == 200) {
            this.inviteUserModal = false;
            this.isLoading = true;
            this.getSubusers();
          }
        })
        .catch(e => {
          this.isLoading = false;
          this.serverError.status = true;
          this.serverError.message = e.response.data.message;
        });
    },
    showPageList: function() {
      this.addablePageList = [];
      this.xhrAssignablePage = true;
      HTTP.get("user/me")
        .then(result => {
          if (result.data.socialMediaAccounts.length > 0) {
            result.data.socialMediaAccounts.forEach(account => {
              account.selected = false;
              this.addablePageList.push(account);
            });
          }
          this.xhrAssignablePage = false;
        })
        .catch(() => {
          this.xhrAssignablePage = false;
        });
      this.addPageToSubuserModal = true;
    },
    assignPages: function() {
      let pages = this.addablePageList.filter(page => page.selected);

      let data = [];
      pages.forEach(page => {
        data.push({
          accountId: page.accountId,
          accountName: page.accountName,
          socialMediaApp: page.socialMediaApp
        });
      });

      if (this.selectedUser.authorizedUserId != undefined) {
        console.log("selecteduser", this.selectedUser);
        let uri = "settings/invitation-add-page/";
        if (this.selectedUser.id) {
          uri = "settings/update-account-auth/";
        }
        HTTP.post(uri + this.selectedUser.authorizedUserId, data).then(res => {
          if (res.status == 200) {
            this.getSubusers(true);
            this.addPageToSubuserModal = false;
          }
        });
      } else {
        HTTP.post(
          "settings/invitation-add-page/" + this.selectedUser.id,
          data
        ).then(res => {
          console.log(this.selectedUser.id);
          if (res.status == 200) {
            this.getSubusers(true);
            this.addPageToSubuserModal = false;
          }
        });
      }
    },
    deleteInvitedUser: function(user) {
      if (user.inviteStatus != undefined) {
        HTTP.delete("settings/update-invitation/" + user.id).then(res => {
          console.log(res);
          this.subusers = this.subusers.filter(
            subuser => subuser.id != user.id
          );
        });
      } else {
        HTTP.delete("settings/delete-auth-user/" + user.id).then(() => {
          this.subusers = this.subusers.filter(
            subuser => subuser.id != user.id
          );
        });
      }
    },
    deleteAssignedPage: function(page) {
      console.log("user", this.selectedUser);
      if (this.selectedUser.authorizedUserId != undefined) {
        HTTP.delete(
          "settings/delete-auth-account/" +
            page.id +
            "?email=" +
            this.selectedUser.email
        ).then(res => {
          console.log(res);
          this.getSubusers(true);
        });
      } else {
        HTTP.delete(
          "settings/update-invitation/" + this.selectedUser.id + "/" + page.id
        ).then(res => {
          console.log(res);
          this.selectedUserAuthorizedPages = [];
          this.getSubusers(true);
        });
      }
    },
    resendMail: function(userId) {
      console.log("resend", userId);
    },
    /*eslint-disable */
    setAccountAccess: function(data, accountId) {
      HTTP.put(
        "/settings/update-account-auth/" + accountId,
        data
      ).then(res => {});
    }
    /*eslint-disable */
  },
  mounted() {
    EventBus.$on("delete-invited-user", user => {
      this.deleteInvitedUser(user);
    });
    EventBus.$on("resend-mail-to-invited-user", userId => {
      this.resendMail(userId);
    });
    EventBus.$on("deleteAssignedPageTrigger", page => {
      this.deleteAssignedPage(page);
    });
    EventBus.$on("updateAccountSetting", (data, accountId) => {
      this.setAccountAccess(data, accountId);
    });
  },
  watch: {
    inviteUserModal: function() {
      this.userInviteEmail = null;
      this.isLoading = false;
      this.$v.$reset();
    },
    selectedUser: function() {
      this.getMovements();
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/scss/colors.scss";
.wrap {
  padding: 0px 30px;
  .inner {
    background: $white;
    border-radius: 30px;
    height: calc(100vh - 160px);
    margin: 0px -15px;
    overflow: hidden;
  }
  .body {
    padding: 15px;
    height: 100%;
    overflow: scroll;
    padding-bottom: 15px;
  }
  .list {
    .sub-user {
      cursor: pointer;
      margin-bottom: 5px;
      &:hover {
        background: $main-bg-color;
      }
    }
    .active {
      background: $main-bg-color;
    }
  }
  .page-list {
    height: calc(100% - 220px);
    overflow: scroll;
    padding-bottom: 15px;
  }
  .head {
    color: lighten($black, 50%);
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    padding: 15px;
    position: relative;
    .title {
      font-size: 15px;
      font-weight: bold;
    }
    img {
      margin-right: 10px;
      width: 20px;
      height: auto;
    }
    .btn {
      position: absolute;
      right: 15px;
      top: 12px;
      padding: 5px 10px;
      font-size: 12px;
    }
  }
  .selected-user {
    padding-bottom: 5px;
  }
  .add-page {
    top: 25px !important;
    padding: 10px 15px !important;
  }
  .page-manage {
    padding: 30px;
  }
  .switch-wrap {
    position: relative;

    strong {
      display: block;
    }
    .switch {
      top: 15px;
      right: 0px;
    }
  }
  .sub-title {
    margin-top: 25px;
  }
  .search {
    border-radius: 30px;
    padding: 0px 10px;
    margin-bottom: 15px;
  }
}
.social-account {
  padding: 15px 15px;
  margin: 5px 0px;
  font-weight: bold;
  position: relative;
  border-radius: 5px;
  cursor: pointer;
  .socialmedia {
    position: absolute;
    left: 5px;
    top: 3px;
    width: 20px;
  }
  img {
    width: 50px;
    height: auto;
  }
  &:hover {
    background: $main-bg-color;
  }
  &:after {
    content: "";
    width: 100%;
    position: absolute;
    left: 0;
    bottom: -7.5px;
    border-bottom: 1px solid $main-bg-color;
    border-radius: 5px;
  }
  cursor: pointer;
  &:last-child {
    &:after {
      content: "";
      display: none;
    }
  }
  input {
    position: absolute;
    top: 15px;
    right: 15px;
    pointer-events: none;
  }
}
.selected {
  background: $main-bg-color;
}
.addable-page {
  max-height: 60vh;
  overflow: scroll;
}
@media (max-width: 767.98px) {
  .wrap {
    .inner {
      height: auto;
    }
    margin-bottom: 20px;
  }
}
</style>
