<template>
  <div class="setting wrap">
    <div class="head">
      <img @error="(e) => (e.target.src = $defaultCardAvatar)"
        src="@/assets/images/account-settings/block.svg"
        alt="Email Settings"
      />
      <span>Auto Block</span>
    </div>
    <div class="body">
      <div>
        
        {{ getActiveSocialMedia == "instagram" ? "Restrict" : "Auto block" }} users using profanity
        <label class="switch">
          <input
            type="checkbox"
            v-model="settings.blockAbusiveCommentedUser"
            class="default primary"
          />
          <span class="slider round"></span>
        </label>
      </div>
      <div>
        {{ getActiveSocialMedia == "instagram" ? "Restrict" : "Auto block" }} users using ad content
        <label class="switch">
          <input
            type="checkbox"
            v-model="settings.blockAdCommentUser"
            class="default primary"
          />
          <span class="slider round"></span>
        </label>
      </div>
      <div>
        {{ getActiveSocialMedia == "instagram" ? "Restrict" : "Auto block" }} users using hate speech
        <label class="switch">
          <input
            type="checkbox"
            v-model="settings.blockNegCommentUser"
            class="default primary"
          />
          <span class="slider round"></span>
        </label>
      </div>
      <div class="sub" :class="{ disabled: !settings.blockNegCommentUser }" v-show="false">
        Immediately
        <label class="switch">
          <input
            type="checkbox"
            v-model="settings.blockNegCommentUserImmediately"
            class="default primary"
          />
          <span class="slider round"></span>
        </label>
      </div>
      <div class="sub" :class="{ disabled: !settings.blockNegCommentUser }" v-if="false">
        After
        <select name="#" v-model="settings.blockNegCommentUserNumber">
          <option v-for="i in 30" :value="i" :key="i">{{ i }}</option>
        </select>
        &nbsp;
        <select name="#" v-model="settings.blockNegCommentUserFreq">
          <option value="DAYS">Days</option>
          <option value="HOURS">Hours</option>
          <option value="MINUTES">Minutes</option>
        </select>
        <label class="switch">
          <input
            type="checkbox"
            v-model="settings.blockNegCommentUserLater"
            class="default primary"
          />
          <span class="slider round"></span>
        </label>
      </div>
    </div>
  </div>
</template>
<script>
import EventBus from "@/components/event-bus";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "auto-block",
  props: {
    settings: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  watch: {
    "settings.blockNegCommentUserNumber": function (value) {
      let data = {
        blockNegCommentUserNumber: value,
      };

      EventBus.$emit("updateAccountSetting", data);
    },
    "settings.blockAbusiveCommentedUser": function (value) {
      let data = {
        blockAbusiveCommentedUser: value,
      };
      EventBus.$emit("updateAccountSetting", data);
    },
    "settings.blockNegCommentUser": function (value) {
      let data = {
        blockNegCommentUser: value,
      };
      if (value) {
        this.settings.blockNegCommentUserImmediately = value;
      } else {
        this.settings.blockNegCommentUserImmediately = false;
        this.settings.blockNegCommentUserLater = false;
      }
      EventBus.$emit("updateAccountSetting", data);
    },
    "settings.blockNegCommentUserLater": function (value) {
      if (value == true) {
        this.settings.blockNegCommentUserImmediately = false;
      }
      let data = {
        blockNegCommentUserLater: value,
      };
      EventBus.$emit("updateAccountSetting", data);
    },
    "settings.blockNegCommentUserFreq": function (value) {
      let data = {
        blockNegCommentUserFreq: value,
      };
      EventBus.$emit("updateAccountSetting", data);
    },
    "settings.blockAdCommentUser": function (value) {
      let data = {
        blockAdCommentUser: value,
      };
      EventBus.$emit("updateAccountSetting", data);
    },
    "settings.blockNegCommentUserImmediately": function (value) {
      if (value == true) {
        this.settings.blockNegCommentUserLater = false;
      }
      let data = {
        blockNegCommentUserImmediately: value,
      };
      EventBus.$emit("updateAccountSetting", data);
    },
  },
  computed: {
    ...mapGetters(["getActiveSocialMedia"]),
  },
};
</script>
