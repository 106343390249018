var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[(_vm.$route.name == 'home' && !_vm.mobileRangeFilter)?_c('div',{staticClass:"btn-add",on:{"click":_vm.showAddAccountBox}},[_vm._m(0),_c('span',{staticClass:"text"},[_vm._v(" Add Account ")])]):_vm._e(),_c('notifications',{attrs:{"group":"topNotifications","position":"top center","classes":"notify-info top"}}),(!_vm.isPageAuth && !_vm.addAccount)?[(!_vm.hasErrorNotify)?_c('div',{staticClass:"col-12 notify",class:[
        { active: _vm.showNotify },
        { 'bg-red': _vm.accountStatus == 'TRIAL_EXPIRED' },
      ]},[(false)?_c('div',[(_vm.lessDay > 0)?_c('span',[_vm._v(" You are currently using the trial version, and it's ending in "+_vm._s(_vm.lessDay)+" day"+_vm._s(_vm.lessDay > 1 ? "s" : "")+". ")]):_vm._e(),(_vm.lessDay <= 0)?_c('span',[_vm._v(" Your trial period has expired. ")]):_vm._e(),_c('span',{staticClass:"close"},[_c('close',{nativeOn:{"click":function($event){return _vm.closeNotify.apply(null, arguments)}}})],1)]):_vm._e()]):_vm._e(),(_vm.hasErrorNotify)?_c('div',{staticClass:"col-12 notify bg-red",class:[{ active: _vm.hasErrorNotify }]},[_c('div',[_vm._v(" Some of your social media accounts are broken. "),_c('span',{staticClass:"underline"},[_c('router-link',{attrs:{"to":{ name: 'settings' }}},[_vm._v(" Please reconnect by clicking here. ")])],1),_c('span',{staticClass:"close"},[_c('close',{nativeOn:{"click":function($event){return _vm.closeNotify.apply(null, arguments)}}})],1)])]):_vm._e(),_c('topbar'),_c('sidebar'),_c('div',{staticClass:"main"},[_c('div',{staticClass:"page",class:[
          { open: _vm.getActiveSocialMedia != '' },
          { 'sidebar-closed': !_vm.menu },
          { filteractive: _vm.mobileAiFilter },
        ]},[_c('router-view',{staticClass:"content",class:{ 'disable-scroll': _vm.isCommentOrPost }})],1)])]:_vm._e(),(_vm.isPageAuth && !_vm.addAccount)?[_c('div',{staticClass:"row container-fluid p-0 loginSignup"},[_c('div',{staticClass:"col left"},[_c('div',{staticClass:"row m-0"},[_c('div',{staticClass:"col-12 col-sm-9"},[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/images/logo-w-text.svg"),"alt":"Socialays"}}),_c('router-view')],1)])]),_c('div',{staticClass:"col right d-none d-sm-block"})])]:_vm._e(),(_vm.addAccount)?[_c('router-view')]:_vm._e(),_c('notifications',{attrs:{"group":"info","position":"bottom center","classes":"notify-info"}})],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("@/assets/images/sidebar/add-w.svg"),"alt":"Down"}})])
}]

export { render, staticRenderFns }