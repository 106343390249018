<template>
  <Modal v-if="active" @close="active = false">
    <div slot="title">
      <slot name="title" />
      <template v-if="!$slots.title">
        {{ title }}
      </template>
    </div>
    <div class="row head">
      <div class="col">
        <i class="fas fa-exclamation-triangle"></i>
        Warning
      </div>
      <div class="col text-right">
        <i class="fas fa-times" @click="active = false"></i>
      </div>
    </div>
    <div slot="body">
      <slot name="body" />
      <template v-if="!$slots.body">
        {{ message }}
      </template>
    </div>
    <div slot="footer">
      <button class="btn btn-primary" @click="active = false">CANCEL</button>
      <button class="btn" :class="`btn-${btnColor}`" @click="$emit('confirm'); active = false">{{ confirmText }}</button>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/modal';

export default {
  components: {
    Modal,
  },

  data() {
    return {
      active: false,
    };
  },

  mounted() {
    this.$eventBus.$register(`toggle-${this.id}`, (config) => {
      this.active = this.$safeGet(config, 'active', true);
    });
  },

  props: {
    id: {
      type: String,
      required: true,
    },

    title: {
      type: String,
      default: 'Confirm Action',
    },

    message: {
      type: String,
      default: 'Are you sure you want to proceed with this action? This action cannot be undone.',
    },

    btnColor: {
      type: String,
      default: 'danger',
    },

    confirmText: {
      type: String,
      default: 'CONFIRM',
    },
  },
};
</script>

<style>
</style>