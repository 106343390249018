<template>
  <div>
    <!-- Comments -->
    <div class="row filter d-none d-md-block" :key="changeData">
      <div class="col-12 left-filter">
        <!-- Left -->
        <div class="col-6 left">
          <div class="col filters p-0 has-icon elem">
            <i
              class="dot main all"
              v-if="
                selectedFilter[0] == 'All Results' && selectedFilter.length == 1
              "
            ></i>
            <v-select
              v-model="selectedFilter"
              :options="filters"
              :class="{
                selectedFilterPosition: selectedFilter.length > 1,
              }"
              :clearable="true"
              :searchable="false"
              :closeOnSelect="closeOnSelect"
              multiple
              :tabindex="10"
            >
              <template
                slot="selected-option-container"
                slot-scope="selectedFilter"
              >
                <div>
                  <i
                    class="dot main positive"
                    v-if="selectedFilter.option.label == 'Positive'"
                  ></i>
                  <i
                    class="dot main negative"
                    v-if="selectedFilter.option.label == 'Negative'"
                  ></i>
                  <i
                    class="dot main neutral"
                    v-if="selectedFilter.option.label == 'Neutral'"
                  ></i>
                  <i
                    class="dot main question"
                    v-if="selectedFilter.option.label == 'Question'"
                  ></i>
                  <i
                    class="dot main hidden"
                    v-if="selectedFilter.option.label == 'Hidden'"
                  ></i>
                  <i
                    class="dot main spam"
                    v-if="selectedFilter.option.label == 'Spam/Ad Content'"
                  ></i>
                  <i
                    class="dot main notassigned"
                    v-if="selectedFilter.option.label == 'Not Assigned'"
                  ></i>

                  <div
                    class="all"
                    v-if="
                      selectedFilter.option.label.trim() == 'All Results' &&
                        selectedFilterCount == 1
                    "
                  >
                    Sentiments
                  </div>
                </div>
              </template>

              <template slot="option" slot-scope="option">
                <div
                  @click="
                    option.label == 'All' ? (selectedFilter = ['All']) : ''
                  "
                >
                  <i
                    class="dot"
                    :class="[
                      { all: option.label == 'All' },
                      { positive: option.label == 'Positive' },
                      { negative: option.label == 'Negative' },
                      { neutral: option.label == 'Neutral' },
                      { question: option.label == 'Question' },
                      { hidden: option.label == 'Hidden' },
                      { spam: option.label == 'Spam/Ad Content' },
                    ]"
                  ></i>
                  {{ option.label }}
                  <small v-if="option.label == 'Positive'"
                    >( {{ polarityCounts.positive }} )</small
                  >
                  <small v-if="option.label == 'All'"
                    >( {{ polarityCounts.totalComments }} )</small
                  >
                  <small v-if="option.label == 'Negative'"
                    >( {{ polarityCounts.negative }} )</small
                  >
                  <small v-if="option.label == 'Neutral'"
                    >( {{ polarityCounts.neutral }} )</small
                  >
                  <small v-if="option.label == 'Hidden'"
                    >( {{ polarityCounts.hidden }} )</small
                  >
                  <small v-if="option.label == 'Question'"
                    >( {{ polarityCounts.question }} )</small
                  >
                  <small v-if="option.label == 'Not Assigned'"
                    >( {{ polarityCounts.notassigned }} )</small
                  >
                  <small v-if="option.label == 'Spam/Ad Content'"
                    >( {{ polarityCounts.spam }} )</small
                  >

                  <template v-for="(filter, index) in selectedFilter">
                    <span
                      :key="index"
                      class="remove"
                      v-if="
                        option.label.toLowerCase() == filter.toLowerCase() &&
                          option.label !== 'All Results'
                      "
                      @click.stop.prevent=""
                      @click="removeFilter(option)"
                    >
                      <i class="fas fa-times"></i>
                    </span>
                  </template>
                </div>
              </template>
            </v-select>
          </div>
          <!-- Emotions Filter -->
          <div class="col filters p-0 has-icon elem emotions-filter">
            <!-- <component :is="'neutral'" class="main-emoji"></component> -->

            <component
              class="main-icon"
              :is="'offensive'"
              v-if="selectedEmotion[0] == 'All' && selectedEmotion.length == 1"
            ></component>
            <v-select
              v-model="selectedEmotion"
              :options="feelings"
              :clearable="true"
              :searchable="false"
              :closeOnSelect="false"
              multiple
              :tabindex="10"
              :class="{
                selectedEmotionPosition: selectedEmotion.length > 1,
              }"
            >
              <template
                slot="selected-option-container"
                slot-scope="selectedEmotion"
              >
                <div>
                  <component
                    :is="'profanity'"
                    v-if="
                      selectedEmotion.option.label.toLowerCase() == 'profanity'
                    "
                  ></component>
                  <component
                    :is="'hate'"
                    v-if="
                      selectedEmotion.option.label.toLowerCase() ==
                        'hate speech'
                    "
                  ></component>
                  <div
                    class="all"
                    v-if="
                      selectedEmotion.option.label.trim() == 'All' &&
                        selectedEmotionCount == 1
                    "
                  >
                    Offensive
                  </div>
                </div>
              </template>

              <template slot="option" slot-scope="option">
                <div>
                  <component
                    :is="'profanity'"
                    v-if="option.label.toLowerCase() == 'profanity'"
                  ></component>
                  <component
                    :is="'hate'"
                    v-if="option.label.toLowerCase() == 'hate speech'"
                  ></component>

                  {{ option.label }}
                  <small v-if="option.label.toLowerCase() != 'all'"
                    >(
                    {{
                      option.label.toLowerCase() == "profanity"
                        ? offensiveCounts.profanity
                        : offensiveCounts.hate
                    }}
                    )
                  </small>

                  <template v-for="(filter, index) in selectedEmotion">
                    <span
                      class="remove"
                      :key="index"
                      v-if="
                        option.label.toLowerCase() == filter.toLowerCase() &&
                          option.label != 'All'
                      "
                    >
                      <i
                        class="fas fa-times"
                        @click.stop.prevent=""
                        @click="removeFeeling(option)"
                      ></i>
                    </span>
                  </template>
                </div>
              </template>
            </v-select>
          </div>
        </div>
      </div>
    </div>
    <div class="row result-info " v-if="totalElements > 0 && show">
      <div class="col-10 col-sm-6">Listed {{ totalElements }} comments</div>
      <div class="col-2 col-sm-6 text-right p-0" v-if="false">
        <button
          class="btn-filter download-button d-none d-sm-inline-block"
          type="button"
          :disabled="xhrDownload"
          @click="exportExcel"
        >
          <img
            @error="(e) => (e.target.src = $defaultCardAvatar)"
            src="@/assets/images/download.svg"
            alt="Download"
            v-if="!xhrDownload"
          />
          <span v-if="!xhrDownload">Export</span>
          <Loading v-if="xhrDownload"></Loading>
        </button>
      </div>
      <div class="col-12 col-sm-5"></div>
    </div>
    <div class="row mobile-filter p-0 d-sm-none">
      <div
        class="d-flex justify-content-end align-items-center group-select"
        :class="[mobileSearchActive ? 'col-3 p-0' : 'col-12']"
      >
        <span @click="showFilter">
          <img
            @error="(e) => (e.target.src = $defaultCardAvatar)"
            src="@/assets/images/filter-outline.svg"
            class="filter-outline"
            alt="Filter"
          />
          <span
            class="badge"
            v-if="selectedFilterCount > 1 && selectedFilter[1] != 'All'"
            >{{ selectedFilterCount - 1 }}
          </span>
        </span>
        <span @click="showEmotionFilter">
          <component :is="'offensive'"></component>
          <span
            class="badge"
            v-if="selectedEmotionCount > 1 && selectedEmotion[1] != 'All'"
            >{{ selectedEmotionCount - 1 }}
          </span>
        </span>
      </div>
    </div>

    <div class="row">
      <!-- has active add when select some elements -->

      <div
        id="comments"
        class="col-12 col-md-6 list"
        :class="[
          { 'no-data': results.length == 0 && show },
          { 'has-selected': selectedComment.id != null },
        ]"
      >
        <span class="placeholder" v-if="results.length == 0 && show"
          >No results ✌️</span
        >
        <div v-if="!show">
          <comment-placeholder v-for="n in 5" :key="n"></comment-placeholder>
        </div>

        <comment
          v-for="comment in results"
          :key="comment.id"
          :id="comment.id"
          :commentId="comment.id"
          :socialMediaAccounts="socialMediaAccounts"
          :isSub="false"
          :comment="comment"
          :name="comment.authorName"
          :profilePic="comment.authorProfilePicUrl"
          :postImageUrl="comment.postImageUrl ? comment.postImageUrl : ''"
          :location="comment.locations"
          :content="comment.content"
          :countOfReplies="comment.numOfReplies"
          :hidden="comment.hidden"
          :isQuestion="comment.isQuestion"
          :isSpam="comment.isSpam"
          :polarity="comment.polarity"
          :polarityStrings="
            comment.polarityStringsGpt
              ? comment.polarityStringsGpt
              : comment.polarityStrings
          "
          :commentImgs="comment.imageUrl ? comment.imageUrl : ''"
          :isLiked="comment.liked"
          :activeAccountDetails="activeAccountDetails"
          :likeCount="comment.numOfLikes"
          :authorId="comment.authorId"
          :date="comment.createdTime"
          class="comment-item"
          @click.native="
            goToDetails(
              comment.contentType,
              comment.originalUserId,
              comment.postId,
              comment.commentId
            )
          "
        ></comment>

        <!-- <div v-for="comment in results" :key="comment.id">
          <component
            :is="setComponent(comment.contentType)"
            :id="comment.id"
            :commentId="comment.id"
            :name="comment.authorName"
            :profilePic="
              profileImg(
                comment.profilePic
                  ? comment.profilePic
                  : comment.authorProfilePicUrl,
                comment.authorId
              )
            "
            :content="comment.content"
            :countOfReplies="comment.numOfReplies"
            :isHidden="comment.isHidden"
            :isQuestion="comment.isQuestion"
            :isSpam="comment.isSpam"
            :polarity="comment.polarity"
            :polarityStrings="comment.polarityStrings"
            :imageUrl="null"
            :isLiked="comment.isLiked"
            :activeAccountDetails="activeAccountDetails"
            :likeCount="comment.numOfLikes"
            :date="comment.createdTime"
            class="comment-item"
            :class="{ active: comment.id == selectedComment.id }"
            :type="comment.contentType"
            :pageId="comment.originalUserId"
            :authorId="comment.authorId"
            :accountName="getAccountName(comment.originalUserId)"
            :postImageUrl="comment.postImageUrl"
            :comment="comment"
            @click.native="
              goToDetails(
                comment.contentType,
                comment.originalUserId,
                comment.postId,
                comment.commentId
              )
            "
          ></component>
          <message v-if="false"></message>
          
        </div> -->
      </div>

      <!-- add Not selected class if not selected element  -->
    </div>
    <!-- Comments End -->
    <!-- Mobile Status Filter Start -->
    <div class="mobile-ai-filter" :class="{ show: mobileAiFilter }">
      <div class="close-trigger" @click="filtersDone"></div>
      <div class="wrap">
        <div class="head">
          <div class="row">
            <div class="col">
              <span class="h5">Filter</span>
            </div>
            <div class="col text-right done" @click="filtersDone">Done</div>
          </div>
        </div>
        <div class="body">
          <div class="row" @click="setFilter('positive')">
            <div class="col-8">
              <status :status="'positive'"></status>Positive
              <small>( {{ polarityCounts.positive }} )</small>
            </div>
            <div class="col text-right">
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/checked.svg"
                v-if="selectedFilter.includes('positive')"
              />
            </div>
          </div>
          <div class="row" @click="setFilter('neutral')">
            <div class="col-8">
              <status :status="'neutral'"></status>Neutral
              <small>( {{ polarityCounts.neutral }} )</small>
            </div>
            <div class="col text-right">
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/checked.svg"
                v-if="selectedFilter.includes('neutral')"
              />
            </div>
          </div>
          <div class="row" @click="setFilter('negative')">
            <div class="col-8">
              <status :status="'negative'"></status>Negative
              <small>( {{ polarityCounts.negative }} )</small>
            </div>
            <div class="col text-right">
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/checked.svg"
                v-if="selectedFilter.includes('negative')"
              />
            </div>
          </div>

          <div class="row" @click="setFilter('hidden')">
            <div class="col-8">
              <status :status="'hidden'"></status>Hidden
              <small>( {{ polarityCounts.hidden }} )</small>
            </div>
            <div class="col text-right">
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/checked.svg"
                v-if="selectedFilter.includes('hidden')"
              />
            </div>
          </div>
          <div class="row" @click="setFilter('question')">
            <div class="col-8">
              <status :status="'question'"></status>Question
              <small>( {{ polarityCounts.question }} )</small>
            </div>
            <div class="col text-right">
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/checked.svg"
                v-if="selectedFilter.includes('question')"
              />
            </div>
          </div>
          <div class="row" @click="setFilter('spam')">
            <div class="col-8">
              <status :status="'spam'"></status>Spam/Ad Content
              <small>( {{ polarityCounts.spam }} )</small>
            </div>
            <div class="col text-right">
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/checked.svg"
                v-if="selectedFilter.includes('spam')"
              />
            </div>
          </div>
          <div class="row" @click="setFilter('notassigned')">
            <div class="col-8">
              <status></status>Not Assigned
              <small>( {{ polarityCounts.notassigned }} )</small>
            </div>
            <div class="col text-right">
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/checked.svg"
                v-if="selectedFilter.includes('notassigned')"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Mobile Status Filter End -->
    <!-- Mobile Emotion Filter Start -->
    <div class="mobile-ai-filter" :class="{ show: mobileEmotionFilter }">
      <div class="close-trigger" @click="filtersDone"></div>
      <div class="wrap">
        <div class="head">
          <div class="row">
            <div class="col">
              <span class="h5">Filter</span>
            </div>
            <div class="col text-right done" @click="filtersDone">Done</div>
          </div>
        </div>
        <div class="body">
          <div
            class="row"
            v-for="(emotion, index) in feelings.slice(1)"
            @click="setEmotion(emotion)"
            :key="index"
          >
            <div class="col-9">
              <component
                :is="'profanity'"
                v-if="emotion.toLowerCase() == 'profanity'"
              ></component>
              <component
                :is="'hate'"
                v-if="emotion.toLowerCase() == 'hate speech'"
              ></component>

              {{ emotion }}
              <small v-if="emotion.toLowerCase() != 'all'"
                >(
                {{
                  emotion.toLowerCase() == "profanity"
                    ? offensiveCounts.profanity
                    : offensiveCounts.hate
                }}
                )
              </small>
            </div>
            <div class="col text-right">
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/checked.svg"
                v-if="selectedEmotion.includes(emotion)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Mobile Emotion Filter End -->
  </div>
</template>
<script>
/*eslint-disable */
import { HTTP } from "@/main-source";
import EventBus from "@/components/event-bus";
import comment from "@/components/facebook/comment.vue";
import message from "@/components/search/message.vue";
import commentHolder from "@/components/placeholders/comment.vue";
import router from "@/router";
import { mapGetters } from "vuex";
import rangeFilter from "@/components/filters/range";
import InputTag from "vue-input-tag";
import modal from "@/components/modal";
import { mapActions } from "vuex";

// Emotions - Will be improved
import hate from "@/components/icons/offensive/hate";
import offensive from "@/components/icons/offensive/offensive";
import profanity from "@/components/icons/offensive/profanity";

export default {
  name: "search",
  data() {
    return {
      results: [],
      subcomments: [],
      selectedComment: {
        id: null,
      },
      filters: [
        "All Results",
        "Positive",
        "Neutral",
        "Negative",
        "Hidden",
        "Question",
        "Not Assigned",
      ],
      selectedEmotion: ["All"],
      selectedFilter: ["All Results"],
      socialMediaAccounts: [],
      selectedCommentPost: null,
      isShowSubComments: false,
      mobileAiFilter: false,
      mobileEmotionFilter: false,
      commentPost: [],
      page: 0,
      date: JSON.parse(localStorage.getItem("selectedDate")) || null,
      start: null,
      finish: null,
      order: "DESC",
      search: [],
      changeData: false,
      range: localStorage.getItem("selectedRange") || "ALL",
      view: null,
      mobileSearchActive: false,
      polarityCounts: {
        totalComments: null,
      },
      pocket: {
        polarityStrings: null,
        isHidden: null,
        isQuestion: null,
        isSpam: null,
      },
      facebookAccessToken: "",
      activeAccountDetails: [],
      show: false,
      isShowDetailsOnMobile: false,
      closeOnSelect: false,

      deleteModal: false,
      commentUniqueId: null,
      isDeletedCommentSub: false,
      q: this.$route.query.q,
      totalElements: "",
      feelings: ["All", "Hate Speech", "Profanity"],
      offensiveCounts: {
        hate: 0,
        prafanity: 0,
      },
    };
  },
  created() {
    this.getResults();
    this.setAccessToken();
  },
  components: {
    comment,
    message,
    "range-filter": rangeFilter,
    "comment-placeholder": commentHolder,
    InputTag,
    modal,
    hate,
    offensive,
    profanity,
  },
  methods: {
    ...mapActions(["setActiveSocialMedia", "setActiveSocialMediaAccount"]),
    goToDetails: function(type, pageId, postId, commentId) {
      this.setActiveSocialMedia(type.split("_")[0].toLowerCase());
      this.setActiveSocialMediaAccount(pageId);

      router.push({
        name: "comments",
        params: {
          socialmedia: type.split("_")[0],
          accountId: pageId,
        },
        query: {
          post: postId,
          comment: commentId,
        },
      });
    },

    close: function() {
      this.selectedCommentPost = null;
      this.selectedComment = {
        id: null,
      };
      this.subcomments = [];
      this.isShowDetailsOnMobile = false;
      this.endOfSubComments = false;
      // this.selectedAuthor = null;
      this.subPage = 0;
    },
    setComponent: function(val) {
      let component_name = "";
      switch (val) {
        case "facebook_conversation":
          component_name = "message";
          break;
        case "facebook_comment":
          component_name = "message";
          break;
        case "instagram_comment":
          component_name = "message";
          break;
        case "twitter_comment":
          component_name = "message";
          break;
        default:
          component_name = "message";
          break;
      }
      return component_name;
    },
    filtersDone: function() {
      this.mobileAiFilter = false;
      this.mobileEmotionFilter = false;
      EventBus.$emit("mobileAiFilter", false);
      this.comments = [];
      this.close();
      this.getResults();
    },
    getResults: function(next) {
      let filterText = "";

      this.selectedFilter.forEach((filter) => {
        if (filter != "All Results") {
          if (filterText != "") {
            filterText += ",";
          }
          filterText += filter.toLowerCase();
        }
      });

      if (next) {
        this.page++;
      }

      let uri =
        "social/master-search?text=" +
        this.q +
        "&page=" +
        this.page +
        "&order=" +
        this.order +
        "&orderby=createdTime";
      // Hate Speech Filter
      if (this.selectedEmotion.includes("Hate Speech")) {
        uri += "&emotion=hate";
      }

      // Profanity Filter
      if (this.selectedEmotion.includes("Profanity")) {
        uri += "&profanity=true";
      }
      if (this.range != "") {
        uri += "&range=" + this.range;
      }

      if (this.selectedFilter != "All Results") {
        uri += "&polarity=" + filterText;
      }

      if (this.start && this.finish) {
        uri +=
          "&page=" +
          this.page +
          "&start=" +
          this.start +
          "&finish=" +
          this.finish;
      }
      this.show = false;
      HTTP.get(uri)
        .then((result) => {
          //   if (filterText == "") {
          //     this.polarityCounts = result.data.polarityCounts;
          //     this.polarityCounts.totalComments = result.data.totalElements;
          //   }
          // this.polarityCounts = result.data.polarityCounts;
          this.changeData = !this.changeData;
          this.show = true;

          console.log(result.data.polarityCounts, "result.data.polarityCounts");

          this.totalElements = result.data.totalElements;
          result.data.content.forEach((comment) => this.results.push(comment));
          console.log(this.results);

          this.show = true;
        })
        .catch((e) => {
          if (e.response.status == 400) {
            this.show = true;
          }
        });
    },

    setRange: function(value) {
      this.range = value;
    },
    removeFilter: function(option) {
      this.selectedFilter = this.selectedFilter.filter(
        (filter) => filter != option.label
      );
    },
    removeFeeling: function(option) {
      const item = this.selectedEmotion.filter(
        (filter) => filter == option.label
      );

      this.selectedEmotion = this.selectedEmotion.filter(
        (filter) => filter != item[0]
      );

      if (this.selectedEmotion.length == 0) {
        this.selectedEmotion.push("All");
      }
    },
    scroll: function() {
      let commentListView = document.getElementById("comments");
      // let lastItem = document.querySelector("#comments .col-12:last-child");

      commentListView.onscroll = () => {
        let position = commentListView.scrollTop - 200;
        let end =
          commentListView.scrollHeight - commentListView.offsetHeight - 200;
        if (position == end) {
          this.getResults(true);
        }
      };
    },

    profileImg: function(img, authorId) {
      let imgUrl = img ? img : null;
      if (
        (this.getActiveSocialMedia == "facebook" && authorId) ||
        this.getActiveSocialMedia == "instagram"
      ) {
        imgUrl =
          "https://graph.facebook.com/" +
          authorId +
          "/picture?access_token=" +
          this.facebookAccessToken;
      }

      return imgUrl;
    },
    likeTrigger: function(id) {
      let data = {
        id: id,
        originalUserId: this.getActiveSocialMediaAccount,
      };
      HTTP.put("social/like", data).then((result) => {
        this.results.filter((comment) => comment.id == id)[0].isLiked = true;
        this.subcomments.filter(
          (comment) => comment.id == id
        )[0].isLiked = true;
        console.log(result.data);
      });
    },
    replyComment: function(id, reply) {
      let comment = this.results.filter((comment) => comment.id == id)[0];
      let data = {
        authorId: this.getActiveSocialMediaAccount,
        authorName: this.activeAccountDetails.accountName,
        content: reply,
        contentType: this.getActiveSocialMedia + "_comment",
        originalUserId: this.getActiveSocialMediaAccount,
        pageId: this.getActiveSocialMediaAccount, // eğer facebooksa -> accountId
        parentId: id,
        postId: comment.postId,
      };

      HTTP.post("social/create", data).then((result) => {
        console.log(result.data);
        comment.numOfReplies += 1;
        this.$notify({
          group: "info",
          // title: "Important message",
          text: "Your reply has been sent",
        });
      });
    },
    setAccessToken: function() {
      HTTP.get("user/me").then((result) => {
        let accountId = this.$route.params.accountId
          ? this.$route.params.accountId
          : this.getActiveSocialMediaAccount;

        this.socialMediaAccounts = result.data.socialMediaAccounts;

        this.activeAccountDetails = result.data.socialMediaAccounts.filter(
          (account) => account.accountId == accountId
        )[0];

        this.accounts = result.data.socialMediaAccounts;
        if (result.data.authorizedAccounts.length > 0) {
          result.data.authorizedAccounts.forEach((a) => {
            this.account.push(a);
          });
        }
      });
    },
    deleteComment: function() {
      let data = {
        id: this.commentUniqueId,
        originalUserId: this.getActiveSocialMediaAccount,
      };

      HTTP.delete("social", { data: data }).then((result) => {
        if (result.status == 200) {
          this.deleteModal = false;

          if (this.isDeletedCommentSub) {
            this.subcomments = this.subcomments.filter(
              (subcomment) => subcomment.id != this.commentUniqueId
            );
          } else {
            this.results = this.results.filter(
              (comment) => comment.id != this.commentUniqueId
            );
          }

          // this.getResults();
        }
      });
    },
    showFilter: function() {
      console.log("showFilter");

      this.mobileAiFilter = true;
      EventBus.$emit("mobileAiFilter", true);
    },
    showEmotionFilter: function() {
      console.log("showEmotionFilter", this.mobileEmotionFilter);
      this.mobileEmotionFilter = true;
      console.log("showEmotionFilter", this.mobileEmotionFilter);

      EventBus.$emit("mobileAiFilter", true);
    },
    getAccountName: function(id) {
      return (
        this.accounts.filter((a) => a.accountId == id)[0].accountName || null
      );
    },
    setFilter: function(option) {
      if (this.selectedFilter.includes(option)) {
        this.selectedFilter = this.selectedFilter.filter(
          (filter) => filter != option
        );
      } else {
        this.selectedFilter.push(option);
      }
    },
    setEmotion: function(option) {
      if (this.selectedEmotion.includes(option)) {
        this.selectedEmotion = this.selectedEmotion.filter(
          (filter) => filter != option
        );
      } else {
        this.selectedEmotion.push(option);
      }
    },
    /*eslint-disable*/
    mobileAndTabletCheck: function() {
      let check = false;
      (function(a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    },
    /*eslint-disable*/
    showDetailsOnMobile: function() {
      if (this.mobileAndTabletCheck()) {
        this.isShowDetailsOnMobile = true;
      }
    },
  },
  mounted() {
    this.scroll();

    EventBus.$on("getResults", (commentId) => {
      this.selectComment(commentId, "post");
      this.showDetailsOnMobile();
    });

    EventBus.$on("likeTrigger", (id) => {
      this.results;
      this.likeTrigger(id);
    });

    EventBus.$on("setRange", (range) => {
      this.setRange(range);
    });
    EventBus.$on("deleteComment", (id, isSub) => {
      this.deleteModal = true;
      this.commentUniqueId = id;
      this.isDeletedCommentSub = isSub;
    });
    EventBus.$on("replyTrigger", (id, text) => {
      this.replyComment(id, text);
    });
  },
  beforeDestroy: function() {
    EventBus.$off("replyTrigger");
  },
  destroyed: function() {
    EventBus.$emit("clearSearchInput", true);
  },
  computed: {
    ...mapGetters(["getActiveSocialMedia", "getActiveSocialMediaAccount"]),
    selectedFilterCount: function() {
      return this.selectedFilter.length;
    },
    selectedEmotionCount: function() {
      return this.selectedEmotion.length;
    },
  },

  watch: {
    search: function() {
      let searchQuery = "";
      let i = 0;
      this.search.forEach((q) => {
        if (i != 0) {
          searchQuery += ",";
        }
        searchQuery += q;
        i++;
      });
      HTTP.get(
        "social/" +
          this.getActiveSocialMediaAccount +
          "/" +
          this.getActiveSocialMedia +
          "/comment/?text=" +
          searchQuery +
          "&range=" +
          this.range
      ).then((result) => {
        this.results = [];
        result.data.content.forEach((comment) => this.results.push(comment));
      });

      if (this.search.length == 0) {
        this.results = [];
        this.getResults();
      }
    },
    range: function() {
      this.results = [];
      this.getResults();
    },
    order: function() {
      this.results = [];
      this.getResults();
    },
    selectedFilter: function() {
      this.page = 0;
      this.results = [];
      this.close();
      this.getResults();
    },

    selectedEmotion: function() {
      this.page = 0;
      this.results = [];
      this.close();
      this.getResults();
    },
    date: {
      deep: true,
      handler: function() {
        this.start = this.date[0].getTime();
        this.finish = this.date[1].getTime();
        this.range = "";
        this.results = [];
        this.page = 0;
        this.getResults();
      },
    },
    "$route.query.q": {
      deep: true,
      handler(val) {
        // console.log(
        //   "🚀 ~ file: Search.vue ~ line 481 ~ handler ~ this.$route.query.params.socialmedia",
        //   this.$route.query.params.socialmedia
        // );
        // if (this.$route.query.params.socialmedia) {
        //   this.q = val;
        //   this.page = 0;
        //   this.results = [];
        //   this.getResults();
        // }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-filter {
  margin-bottom: 15px !important;
}
</style>
