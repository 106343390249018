<template>
  <div class="chart-card">
    <div class="chart-header">
      <span class="count"> {{ sumValues == 0 ? "~" : abbreviate(sumValues) }} </span>
      <span class="sub-title">{{ metric.title }}</span>
      <!-- <span class="d-none chart-desc">
        <status></status> %20 Toplam Sayfa Görüntülenmeleri
      </span> -->
    </div>

    <bar-chart :graph="graph" class="Bar-chart" :options="options" :height="180" :class="{ transparented: !graph }">
    </bar-chart>
  </div>
</template>
<script>
import BarChart from "@/components/charts/components/Bar";
import statusTriangle from "@/components/icons/triangle";
import abbreviate from "number-abbreviate";

export default {
  name: "Bar",
  props: ["metric"],
  components: {
    "bar-chart": BarChart,
    status: statusTriangle,
  },
  data() {
    return {
      graph: [],
      sumValues: 0,
      options: {
        showAllTooltips: false,
        responsive: true,
        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                // callback: (value) => numeral(value).format("$0,0"),
              },
            },

          ],

        },
      },
    };
  },

  methods: {
    setData() {
      let keys = this.metric.insightData.map(item => item.key);
      let uniqueKeys = [...new Set(keys)];
      let sumValues = [];
      console.log("🚀 ~ file: Bar.vue ~ line 57 ~ setData ~ uniqueKeys", uniqueKeys)

      uniqueKeys.forEach(key => {
        let sum = this.metric.insightData.filter(item => item.key == key)
          .map(item => item.value)
          .reduce((a, b) => a + b, 0);
        sumValues.push(sum)
        this.sumValues += sum;
      });

      this.graph = { labels: uniqueKeys, values: sumValues };


    },
    setSum: function () {
      let sum = 0;

      if (this.metric.insightData) {
        this.metric.insightData.forEach((d) => {
          sum += d.value;
        });
      }
      this.sumValues = this.$intToString(sum);
    },
    abbreviate(value) {
      return value < 10000
        ? this.$intlNumber(value)
        : `${abbreviate(value)}`.toUpperCase();
    },
  },
  watch: {
    metric: {
      deep: true,
      handler: function () {
        localStorage.setItem("selectedDate", JSON.stringify(this.date));
        this.setData();
      },
    },
  },
};
</script>
