<template>
  <div>
    <div class="container login p-0">
      <span class="h1">Password Reset</span>
      <div v-if="result ? result.status == 200 : false">
        <span class="valid">Password successfuly changed</span>

        <router-link :to="{
          name: 'login',
        }" class="btn btn-primary btn-block">
          Login
        </router-link>
      </div>
      <div class="form" v-if="result ? result.status != 200 && result.status != 500 : true">
        <label>Please type your new password.</label>
        <div class="form-group">
          <input type="password" @blur="$v.password.$touch()" v-model="password" class="form-control"
            :class="{ 'invalid-b': $v.password.$error }" placeholder="New Password" />
          <div v-if="$v.password.$error">
            <span class="invalid" v-if="$v.password.$anyError">
              <span class="invalid" v-if="!$v.password.required">Password required</span>
              <span class="invalid" v-if="!$v.password.minLength">Password min 5 charaters</span>
              <span class="invalid" v-if="!$v.password.maxLength">Password max 20 charaters</span>
            </span>
          </div>
        </div>

        <div class="form-group">
          <input type="password" @blur="$v.passwordCheck.$touch()" v-model="passwordCheck" class="form-control"
            :class="{ 'invalid-b': $v.passwordCheck.$error }" placeholder="New Password Again"
            @keyup.enter="resetPassword" />
          <div v-if="$v.passwordCheck.$anyError">
            <span class="invalid" v-if="!$v.passwordCheck.sameAs">Passwords doesn't match</span>
            <span class="invalid" v-if="!$v.passwordCheck.required">Password required</span>
          </div>
        </div>

        <span class="valid" v-if="result ? result.status == 200 : false">Password successfuly changed</span>
        <span class="invalid" v-if="result ? result.status == 500 : false">Server error. Please try again later.</span>
        <span class="invalid" v-if="result ? result.status == 404 : false">User not found</span>
        <span class="invalid" v-if="result ? result.status == 400 : false">Password reset token already has been
          used.</span>
        <button class="btn btn-primary btn-block" @click="resetPassword">
          Reset Password <Loading :white="true" v-if="isLoading"></Loading>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import EventBus from "@/components/event-bus";
import {
  required,
  minLength,
  maxLength,
  sameAs,
} from "vuelidate/lib/validators";
import Loading from "@/components/Loading";

export default {
  name: "RenewPassword",
  props: ["isLoading", "result"],
  data() {
    return {
      title: "Forgot",
      password: null,
      passwordCheck: null,
    };
  },
  validations: {
    password: {
      required,
      minLength: minLength(5),
      maxLength: maxLength(20),
      // containsUppercase: function (value) {
      //   return /[A-Z]/.test(value);
      // },
      // containsLowercase: function (value) {
      //   return /[a-z]/.test(value);
      // },
      // containsNumber: function (value) {
      //   return /[0-9]/.test(value);
      // },
      // containsSpecial: function (value) {
      //   return /[#?!@$%^&*-]/.test(value);
      // },
    },
    passwordCheck: {
      required,
      sameAs: sameAs("password"),
    },
  },
  components: {
    Loading,
  },
  methods: {
    resetPassword: function () {
      this.$v.$touch();
      if (!this.$v.password.$error && !this.$v.passwordCheck.$error) {
        let data = {
          password: this.password,
          token: this.$route.query.token,
        };
        EventBus.$emit("renewpassword", data);
      }
    },
  },
};
</script>
<style lang="scss">
// @import "../../scss/colors.scss";
// .login {
//   text-align: center;
//   .h1 {
//     font-size: 27px;
//     font-weight: 900;
//     letter-spacing: 2px;
//     text-transform: uppercase;
//     display: block;
//     font-family: "Fenomen Sans";
//   }
//   .form {
//     margin-top: 30px;
//     input.underline {
//       background: transparent;
//       border: 0;
//       border-radius: 0;
//       border-bottom: 1px solid darken($white, 20%);
//       transition: all 0.2s ease;
//       &:focus {
//         box-shadow: none;
//       }
//     }
//   }
//   .form-group {
//     text-align: left;
//   }
//   .social {
//     margin: 0;
//     .col {
//       padding: 0;
//     }
//   }
//   .btn-primary {
//     margin-top: 20px;
//     transition: all 0.2s ease;
//     &:hover {
//       transform: scale(1.01);
//       box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05) !important;
//     }
//   }
//   .facebook {
//     background: #3b5998;
//     font-size: 12px;
//     width: calc(100% - 10px);
//   }
//   .google {
//     background: #db4437;
//     font-size: 12px;
//     float: right;
//     width: calc(100% - 10px);
//   }
//   .error {
//     border-color: $brand-red !important;
//   }
//   a {
//     // color: $black;
//   }
//   .btn.btn-primary.btn-block {
//     color: $white;
//   }
// }
a.btn.btn-primary.btn-block {
  color: #fff;
}
</style>
