<template>
  <div>
    <div class="col-12 wrap">
      <div class="close" @click="close">
        <close :dark="true"></close>
      </div>
      <div class="col p-0">
        <img @error="(e) => (e.target.src = $defaultCardAvatar)" class="profile" :src="activeAccountPhoto" alt="Profile" />

        <div class="info">
          <span class="name">{{ account.accountName }}</span>
          <span class="date">
            <heart
              :isLiked="parseInt(post.data.numOfLikes) > 0 ? true : false"
            ></heart>
            {{ intToString(post.data.numOfLikes) }} -
            {{ timeSince(post.data.createdTime) }}
          </span>
        </div>
      </div>

      <div class="text col-12">{{ postContent }}</div>

      <div class="row">
        <div style="width: 100%">
          <div
            id="socialays-carousel"
            class="carousel slide"
            data-ride="carousel"
            v-if="post.data.videoUrl == null"
          >
            <img @error="(e) => (e.target.src = $defaultCardAvatar)"
              class="media-loop"
              src="@/assets/images/multi.svg"
              alt="Carousel"
              v-if="imageCount > 1"
            />

            <ol class="carousel-indicators" v-if="imageCount != 1">
              <li
                data-target="#socialays-carousel"
                v-for="n in imageCount"
                :data-slide-to="n - 1"
                :key="n"
              ></li>
            </ol>

            <div class="carousel-inner media">
              <div class="carousel-item active">
                <img @error="(e) => (e.target.src = $defaultCardAvatar)"
                  :src="firstImg"
                  alt
                  v-if="post.data.imageUrl && post.data.videoUrl == null"
                />
              </div>
              <template v-if="imageCount > 1 && post.data.videoUrl == null">
                <div
                  class="carousel-item"
                  v-for="(img, index) in mediaData"
                  :key="index"
                >
                  <img @error="(e) => (e.target.src = $defaultCardAvatar)"
                    :src="img.url"
                    alt
                    v-if="img.type.toLowerCase() == 'image'"
                  />
                  <vue-plyr
                    v-if="
                      videoSource != null &&
                      getActiveSocialMedia != 'youtube' &&
                      img.type == 'video'
                    "
                    class="video"
                  >
                    <video :src="img.url" :poster="post.data.imageUrl">
                      <source :src="img.url" type="video/mp4" />
                    </video>
                  </vue-plyr>
                </div>
              </template>
            </div>
          </div>

          <vue-plyr
            v-if="videoSource != null && getActiveSocialMedia != 'youtube'"
            class="video"
          >
            <video :src="videoSource" :poster="post.data.imageUrl">
              <source :src="videoSource" type="video/mp4" />
            </video>
          </vue-plyr>

          <vue-plyr v-if="getActiveSocialMedia == 'youtube'">
            <div class="plyr__video-embed">
              <iframe
                width="560"
                height="315"
                :src="post.data.videoUrl[0]"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </vue-plyr>
        </div>

        <div class="col comments-total">
          <img @error="(e) => (e.target.src = $defaultCardAvatar)" src="@/assets/images/comment.svg" />
          Comments ({{ intToString(post.totalElements) }})
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from "@/main-source";
import { mapGetters } from "vuex";
import close from "@/components/close";
import heart from "@/components/icons/heart";
import status from "@/components/dot";

export default {
  name: "Right-Post-View",
  props: ["post", "account", "profilePic"],
  data() {
    return {
      activeAccountPhoto: "/images/no-image.jpg",
      firstImg: "/images/no-image.jpg",
      videoSource: null,
      mediaData: [],
    };
  },
  components: {
    close,
    heart,
  },
  methods: {
    intToString: function (value) {
      return this.$intToString(value);
    },
    close: function () {
      this.$emit("close");
    },

    timeSince: function (timeStamp) {
      return this.$timeSince(timeStamp);
    },
  },
  computed: {
    ...mapGetters(["getActiveSocialMedia", "getActiveSocialMediaAccount"]),

    imageCount: function () {
      return this.mediaData.length;
    },
    postContent: function () {
      if (this.post.data.content != "undefined") {
        return this.post.data.content;
      }
      return "";
    },
  },
  mounted() {
    let result = this.post.data.imageUrl ? this.post.data.imageUrl[0] : null;
    if (
      this.getActiveSocialMedia == "instagram" ||
      this.getActiveSocialMedia == "facebook"
    ) {
      HTTP.get(
        "/media/" +
          this.getActiveSocialMedia +
          "?accountid=" +
          this.getActiveSocialMediaAccount +
          "&uniqueid=" +
          this.post.data.id
      )
        .then((xhrResult) => {
          if (xhrResult.status == 200) {
            this.mediaData = xhrResult.data;

            result = xhrResult.data[0].url;
            if (xhrResult.data[0].type == "VIDEO") {
              this.videoSource = xhrResult.data[0].url;
            }
          }
        })
        .then(() => {
          this.firstImg = result;
        });
    }
    // Profile Photo
    setTimeout(() => {
      if (this.getActiveSocialMedia == "facebook") {
        HTTP.get(
          "/picture/" +
            this.getActiveSocialMedia +
            "?accountid=" +
            this.account.accountId +
            "&authorId=" +
            this.account.accountId
        ).then((result) => {
          if (result.status == 200) {
            this.activeAccountPhoto = result.data.url;
          }
        });
      } else {
        this.activeAccountPhoto = this.account.imageUrl;
      }
    }, 500);
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/colors.scss";
.wrap {
  border-radius: 30px;
  background: $white;
  margin-bottom: 20px;
  padding: 15px 15px 0px 15px;
  transition: all 0.25s ease;
  overflow: hidden;

  .profile {
    width: 45px;
    height: 45px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    float: left;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .name {
    font-weight: bold;
    display: block;
  }
  .date {
    font-size: 12px;
    display: block;
    margin-top: -2px;
    color: $light-gray;
  }
  .text {
    padding: 0;
    clear: both;
    margin: 10px 0px 0px;
    min-height: 15px;
  }
  .media {
    margin: 10px 0px;
    img {
      width: 100%;
      // height: 320px;
      margin: auto;
      object-fit: cover;
      object-position: center;
    }
  }
  .status {
    .dot {
      margin-bottom: -5px;
      margin-right: 2px;
      transform: scale(0.8);
    }
  }

  .positive {
    border-bottom-color: $positive !important;
  }
  .neutral {
    border-bottom-color: $neatral !important;
  }
  .negative {
    border-bottom-color: $negative !important;
  }
  .question {
    border-bottom-color: $question !important;
  }
  .hidden {
    border-bottom-color: $hidden !important;
  }
  .notassigned {
    border-bottom-color: $notassigned !important;
  }
}

.has-selected {
  .status {
    opacity: 0.4;
  }
  .active {
    opacity: 1 !important;
    color: $black;
  }
}
.close {
  color: lighten($black, 30%);
  position: absolute;
  right: 15px;
  z-index: 99;
  cursor: pointer;
}
.sub-filters {
  margin-bottom: 10px;
  padding: 0px 10px;
}
.imgs {
  width: 100%;
  clear: both;
  position: relative;
  margin: 15px auto 10px auto;
  img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    object-position: center;
    margin-right: 10px;
    margin-bottom: 10px;
  }
}
.video {
  width: 100%;
  height: auto;
  max-height: 60vh;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
  margin: 10px auto;
}
.plyr {
  margin: 10px 0px !important;
  audio,
  video {
    max-height: 60vh;
  }
}
.carousel {
  width: 100%;
  position: relative;
  .media-loop {
    position: absolute;
    width: 15px;
    height: 15px;
    right: 12px;
    top: 12px;
    z-index: 999;
  }
}
.comments-total {
  margin-bottom: 10px;
}
</style>
