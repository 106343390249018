<template>
  <svg
    id="_003-laugh"
    data-name="003-laugh"
    xmlns="http://www.w3.org/2000/svg"
    width="16.001"
    height="16"
    viewBox="0 0 16.001 16"
  >
    <path
      id="Path_706"
      data-name="Path 706"
      d="M113.862,138.94a.469.469,0,0,0,.469-.469.676.676,0,1,1,1.353,0,.469.469,0,1,0,.938,0,1.614,1.614,0,1,0-3.228,0A.469.469,0,0,0,113.862,138.94Z"
      transform="translate(-109.849 -132.58)"
      fill="#5a5a5a"
    />
    <path
      id="Path_707"
      data-name="Path 707"
      d="M294.19,138.94a.469.469,0,0,0,.469-.469.676.676,0,1,1,1.353,0,.469.469,0,0,0,.938,0,1.614,1.614,0,1,0-3.228,0A.469.469,0,0,0,294.19,138.94Z"
      transform="translate(-284.542 -132.58)"
      fill="#5a5a5a"
    />
    <path
      id="Path_708"
      data-name="Path 708"
      d="M16,9.487a1.851,1.851,0,0,0-.063-.477,7.768,7.768,0,0,0,.062-1A8,8,0,0,0,2.343,2.351,7.948,7.948,0,0,0,0,8.008a8.615,8.615,0,0,0,.066,1.02,1.842,1.842,0,0,0,.4,1.678A8.042,8.042,0,0,0,3.314,14.48,7.986,7.986,0,0,0,15.53,10.717,1.827,1.827,0,0,0,16,9.487Zm-14.793.638a.9.9,0,0,1,0-1.277.684.684,0,0,1,.374-.19L3.09,8.286,2.683,9.761a.74.74,0,0,1-.2.365.9.9,0,0,1-1.277,0Zm10.623.8H4.169a3.155,3.155,0,0,1-.387-1.089h8.437A3.155,3.155,0,0,1,11.832,10.93Zm-.78.938A4.658,4.658,0,0,1,8,12.957a4.658,4.658,0,0,1-3.051-1.089Zm1.865-3.582,1.508.373a.684.684,0,0,1,.374.19.9.9,0,1,1-1.277,1.277.74.74,0,0,1-.2-.365ZM3.006,3.014a7.062,7.062,0,0,1,12.055,4.88,1.925,1.925,0,0,0-.412-.145l-2.285-.565a.469.469,0,0,0-.564.58l.314,1.14H3.892l.315-1.14a.469.469,0,0,0-.564-.58l-2.285.565A1.914,1.914,0,0,0,.939,7.9,7.014,7.014,0,0,1,3.006,3.014ZM8,15.071a6.987,6.987,0,0,1-4.135-1.349,7.129,7.129,0,0,1-2.1-2.4l.078,0a1.835,1.835,0,0,0,1.241-.481,4.466,4.466,0,0,0,1.267,1.749A5.581,5.581,0,0,0,8,13.895a5.581,5.581,0,0,0,3.645-1.3,4.466,4.466,0,0,0,1.269-1.753,1.835,1.835,0,0,0,1.246.486l.079,0A7.064,7.064,0,0,1,8,15.071Z"
      transform="translate(0 -0.008)"
      fill="#5a5a5a"
    />
  </svg>
</template>

<script>
export default {
  name: "fun"
};
</script>
