<template>
  <div class="col-12 wrap main"> 
    <div class="close" @click="close">
      <close :dark="true"></close>
    </div>
    <comment
      :id="selectedComment.id"
      :selectedCommentId="selectedComment.selectedCommentId"
      :name="selectedComment.authorName"
      :location="selectedComment.locations"
      :content="selectedComment.content"
      :countOfReplies="selectedComment.numOfReplies"
      :isHidden="selectedComment.isHidden"
      :isQuestion="selectedComment.isQuestion"
      :isSpam="selectedComment.isSpam"
      :polarity="selectedComment.polarity"
      :polarityStrings="selectedComment.polarityStrings"
      :commentImgs="selectedComment.imageUrl"
      :isLiked="activeAccountDetails.isLiked"
      :profilePic="activeAccountDetails.imageUrl"
      :activeAccountDetails="activeAccountDetails"
      :likeCount="selectedComment.numOfLikes"
      :date="selectedComment.createdTime"
      :authorId="selectedComment.authorId"
      :comment="selectedComment"
      :isReplyView="true"
      class="no-transform right-comment-view"
    ></comment>
  </div>
</template>

<script>
import comment from "@/components/facebook/comment.vue";
import close from "@/components/close";

export default {
  name: "Right-Comment-View",
  props: ["selectedComment", "activeAccountDetails", "profilePic"],
  data() {
    return {
      selectedFilter: null
    };
  },
  components: {
    comment,
    close
  },
  methods: {
    filterSubComments: function(filter) {
      // console.log(filter);
      if (this.selectedFilter == filter) {
        filter = null;
      }
      this.selectedFilter = filter;
      this.$emit("filterSubComment", filter);
    },
    close: function() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/colors.scss";
.wrap {
  border-radius: 30px;
  padding: 0;
  margin-bottom: 20px;
  transition: all 0.25s ease;
  overflow: visible !important;
  background: transparent;
  box-shadow: none;
  .profile {
    width: 45px;
    height: 45px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    float: left;
    margin-right: 10px;
  }
  .name {
    font-weight: bold;
    display: block;
  }

  .date {
    font-size: 12px;
    display: block;
    margin-top: -2px;
  }
  .text {
    padding: 0;
    clear: both;
    margin: 10px 0px;
  }
  .media {
    margin: 10px 0px;
    img {
      margin: auto;
    }
  }
  .status {
    .dot {
      margin-bottom: -5px;
      margin-right: 2px;
      transform: scale(0.8);
    }
  }
  .statuses {
    display: flex;
    padding: 10px 0px 0px;
    font-size: 14px;
    margin: 5px -15px 0px -15px;
    justify-content: space-around;
    border-top: 1px solid lighten($black, 95%);
    .status {
      position: relative;
      flex: 1;
      padding-bottom: 7px;
      border-right: 1px solid lighten($black, 95%);
      border-bottom: 7px solid;
      text-align: center;
      cursor: pointer;
      &:last-child {
        border-right: 0px;
      }
    }
  }
  .positive {
    border-bottom-color: $positive !important;
  }
  .neutral {
    border-bottom-color: $neatral !important;
  }
  .negative {
    border-bottom-color: $negative !important;
  }
  .question {
    border-bottom-color: $question !important;
  }
  .hidden {
    border-bottom-color: $hidden !important;
  }
  .notassigned {
    border-bottom-color: $notassigned !important;
  }
}
.has-selected {
  .status {
    opacity: 0.2;
  }
  .active {
    opacity: 1 !important;
  }
}
.close {
  color: lighten($black, 30%);
  position: absolute;
  right: 20px;
  top: 10px;
  z-index: 99;
  cursor: pointer;
}

</style>
