var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"setting account"},[_c('div',{staticClass:"head"},[_c('img',{attrs:{"src":require("@/assets/images/settings/security.svg"),"alt":"Email Settings"},on:{"error":(e) => (e.target.src = _vm.$defaultCardAvatar)}}),_c('span',[_vm._v("Password")])]),_c('div',{staticClass:"body"},[_c('div',{staticClass:"form"},[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pass.oldPassword),expression:"pass.oldPassword"}],staticClass:"form-control underline",attrs:{"type":"password","placeholder":"Old Password","autocomplete":"new-password"},domProps:{"value":(_vm.pass.oldPassword)},on:{"blur":function($event){return _vm.$v.pass.oldPassword.$touch()},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pass, "oldPassword", $event.target.value)}}}),(_vm.$v.pass.oldPassword.$error)?_c('div',[(!_vm.$v.pass.oldPassword.required)?_c('span',{staticClass:"invalid"},[_vm._v("Password required")]):_vm._e(),(
              !_vm.$v.pass.oldPassword.minLength || !_vm.$v.pass.oldPassword.maxLength
            )?_c('span',{staticClass:"invalid"},[_vm._v("Password must be within min 4 - max 20 character length")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pass.newPassword),expression:"pass.newPassword"}],staticClass:"form-control underline",attrs:{"type":"password","placeholder":"New Password"},domProps:{"value":(_vm.pass.newPassword)},on:{"blur":function($event){return _vm.$v.pass.newPassword.$touch()},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.changePassword.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pass, "newPassword", $event.target.value)}}}),(_vm.$v.pass.newPassword.$error)?_c('div',[(!_vm.$v.pass.newPassword.required)?_c('span',{staticClass:"invalid"},[_vm._v("Password required")]):_vm._e(),(
              !_vm.$v.pass.newPassword.minLength || !_vm.$v.pass.newPassword.maxLength
            )?_c('span',{staticClass:"invalid"},[_vm._v("Password must be within min 4 - max 20 character length")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pass.newPasswordCheck),expression:"pass.newPasswordCheck"}],staticClass:"form-control underline",attrs:{"type":"password","placeholder":"Confirm New Password"},domProps:{"value":(_vm.pass.newPasswordCheck)},on:{"blur":function($event){return _vm.$v.pass.newPasswordCheck.$touch()},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.changePassword.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pass, "newPasswordCheck", $event.target.value)}}}),(_vm.$v.pass.newPasswordCheck.$error)?_c('div',[(!_vm.$v.pass.newPasswordCheck.required)?_c('span',{staticClass:"invalid"},[_vm._v("Password required")]):_vm._e(),(
              !_vm.$v.pass.newPasswordCheck.minLength ||
                !_vm.$v.pass.newPasswordCheck.maxLength
            )?_c('span',{staticClass:"invalid"},[_vm._v("Password must be within min 4 - max 20 character length")]):_vm._e()]):_vm._e()]),(_vm.$v.pass.newPassword.$error || _vm.$v.pass.newPasswordCheck.$error)?_c('div',[(!_vm.$v.pass.newPasswordCheck.sameAs)?_c('div',{staticClass:"col"},[_c('span',{staticClass:"invalid"},[_vm._v("Passwords doesn't match")])]):_vm._e()]):_vm._e(),_c('div',{staticClass:"btn-wrap"},[_c('button',{staticClass:"btn btn-primary",on:{"click":_vm.changePassword}},[_vm._v(" Change Password "),(_vm.isLoading)?_c('Loading',{attrs:{"white":true}}):_vm._e()],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }