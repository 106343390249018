<template>
  <div>
    <div class="policy">
      <p class="p1">
        <b>Contact</b>
      </p>
      Merdivenköy Mah. Dikyol Sokak, <br>Business Istanbul Plaza B Blok Kat:17 Kadıköy,
      <br />
      Istanbul/Turkey
      <br />
      <br />
      info@socialays.com
    </div>
    <div class="col authAction">
      <router-link
        :to="{
          name: 'login'
        }"
        >Login</router-link
      >&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
      <router-link
        :to="{
          name: 'signup'
        }"
        >Sign Up</router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "contact"
};
</script>