<script>
import { Bar, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  name: "Bar",
  extends: Bar,
  mixins: [reactiveProp],
  props: ["graph", "options"],
  mounted() {
    this.setChart();
  },
  methods: {
    setChart() {
      this.renderChart(
        {
          labels: this.graph.labels,
          datasets: [
            {
              data: this.graph.values,
              // borderColor: ["rgba(2, 150, 60, .1)"],
              backgroundColor: [
                "rgba(0, 161, 237, .7)",
                "rgba(165, 76, 255, .7)",
                "rgba(162, 162, 162, .7)",
                "rgba(162, 162, 162, .7)",
                "rgba(229, 238, 243, .7)",
                "rgba(237, 199, 162, .7)",
                "rgba(255, 216, 0, .7)",
                "rgba(255, 0, 0, .7)",
              ],
            },
          ],
        },
        this.options
      );
    }
  },
  watch: {
    graph: function () {
      this.setChart();
    }
  }
};
</script>
