<template>
  <div class="setting account">
    <div class="head">
      <img @error="(e) => (e.target.src = $defaultCardAvatar)" src="@/assets/images/settings/invitations.svg" alt="Invitations" />
      <span class="notify-dot right" v-if="invitations.length > 0">{{
        invitations.length
      }}</span>
      <span>Invitations</span>
    </div>
    <div
      class="body invitations"
      :class="{ 'no-data': invitations.length == 0 }"
    >
      <span v-if="invitations.length == 0"> No invitations </span>
      <div
        class="social-account"
        v-for="(invitation, index) in invitations"
        :key="index"
      >
        <div class="img">
          <img @error="(e) => (e.target.src = $defaultCardAvatar)"
            :src="profileImg(invitation.invitor.profilePic)"
            class="img-fluid"
            v-if="invitation.invitor.profilePic"
          />
          <img @error="(e) => (e.target.src = $defaultCardAvatar)"
            class="img-fluid"
            src="@/assets/images/profile.png"
            alt="Invitor Profile Photo"
            v-if="!invitation.invitor.profilePic"
          />
        </div>
        <div class="info col-9 pc-5">
          <span class="name">{{ invitation.invitor.name }}</span>
          <span class="slug">{{ invitation.invitor.email }}</span>
        </div>
        <img @error="(e) => (e.target.src = $defaultCardAvatar)"
          src="@/assets/images/accept.svg"
          class="accept"
          alt="Accept Invitations"
          v-if="!isLoading"
          @click="reply(invitation.invitee.id, true)"
        />
        <img @error="(e) => (e.target.src = $defaultCardAvatar)"
          src="@/assets/images/comments/delete.svg"
          class="denied"
          alt="Reject"
          v-if="!isLoading"
          @click="reply(invitation.invitee.id, false)"
        />
        <Loading class="iLoading" v-if="isLoading"></Loading>
      </div>
    </div>
  </div>
</template>
<script>
import EventBus from "@/components/event-bus";
import Loading from "@/components/Loading";

export default {
  name: "Invitations",
  props: {
    invitations: { default: null },
    isLoading: {
      default: false
    }
  },
  components: {
    Loading
  },
  methods: {
    profileImg: function(img) {
      return img + "?token=" + localStorage.getItem("token");
    },
    reply: function(id, decision) {
      EventBus.$emit("invitation-reply", id, decision);
    }
  }
};
</script>

<style lang="scss">
@import "../../scss/colors.scss";
.invitations {
  .social-account {
    padding: 10px;
    border-radius: 30px;
    position: relative;
    &:hover {
      background: lighten($black, 97%);
    }
  }
  .denied {
    position: absolute;
    right: 25px;
    top: 27px;
    transition: all 0.2s ease;
    cursor: pointer;
  }
  .accept {
    position: absolute;
    right: 50px;
    top: 27px;
    transition: all 0.2s ease;
    cursor: pointer;
  }
  .profile {
    width: 90px;
    height: 90px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    float: left;
    margin-right: 10px;
  }
  .img {
    img {
      width: 50px;
      height: 50px;
    }
  }
  .info {
    padding-top: 0;
    .name {
      font-weight: bold;
    }
  }
  .period {
    font-size: 12px;
    text-align: center;
    .bold {
      font-weight: bold;
      display: block;
    }
    .col {
      &:nth-child(2) {
        border-right: 1px solid lighten($black, 90%);
        border-left: 1px solid lighten($black, 90%);
      }
    }
  }
  .btn-wrap {
    .btn {
      width: calc(50% - 5px);
      margin-right: 10px;
      font-size: 12px;
      font-weight: bold;
      color: $white;
    }
    .btn-danger {
      margin-right: 0px;
    }
  }
  .iLoading {
    position: absolute;
    top: 25px;
    right: 36px;
  }
  .pc-5 {
    padding-top: 5px !important;
  }
}
</style>
