<template>
  <div></div>
</template>
<script>
import { HTTP } from "@/main-source";
import router from "@/router";
export default {
  name: "LoginWithCallback",
  created() {
    const token = this.$route.query.token;
    if (token) {
      HTTP.defaults.headers.common["Authorization"] = "Bearer " + token;
      localStorage.token = token;

      if (this.$route.query.signedup == "true") {
        window.location = this.$redirectURL + "welcome";
      } else {
        window.location = "/";
      }
    } else {
      window.location =
        this.$redirectURL + "/login?error=" + this.$route.query.error;
    }
  },
};
</script>
