import Vue from "vue";
import VueRouter from "vue-router";
import { HTTP } from "@/main-source";
import nprogress from "nprogress";
import Home from "@/views/Home.vue";
import Welcome from "@/views/Welcome.vue";
import Search from "@/views/Search.vue";
// import Empty from "@/views/EmptyPage.vue";
import Login from "@/views/Login.vue";
import SignUp from "@/components/user/SignUp.vue";
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";
import TermsOfUse from "@/views/TermsOfUse.vue";
import ForgotPassword from "@/views/Forgot.vue";
import RenewPassword from "@/views/RenewPassword.vue";
import Contact from "@/views/Contact.vue";
import LoginWithCallback from "@/components/user/LoginWithCallback.vue";
import ConfirmMail from "@/components/user/confirmMail.vue";
import AddAccountList from "@/views/AddAccountList.vue";
import UpdateAccountToken from "@/views/UpdateAccountToken.vue";
// Pages
import AIReports from "@/views/facebook/General.vue";
import Insights from "@/views/facebook/Reports.vue";
import Insightsv2 from "@/views/facebook/Reports-v2.vue";
import Comments from "@/views/facebook/Comments.vue";
import Posts from "@/views/facebook/Posts.vue";
import Messages from "@/views/facebook/Messages.vue";
import Mentions from "@/views/facebook/Mentions.vue";
import AIActions from "@/views/facebook/AI-Actions.vue";
import AccountSettings from "@/views/facebook/Account-Settings.vue";
// Account
import Settings from "@/views/Settings";
import Support from "@/views/Support";
import Subusers from "@/views/Subusers";
import vSelect from "vue-select";
import DatePicker from "vue2-datepicker";

import "vue2-datepicker/index.css";

// For Mobile App Redirects
import MobileAddAccountCallback from "@/views/mobile/MobileAddAccountCallback";
import MobileReconnect from "@/views/mobile/MobileReconnect";

Vue.component("v-select", vSelect);
Vue.component("DatePicker", DatePicker);

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      title: "Home",
      requiresAuth: true,
    },
  },
  {
    path: "/oauth2/callback/",
    name: "loginWithCallback",
    component: LoginWithCallback,
    meta: {
      title: "loginWithCallback",
    },
  },
  {
    path: "/complete-register/",
    name: "confirmMail",
    component: ConfirmMail,
    meta: {
      title: "confirmMail",
    },
  },
  {
    path: "/signup",
    name: "signup",
    component: SignUp,
    meta: {
      title: "Sign Up",
    },
  },
  {
    path: "/welcome",
    name: "welcome",
    component: Welcome,
    meta: {
      title: "Welcome",
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      title: "Login",
      requiresAuth: false,
    },
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: PrivacyPolicy,
    meta: {
      title: "PrivacyPolicy",
    },
  },
  {
    path: "/terms-of-use",
    name: "terms-of-use",
    component: TermsOfUse,
    meta: {
      title: "TermsOfUse",
    },
  },
  {
    path: "/forgot",
    name: "forgot-password",
    component: ForgotPassword,
    meta: {
      title: "Forgot",
    },
  },
  {
    path: "/renew-password",
    name: "renew-password",
    component: RenewPassword,
    meta: {
      title: "Forgot",
    },
  },
  {
    path: "/contact",
    name: "contact",
    component: Contact,
    meta: {
      title: "Contact",
    },
  },
  {
    path: "/search",
    name: "search",
    component: Search,
    meta: {
      title: "Search",
      requiresAuth: true,
    },
  },
  {
    path: "/settings",
    name: "settings",
    component: Settings,
    meta: {
      title: "Settings",
      requiresAuth: true,
    },
  },
  {
    path: "/support",
    name: "support",
    component: Support,
    meta: {
      title: "Support",
      requiresAuth: true,
    },
  },
  {
    path: "/upgrade",
    name: "upgrade",
    component: AddAccountList,
    meta: {
      title: "Upgrade",
      requiresAuth: true,
    },
  },
  {
    path: "/settings/subusers",
    name: "subusers",
    component: Subusers,
    meta: {
      title: "Subusers",
      requiresAuth: true,
    },
  },
  {
    path: "/social/:socialmedia/:accountId?/ai-reports",
    name: "ai-reports",
    component: AIReports,
    meta: {
      title: "AI Reports",
      requiresAuth: true,
    },
  },
  {
    path: "/social/:socialmedia/:accountId?/insight",
    name: "insights",
    component: Insights,
    meta: {
      title: "Insights",
      requiresAuth: true,
    },
  },
  {
    path: "/social/:socialmedia/:accountId?/insight-v2",
    name: "insightsv2",
    component: Insightsv2,
    meta: {
      title: "Insights",
      requiresAuth: true,
    },
  },
  {
    path: "/social/:socialmedia/:accountId?/comments",
    name: "comments",
    component: Comments,
    meta: {
      title: "Comments",
      requiresAuth: true,
    },
  },
  {
    path: "/social/:socialmedia/:accountId?/posts",
    name: "posts",
    component: Posts,
    meta: {
      title: "Posts",
      requiresAuth: true,
    },
  },
  {
    path: "/social/:socialmedia/:accountId?/direct-messages",
    name: "directmessages",
    component: Messages,
    meta: {
      title: "Direct Message",
      requiresAuth: true,
    },
  },
  {
    path: "/social/:socialmedia/:accountId?/mentions",
    name: "mentions",
    component: Mentions,
    meta: {
      title: "Mentions",
      requiresAuth: true,
    },
  },
  {
    path: "/social/:socialmedia/:accountId?/ai-actions",
    name: "ai-actions",
    component: AIActions,
    meta: {
      title: "AI Actions",
      requiresAuth: true,
    },
  },
  {
    path: "/social/:socialmedia/:accountId?/settings",
    name: "account-settings",
    component: AccountSettings,
    meta: {
      title: "Account Settings",
      requiresAuth: true,
    },
  },
  {
    path: "/social/:socialmedia?/:accountId?/save",
    name: "addAccountList",
    component: AddAccountList,
    meta: {
      title: "AddAccountList",
      requiresAuth: true,
    },
  },
  {
    path: "/social/:socialmedia/update",
    name: "updateAccountToken",
    component: UpdateAccountToken,
    meta: {
      title: "UpdateAccountToken",
      requiresAuth: true,
    },
  },
  {
    path: "/payment/callback",
    name: "paymentCallback",
    component: AddAccountList,
    meta: {
      title: "paymentCallback",
      requiresAuth: true,
    },
  },
  {
    path: "/mobile/add-account",
    name: "mobile-add-account",
    component: MobileAddAccountCallback,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/mobile/reconnect",
    name: "mobile-reconnect",
    component: MobileReconnect,
    meta: {
      requiresAuth: false,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeResolve((to, from, next) => {
  if (to.path) {
    nprogress.start();
  }
  next();
});

router.afterEach(() => {
  nprogress.done();
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth == true)) {
    let token = localStorage.token;

    if (!token) {
      HTTP.defaults.headers.common.Authorization = "";
      next({
        path: "/login",
        query: to.query,
      });
    }
  }
  next();
});

export default router;
