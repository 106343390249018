<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.003"
    height="16.001"
    viewBox="0 0 16.003 16.001"
  >
    <g id="_009-in-love" data-name="009-in-love" transform="translate(0)">
      <path
        id="Path_736"
        data-name="Path 736"
        d="M115.686,166.109a.469.469,0,0,0,.938,0,1.614,1.614,0,1,0-3.228,0,.469.469,0,0,0,.938,0,.676.676,0,0,1,1.353,0Z"
        transform="translate(-109.852 -159.355)"
        fill="#5a5a5a"
      />
      <path
        id="Path_737"
        data-name="Path 737"
        d="M296.483,166.578a.469.469,0,0,0,.469-.469,1.614,1.614,0,1,0-3.228,0,.469.469,0,1,0,.938,0,.676.676,0,1,1,1.353,0A.469.469,0,0,0,296.483,166.578Z"
        transform="translate(-284.544 -159.355)"
        fill="#5a5a5a"
      />
      <path
        id="Path_738"
        data-name="Path 738"
        d="M179.247,339.846a14.053,14.053,0,0,1-3.991-.006l-.021,0a.469.469,0,0,0-.486.674,2.73,2.73,0,0,0,4.981.011.469.469,0,0,0-.484-.676Zm-2,1.346a1.723,1.723,0,0,1-.964-.3,14.974,14.974,0,0,0,1.921,0A1.721,1.721,0,0,1,177.243,341.192Z"
        transform="translate(-169.24 -329.212)"
        fill="#5a5a5a"
      />
      <path
        id="Path_739"
        data-name="Path 739"
        d="M15.509,12.166a1.589,1.589,0,0,0-.473-.315,8.024,8.024,0,0,0-.226-8.02l.662-.691.014-.015A1.8,1.8,0,0,0,15.391.58,1.754,1.754,0,0,0,13.176.367a1.788,1.788,0,0,0-2.207.2l-.027.026A7.737,7.737,0,0,0,8,.035a8,8,0,0,0-8,8A7.845,7.845,0,0,0,.785,11.5a1.8,1.8,0,0,0-.22.172,1.712,1.712,0,0,0-.557,1.1,1.783,1.783,0,0,0,.438,1.317l.028.032,1.6,1.676a.782.782,0,0,0,1.13,0L4,14.966a8.015,8.015,0,0,0,8.2-.12l.92.962a.739.739,0,0,0,1.069,0l1.385-1.448.014-.015A1.54,1.54,0,0,0,15.509,12.166ZM11.6,1.257a.857.857,0,0,1,1.24.041.469.469,0,0,0,.654.008.843.843,0,0,1,1.246-.051A.864.864,0,0,1,14.786,2.5l-.745.779-.166.173,0,0-.7.732L11.563,2.505a.852.852,0,0,1-.116-1.067l.016-.023a.984.984,0,0,1,.139-.157ZM2.642,15.029,1.164,13.484A.762.762,0,0,1,1.2,12.363a.848.848,0,0,1,.343-.193l.01,0a.771.771,0,0,1,.762.233.469.469,0,0,0,.654.008.758.758,0,0,1,1.12-.047.777.777,0,0,1,.04,1.118l-.537.561h0Zm2.027-.763.141-.147.014-.015a1.742,1.742,0,0,0-1.217-2.9,1.684,1.684,0,0,0-.961.271,1.647,1.647,0,0,0-.961-.267,6.911,6.911,0,0,1-.749-3.17A7.063,7.063,0,0,1,8,.973a6.845,6.845,0,0,1,2.443.431,1.725,1.725,0,0,0-.061.317,1.877,1.877,0,0,0,.463,1.387l.028.032,1.718,1.8a.8.8,0,0,0,1.163,0l.385-.4a6.878,6.878,0,0,1,.924,3.5,6.874,6.874,0,0,1-1.091,3.773,1.607,1.607,0,0,0-.311.145,1.569,1.569,0,0,0-2.345,1.19,1.549,1.549,0,0,0,.252.991,7.085,7.085,0,0,1-6.9.132Zm10.222-.546-1.234,1.29-1.23-1.285a.618.618,0,1,1,.9-.848.469.469,0,0,0,.654.008.675.675,0,0,1,.485-.216.6.6,0,0,1,.395.175A.613.613,0,0,1,14.891,13.72Z"
        transform="translate(0 -0.035)"
        fill="#5a5a5a"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "love"
};
</script>
