<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.004"
    height="16"
    viewBox="0 0 16.004 16"
  >
    <g id="hate-speech" transform="translate(-52.5 -47.5)">
      <path
        id="Path_753"
        data-name="Path 753"
        d="M68.339,53.895a8,8,0,1,0-14.617,5.855l-1.028,2.884a.512.512,0,0,0,.654.654l2.88-1.028A7.948,7.948,0,0,0,60.486,63.5a8.029,8.029,0,0,0,6.69-3.593A7.941,7.941,0,0,0,68.339,53.895ZM54.777,59.852a.509.509,0,0,0-.059-.457,6.972,6.972,0,1,1,5.788,3.081h-.012a6.9,6.9,0,0,1-3.9-1.206.508.508,0,0,0-.288-.091.519.519,0,0,0-.173.032l-2.1.749Z"
        transform="translate(0)"
        :fill="filled ? '#c40d3c' : '#5a5a5a'"
      />
      <path
        id="Path_754"
        data-name="Path 754"
        d="M343.331,154.334a.286.286,0,0,0,.288.284h.563a.291.291,0,0,0,.288-.284l.035-5.343a.289.289,0,0,0-.284-.292h-.634a.289.289,0,0,0-.288.288l.032,5.347Z"
        transform="translate(-279.342 -97.213)"
        :fill="filled ? '#c40d3c' : '#5a5a5a'"
      />
      <path
        id="Path_755"
        data-name="Path 755"
        d="M135.71,185.617l3.538.035h0a.177.177,0,0,0,.126-.055.356.356,0,0,0,.091-.236V184.7c0-.165-.095-.3-.221-.3l-3.538.032h0c-.118,0-.213.13-.213.3v.591C135.493,185.487,135.588,185.617,135.71,185.617Z"
        transform="translate(-79.727 -131.506)"
        :fill="filled ? '#c40d3c' : '#5a5a5a'"
      />
      <path
        id="Path_756"
        data-name="Path 756"
        d="M135.909,252.914l6.15.035h0a.31.31,0,0,0,.319-.292V252a.3.3,0,0,0-.095-.213.323.323,0,0,0-.225-.083l-6.15.032h0a.307.307,0,0,0-.315.3v.591A.3.3,0,0,0,135.909,252.914Z"
        transform="translate(-79.824 -196.155)"
        :fill="filled ? '#c40d3c' : '#5a5a5a'"
      />
      <path
        id="Path_757"
        data-name="Path 757"
        d="M142.066,320.249a.31.31,0,0,0,.319-.292V319.3a.3.3,0,0,0-.095-.213.323.323,0,0,0-.225-.083l-6.15.032h0a.307.307,0,0,0-.315.3v.591a.307.307,0,0,0,.315.3l6.15.035Z"
        transform="translate(-79.826 -260.803)"
        :fill="filled ? '#c40d3c' : '#5a5a5a'"
      />
      <path
        id="Path_758"
        data-name="Path 758"
        d="M341.378,318.574h.024a.727.727,0,0,0,.512-.2h0a.669.669,0,0,0,.2-.485.71.71,0,0,0-.193-.5.7.7,0,0,0-.516-.209.676.676,0,0,0-.516.213.687.687,0,0,0-.189.489.675.675,0,0,0,.193.485A.692.692,0,0,0,341.378,318.574Z"
        transform="translate(-276.841 -259.061)"
        :fill="filled ? '#c40d3c' : '#5a5a5a'"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "hate",
  props: ["filled"],
};
</script>

 <style type="text/css">
.st0 {
  fill: #231f20;
}
</style>