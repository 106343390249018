<template>
  <div class="first">
    <div class="social-media">
      <router-link :to="{
        name: 'home'
      }"
        @click.native="
          setActiveSocialMedia('');
        closeOnMobile();
                            ">
        <home-icon :active="[
          getActiveSocialMedia == '' && activeRouteName == 'home'
            ? 'true'
            : 'false'
        ]"></home-icon>
      </router-link>
      <router-link :to="{
        name: 'ai-reports',
        params: {
          socialmedia: 'facebook'
        }
      }"
        :class="{ selectedSocialMedia: getActiveSocialMedia == 'facebook' }"
        @click.native="setActiveSocialMedia('facebook')">
        <facebook-icon :active="[getActiveSocialMedia == 'facebook' ? 'true' : 'false']"></facebook-icon>
      </router-link>
      <router-link :to="{
        name: 'ai-reports',
        params: {
          socialmedia: 'instagram'
        }
      }"
        :class="{ selectedSocialMedia: getActiveSocialMedia == 'instagram' }"
        @click.native="setActiveSocialMedia('instagram')">
        <instagram-icon :active="[getActiveSocialMedia == 'instagram' ? 'true' : 'false']"></instagram-icon>
      </router-link>
      <router-link v-if="$testAccounts.includes(getUser.name)"
        :to="{
          name: 'ai-reports',
          params: {
            socialmedia: 'twitter'
          }
        }"
        :class="{ selectedSocialMedia: getActiveSocialMedia == 'twitter' }"
        @click.native="setActiveSocialMedia('twitter')">
        <twitter-icon :active="[getActiveSocialMedia == 'twitter' ? 'true' : 'false']"></twitter-icon>
      </router-link>

      <router-link :to="{
        name: 'ai-reports',
        params: {
          socialmedia: 'youtube'
        }
      }"
        :class="{ selectedSocialMedia: getActiveSocialMedia == 'youtube' }"
        @click.native="setActiveSocialMedia('youtube')">
        <youtube-icon :active="[getActiveSocialMedia == 'youtube' ? 'true' : 'false']"></youtube-icon>
      </router-link>
    </div>
    <img @error="(e) => (e.target.src = $defaultCardAvatar)"
      src="@/assets/images/logo-vertical.svg"
      class="vertical-logo"
      alt="Socialays Logo" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import homeIcon from "@/components/icons/home";
import facebookIcon from "@/components/icons/facebook";
import instagramIcon from "@/components/icons/instagram";
import twitterIcon from "@/components/icons/twitter";
import youtubeIcon from "@/components/icons/youtube";
import EventBus from "@/components/event-bus";

export default {
  name: "first",
  data: function () {
    return {
      activeRouteName: this.$route.name
    };
  },
  methods: {
    ...mapActions(["setActiveSocialMedia"]),
    closeOnMobile: function () {
      /*eslint-disable*/
      let mobileAndTabletCheck = function () {
        let check = false;
        (function (a) {
          if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
              a
            ) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
              a.substr(0, 4)
            )
          )
            check = true;
        })(navigator.userAgent || navigator.vendor || window.opera);
        return check;
      };
      /*eslint-disable*/
      if (mobileAndTabletCheck()) {
        EventBus.$emit("menu", false);
      }
    }
  },
  computed: {
    ...mapGetters(["getActiveSocialMedia", "getActiveSocialMediaAccount", "getUser"])
  },
  components: {
    homeIcon,
    facebookIcon,
    instagramIcon,
    twitterIcon,
    youtubeIcon
  },
  watch: {
    $route: function () {
      this.activeRouteName = this.$route.name;
    }
  }
};
</script>

<style lang="scss">
@import "../../scss/colors.scss";

.first {
  border-right: 1px solid $main-bg-color;
  height: 100%;
  width: 90px;
  min-width: 90px !important;
  padding: 15px 10px 0px 10px;
  text-align: center;
  background: $white;
  position: relative;
}

.social-media {
  margin-top: 30px;

  a {
    display: block;
    margin: 15px auto;
    padding: 3px 5px 5px;
    transition: all 0.25s ease;
    border-radius: 3px;

    #facebook-logo,
    #youtube {
      stroke: $white;
    }

    svg {
      fill: #f5f6fa;
      border-radius: 13px;

      * {
        transition: all 0.3s ease;
      }
    }
  }

  .selectedSocialMedia svg {
    box-shadow: 0 0px 15px rgba(0, 0, 0, 0.2);
  }
}

.icon {
  width: 100%;
  height: auto;
  display: block;
  margin: auto;
}

.vertical-logo {
  position: absolute;
  height: 200px;
  width: auto;
  bottom: 20px;
  left: 25px;
}

@media (max-width: 767.98px) {
  .vertical-logo {
    position: absolute;
    height: 150px;
    width: auto;
    bottom: 20px;
    left: 32px;
  }
}
</style>
