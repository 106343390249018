<template>
  <div class="wrap">
    <div class="row head">
      <img @error="(e) => (e.target.src = $defaultCardAvatar)"
        class="profile"
        alt="Profile"
        :src="profilePhoto"
        @click="goToProfile"
      />
      <div class="info">
        <div>
          <span class="name" @click="goToProfile">{{
            account.accountName
          }}</span>
        </div>
        <div stlye="height:15px" class="clearfix">
          <!-- <span class="slug" @click="goToProfile">
            @{{ account.accountName.toLowerCase().substr(0, 26)
            }}{{ account.accountName.length > 26 ? ".." : "" }}
          </span> -->
        </div>
      </div>
      <div class="icon" @click="goToProfile">
        <facebookIcon
          :active="'true'"
          v-if="
            getActiveSocialMedia
              ? getActiveSocialMedia == 'facebook'
              : socialmedia == 'facebook'
          "
        ></facebookIcon>
        <instagramIcon
          :active="'true'"
          v-if="
            getActiveSocialMedia
              ? getActiveSocialMedia == 'instagram'
              : socialmedia == 'instagram'
          "
        ></instagramIcon>
        <twitterIcon
          :active="'true'"
          v-if="
            getActiveSocialMedia
              ? getActiveSocialMedia == 'twitter'
              : socialmedia == 'twitter'
          "
        ></twitterIcon>
        <youtubeIcon
          :active="'true'"
          v-if="
            getActiveSocialMedia
              ? getActiveSocialMedia == 'youtube'
              : socialmedia == 'youtube'
          "
        ></youtubeIcon>
      </div>
    </div>
    <div class="body">
      <span class="title" v-if="$route.name != 'mentions'"
        >Comments ({{ totals ? intToString(totals.comments) : "no-data" }}) &
        Replies ({{ totals ? intToString(totals.replies) : "no-data" }})</span
      >
      <span class="title" v-if="$route.name == 'mentions'"
        >Mentions ({{ totals ? intToString(totals) : "no-data" }}) <br/></span
      >
      <span class="subtitle" v-if="$route.name != 'mentions'">
        {{ dateFormat() }}
      </span>
      <div class="row info">
        <div class="col" v-if="$route.name != 'mentions'">
          <div class="row details">
            <div class="col-9 pr-0">
              <status :status="'positive'"></status>Positive
            </div>
            <div class="col p-0">{{ intToString(graph.Positive) }}</div>
          </div>
          <div class="row details">
            <div class="col-9 pr-0">
              <status :status="'neutral'"></status>Neutral
            </div>
            <div class="col p-0">{{ intToString(graph.Neutral) }}</div>
          </div>
          <div class="row details">
            <div class="col-9 pr-0">
              <status :status="'negative'"></status>Negative
            </div>
            <div class="col p-0">{{ intToString(graph.Negative) }}</div>
          </div>
          <div class="row details">
            <div class="col-9 pr-0">
              <status :status="'question'"></status>Question
            </div>
            <div class="col p-0">{{ intToString(graph.Questioned) }}</div>
          </div>
          <div class="row details">
            <div class="col-9 pr-0">
              <status :status="'spam'"></status>Spam/Ad Content
            </div>
            <div class="col p-0">{{ intToString(graph.Spam) }}</div>
          </div>
          <div class="row details">
            <div class="col-9 pr-0">
              <status :status="'hidden'"></status>Hidden/Deleted
            </div>
            <div class="col p-0">
              {{ intToString(graph.Hidden + graph.Deleted) }}
            </div>
          </div>
        </div>
        <div class="col" v-if="$route.name == 'mentions'">
          <div class="row details">
            <div class="col-9 pr-0">
              <status :status="'positive'"></status>Positive
            </div>
            <div class="col p-0">{{ intToString(graph.positive) }}</div>
          </div>
          <div class="row details">
            <div class="col-9 pr-0">
              <status :status="'neutral'"></status>Neutral
            </div>
            <div class="col p-0">{{ intToString(graph.neutral) }}</div>
          </div>
          <div class="row details">
            <div class="col-9 pr-0">
              <status :status="'negative'"></status>Negative
            </div>
            <div class="col p-0">{{ intToString(graph.negative) }}</div>
          </div>
          <div class="row details">
            <div class="col-9 pr-0">
              <status :status="'question'"></status>Questions
            </div>
            <div class="col p-0">{{ intToString(graph.question) }}</div>
          </div>
          <div class="row details">
            <div class="col-9 pr-0">
              <status :status="'spam'"></status>Spam/Ad Content
            </div>
            <div class="col p-0">{{ intToString(graph.spam) }}</div>
          </div>
          <div class="row details">
            <div class="col-9 pr-0">
              <status :status="'hidden'"></status
              >{{
                type == "facebook" || type == "FACEBOOK"
                  ? "Hidden/Deleted"
                  : "Hidden/Deleted"
              }}
            </div>
            <div
              class="col p-0"
              v-if="type == 'facebook' || type == 'FACEBOOK'"
            >
              {{
                intToString(
                  graph.hidden
                    ? graph.hidden
                    : 0 + graph.deleted
                    ? graph.deleted
                    : 0
                )
              }}
            </div>
            <div
              class="col p-0"
              v-if="type != 'facebook' && type != 'FACEBOOK'"
            >
              {{ intToString(graph.hidden) }}
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 text-center">
          <i
            class="fas fa-exclamation-triangle"
            id="hasError"
            v-tooltip.top-center="
              'Account connection is broken, please connect again.'
            "
            v-if="account.hasError"
          ></i>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="200px"
            height="200px"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
            id="sync"
            v-if="account.isSynced"
          >
            <circle
              cx="50"
              cy="50"
              r="32"
              stroke-width="4"
              stroke="#646464"
              stroke-dasharray="50.26548245743669 50.26548245743669"
              fill="none"
              stroke-linecap="round"
              transform="matrix(1,0,0,1,0,0)"
              style="
                transform: matrix(1, 0, 0, 1, 0, 0);
                animation-play-state: paused;
              "
            ></circle>
          </svg>
          <Doughnut
            class="graph filterable"
            :class="{ 'disable-click': $route.name == 'mentions' }"
            :chart-data="chartData"
            :type="type"
            @clicked="triggerRoute"
          ></Doughnut>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Doughnut from "./rDoughnut";
import status from "@/components/dot";
import { mapGetters, mapActions } from "vuex";
import facebookIcon from "@/components/icons/facebook";
import instagramIcon from "@/components/icons/instagram";
import twitterIcon from "@/components/icons/twitter";
import youtubeIcon from "@/components/icons/youtube";
import router from "@/router";
import { HTTP } from "@/main-source";

export default {
  name: "general-item",
  data() {
    return {
      title: "general-item",
      chartData: null,
      hasData: false,
      profilePhoto: this.account.imageUrl || this.$defaultCardAvatar,
    };
  },
  props: [
    "graph",
    "account",
    "type",
    "socialmedia",
    "totals",
    "range",
    "start",
    "finish",
  ],
  components: {
    Doughnut,
    status,
    facebookIcon,
    instagramIcon,
    twitterIcon,
    youtubeIcon,
  },
  computed: {
    ...mapGetters(["getActiveSocialMedia"]),
  },
  methods: {
    ...mapActions(["setActiveSocialMedia", "setActiveSocialMediaAccount"]),
    goToProfile: function () {
      if (this.$route.name == "home") {
        localStorage.removeItem(
          "lastSelected" + this.type.toLowerCase() + "Account"
        );
        this.setActiveSocialMedia(this.type.toLowerCase());
        this.setActiveSocialMediaAccount(this.account.accountId);

        router.push({
          name: "ai-reports",
          params: {
            socialmedia: this.type.toLowerCase(),
            accountId: this.account.accountId,
          },
        });
      }
    },
    intToString: function (value) {
      return this.$intToString(value);
    },
    dateFormat: function () {
      let syncDate = new Date(this.account.commentLastSyncDate);
      let result;
      switch (this.range) {
        case "ALL":
          result = syncDate;
          break;
        case "LAST7":
          result = new Date(Date.now() - 6 * 24 * 60 * 60 * 1000);
          break;
        case "LAST24":
          result = new Date(Date.now() - 24 * 60 * 60 * 1000);
          break;
      }

      if (this.start != null && this.finish != null) {
        result =
          new Date(this.start).toLocaleDateString("tr-TR") +
          " - " +
          new Date(this.finish).toLocaleDateString("tr-TR");
      } else {
        result = "Since " + result.toLocaleDateString("tr-TR");
      }

      return result;
    },
    triggerRoute: function (index) {
      this.setActiveSocialMedia(this.type.toLowerCase());
      if (this.hasData == true) {
        router.push({
          name: "comments",
          params: {
            socialmedia: this.type.toLowerCase(),
            accountId: this.account.accountId,
          },
          query: {
            filter: index,
            range: this.range,
            start: this.start,
            finish: this.finish,
          },
        });
      } else {
        router.push({
          name: "comments",
          params: {
            socialmedia: this.type.toLowerCase(),
            accountId: this.account.accountId,
          },
          query: {
            filter: 6,
            range: this.range,
            start: this.start,
            finish: this.finish,
          },
        });
      }
    },
    setData: function () {
      var cData = [];
      if (this.$route.name != "mentions") {
        if (
          this.graph.Positive != 0 ||
          this.graph.Neutral != 0 ||
          this.graph.Negative != 0 ||
          this.graph.Questioned != 0 ||
          this.graph.Hidden != 0 ||
          this.graph.Spam != 0 ||
          this.graph.Deleted != 0
        ) {
          this.hasData = true;
          cData.push(this.graph.Positive);
          cData.push(this.graph.Neutral);
          cData.push(this.graph.Negative);
          cData.push(this.graph.Questioned);
          cData.push(this.graph.Spam);
          let total =
            parseInt(this.graph.Deleted) + parseInt(this.graph.Hidden);
          cData.push(total);
        } else {
          this.hasData = false;
          this.graph.Unassigned > 0
            ? cData.push(this.graph.Unassigned)
            : cData.push(1);
        }
      } else {
        if (
          this.graph.positive != 0 ||
          this.graph.neutral != 0 ||
          this.graph.negative != 0 ||
          this.graph.question != 0 ||
          this.graph.hidden != 0 ||
          this.graph.spam != 0 ||
          this.graph.deleted != 0
        ) {
          this.hasData = true;
          cData.push(this.graph.positive);
          cData.push(this.graph.neutral);
          cData.push(this.graph.negative);
          cData.push(this.graph.question);
          cData.push(this.graph.spam);
          let total =
            parseInt(this.graph.seleted) + parseInt(this.graph.hidden);
          cData.push(total);
        } else {
          this.hasData = false;
          this.graph.unassigned > 0
            ? cData.push(this.graph.unassigned)
            : cData.push(1);
        }
      }

      let labels = ["Positive", "Neutral", "Negative", "Question"];

      labels.push("Spam/Ad Content");

      // if (this.getActiveSocialMedia == "facebook" || this.type == "FACEBOOK") {
      //   labels.push("Hidden/Deleted");
      // } else {
      //   labels.push("Deleted");
      // }

      labels.push("Hidden/Deleted");

      this.chartData = {
        labels: this.hasData ? labels : ["Not Assigned"],
        datasets: [
          {
            backgroundColor: this.hasData
              ? ["#00a1ed", "#ffd800", "#f00", "#a54cff", "#edc7a2", "#a2a2a2"]
              : ["#f8f8f8"],
            data: cData,
          },
        ],
      };
    },
  },
  created() {
    this.setData();

    if (this.$route.name == "home") {
      if (
        this.type == "facebook" ||
        this.type == "FACEBOOK" ||
        this.type == "twitter" ||
        this.type == "TWITTER"
      ) {
        // Set Comment Author Photo

        HTTP.get(
          "/picture/" +
            this.type.toLowerCase() +
            "?accountid=" +
            this.account.accountId +
            "&authorId=" +
            this.account.accountId
        ).then((result) => {
          if (result.status == 200) {
            this.profilePhoto = result.data.url;
          }
        });

        // Set Comment Author Photo End
      }
    } else {
      if (
        this.getActiveSocialMedia == "facebook" ||
        this.getActiveSocialMedia == "FACEBOOK" ||
        this.getActiveSocialMedia == "twitter" ||
        this.getActiveSocialMedia == "TWITTER"
      ) {
        // Set Comment Author Photo

        HTTP.get(
          "/picture/" +
            this.getActiveSocialMedia +
            "?accountid=" +
            this.account.accountId +
            "&authorId=" +
            this.account.accountId
        ).then((result) => {
          if (result.status == 200) {
            this.profilePhoto = result.data.url;
          }
        });

        // Set Comment Author Photo End
      }
    }
  },
  watch: {
    graph: function () {
      this.setData();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/colors.scss";
.wrap {
  background: $white;
  min-height: 40px;
  border-radius: 30px;
  padding: 10px 15px;
}
.head {
  padding: 5px 15px 5px 15px;
  // border-bottom: 1px solid darken($white, 20%);
  display: block;
  position: relative;
  .profile {
    width: 45px;
    height: 45px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    float: left;
    margin-right: 10px;
  }
  .name {
    font-weight: bold;
  }
  .city {
    font-size: 12px;
    position: relative;
    top: -5px;
  }
  .icon {
    position: absolute;
    right: 20px;
    top: 10px;
    width: auto;
    height: 40px;
    svg {
      width: 30px;
      height: auto;
      display: block;
      overflow: visible;
    }
  }
}
.body {
  padding: 10px 0px 0px;
  font-size: 13px;
  .title {
    display: block;
    color: darken($white, 40%);
    font-weight: bold;
    text-align: center;
  }
  .subtitle {
    display: block;
    color: darken($white, 40%);
    text-align: center;
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
  }
  .details {
    margin-bottom: 10px;
    color: $darker;
    i {
      float: left;
      margin-right: 5px;
    }
    line-height: 20px;
  }
  .info {
    padding-top: 10px;
    min-height: 200px;
    .col {
      padding-right: 0;
    }
  }
}
.graph {
  width: 180px;
  height: auto;
  margin: -5px auto 0px auto;
}
@media (max-width: 767.98px) {
  .body {
    .info {
      flex-wrap: wrap-reverse;
    }
    .graph {
      margin: auto;
    }
  }
}
@media screen and (max-device-width: 1450px) {
  .graph {
    width: 140px;
    height: auto;
    margin-top: 20px;
  }
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
#sync {
  position: absolute;
  width: 30px;
  height: 30px;
  right: 12px;
  bottom: 0px;
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}
#hasError {
  color: $brand-orange;
  position: absolute;
  right: 20px;
  bottom: 10px;
}
</style>