<template>
  <svg
    id="like"
    :class="{ liked: isLiked }"
    width="14"
    height="14"
    viewBox="0 0 13.571 13.029"
  >
    <g id="like_1_" data-name="like (1)" transform="translate(0.3 -10.367)">
      <g id="Group_1192" data-name="Group 1192" transform="translate(0 16.071)">
        <g id="Group_1191" data-name="Group 1191">
          <path
            id="Path_693"
            data-name="Path 693"
            d="M1.351,224A1.353,1.353,0,0,0,0,225.351v4.323a1.353,1.353,0,0,0,1.351,1.351H2.972a1.341,1.341,0,0,0,.811-.273V224Z"
            transform="translate(0 -224)"
            fill="none"
            stroke="#707070"
            stroke-width="0.6"
          />
        </g>
      </g>
      <g
        id="Group_1194"
        data-name="Group 1194"
        transform="translate(4.323 10.667)"
      >
        <g id="Group_1193" data-name="Group 1193">
          <path
            id="Path_694"
            data-name="Path 694"
            d="M179.314,18.1a1.2,1.2,0,0,0-.352-.851,1.342,1.342,0,0,0,.346-1.032A1.4,1.4,0,0,0,177.9,14.99H174.56a7.77,7.77,0,0,0,.43-2.162,2.209,2.209,0,0,0-1.621-2.162,1.7,1.7,0,0,0-.98.329.272.272,0,0,0-.1.211V13.04l-1.556,3.372-.065.033v5.792a3.541,3.541,0,0,0,1.351.319h4.96a1.243,1.243,0,0,0,1.225-.944,1.216,1.216,0,0,0-.1-.812,1.21,1.21,0,0,0,.541-1.622A1.21,1.21,0,0,0,179.314,18.1Z"
            transform="translate(-170.667 -10.667)"
            fill="none"
            stroke="#707070"
            stroke-width="0.6"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "like",
  props: ["isLiked"]
};
</script>

<style lang="scss">
@import "@/scss/colors.scss";
#like {
  path {
    transition: all 0.25s ease;
    // fill: transparent !important;
    // stroke: $brandcolor !important;
  }
  &:hover {
    path {
      fill: $brandcolor !important;
      stroke: transparent !important;
    }
  }
}
.liked {
  path {
    fill: $brandcolor;
    stroke: transparent;
  }
}
</style>
