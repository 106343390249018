<template>
  <div class="mobile-ai-filter" :class="{ show: mobileRangeModal }">
    <div class="close-trigger" @click="setAndClose"></div>
    <div class="wrap">
      <div class="head">
        <div class="row">
          <div class="col">
            <span class="h5">Select Date</span>
          </div>
          <div class="col text-right done" @click="setAndClose">Done</div>
        </div>
      </div>
      <div class="body">
        <!-- Option List -->
        <div class="olist" :class="{ 'custom-active': isCustomRange }">
          <div
            class="row"
            @click="
              range = 'LAST24';
              start = null;
              finish = null;
            "
          >
            <div class="col-9">Last 24 Hours</div>
            <div class="col text-right">
              <img @error="(e) => (e.target.src = $defaultCardAvatar)" src="@/assets/images/checked.svg" v-if="range == 'LAST24'" />
            </div>
          </div>

          <div
            class="row"
            @click="
              range = 'LAST7';
              start = null;
              finish = null;
            "
          >
            <div class="col-9">Last 7 Days</div>
            <div class="col text-right">
              <img @error="(e) => (e.target.src = $defaultCardAvatar)" src="@/assets/images/checked.svg" v-if="range == 'LAST7'" />
            </div>
          </div>

          <div
            class="row"
            @click="
              range = 'ALL';
              start = null;
              finish = null;
            "
          >
            <div class="col-9">All Time</div>
            <div class="col text-right">
              <img @error="(e) => (e.target.src = $defaultCardAvatar)" src="@/assets/images/checked.svg" v-if="range == 'ALL'" />
            </div>
          </div>
          <div
            class="row"
            @click="
              range = 'CUSTOM';
              date = null;
              isCustomRange = true;
            "
          >
            <div class="col-10">
              Custom Date
              <span class="small" v-if="range == 'CUSTOM'"
                >{{ start }} - {{ finish }}</span
              >
            </div>
            <div class="col text-right">
              <img @error="(e) => (e.target.src = $defaultCardAvatar)" src="@/assets/images/checked.svg" v-if="range == 'CUSTOM'" />
            </div>
          </div>
        </div>
        <!-- Option List END -->
        <div
          class="custom-range"
          :class="{ 'date-range-active': isCustomRange }"
        >
          <div class="d-flex align-items-stretch h-100">
            <div
              class="
                col
                justify-content-center
                align-items-center
                d-flex
                text-center
              "
            >
              <div @click="focusStartDate">
                <span> Select Start Date </span>
                <span class="d-block small">
                  {{ start ? start : "" }}
                </span>
                <div class="form-control hide">
                  <input type="date" ref="startDate" v-model="start" />
                </div>
              </div>
            </div>
            <div
              class="
                col
                justify-content-center
                align-items-center
                d-flex
                text-center
              "
            >
              <div @click="focusFinishDate">
                <span> Select End Date </span>
                <span class="d-block small">
                  {{ finish ? finish : "" }}
                </span>
                <div class="form-control hide">
                  <input type="date" ref="finishDate" v-model="finish" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "mobileRangeFilter",
  props: ["mobileRangeModal", "date", "f_range"],
  data() {
    return {
      range: null,
      isCustomRange: false,
      start: "",
      finish: "",
    };
  },
  created() {
    this.range = this.f_range;
  },
  methods: {
    setAndClose: function name() {
      this.isCustomRange = false;
      this.$emit("close", {
        range: this.range,
        start: this.start,
        finish: this.finish,
      });
    },
    focusStartDate: function () {
      this.$nextTick(() => this.$refs.startDate.focus());
    },
    focusFinishDate: function () {
      this.$nextTick(() => this.$refs.finishDate.focus());
    },
  },
  watch: {
    // mobileRangeModal: function () {
    //   if (!this.mobileRangeModal) {
    //     this.range = this.f_range;
    //   }
    // },
  },
};
</script>
