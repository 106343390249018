var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"setting wrap"},[_c('div',{staticClass:"head"},[_c('img',{attrs:{"src":require("@/assets/images/account-settings/mail.svg"),"alt":"Email Settings"},on:{"error":(e) => (e.target.src = _vm.$defaultCardAvatar)}}),_c('span',[_vm._v("e-Mail Notification")])]),_c('div',{staticClass:"body"},[_c('div',[_vm._v(" Hate speech comment notification "),_c('label',{staticClass:"switch"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.settings.emailNotificationNegative.notifyMeNegativeComment),expression:"settings.emailNotificationNegative.notifyMeNegativeComment"}],staticClass:"default primary",attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.settings.emailNotificationNegative.notifyMeNegativeComment)?_vm._i(_vm.settings.emailNotificationNegative.notifyMeNegativeComment,null)>-1:(_vm.settings.emailNotificationNegative.notifyMeNegativeComment)},on:{"change":function($event){var $$a=_vm.settings.emailNotificationNegative.notifyMeNegativeComment,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.settings.emailNotificationNegative, "notifyMeNegativeComment", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.settings.emailNotificationNegative, "notifyMeNegativeComment", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.settings.emailNotificationNegative, "notifyMeNegativeComment", $$c)}}}}),_c('span',{staticClass:"slider round"})])]),_c('div',{staticClass:"sub",class:{
        disabled: !_vm.settings.emailNotificationNegative.notifyMeNegativeComment
      }},[_vm._v(" Notify me in every "),_c('select',{directives:[{name:"model",rawName:"v-model",value:(
          _vm.settings.emailNotificationNegative
            .notifyMeNumOfNegativeCommentReceived
        ),expression:"\n          settings.emailNotificationNegative\n            .notifyMeNumOfNegativeCommentReceived\n        "}],attrs:{"name":"#"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.settings.emailNotificationNegative
            , "notifyMeNumOfNegativeCommentReceived", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((24),function(i){return _c('option',{key:i,domProps:{"value":i}},[_vm._v(_vm._s(i))])}),0),_vm._v(" comment(s) received during the day. ")]),_c('div',{staticClass:"break"}),_c('div',[_vm._v(" Question comment notification "),_c('label',{staticClass:"switch"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.settings.emailNotificationQuestion.notifyMeQuestionComment),expression:"settings.emailNotificationQuestion.notifyMeQuestionComment"}],staticClass:"default primary",attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.settings.emailNotificationQuestion.notifyMeQuestionComment)?_vm._i(_vm.settings.emailNotificationQuestion.notifyMeQuestionComment,null)>-1:(_vm.settings.emailNotificationQuestion.notifyMeQuestionComment)},on:{"change":function($event){var $$a=_vm.settings.emailNotificationQuestion.notifyMeQuestionComment,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.settings.emailNotificationQuestion, "notifyMeQuestionComment", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.settings.emailNotificationQuestion, "notifyMeQuestionComment", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.settings.emailNotificationQuestion, "notifyMeQuestionComment", $$c)}}}}),_c('span',{staticClass:"slider round"})])]),_c('div',{staticClass:"sub",class:{
        disabled: !_vm.settings.emailNotificationQuestion.notifyMeQuestionComment
      }},[_vm._v(" Notify me in every "),_c('select',{directives:[{name:"model",rawName:"v-model",value:(
          _vm.settings.emailNotificationQuestion
            .notifyMeNumOfQuestionCommentReceived
        ),expression:"\n          settings.emailNotificationQuestion\n            .notifyMeNumOfQuestionCommentReceived\n        "}],attrs:{"name":"#"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.settings.emailNotificationQuestion
            , "notifyMeNumOfQuestionCommentReceived", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((24),function(i){return _c('option',{key:i,domProps:{"value":i}},[_vm._v(_vm._s(i))])}),0),_vm._v(" comment(s) received during the day. ")]),_c('div',{staticClass:"break"}),_c('div',[_vm._v(" Mention notification "),_c('label',{staticClass:"switch"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.settings.emailNotificationMention.notifyMeMention),expression:"settings.emailNotificationMention.notifyMeMention"}],staticClass:"default primary",attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.settings.emailNotificationMention.notifyMeMention)?_vm._i(_vm.settings.emailNotificationMention.notifyMeMention,null)>-1:(_vm.settings.emailNotificationMention.notifyMeMention)},on:{"change":function($event){var $$a=_vm.settings.emailNotificationMention.notifyMeMention,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.settings.emailNotificationMention, "notifyMeMention", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.settings.emailNotificationMention, "notifyMeMention", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.settings.emailNotificationMention, "notifyMeMention", $$c)}}}}),_c('span',{staticClass:"slider round"})])]),_c('div',{staticClass:"sub",class:{
        disabled: !_vm.settings.emailNotificationMention.notifyMeMention
      }},[_vm._v(" Notify me in every "),_c('select',{directives:[{name:"model",rawName:"v-model",value:(
          _vm.settings.emailNotificationMention.notifyMeNumOfMentionReceived
        ),expression:"\n          settings.emailNotificationMention.notifyMeNumOfMentionReceived\n        "}],attrs:{"name":"#"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.settings.emailNotificationMention, "notifyMeNumOfMentionReceived", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((24),function(i){return _c('option',{key:i,domProps:{"value":i}},[_vm._v(_vm._s(i))])}),0),_vm._v(" mentions during the day. ")]),_c('div',{staticClass:"break"}),_c('div',[_vm._v(" Profanity comment notification "),_c('label',{staticClass:"switch"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
            _vm.settings.emailNotificationBlasphemy.notifyMeDeleteBlasphemy
          ),expression:"\n            settings.emailNotificationBlasphemy.notifyMeDeleteBlasphemy\n          "}],staticClass:"default primary",attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(
            _vm.settings.emailNotificationBlasphemy.notifyMeDeleteBlasphemy
          )?_vm._i(
            _vm.settings.emailNotificationBlasphemy.notifyMeDeleteBlasphemy
          ,null)>-1:(
            _vm.settings.emailNotificationBlasphemy.notifyMeDeleteBlasphemy
          )},on:{"change":function($event){var $$a=
            _vm.settings.emailNotificationBlasphemy.notifyMeDeleteBlasphemy
          ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.settings.emailNotificationBlasphemy, "notifyMeDeleteBlasphemy", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.settings.emailNotificationBlasphemy, "notifyMeDeleteBlasphemy", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.settings.emailNotificationBlasphemy, "notifyMeDeleteBlasphemy", $$c)}}}}),_c('span',{staticClass:"slider round"})])]),_c('div',{staticClass:"sub",class:{
        disabled: !_vm.settings.emailNotificationBlasphemy.notifyMeDeleteBlasphemy
      }},[_vm._v(" Notify me in every "),_c('select',{directives:[{name:"model",rawName:"v-model",value:(
          _vm.settings.emailNotificationBlasphemy
            .notifyMeNumOfBlasphemyCommentDeleted
        ),expression:"\n          settings.emailNotificationBlasphemy\n            .notifyMeNumOfBlasphemyCommentDeleted\n        "}],attrs:{"name":"#"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.settings.emailNotificationBlasphemy
            , "notifyMeNumOfBlasphemyCommentDeleted", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((24),function(i){return _c('option',{key:i,domProps:{"value":i}},[_vm._v(_vm._s(i))])}),0),_vm._v(" abusive comment(s) during the day. ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }