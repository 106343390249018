<template>
  <div></div>
</template>
<script>
import { HTTP } from "@/main-source";
export default {
  name: "updateAccountToken",
  created() {
    let socialMedia = this.$route.params.socialmedia;
    let code = this.$route.query.code;
    HTTP.put(
      "accounts/" +
        socialMedia +
        "?redirect_url=" +
        this.$redirectURL +
        "social/" +
        socialMedia +
        "/update&code=" +
        code
    )
      .then(result => {
        if (result.status == 200) {
          window.location = this.$redirectURL;
        }
        if (result.status == 400) {
          window.location = this.$redirectURL + "?erCode=404";
        }
        if (result.status != 400 && result.status != 200) {
          window.location = this.$redirectURL;
        }
      })
      .catch(e => {
        window.location = this.$redirectURL + "?erCode=" + e.response.status;
      });
  }
};
</script>
