<template>
  <div class="add-account">
    <!-- Start Add Modal -->
    <modal v-if="modal"
      @close="modal = false">
      <div slot="title">Add Account</div>
      <div slot="body"
        class="desc text-justify">
        <div class="row">
          <div class="col-12 text-center linked-image">
            <facebookIcon class="socialmedia"
              :active="'true'"
              v-if="selectedSocialMedia.toLowerCase() == 'facebook'">
            </facebookIcon>
            <instagramIcon class="socialmedia"
              :active="'true'"
              v-if="selectedSocialMedia.toLowerCase() == 'instagram'">
            </instagramIcon>
            <twitterIcon class="socialmedia"
              :active="'true'"
              v-if="selectedSocialMedia.toLowerCase() == 'twitter'">
            </twitterIcon>
            <youtubeIcon class="socialmedia"
              :active="'true'"
              v-if="selectedSocialMedia.toLowerCase() == 'youtube'">
            </youtubeIcon>
            <img @error="(e) => (e.target.src = $defaultCardAvatar)"
              src="@/assets/images/linked.svg"
              alt="Linked Image">
            <img @error="(e) => (e.target.src = $defaultCardAvatar)"
              src="@/assets/images/mascot.png"
              alt="Socialays">
          </div>
        </div>
        <div class="text-justify">
          <b>To use Socialays and link your account;</b><br>
          <div v-if="selectedSocialMedia.toLowerCase() == 'instagram'">
            · Account must be an “Instagram Business” account.<br>
            · “Instagram Business” account must be linked
            to a “Facebook Page”
          </div>
          <div v-if="selectedSocialMedia.toLowerCase() == 'facebook'">
            · Account must be a “Facebook Page”.
          </div>
        </div>
      </div>
      <div slot="footer"
        class="xs-block-button d-flex w-100 flex-wrap">
        <button class="btn left m-0 p-l-0"
          v-if="selectedSocialMedia.toLowerCase() == 'instagram' || selectedSocialMedia.toLowerCase() == 'facebook'"
          @click="showDetail = !showDetail">More details <i class="fas fa-angle-down"
            style="font-size:12px;"
            :class="{ 'rotate-180': showDetail }"></i></button>
        <button class="btn btn-primary"
          @click="addAccount(selectedSocialMedia)">
          Continue
        </button>
        <div class="row more-details"
          :class="{ 'show': showDetail }">
          <div class="col-12">
            Instagram uses Facebook API’s. Therefore, Socialays gets your Instagram data via Facebook APIs in line with
            your permissions.<br><br>
            The API cannot access Instagram consumer accounts (i.e., non-Business or non-Creator Instagram
            accounts)<br><br>
            IGTV and Reels are not supported <br>
            · To change your Instagram account to “Instagram Business” for free, <a
              href="https://www.facebook.com/help/instagram/502981923235522"
              target="_blank"
              @click="
                goLink(
                  'https://www.facebook.com/help/instagram/502981923235522'
                )
              ">click here</a><br>
            · To link your “Instagram Business” account to a Facebook page, <a
              href="https://www.facebook.com/help/1148909221857370"
              target="_blank"
              @click="goLink('https://www.facebook.com/help/1148909221857370')">click here</a><br>
          </div>
        </div>
      </div>
    </modal>
    <!-- End Add Modal -->
    <img @error="(e) => (e.target.src = $defaultCardAvatar)"
      src="../../assets/images/close.svg"
      class="close icon"
      @click="close"
      alt="Socialays"
      v-if="accountCount != 0 && showClose != false" />
    <img @error="(e) => (e.target.src = $defaultCardAvatar)"
      src="../../assets/images/back.svg"
      class="goBack d-none"
      @click="goBack"
      alt="Socialays"
      v-if="accountCount != 0 && showClose != false && currentFlow != null" />
    <div class="container options first-step"
      v-if="currentFlow == null">
      <div class="row">
        <!-- <div class="col-12 col-sm-5 offset-sm-1" @click="currentFlow = 'OWN'"> -->
        <div class="col-12 col-sm-5 m-auto"
          @click="currentFlow = 'OWN'">
          <img @error="(e) => (e.target.src = $defaultCardAvatar)"
            class="back-box-hero"
            src="@/assets/images/socialays-blink.png"
            alt="OWN ACCOUNT" />
          <div class="box m-y-n-50">
            <span class="h1">
              Analyze and React
            </span>
            <p>
              Connect your own account via API
              <info class="info"
                v-tooltip.top-center="
                  'Socialays uses social channels API’s. Therefore, Socialays gets your social media data via APIs in line with your permissions. Linking an account that you’re authorized with the API allows you to take action with real-time data. '
                "></info>
            </p>
            <div class="row icons">
              <div class="col">
                <img @error="(e) => (e.target.src = $defaultCardAvatar)"
                  src="@/assets/images/add-account-box/instant.svg"
                  style="width:20px"
                  alt="" />Real-Time Sentiment
                Analysis
              </div>
              <div class="col">
                <img @error="(e) => (e.target.src = $defaultCardAvatar)"
                  src="@/assets/images/add-account-box/ai-actions.svg"
                  alt="" />
                Auto AI Actions
              </div>
              <div class="col">
                <img @error="(e) => (e.target.src = $defaultCardAvatar)"
                  src="@/assets/images/add-account-box/comment-management.svg"
                  alt="" />
                Comment Management
              </div>
            </div>
            <button class="btn btn-primary "
              @click="currentFlow = 'OWN'">Add Social Account</button>
          </div>
        </div>
        <div class="col-12 col-sm-5"
          @click="currentFlow = 'COMPETITOR'">
          <img @error="(e) => (e.target.src = $defaultCardAvatar)"
            class="back-box-hero"
            src="@/assets/images/socialays-competitor.png"
            alt="COMPETITOR" />
          <div class="box m-y-n-50">
            <span class="h1">
              Competitor Analyze
            </span>
            <p>
              Connect a social media account via URL
              <info class="info"
                v-tooltip.top-center="'Socialays can gets public social media data with URLs. Adding an account with a URL allows you to analyze public comments of an account.'">
              </info>
            </p>
            <div class="row icons">
              <div class="col">
                <img @error="(e) => (e.target.src = $defaultCardAvatar)"
                  src="@/assets/images/add-account-box/real-time-analysis.svg"
                  alt="" />
                Sentiment Analysis
              </div>
              <div class="col">
                <img @error="(e) => (e.target.src = $defaultCardAvatar)"
                  src="@/assets/images/add-account-box/get-notified.svg"
                  alt="" />
                Get Notified
              </div>
              <div class="col">
                <img @error="(e) => (e.target.src = $defaultCardAvatar)"
                  src="@/assets/images/add-account-box/insights.svg"
                  alt="" />
                Competitor Tracking and Report
              </div>
            </div>
            <button class="btn btn-orange px-4">Link account</button>
          </div>
        </div>
      </div>
    </div>
    <div class="container competitor-account-box"
      v-if="currentFlow == 'COMPETITOR'">

      <img @error="(e) => (e.target.src = $defaultCardAvatar)"
        src="@/assets/images/socialays-competitor.png"
        alt="COMPETITOR" />
      <span class="h1">
        Competitor Analyze
      </span>
      <p>
        Copy and paste a social media profile URL.
      </p>
      <div class="my-3">

        <component :is="'facebookIcon'"
          class="mx-2"
          :active="'true'"></component>
        <!-- <component :is="'instagramIcon'"
          class="mx-2"
          :active="'true'"></component> -->
        <!-- <component class="mx-2" :is="'twitterIcon'" :active="'true'"></component> -->
        <!-- <component :is="'youtubeIcon'"
          class="mx-2"
          :active="'true'"></component> -->
        <p class="my-1">
          For public profiles
        </p>
      </div>
      <div class="url-list">
        <div class="url-wrapper"
          v-for="(url, index) in competitorURLs"
          :key="index">
          <component class="url-icon"
            :active="'true'"
            :is="whichComponent(competitorURLs[index].url)"
            v-if="competitorURLs[index].url"></component>

          <input type="text"
            class="url"
            placeholder="Copy and paste a social media profile URL."
            v-model="competitorURLs[index].url"
            @paste="checkURL(competitorURLs[index].url, index)"
            @keyup.enter="checkURL(competitorURLs[index].url, index)"
            @change.once="checkURL(competitorURLs[index].url, index)"
            :class="[(competitorURLs[index].isValid == true ? 'valid' : ''), (competitorURLs[index].isValid == false ? 'invalid' : '')]" />

          <img @error="(e) => (e.target.src = $defaultCardAvatar)"
            src="@/assets/images/comments/delete.svg"
            class="delete"
            alt="Delete"
            v-if="competitorURLs.length != 1"
            @click="removeItem(index)" />

          <span class="invalid d-block text-right"
            v-if="competitorURLs[index].isValid == false">
            {{ competitorURLErrrorMessage }}
          </span>

          <Loading class="status"
            v-if="competitorURLs[index].checking"></Loading>
          <img @error="(e) => (e.target.src = $defaultCardAvatar)"
            class="status"
            src="@/assets/images/valid.svg"
            alt="Valid Account"
            v-if="competitorURLs[index].isValid == true && competitorURLs[index].checking == false" />
          <img @error="(e) => (e.target.src = $defaultCardAvatar)"
            class="status"
            src="@/assets/images/invalid.svg"
            alt="Invalid Account"
            v-if="competitorURLs[index].isValid == false && competitorURLs[index].checking == false" />
        </div>

        <button class="add-more"
          @click="addNewCompetitorURL()">+</button>
        <button class="btn btn-primary px-5 my-2"
          @click="proceedToCompetitor"
          :disabled="isAnyInvalid">Continue</button>
      </div>
    </div>

    <div class="container"
      v-if="currentFlow == 'OWN'">
      <img @error="(e) => (e.target.src = $defaultCardAvatar)"
        src="../../assets/images/icon.svg"
        class="icon"
        alt="Socialays" />
      <span class="h1">{{
        accountStatus == "TRIAL"
        ? "Add an account to get started"
        : "ADD SOCIAL ACCOUNT"
      }}</span>
      <p>Watch our AI works.</p>
      <div class="row options justify-content-center">
        <div class="col-6 col-md-3">
          <div class="box f"
            @click="addAccount('facebook')">
            <svg xmlns="http://www.w3.org/2000/svg"
              width="12.114"
              height="21.133"
              viewBox="0 0 12.114 21.133">
              <g id="facebook-logo"
                transform="translate(-21.327 0.75)">
                <path id="Path_584"
                  data-name="Path 584"
                  d="M32.292,0,29.746,0a4.471,4.471,0,0,0-4.709,4.832V7.059h-2.56a.4.4,0,0,0-.4.4v3.228a.4.4,0,0,0,.4.4h2.56v8.144a.4.4,0,0,0,.4.4h3.34a.4.4,0,0,0,.4-.4V11.088h2.993a.4.4,0,0,0,.4-.4V7.46a.4.4,0,0,0-.4-.4H29.178V5.171c0-.908.216-1.368,1.4-1.368h1.715a.4.4,0,0,0,.4-.4V.4A.4.4,0,0,0,32.292,0Z"
                  fill="none"
                  stroke-width="0.8" />
              </g>
            </svg>
            <span class="h2">Facebook</span>
            <div class="body">
              <p>Facebook Pages</p>
            </div>
            <div class="add">
              <img @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/sidebar/add.svg"
                alt="Down" />
              {{ accountStatus == "TRIAL" ? "Try for free" : "Add Account" }}
            </div>
          </div>
        </div>
        <div class="col-6 col-md-3">
          <div class="box i"
            @click="addAccount('instagram')">
            <svg xmlns="http://www.w3.org/2000/svg"
              width="17.791"
              height="17.791"
              viewBox="0 0 17.791 17.791">
              <g data-name="Group 1045"
                transform="translate(-20 -190.87)">
                <path id="Path_360"
                  data-name="Path 360"
                  d="M397.881,110.331H389.91a4.915,4.915,0,0,0-4.91,4.91v7.972a4.915,4.915,0,0,0,4.91,4.91h7.972a4.915,4.915,0,0,0,4.91-4.91v-7.972a4.915,4.915,0,0,0-4.91-4.91Zm3.331,12.881a3.335,3.335,0,0,1-3.331,3.331H389.91a3.335,3.335,0,0,1-3.331-3.331v-7.972a3.335,3.335,0,0,1,3.331-3.331h7.972a3.335,3.335,0,0,1,3.331,3.331Zm0,0"
                  transform="translate(-365 80.539)" />
                <path id="Path_361"
                  data-name="Path 361"
                  d="M422.357,143.109a4.584,4.584,0,1,0,4.584,4.584,4.589,4.589,0,0,0-4.584-4.584Zm0,7.589a3.006,3.006,0,1,1,3.006-3.005,3.009,3.009,0,0,1-3.006,3.005Zm0,0"
                  transform="translate(-393.462 52.072)" />
                <path id="Path_362"
                  data-name="Path 362"
                  d="M481.295,132.933a1.157,1.157,0,1,0,.818.339,1.161,1.161,0,0,0-.818-.339Zm0,0"
                  transform="translate(-447.622 60.91)" />
              </g>
            </svg>
            <span class="h2">Instagram</span>
            <div class="body">
              <p>Business Account</p>
            </div>
            <div class="add">
              <img @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/sidebar/add.svg"
                alt="Down" />
              {{ accountStatus == "TRIAL" ? "Try for free" : "Add Account" }}
            </div>
          </div>
        </div>
        <div class="col-6 col-md-3"
          v-if="$testAccounts.includes(getUser.name)">
          <div class="box t"
            @click="addAccount('twitter')">
            <svg xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="18"
              height="15"
              viewBox="0 0 18 15">
              <defs>
                <clipPath id="clip-path">
                  <rect id="Rectangle_567"
                    data-name="Rectangle 567"
                    width="18"
                    height="15"
                    transform="translate(0 0.399)" />
                </clipPath>
              </defs>
              <g id="twitter"
                data-name="Group 6"
                transform="translate(0 -0.399)">
                <g id="Group_5"
                  data-name="Group 5"
                  clip-path="url(#clip-path)">
                  <path id="Path_363"
                    data-name="Path 363"
                    d="M264.238,33.72a9.93,9.93,0,0,0,5.331,1.536,9.75,9.75,0,0,0,9.891-9.913,4.048,4.048,0,0,0,1.461-2.741.535.535,0,0,0-.805-.461,2.211,2.211,0,0,1-1.8.3,3.818,3.818,0,0,0-4.523-.609,3.674,3.674,0,0,0-1.995,3.443,8.86,8.86,0,0,1-5.747-3.139.535.535,0,0,0-.874.071,3.727,3.727,0,0,0-.083,3.68.556.556,0,0,0-.465.554,3.772,3.772,0,0,0,1.314,2.848.534.534,0,0,0-.137.554,3.839,3.839,0,0,0,2.172,2.327,6.225,6.225,0,0,1-3.39.566.535.535,0,0,0-.352.982Zm5.477-1.365a.535.535,0,0,0-.314-.958,2.789,2.789,0,0,1-2.19-1.139,3.762,3.762,0,0,0,.73-.117.535.535,0,0,0-.034-1.04,2.745,2.745,0,0,1-2.08-1.868,3.683,3.683,0,0,0,.816.108.535.535,0,0,0,.3-.981,2.656,2.656,0,0,1-1.138-2.9,9.94,9.94,0,0,0,6.585,2.939.533.533,0,0,0,.547-.654,2.609,2.609,0,0,1,1.362-2.971,2.734,2.734,0,0,1,3.342.508,2.7,2.7,0,0,0,1.94.221,3.446,3.446,0,0,1-.975,1.147.535.535,0,0,0-.228.464,8.679,8.679,0,0,1-8.813,9.071,8.98,8.98,0,0,1-2.881-.472,7.219,7.219,0,0,0,3.026-1.359Zm0,0"
                    transform="translate(-263.946 -20.561)" />
                </g>
              </g>
            </svg>
            <span class="h2">Twitter</span>
            <div class="body">
              <p>Profile</p>
            </div>
            <div class="add">
              <img @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/sidebar/add.svg"
                alt="Down" />
              {{ accountStatus == "TRIAL" ? "Try for free" : "Add Account" }}
            </div>
          </div>
        </div>
        <div class="col-6 col-md-3">
          <div class="box y"
            @click="addAccount('youtube')">
            <svg xmlns="http://www.w3.org/2000/svg"
              width="17.649"
              height="13.67"
              viewBox="0 0 17.649 13.67">
              <g id="youtube"
                data-name="Group 1046"
                transform="translate(-19.75 -283.885)">
                <path id="Path_463"
                  data-name="Path 463"
                  d="M9.413,72.9c-.172,0-4.352-.057-5.669-.172a.63.63,0,0,0-.229-.029,2.552,2.552,0,0,1-1.775-.83,4.154,4.154,0,0,1-.716-1.918c0-.029-.172-1.489-.172-2.92V65.688a28.644,28.644,0,0,1,.172-2.92,4.045,4.045,0,0,1,.744-1.947,2.4,2.4,0,0,1,1.746-.83H3.6c2.29-.229,5.783-.258,5.812-.258.057,0,3.521.029,5.841.2h.115a2.491,2.491,0,0,1,1.746.83,4.155,4.155,0,0,1,.716,1.918h0c0,.029.172,1.489.172,2.92v1.346a28.644,28.644,0,0,1-.172,2.92,4.045,4.045,0,0,1-.744,1.947,2.4,2.4,0,0,1-1.746.83h-.086C12.935,72.9,9.442,72.9,9.413,72.9Zm0-12.6c-.029,0-3.493.029-5.783.2H3.544a1.926,1.926,0,0,0-1.4.659A3.737,3.737,0,0,0,1.569,62.8,27.539,27.539,0,0,0,1.4,65.659v1.346c0,1.4.172,2.834.172,2.863a3.519,3.519,0,0,0,.573,1.6,1.984,1.984,0,0,0,1.432.63c.086,0,.172.029.258.029,1.26.143,5.554.2,5.583.2s3.493,0,5.783-.172h.086a1.874,1.874,0,0,0,1.374-.659,3.817,3.817,0,0,0,.6-1.632,27.538,27.538,0,0,0,.172-2.863V65.659a22.532,22.532,0,0,0-.172-2.863,4.146,4.146,0,0,0-.573-1.632,1.875,1.875,0,0,0-1.374-.659H15.2C12.906,60.334,9.442,60.306,9.413,60.306Z"
                  transform="translate(19.147 224.402)"
                  stroke-width="0.5" />
                <path id="Path_464"
                  data-name="Path 464"
                  d="M188.019,167.653a.258.258,0,0,1-.143-.029.3.3,0,0,1-.143-.258v-6.213a.3.3,0,0,1,.143-.258.26.26,0,0,1,.286,0l5.44,3.178a.3.3,0,0,1,0,.515l-5.44,3.035A.258.258,0,0,1,188.019,167.653Zm.286-6.012v5.239l4.581-2.548Z"
                  transform="translate(-161.463 126.674)"
                  stroke-width="0.5" />
              </g>
            </svg>
            <span class="h2">Youtube</span>
            <div class="body">
              <p>Channel</p>
            </div>
            <div class="add">
              <img @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/sidebar/add.svg"
                alt="Down" />
              {{ accountStatus == "TRIAL" ? "Try for free" : "Add Account" }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { HTTP } from "@/main-source";
import modal from "@/components/modal";
import info from "@/components/icons/info";
import EventBus from "@/components/event-bus";
import { mapGetters } from "vuex";

import Loading from "@/components/Loading";
// Icons
import facebookIcon from "@/components/icons/facebook";
import instagramIcon from "@/components/icons/instagram";
import twitterIcon from "@/components/icons/twitter";
import youtubeIcon from "@/components/icons/youtube";

export default {
  name: "AddAccount",
  props: ["accountCount", "showClose", "currentFlow"],
  data() {
    return {
      redirectURL: this.$redirectURL,
      modal: false,
      selectedSocialMedia: null,
      includeFacebookMessaging: false,
      includeInstagramMessaging: false,
      includeTwitterMessaging: false,
      accountStatus: JSON.parse(localStorage.getItem("accountStatus")).status,
      competitorURLErrrorMessage: "Wrong URL or Private Profile",
      competitorURLs: [
        {
          url: null,
          appName: null,
          accountId: null,
          accountName: null,
          isValid: null,
          checking: false
        }
      ],
      showDetail: false
    };
  },
  components: {
    modal,
    info,
    facebookIcon,
    instagramIcon,
    twitterIcon,
    youtubeIcon,
    Loading
  },
  methods: {
    /*eslint-disable*/
    whichComponent(url) {
      if (url.length <= 0) {
        return;
      }
      let componentName;
      if (url.includes('facebook')) {
        componentName = 'facebookIcon';
      }
      if (url.includes('instagram')) {
        componentName = 'instagramIcon';
      }
      if (url.includes('twitter')) {
        componentName = 'twitterIcon';
      }
      if (url.includes('youtube')) {
        componentName = 'youtubeIcon';
      }
      return componentName;
    },
    removeItem(index) {
      this.competitorURLs.splice(index, 1);
    },
    addNewCompetitorURL() {
      if (this.competitorURLs.length >= 10) {
        this.$notify({
          group: "info",
          type: "error",
          duration: 2000,
          text: "You can only add 10 competitor URLs"
        });
        return;
      }
      this.competitorURLs.push({
        url: null,
        appName: null,
        accountId: null,
        accountName: null,
        isValid: null,
        checking: false
      });
    },
    proceedToCompetitor() {
      let hasInvalid = this.competitorURLs.some(url => url.isValid != true);
      if (hasInvalid) {
        this.$notify({
          group: "info",
          type: "error",
          duration: 2000,
          text: "Please check URLs"
        });
        return;
      }

      localStorage.setItem("competitorURLs", JSON.stringify(this.competitorURLs));
      window.location = this.$redirectURL + "social/competitor/save";

    },
    addAccount: function (socialMedia) {
      this.selectedSocialMedia = socialMedia;
      if (
        (socialMedia == "facebook" || socialMedia == "instagram") &&
        this.modal == false
      ) {
        this.modal = true;
        return false;
      }
      let redirect_url = this.redirectURL + "social/" + socialMedia + "/save";
      let uri = "accounts/" + socialMedia + "/url?redirect_url=" + redirect_url;

      HTTP.get(uri).then((result) => {
        if (result.status == 200) {
          window.location = result.data.url;
        }
      });
    },
    close: function () {
      this.$emit("close", true);
      this.currentFlow = 'OWN';
      
      EventBus.$emit("mobileRangeFilter", false);
    },
    goBack: function () {
      this.currentFlow = null;
    },
    goLink: function (link) {
      window.open(link, "_blank");
    },
    checkURL: function (URL, index) {

      if (!URL) {
        return;
      }

      let isExist = this.competitorURLs.filter(function (item) {
        return item.url == URL;
      });

      if (URL.includes("socialays")) {
        this.competitorURLs[index].isValid = true;
        this.competitorURLs[index].appName = "FACEBOOK";
        this.competitorURLs[index].accountId = "102839634555425";
        this.competitorURLs[index].accountName = "Socialays";
        return;
      }


      if (isExist.length > 1) {
        this.$notify({
          group: "info",
          type: "error",
          text: "URL already exist"
        });
        return;
      }



      let appName = null;
      if (URL.includes('facebook')) {
        appName = 'FACEBOOK';
      }
      if (URL.includes('instagram')) {
        appName = 'INSTAGRAM';
      }
      if (URL.includes('twitter')) {
        appName = 'TWITTER';
      }
      if (URL.includes('youtube')) {
        appName = 'YOUTUBE';
      }

      let data = {
        url: URL,
        appName: appName
      }

      if (appName == null) {
        this.competitorURLs[index].isValid = false;
        console.log("false");
        return;
      }

      this.competitorURLs[index].checking = true;

      HTTP.post('competitor/check', data).then(response => {
        console.log("🚀 ~ file: AddAccount.vue ~ line 398 ~ HTTP.post ~ response", response.data.success)
        if (response.data.success) {
          this.competitorURLs[index].isValid = true;
          this.competitorURLs[index].appName = appName;
          this.competitorURLs[index].accountId = response.data.object.userId;
          this.competitorURLs[index].accountName = response.data.object.accountName;
        } else {
          this.competitorURLs[index].isValid = false;
        }
        this.competitorURLs[index].checking = false;
      }).catch((e) => {
        this.competitorURLErrrorMessage = e.response.data.message;
        this.competitorURLs[index].isValid = false;
        this.competitorURLs[index].checking = false;
      });

    }
  },
  watch: {
    competitorURLs: {
      handler: function (val) {
        this.checkAll
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(["getUser"]),
    isAnyInvalid: function () {
      return this.competitorURLs.some(function (item) {
        return item.isValid == false;
      });
    }
  }
};
</script>
<style lang="scss">
@import "@/scss/colors.scss";

.add-account {
  min-height: calc(100vh - 130px);
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: -20px;

  .icon {
    width: 60px;
    height: auto;
  }

  .h1 {
    font-size: 21px;
    font-weight: bold;
    display: block;
    font-family: "Fenomen Sans";
    margin: 10px auto;
  }

  p {
    color: lighten($black, 30%);
    font-size: 16px;
  }

  .small {
    font-size: 11px;

    a {
      color: $brandcolor;
    }
  }

  .first-step {
    .option {
      padding: 70px 120px;
    }

    .icons {
      margin: 30px auto !important;
      font-size: 11px;
      font-weight: bold;
      color: lighten($black, 50%);

      .col {
        padding: 0;
      }

      img {
        width: 25px;
        height: auto;
        display: block;
        margin: 10px auto;
      }
    }
  }

  .competitor-account-box {
    padding-bottom: 100px;

    .url-list {
      margin-top: 20px;
    }

    .url-wrapper {
      position: relative;
      width: 100%;
      max-width: 600px;
      margin: 20px auto 10px auto;

      .delete {
        position: absolute;
        right: -35px;
        top: 23px;
        width: 20px;
        height: 20px;
        object-fit: contain;
        object-position: center;
        cursor: pointer;
      }

      .status {
        position: absolute;
        right: 20px;
        top: 23px;
        width: 20px;
        height: 20px;
        object-fit: contain;
        object-position: center;
      }

      .url-icon {
        position: absolute;
        left: 10px;
        top: 10px;
      }
    }

    .url {
      width: 100%;
      height: 65px;
      padding-right: 47px;
      line-height: 65px;
      font-size: 21px;
      color: lighten($black, 35%);
      padding-left: 60px;
      border-radius: 15px;
      background: #fff;
      box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.07);
      display: block;
      border: 1px solid transparent;
      font-weight: 400;
    }

    .url.valid {
      border-color: $green;
    }

    .url.invalid {
      border-color: lighten($brand-red, 20%);
    }

    .add-more {
      background: $white;
      border: 1px dashed darken($white, 25%);
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: block;
      margin: 50px auto;
      position: relative;

      &:after {
        content: "Add more";
        display: block;
        text-align: center;
        width: 200px;
        left: -80px;
        margin-top: 15px;
        position: absolute;
        color: darken($white, 25%);
      }
    }
  }

  .options {
    margin-top: 20px;

    .col-md-3,
    .col-sm-5 {
      .box {
        background: $white;
        min-height: 100px;
        padding: 15px;
        border-radius: 15px;
        transition: all 0.3s ease;
        position: relative;
        z-index: 9;

        .body {
          height: 40px;
          margin-top: 5px;
          padding-top: 5px;
        }

        p {
          position: relative;
          width: 100%;
        }

        .has-switch {
          // padding-right: 40px;
          position: relative;
          font-size: 13px;
          width: 100%;
        }

        .info {
          width: 10px;
          height: 10px;
          margin: 0;
          margin-top: -10px;
          fill: lighten($black, 30%);
        }

        .switch {
          position: relative;
          top: 2px;
          margin: 5px 0px 0px 5px !important;
        }

        cursor: pointer;

        &:hover {
          transform: scale(1.05);
          box-shadow: 0px 3px 40px rgba(0, 0, 0, 0.05);
        }
      }

      svg {
        margin: 40px auto;
        width: 40px;
        height: 70px;
        object-fit: contain;
        object-position: center;
      }

      .f {
        svg {
          margin-top: 50px;
          margin-bottom: 50px;
          width: 40px;
          height: 50px;
          stroke: $facebook;
        }
      }

      .i {
        svg {
          fill: $instagram;
          stroke: none;
        }
      }

      .t {
        svg {
          fill: $twitter;
        }
      }

      .y {
        svg {
          fill: $youtube;
        }
      }

      .h2 {
        text-transform: uppercase;
        font-size: 18px;
        font-family: "Fenomen Sans Black";
        display: block;
        letter-spacing: 3px;
      }

      .add {
        margin: 40px -15px 0px -15px;
        border-top: 1px solid lighten($black, 95%);
        padding-top: 15px;
      }
    }
  }

  .close {
    position: absolute;
    right: 25px;
    top: 25px;
    width: 20px;
    cursor: pointer;
  }

  .goBack {
    position: absolute;
    left: 25px;
    top: 25px;
    width: 13px;
    cursor: pointer;
  }
}

.more-details {
  font-size: 12px;
  text-align: left;
  height: 0px;
  overflow: hidden;
  transition: all .3s ease;

  &.show {
    margin-top: 15px;
    height: auto;
    overflow: scroll;
  }
}

@media screen {
  .add-account {
    margin-top: 10px;

    .options {
      padding-bottom: 120px;

      .col-md-3 {
        padding: 5px;

        .add {
          margin: 0px;
        }

        p {
          font-size: 13px;
          font-weight: bold;
          padding: 0;
          width: 100%;
        }

        .f svg {
          margin-top: 20px;
          margin-bottom: 15px;
          width: 40px;
          height: 42px;
          stroke: $facebook;
        }

        svg {
          margin-top: 10px;
          margin-bottom: 15px;
          width: 40px;
          height: 50px;
        }
      }
    }
  }
}
</style>
