<script>
import { Line, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  name: "LineChart",
  extends: Line,
  mixins: [reactiveProp],
  props: ["graph", "options", "height"],
  data() {
    return {
      ctx: null,
      gradientStroke: null,
      gradientFill: null,
    };
  },
  methods: {
    setChart() {
      this.renderChart(
        {
          labels: this.labels,
          datasets: [
            {
              data: this.values,
              borderColor: this.gradientStroke,
              pointBorderColor: this.gradientStroke,
              pointBackgroundColor: this.gradientStroke,
              pointHoverBackgroundColor: this.gradientStroke,
              pointHoverBorderColor: this.gradientStroke,
              pointBorderWidth: this.height > 70 ? 5 : 0,
              pointHoverRadius: this.height > 70 ? 5 : 0,
              pointHoverBorderWidth: this.height > 70 ? 2 : 0,
              pointRadius: this.height > 70 ? 1 : 0,
              fill: true,
              backgroundColor: this.gradientFill,
              borderWidth: 0.4,
            },
          ],
        },
        this.options
      );
    },
  },
  mounted() {
    var ctx = document.getElementById("line-chart").getContext("2d");
    this.gradientStroke = ctx.createLinearGradient(0, 0, 0, 80);
    this.gradientStroke.addColorStop(0, "#1DC3A0");
    this.gradientStroke.addColorStop(1, "#7EF6CD");

    let heightSize = 60;
    if (this.height > 70) {
      heightSize = 220;
    }

    this.gradientFill = ctx.createLinearGradient(0, 0, 0, heightSize);
    this.gradientFill.addColorStop(0, "rgba(29, 195, 160, 5)");
    this.gradientFill.addColorStop(0.5, "rgba(255, 255, 255, 0.25)");
    this.gradientFill.addColorStop(1, "rgba(255, 255, 255, 0)");

    this.setChart();
  },
  watch: {
    graph: function () {
      this.setChart();
    },
  },
  computed: {
    values: function () {
      let values = this.graph.map((d) => {
        return d.value;
      });
      return values;
    },
    labels: function () {
      let labels = this.graph.map((d) => {
        return new Date(d.createdDate * 1000).toLocaleDateString("en", {
          month: "short",
          day: "numeric",
        });
      });
      // let pocket = [...labels];
      // labels.length = 6;
      // labels.push(pocket[pocket.length - 1]);
      return labels;
    },
  },
};
</script>
