<template>
  <div id="page--posts">
    <!-- Delete All Comment From User -->
    <modal
      v-if="deleteAllCommentByUserModal"
      @close="deleteAllCommentByUserModal = false"
    >
      <div slot="title">Delete All Comments</div>
      <div class="row head">
        <div class="col">
          <i class="fas fa-exclamation-triangle"></i>
          Warning
        </div>
        <div class="col text-right">
          <i
            class="fas fa-times"
            @click="deleteAllCommentByUserModal = false"
          ></i>
        </div>
      </div>
      <div slot="body">
        All comments of this user will be deleted. Do you want to continue?
      </div>
      <div slot="footer">
        <button
          class="btn btn-primary"
          @click="deleteAllCommentByUserModal = false"
        >
          CANCEL
        </button>
        <button
          class="btn btn-danger"
          @click="deleteAllCommentByUser"
          :disabled="xhrDeleteComment"
        >
          <span v-if="!xhrDeleteComment"> DELETE </span>

          <Loading v-if="xhrDeleteComment" :white="true"></Loading>
        </button>
      </div>
    </modal>
    <!-- Delete All Comment From User -->

    <!-- Block User Modal -->
    <modal v-if="blockUserModal" @close="blockUserModal = false">
      <div slot="title">Block User</div>
      <div class="row head">
        <div class="col">
          <i class="fas fa-exclamation-triangle"></i>
          Warning
        </div>
        <div class="col text-right">
          <i class="fas fa-times" @click="blockUserModal = false"></i>
        </div>
      </div>
      <div slot="body">You are blocking a user. Do you want to continue?</div>
      <div slot="footer">
        <button class="btn btn-primary" @click="blockUserModal = false">
          CANCEL
        </button>
        <button
          class="btn btn-danger"
          @click="blockUser(false)"
          :disabled="xhrDeleteComment"
        >
          <span v-if="!xhrDeleteComment"> BLOCK </span>

          <Loading v-if="xhrDeleteComment" :white="true"></Loading>
        </button>
      </div>
    </modal>
    <!-- Block User Modal -->
    <!-- Delete Modal -->
    <modal v-if="deleteModal" @close="deleteModal = false">
      <div slot="title">Delete Comment</div>
      <div class="row head">
        <div class="col">
          <i class="fas fa-exclamation-triangle"></i>
          Warning
        </div>
        <div class="col text-right">
          <i class="fas fa-times" @click="deleteModal = false"></i>
        </div>
      </div>
      <div slot="body">
        You are deleting a comment.
        <br />Comment will be deleted from the linked account.
      </div>
      <div slot="footer">
        <button class="btn btn-primary" @click="deleteModal = false">
          CANCEL
        </button>
        <button class="btn btn-danger" @click="deleteComment">DELETE</button>
      </div>
    </modal>
    <!-- Delete Modal -->
    <!-- Filter Desktop -->
    <div class="row filter d-none d-md-block">
      <div class="col-12 left-filter">
        <!-- Left -->
        <div class="col-7 left">
          <div class="col-5 has-icon p-0">
            <i class="fas fa-search"></i>
            <input-tag
              v-model="search"
              class="search taggable"
              placeholder="Search Posts, User or Word..."
            ></input-tag>
          </div>

          <div class="col-6 filters p-0 has-icon">
            <i
              class="dot main"
              :class="[
                { all: selectedFilter.value == 'All Posts' },
                { positive: selectedFilter.value == 'Positive' },
                { negative: selectedFilter.value == 'Negative' },
                { neutral: selectedFilter.value == 'Neutral' },
                { question: selectedFilter.value == 'Question' },
                { hidden: selectedFilter.value == 'Hidden' },
                { spam: selectedFilter.value == 'Spam' },
              ]"
            ></i>
            <v-select
              v-model="selectedFilter"
              :options="filters"
              :clearable="false"
              :searchable="false"
              class="filter--polarity"
            >
              <template slot="option" slot-scope="option">
                <div
                  @click="
                    option.value == 'All Posts'
                      ? (selectedFilter = filters[0])
                      : ''
                  "
                  :class="{ selected: selectedFilter.value === option.value }"
                >
                  <i
                    class="dot"
                    :class="[
                      { positive: option.value == 'Positive' },
                      { negative: option.value == 'Negative' },
                      { neutral: option.value == 'Neutral' },
                      { question: option.value == 'Question' },
                      { hidden: option.value == 'Hidden' },
                      { spam: option.value == 'Spam' },
                    ]"
                  ></i>
                  {{ commentTypeSortLabel(option) }}
                </div>
              </template>
            </v-select>
          </div>
          <!-- Eski Bitiş -->
          <button
            class="btn-filter"
            type="button"
            id="dropdownMenu"
            data-toggle="dropdown"
          >
            <img
              @error="(e) => (e.target.src = $defaultCardAvatar)"
              src="@/assets/images/comment.svg"
              alt
              v-if="orderBy == 'numOfReplies'"
            />
            <img
              @error="(e) => (e.target.src = $defaultCardAvatar)"
              src="@/assets/images/sort/desc.svg"
              alt
              v-if="order == 'DESC' && orderBy == 'createdTime'"
            />
            <img
              @error="(e) => (e.target.src = $defaultCardAvatar)"
              src="@/assets/images/sort/asc.svg"
              alt
              v-if="order == 'ASC' && orderBy == 'createdTime'"
            />
            <img
              @error="(e) => (e.target.src = $defaultCardAvatar)"
              src="@/assets/images/sort/heart.svg"
              alt
              v-if="orderBy == 'numOfLikes'"
            />
          </button>
          <div class="dropdown-menu order animate slideIn">
            <button
              class="dropdown-item"
              :class="{ selected: order == 'DESC' && orderBy == 'createdTime' }"
              type="button"
              @click="
                order = 'DESC';
                orderBy = 'createdTime';
              "
            >
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/sort/desc.svg"
                @click="mobileSearchActive = true"
                alt
              />
              Newest
            </button>
            <button
              class="dropdown-item"
              :class="{ selected: order == 'ASC' && orderBy == 'createdTime' }"
              type="button"
              @click="
                order = 'ASC';
                orderBy = 'createdTime';
              "
            >
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/sort/asc.svg"
                @click="mobileSearchActive = true"
                alt
              />
              Oldest
            </button>
            <button
              class="dropdown-item"
              :class="{ selected: order == 'DESC' && orderBy == 'numOfLikes' }"
              type="button"
              @click="
                order = 'DESC';
                orderBy = 'numOfLikes';
              "
            >
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/sort/heart.svg"
                @click="mobileSearchActive = true"
                alt
              />
              Most Liked
            </button>
            <button
              class="dropdown-item"
              :class="{
                selected: order == 'DESC' && orderBy == 'numOfReplies',
              }"
              type="button"
              @click="
                order = 'DESC';
                orderBy = 'numOfReplies';
              "
            >
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/comment.svg"
                @click="mobileSearchActive = true"
                alt
              />
              Most Replies
            </button>
          </div>
        </div>
        <!-- Right -->
        <div class="col-5 text-right pr-0 topfilter">
          <range-filter :range="range"></range-filter>

          <DatePicker
            class="datepicker"
            ref="datepicker"
            v-model="date"
            placeholder="Select Date"
            :disabled-date="disabledDates"
            range
          >
            <template v-slot:header="{ emit }">
              <!-- <button
                class="btn radio p-0 d-block w-100 text-left"
                @click="setRange('ALL')"
              >
                <radio-button :checked="range == 'ALL'"></radio-button>All Time
              </button> -->
              <button
                class="btn radio p-0 d-block w-100 text-left"
                @click="setQuickRange('14Days')"
              >
                <radio-button :checked="quickRange == '14Days'"></radio-button
                >Last 14 Days
              </button>
              <button
                class="btn radio p-0 d-block w-100 text-left"
                @click="setQuickRange('30Days')"
              >
                <radio-button :checked="quickRange == '30Days'"></radio-button
                >Last 30 Days
              </button>
              <button
                class="btn radio p-0 d-block w-100 text-left"
                @click="setQuickRange('ThisWeek')"
              >
                <radio-button :checked="quickRange == 'ThisWeek'"></radio-button
                >This Week
              </button>
              <button
                class="btn radio p-0 d-block w-100 text-left"
                @click="setQuickRange('LastWeek')"
              >
                <radio-button :checked="quickRange == 'LastWeek'"></radio-button
                >Last Week
              </button>
              <button
                class="btn radio p-0 d-block w-100 text-left"
                @click="setQuickRange('ThisMonth')"
              >
                <radio-button
                  :checked="quickRange == 'ThisMonth'"
                ></radio-button
                >This Month
              </button>
              <button
                class="btn radio p-0 d-block w-100 text-left mb-2"
                @click="setQuickRange('LastMonth')"
              >
                <radio-button
                  :checked="quickRange == 'LastMonth'"
                ></radio-button
                >Last Month
              </button>
            </template>
          </DatePicker>
        </div>
        <!-- Right End -->
      </div>
    </div>

    <!-- Filter Desktop End -->
    <!-- Filter mobile -->
    <div class="row mobile-filter p-0 d-sm-none">
      <div class="p-0" :class="[mobileSearchActive ? 'col-5' : 'col-4']">
        <span>
          <img
            @error="(e) => (e.target.src = $defaultCardAvatar)"
            src="@/assets/images/search.svg"
            @click="mobileSearchActive = true"
            alt
          />
        </span>
        <input-tag
          v-model="search"
          class="taggable mobile-search"
          :class="{ active: mobileSearchActive }"
          placeholder="Search comment, @user or word..."
        ></input-tag>
      </div>
      <div
        class="text-center group-select"
        :class="[mobileSearchActive ? 'col-3 p-0' : 'col-4']"
      >
        <!-- Mobile Post Filter -->
      </div>
      <div
        class="text-right mobile"
        :class="[mobileSearchActive ? 'col ' : 'col-4']"
      >
        <span @click="mobileOrderModal = true">
          <img
            @error="(e) => (e.target.src = $defaultCardAvatar)"
            src="@/assets/images/filter.svg"
            alt="Filter"
          />
        </span>
        <span @click="mobileRangeModal = true">
          <img
            @error="(e) => (e.target.src = $defaultCardAvatar)"
            src="@/assets/images/calander.svg"
            alt="Set"
          />
        </span>
      </div>

      <div class="col-12 tags" v-if="search.length > 0">
        <span v-for="(q, index) in search" :key="index">
          {{ q }}
          <i class="remove" @click="removeTag(q)">x</i>
        </span>
      </div>
    </div>
    <!-- Filter mobile -->
    <!-- Result Info -->
    <div class="row result-info">
      <div class="col-12 col-sm-6">
        <div class="row" v-if="resultCount > 0">
          <div class="col-8">Approximately {{ resultCount }} results found</div>
          <div class="col-4 text-right p-0"></div>
        </div>
      </div>
      <div class="col-6"></div>
    </div>
    <!-- Result Info -->
    <!-- Comments -->
    <div class="row">
      <!-- has active add when select some elements -->
      <div
        id="posts"
        class="col-12 col-md-7 list"
        :class="[
          { 'no-data': !posts.length && !isLoading },
          { 'has-selected': selectedPost.data.id != null },
        ]"
      >
        <span class="placeholder" v-if="!posts.length && !isLoading">
          <img
            @error="(e) => (e.target.src = $defaultCardAvatar)"
            src="@/assets/images/icon.svg"
            class="logo"
            alt="Socialays"
          />
          No Posts Yet
        </span>
        <post
          v-for="post in posts"
          :key="post.id"
          :post="post"
          :name="post.author"
          :content="post.content"
          :img="post.imageUrl"
          :countOfReplies="post.numOfReplies"
          :numOfLikes="post.numOfLikes"
          :isHidden="post.isHidden"
          :isQuestion="post.isQuestion"
          :isSpam="post.isSpam"
          :polarity="post.polarity"
          :date="post.createdTime"
          :majorStatus="post.polarityStrings[0]"
          :polarityCounts="post.polarityCounts"
          class="post-item"
          @click.native="selectPost(post.postId)"
          :class="{ active: post.id == selectedPost.data.id }"
        ></post>

        <template v-if="isLoading && !endOfPosts">
          <SkeletonLoader v-for="i in 5" :key="i" />
        </template>

        <div
          class="end placeholder mt-5"
          v-if="!isLoading && endOfPosts && posts.length != 0"
        >
          <img src="@/assets/images/icon.svg" class="logo" alt="Socialays" />
          No More Posts
        </div>
      </div>
      <!-- @click.native="selectComment(index)" -->
      <!-- add Not selected class if not selected element  -->
      <div
        id="rdetails"
        class="col-12 col-sm-5 list"
        :class="{
          'no-data': selectedPost.data.id === null,
          'show-details': isShowDetailsOnMobile,
        }"
      >
        <span
          class="placeholder"
          v-if="selectedPost.data.id === null && posts.length != 0"
        >
          <img
            @error="(e) => (e.target.src = $defaultCardAvatar)"
            src="@/assets/images/icon.svg"
            class="logo"
            alt="Socialays"
          />
          Please Select Post</span
        >

        <!-- right start -->
        <right-post-view
          id="right-content"
          v-if="selectedPost.data.id !== null"
          :post="selectedPost"
          :account="activeAccountDetails"
          :profilePic="'https://via.placeholder.com/200/ff6c37/c40d3c/'"
          @filterSubComment="filterSubComment"
          @close="close"
        ></right-post-view>
        <!-- right end -->

        <!-- SubComments Filter -->

        <subcommentfilter
          id="right-content"
          v-if="selectedPost.data.id !== null"
          @filterSubComment="filterSubComment"
          :polarityCounts="selectedPost.polarityCounts"
          :totalComments="selectedPost.totalElements"
        ></subcommentfilter>
        <!-- SubComments Filter END -->

        <!-- subcomments start -->
        <div class="subcomments">
          <comment
            v-for="subcomment in subcomments"
            :key="subcomment.id"
            :id="subcomment.id"
            :comment="subcomment"
            :authorId="subcomment.authorId"
            :commentId="subcomment.commentId"
            :name="subcomment.authorName"
            :profilePic="profileImg(subcomment.profilePic, subcomment.authorId)"
            :content="subcomment.content"
            :countOfReplies="subcomment.numOfReplies"
            :isHidden="subcomment.isHidden"
            :hidden="subcomment.hidden"
            :isQuestion="subcomment.isQuestion"
            :isSpam="subcomment.isSpam"
            :polarity="subcomment.polarity"
            :polarityStrings="
              subcomment.polarityStringsGpt
                ? subcomment.polarityStringsGpt
                : subcomment.polarityStrings
            "
            :disableSelect="true"
            :commentImgs="subcomment.imageUrl"
            :activeAccountDetails="activeAccountDetails"
            :sub="true"
            :isSub="true"
            :likeCount="subcomment.numOfLikes"
            :date="subcomment.createdTime"
          ></comment>
        </div>
        <template v-if="isSubCommentLoading && selectedPost.data.id != null">
          <SkeletonLoader v-for="i in 5" :key="i" />
        </template>
        <!-- subcomments end -->
      </div>
    </div>
    <!-- Comments End -->
    <!-- Mobile Order Modal Start -->
    <mobileSortFilter
      :mobileOrderModal="mobileOrderModal"
      :f_order="order"
      :f_orderBy="orderBy"
      @close="mobileSortModalSetAndClose"
    ></mobileSortFilter>
    <!-- Mobile Order Modal End -->
    <!-- Mobile Range Modal Start -->
    <mobileRangeFilter
      :mobileRangeModal="mobileRangeModal"
      :date="date"
      :f_range="range"
      @close="mobileRangeModalSetAndClose"
    ></mobileRangeFilter>
    <!-- Mobile Order Modal -->
  </div>
</template>
<script>
import { HTTP } from "@/main-source";
import EventBus from "@/components/event-bus";
import comment from "@/components/facebook/comment.vue";
import post from "@/components/facebook/post.vue";
import rightPostView from "@/components/facebook/right-post-view.vue";
import { mapGetters } from "vuex";
import rangeFilter from "@/components/filters/range";
import modal from "@/components/modal";
import InputTag from "vue-input-tag";
import subcommentfilter from "@/components/filters/subcomments";
import SkeletonLoader from "@/components/placeholders/comment";
import radioButton from "@/components/radio-button";
// Subcomment filter for mobile
import mobileRangeFilter from "@/components/filters/mobile/date";
import mobileSortFilter from "@/components/filters/mobile/sort";

export default {
  name: "comments",
  data() {
    return {
      posts: [],
      subcomments: [],
      selectedPost: {
        data: {
          id: null,
        },
      },
      resultCount: 0,
      selectedFilter: {
        label: "All Posts",
        value: "All Posts",
      },
      isShowSubComments: false,
      commentPost: [],
      page: 0,
      subPage: 0,
      endOfComments: false,
      endOfPosts: false,
      date: JSON.parse(localStorage.getItem("selectedDate")) || null,
      start: null,
      finish: null,
      order: "DESC",
      search: [],
      range: localStorage.getItem("selectedRange") || "ALL",
      quickRange: localStorage.getItem("selectedQuickRange") || null,
      view: null,
      polarityCounts: {
        totalComments: null,
      },
      pocket: {
        polarityStrings: null,
        isHidden: null,
        isQuestion: null,
        isSpam: null,
      },
      facebookAccessToken: "",
      activeAccountDetails: [],
      isShowDetailsOnMobile: false,
      deleteModal: false,
      commentUniqueId: null,
      isDeletedCommentSub: false,
      orderBy: "createdTime",
      subOrder: "DESC",
      isLoading: true,
      isGettingPosts: false,
      mobileSearchActive: false,
      mobileAiFilter: false,
      mobileEmotionFilter: false,
      mobileOrderModal: false,
      mobileRangeModal: false,
      isSubCommentLoading: false,
      deleteAllCommentByUserModal: false,
      blockUserModal: false,
      processingAuthorId: null,
      xhrDeleteComment: false,
    };
  },
  created() {
    this.setActiveSocialMediaAccount();
    this.getPosts();
  },
  components: {
    comment,
    post,
    rightPostView,
    rangeFilter,
    modal,
    InputTag,
    subcommentfilter,
    SkeletonLoader,
    mobileRangeFilter,
    mobileSortFilter,
    radioButton,
  },
  methods: {
    commentTypeSortLabel({ value, label }) {
      if (value === "All Posts") {
        return `${label} (${this.polarityCounts.totalComments})`;
      }

      return label;
    },

    getPosts: function(next) {
      if (this.isGettingPosts) {
        return;
      }

      this.isGettingPosts = true;

      this.isLoading = true;

      if (next) {
        this.page++;
      }

      let postType =
        this.selectedFilter.value !== "All Posts"
          ? this.selectedFilter.value.toLowerCase()
          : "";

      let uri =
        "social/" +
        this.getActiveSocialMediaAccount +
        "/" +
        this.getActiveSocialMedia +
        "/post?page=" +
        this.page +
        "&order=" +
        this.order +
        "&orderby=" +
        this.orderBy;

      if (this.range != null && this.start == null && this.finish == null) {
        uri += "&range=" + this.range;
      }

      if (this.selectedFilter.value != "All Posts") {
        uri += "&polarity=" + postType;
      }

      if (this.start && this.finish) {
        uri +=
          "&page=" +
          this.page +
          "&start=" +
          this.start +
          "&finish=" +
          this.finish;
      }

      if (this.getActiveSocialMediaAccount) {
        HTTP.get(uri)
          .then((result) => {
            
              // this.polarityCounts = result.data.polarityCounts;
              this.resultCount = result.data.totalElements;
              this.polarityCounts.totalComments = result.data.totalElements;
            
            if (!next) {
              this.posts = [];
            }
            result.data.content.forEach((post) => {
              post.data.polarityCounts = post.polarityCounts;
              post.data.offensiveCounts = post.offensiveCounts;
              this.posts.push(post.data);
            });
            this.show = true;
            this.isLoading = false;
          })
          .catch((e) => {
            if (e.response.status == 400) {
              this.endOfPosts = true;
            }
            if (!next) {
              this.resultCount = 0;
              this.posts = [];
            }
          })
          .finally(() => {
            this.isLoading = false;
            this.isGettingPosts = false;
          });
      }
    },
    selectPost: function(postId) {
      this.selectedPost.data.id = null;
      this.subcomments = [];
      let uri =
        "social/" +
        this.getActiveSocialMediaAccount +
        "/" +
        this.getActiveSocialMedia +
        "/post/" +
        postId;

      HTTP.get(uri).then((result) => {
        this.selectedPost = result.data; // Post Data

        let detailsView = document.getElementById("rdetails");
        detailsView.scrollTop = 0;
        this.subPage = 0;
        this.endOfComments = false;
        this.getSubComments();
      });

      this.showDetailsOnMobile();
    },
    getSubComments: function(next = false) {
      this.isSubCommentLoading = true;
      let postId = this.selectedPost.data.id;
      let uri =
        "social/" +
        this.getActiveSocialMediaAccount +
        "/" +
        this.getActiveSocialMedia +
        "/post/" +
        postId;

      if (next) {
        this.subPage++;
      }

      if (!this.endOfComments) {
        uri += "/comments/";

        uri += "?polarity=" + this.subFilter;

        uri += "&page=" + this.subPage + "&order=" + this.subOrder;

        HTTP.get(uri)
          .then((result) => {
            result.data.data.forEach((comment) => {
              this.subcomments.push(comment.data);
            }); // console.log(this.subcomments);
            this.isSubCommentLoading = false;
          })
          .catch((e) => {
            if (e.response.status == 400) {
              this.endOfComments = true;
            }
            if (this.subPage == 0 && e.response.status == 400) {
              this.subcomments = [];
            }
            this.isSubCommentLoading = false;
          }); // Get Subcomments End
      }
    },
    showSubComments: function(commentId) {
      console.log(commentId);
    },
    setRange: function(value) {
      localStorage.setItem("selectedRange", value);
      localStorage.removeItem("selectedQuickRange");
      this.range = value;
      this.quickRange = null;
      this.$refs.datepicker.closePopup();
    },
    setQuickRange: function(which) {
      localStorage.setItem("selectedQuickRange", which);
      localStorage.removeItem("selectedRange");
      this.range = null;

      this.close();
      this.date = null;
      this.start = null;
      this.finish = null;
      let today = new Date();
      switch (which) {
        case "14Days":
          this.quickRange = "14Days";
          today = new Date();
          let f14 = new Date(today.setDate(new Date().getDate() - 14));
          this.date = [f14, new Date()];
          break;
        case "30Days":
          this.quickRange = "30Days";
          today = new Date();
          let f30 = new Date(today.setDate(new Date().getDate() - 30));
          this.date = [f30, new Date()];
          break;
        case "ThisWeek":
          this.quickRange = "ThisWeek";
          let curr = new Date();
          let first = curr.getDate() - curr.getDay() + 1;
          let last = first + 6;

          let firstday = new Date(curr.setDate(first));
          let lastday = new Date(curr.setDate(last));
          this.date = [firstday, lastday];
          break;
        case "LastWeek":
          this.quickRange = "LastWeek";
          today = new Date();
          let curl = new Date(today.setDate(new Date().getDate() - 7));
          let firstl = curl.getDate() - curl.getDay() + 1;
          let lastl = firstl + 6;

          let firstlday = new Date(curl.setDate(firstl));
          let lastlday = new Date(curl.setDate(lastl));
          this.date = [firstlday, lastlday];
          break;
        case "ThisMonth":
          this.quickRange = "ThisMonth";
          var date = new Date(),
            y = date.getFullYear(),
            m = date.getMonth();
          var firstDay = new Date(y, m, 1);
          var lastDay = new Date(y, m + 1, 0);
          this.date = [firstDay, lastDay];
          break;
        case "LastMonth":
          this.quickRange = "LastMonth";
          var date = new Date();
          var firstDayPrevMonth = new Date(
            date.getFullYear(),
            date.getMonth() - 1,
            1
          );
          var lastDayPrevMonth = new Date(
            date.getFullYear(),
            date.getMonth(),
            0
          );
          this.date = [firstDayPrevMonth, lastDayPrevMonth];
          break;
      }

      this.$refs.datepicker.closePopup();
    },
    scroll: function() {
      let commentListView = document.getElementById("posts");

      commentListView.onscroll = () => {
        let position = commentListView.scrollTop - 200;
        let end =
          commentListView.scrollHeight - commentListView.offsetHeight - 200;
        if (position == end && !this.endOfPosts) {
          this.getPosts(true);
        }
      };
    },
    detailScroll: function() {
      let subcommentListView = document.getElementById("rdetails");
      // let lastItem = document.querySelector("#comments .col-12:last-child");
      if (subcommentListView) {
        subcommentListView.addEventListener("scroll", () => {
          {
            let position = subcommentListView.scrollTop - 200;
            let end =
              subcommentListView.scrollHeight -
              subcommentListView.offsetHeight -
              200;
            if (position.toFixed() >= end && !this.endOfComments) {
              this.getSubComments(true);
            }
          }
        });
      }
    },
    filterSubComment: function(filter, order) {
      this.subFilter = filter;
      console.log("🚀 ~ file: Posts.vue:741 ~ filter", filter);
      console.log(
        "🚀 ~ file: Posts.vue:748 ~ this.endOfComments",
        this.endOfComments
      );
      this.subOrder = order;
      this.subPage = 0;
      this.endOfComments = false;
      this.subcomments = [];

      this.getSubComments();
      // HTTP.get(
      //   "social/" +
      //     this.getActiveSocialMediaAccount +
      //     "/" +
      //     this.getActiveSocialMedia +
      //     "/post/" +
      //     this.selectedPost.data.id +
      //     "/comments/" +
      //     filter +
      //     "?order=" +
      //     order
      // ).then((result) => {
      //   this.subcomments = [];
      //   result.data.data.forEach((comment) => {
      //     this.subcomments.push(comment.data);
      //   });
      //   console.log(this.subcomments);
      // });
    },
    close: function() {
      this.view = null;
      this.selectedPost = {
        data: {
          id: null,
        },
      };
      this.subcomments = null;
      this.isShowDetailsOnMobile = false;
    },
    profileImg: function(img, authorId) {
      let imgUrl = img ? img : null;
      if (this.getActiveSocialMedia == "facebook") {
        imgUrl =
          "https://graph.facebook.com/" +
          authorId +
          "/picture?access_token=" +
          this.facebookAccessToken;
      }
      return imgUrl;
    },
    setActiveSocialMediaAccount: function() {
      HTTP.get("user/me").then((result) => {
        let accountId = this.getActiveSocialMediaAccount;

        this.activeAccountDetails = result.data.socialMediaAccounts.filter(
          (account) => account.accountId == accountId
        )[0];

        if (this.activeAccountDetails == undefined) {
          this.activeAccountDetails = result.data.authorizedAccounts.filter(
            (account) => account.accountId == accountId
          )[0];
        }

        // if (this.getActiveSocialMedia == "facebook") {
        //   this.facebookAccessToken = result.data.socialMediaAccounts.filter(
        //     (account) => account.accountId == accountId
        //   )[0].accessToken;
        // }
      });
    },
    updateClassification: function(id, field, isSave) {
      var comment;

      comment = this.subcomments.filter((item) => item.commentId == id)[0];

      switch (field) {
        case "positive":
          comment.polarity = 100;
          break;
        case "negative":
          comment.polarity = 0;
          break;
        case "neutral":
          comment.polarity = 50;
          break;
      }
      switch (field) {
        case "positive":
        case "negative":
        case "neutral":
          if (comment.polarityStrings.includes(field)) {
            comment.polarityStrings = comment.polarityStrings.filter(
              (pol) => pol != field
            );
          } else {
            comment.polarityStrings.push(field);
          }
          break;

        case "hidden":
          comment.hidden = !comment.hidden;
          break;

        case "question":
          comment.isQuestion = !comment.isQuestion;
          break;

        case "spam":
          comment.isSpam = !comment.isSpam;
          break;
      }

      if (isSave) {
        let data = {
          id: comment.id,
          originalUserId: comment.originalUserId,
          polarityStrings: comment.polarityStrings,
          hidden: comment.hidden,
          isQuestion: comment.isQuestion,
          isSpam: comment.isSpam,
          pageId: this.getActiveSocialMediaAccount,
        };

        if (field == "hidden") {
          // comment = pocket;
          HTTP.put("social/hide", data).then((result) => {
            if (result.status == 200) {
              // if (!isSub && ) {
              //   this.close();
              // }
              EventBus.$emit("commentEditSuccess");
              comment.isManual = true;
            }
          });
        } else {
          // comment = pocket;
          HTTP.put("social/changestatus", data).then((result) => {
            if (result.status == 200) {
              // if (!isSub && ) {
              //   this.close();
              // }
              EventBus.$emit("commentEditSuccess");
              comment.isManual = true;
            }
          });
        }
      }
    },
    /*eslint-disable*/
    mobileAndTabletCheck: function() {
      let check = false;
      (function(a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g0|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    },
    /*eslint-disable*/
    showDetailsOnMobile: function() {
      if (this.mobileAndTabletCheck()) {
        this.isShowDetailsOnMobile = true;
      }
    },
    deleteComment: function() {
      let data = {
        uniqueId: this.commentUniqueId,
        originalUserId: this.getActiveSocialMediaAccount,
        pageId: this.getActiveSocialMediaAccount,
      };

      HTTP.delete("social", { params: data }).then((result) => {
        if (result.status == 200) {
          this.deleteModal = false;
          this.subcomments = this.subcomments.filter(
            (subcomment) => subcomment.id != this.commentUniqueId
          );
          this.selectedPost.totalElements -= 1;

          this.posts.filter(
            (post) => post.id == this.selectedPost.data.id
          )[0].numOfReplies -= 1;
        }
      });
    },
    replyComment: function(id, reply) {
      let comment = this.subcomments.filter((comment) => comment.id == id)[0];
      let data = {
        authorId: this.getActiveSocialMediaAccount,
        authorName: this.activeAccountDetails.accountName,
        content: reply,
        contentType: this.getActiveSocialMedia + "_comment",
        originalUserId: this.getActiveSocialMediaAccount,
        pageId: this.getActiveSocialMediaAccount, // eğer facebooksa -> accountId
        parentId: id,
        postId: comment.postId,
      };

      HTTP.post("social/create", data).then((result) => {
        comment.numOfReplies += 1;
        this.selectedPost.totalElements += 1;
        this.$notify({
          group: "info",
          // title: "Important message",
          text: "Your reply has been sent",
        });
      });
    },
    disabledDates: function(date) {
      return this.$disabledDates(date);
    },
    filtersDone: function() {
      this.mobileAiFilter = false;
      this.mobileEmotionFilter = false;
      EventBus.$emit("mobileAiFilter", false);
      this.page = 0;
      this.comments = [];
      this.close();
      this.getComments();
    },
    removeTag: function(t) {
      this.search = this.search.filter((q) => q != t);
    },
    showFilter: function() {
      this.mobileAiFilter = true;
      EventBus.$emit("mobileAiFilter", true);
    },
    showEmotionFilter: function() {
      this.mobileEmotionFilter = true;
      EventBus.$emit("mobileAiFilter", true);
    },
    mobileRangeModalSetAndClose: function(data) {
      if (data.range != "CUSTOM") {
        this.setRange(data.range);
      } else {
        this.range = "CUSTOM";
        this.date = [new Date(data.start), new Date(data.finish)];
      }

      this.mobileRangeModal = false;
    },
    mobileSortModalSetAndClose: function(data) {
      this.order = data.order;
      this.orderBy = data.orderBy;
      this.mobileOrderModal = false;
    },
    deleteAllCommentByUser() {
      let uri =
        "/social/" +
        this.getActiveSocialMediaAccount +
        "/" +
        this.getActiveSocialMedia +
        "/author/" +
        this.processingAuthorId;
      HTTP.delete(uri)
        .then((result) => {
          this.$notify({
            group: "actions",
            type: "success",
            duration: 2000,
            text: "All comments by this user has been deleted.",
          });

          this.deleteAllCommentByUserModal = false;
          this.subcomments = this.subcomments.filter(
            (comment) => comment.authorId != this.processingAuthorId
          );
          this.processingAuthorId = null;
        })
        .catch((error) => {
          this.$notify({
            group: "notifications",
            type: "error",
            duration: 2500,
            text: "Error: " + error.message,
          });
        });
    },
    blockUser(isBlocked = false) {
      let uri =
        "/social/" +
        this.getActiveSocialMediaAccount +
        "/" +
        this.getActiveSocialMedia +
        "/author/" +
        this.processingAuthorId;
      HTTP.put(uri)
        .then((result) => {
          if (isBlocked) {
            this.removeUserFromList(this.processingAuthorId);
          } else {
            let blockedUser = new Array(this.processingAuthorId);
            this.setBlockedUserList(blockedUser);
            this.$notify({
              group: "actions",
              type: "success",
              duration: 2000,
              text: "You blocked a user",
            });
            this.processingAuthorId = null;
            this.blockUserModal = false;
          }
        })
        .catch((error) => {
          this.$notify({
            group: "notifications",
            type: "error",
            duration: 2500,
            text: "Error: " + error.message,
          });
        });
    },
    // setClearable: function() {
    //   let result;
    //   result = this.selectedFilter[0] != "All Posts" ? true : false;
    //   result = this.selectedFilter.length > 1 ? true : false;

    //   return true;
    // }
  },
  beforeMount() {
    this.selectedFilter = this.filters[0];
  },
  mounted() {
    let customDate = JSON.parse(localStorage.getItem("selectedDate")) || null;
    if (this.quickRange != null && customDate == null) {
      this.setQuickRange(this.quickRange);
    }

    if (customDate != null) {
      customDate[0] = new Date(customDate[0]);
      customDate[1] = new Date(customDate[1]);
      this.date = customDate;
    }
    this.scroll();
    this.detailScroll();
    if (this.$route.query.post) {
      this.selectPost(this.$route.query.post);
    }
    EventBus.$on("setRange", (range) => {
      this.setRange(range);
    });
    EventBus.$on("updateClassification", (id, field, isSave) => {
      this.updateClassification(id, field, isSave);
      // console.log(id, field);
    });
    EventBus.$on("deleteComment", (id, isSub) => {
      this.deleteModal = true;
      this.commentUniqueId = id;
      this.isDeletedCommentSub = isSub;
    });
    EventBus.$on("replyTrigger", (id, text) => {
      this.replyComment(id, text);
    });
    EventBus.$on("getSubComments", (commentId) => {
      this.selectComment(commentId, "comment");
      this.showDetailsOnMobile();
    });
    EventBus.$on("deleteAllCommentByUser", (authorId) => {
      this.processingAuthorId = authorId;
      this.deleteAllCommentByUserModal = true;
    });
    EventBus.$on("blockUser", (authorId, isBlocked) => {
      this.processingAuthorId = authorId;
      if (isBlocked) {
        this.blockUser(isBlocked);
      } else {
        this.blockUserModal = true;
      }
    });
  },
  beforeDestroy: function() {
    EventBus.$off("replyTrigger");
    EventBus.$off("setRange");
    EventBus.$off("updateClassification");
    EventBus.$off("deleteComment");
    EventBus.$off("deleteAllCommentByUser");
    EventBus.$off("blockUser");
  },
  computed: {
    ...mapGetters(["getActiveSocialMedia", "getActiveSocialMediaAccount"]),
    filters() {
      return [
        {
          value: "All Posts",
          label:
            this.getActiveSocialMedia === "youtube"
              ? "All Videos"
              : "All Posts",
        },
        {
          value: "Positive",
          label: "Most Positive Commented",
        },
        {
          value: "Neutral",
          label: "Most Neutral Commented",
        },
        {
          value: "Negative",
          label: "Most Negative Commented",
        },
        {
          value: "Question",
          label: "Most Question Commented",
        },
        {
          value: "Spam",
          label: "Most Spam Commented",
        },
      ];
    },
    selectedFilterCount: function() {
      return this.selectedFilter ? this.selectedFilter.length : 0;
    },
    selectedEmotionCount: function() {
      return this.selectedEmotion ? this.selectedEmotion.length : 0;
    },
  },
  watch: {
    search: function() {
      if (this.search.length > 0) {
        this.selectedPost = {
          data: {
            id: null,
          },
        };

        let searchQuery = "";
        let i = 0;
        this.search.forEach((q) => {
          if (i != 0) {
            searchQuery += ",";
          }
          searchQuery += q;
          i++;
        });

        HTTP.get(
          "social/" +
            this.getActiveSocialMediaAccount +
            "/facebook/post/?text=" +
            searchQuery +
            "&range=" +
            this.range
        )
          .then((result) => {
            this.posts = [];
            this.subcomments = [];
            result.data.content.forEach((post) => this.posts.push(post.data));
            // this.comments = ;
          })
          .catch((e) => {
            if (e.response.status == 400) {
              this.posts = [];
              this.subcomments = [];
            }
          });
      } else {
        this.mobileSearchActive = false;
        this.page = 0;
        this.posts = [];
        this.getPosts();
      }
    },
    range: function() {
      if (this.range != null) {
        this.date = null;
        this.start = null;
        this.finish = null;
        this.search = [];
        this.posts = [];
        this.subcomments = [];
        this.selectedPost = {
          data: {
            id: null,
          },
        };
        this.posts = [];
        this.getPosts();
      }
    },
    order: function() {
      if (this.isGettingPosts) {
        return;
      }

      this.selectedFilter = this.filters[0];
      this.page = 0;
      this.posts = [];
      this.subcomments = [];
      this.getPosts();
    },
    orderBy: function() {
      if (this.isGettingPosts) {
        return;
      }

      this.selectedFilter = this.filters[0];
      this.page = 0;
      this.posts = [];
      this.getPosts();
    },
    selectedFilter: function() {
      if (this.isGettingPosts) {
        return;
      }

      this.order = "DESC";
      this.orderBy = "createdTime";
      this.page = 0;
      this.subcomments = [];
      this.close();
      this.getPosts();
    },
    date: {
      deep: true,
      handler() {
        console.log(this.date != null);
        if (this.date[0]) {
          localStorage.setItem("selectedDate", JSON.stringify(this.date));
          this.search = [];
          this.selectedPost = {
            data: {
              id: null,
            },
          };
          this.subcomments = [];

          this.range = null;
          this.posts = [];

          this.start = this.date[0].getTime();
          this.finish = this.date[1].getTime() + 86399000;
          this.posts = [];
          this.getPosts();
        } else {
          this.page = 0;
          this.range = "ALL";
          this.quickRange = null;
          this.start = null;
          this.finish = null;
        }
      },
    },
    getActiveSocialMediaAccount: function() {
      this.posts = [];
      this.subcomments = [];
      this.page = 0;
      this.close();
      this.getPosts();
      this.setActiveSocialMediaAccount();
    },
  },
  selectedPost: {
    deep: true,
    handler: function() {
      this.subcomments = [];
    },
  },
};
</script>
<style lang="scss">
#page--posts {
  .filter--polarity {
    .selected {
      font-weight: 700 !important;
    }
  }
}

.mobile-filter {
  margin-bottom: 15px !important;
}

@media (max-width: 767.98px) {
  .list {
    height: calc(100vh - 130px);
  }

  #details {
    position: absolute;
    background: #fff;
    transition: all 0.5s ease-in-out;
    transform: translateX(100%);
    top: 0;
    height: 100%;
  }

  .show-details {
    transform: translateX(0) !important;
  }
}
</style>
