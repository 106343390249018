<template>
  <div>
    <!-- Delete All Comment From User -->
    <modal
      v-if="deleteAllCommentByUserModal"
      @close="deleteAllCommentByUserModal = false"
    >
      <div slot="title">Delete All Comments</div>
      <div class="row head">
        <div class="col">
          <i class="fas fa-exclamation-triangle"></i>
          Warning
        </div>
        <div class="col text-right">
          <i
            class="fas fa-times"
            @click="deleteAllCommentByUserModal = false"
          ></i>
        </div>
      </div>
      <div slot="body">
        All comments of this user will be deleted. Do you want to continue?
      </div>
      <div slot="footer">
        <button
          class="btn btn-primary"
          @click="deleteAllCommentByUserModal = false"
        >
          CANCEL
        </button>
        <button
          class="btn btn-danger"
          @click="deleteAllCommentByUser"
          :disabled="xhrDeleteComment"
        >
          <span v-if="!xhrDeleteComment"> DELETE </span>

          <Loading v-if="xhrDeleteComment" :white="true"></Loading>
        </button>
      </div>
    </modal>
    <!-- Delete All Comment From User -->

    <!-- Block User Modal -->
    <modal v-if="blockUserModal" @close="blockUserModal = false">
      <div slot="title">Block User</div>
      <div class="row head">
        <div class="col">
          <i class="fas fa-exclamation-triangle"></i>
          Warning
        </div>
        <div class="col text-right">
          <i class="fas fa-times" @click="blockUserModal = false"></i>
        </div>
      </div>
      <div slot="body">You are blocking a user. Do you want to continue?</div>
      <div slot="footer">
        <button class="btn btn-primary" @click="blockUserModal = false">
          CANCEL
        </button>
        <button
          class="btn btn-danger"
          @click="blockUser(false)"
          :disabled="xhrDeleteComment"
        >
          <span v-if="!xhrDeleteComment"> BLOCK </span>

          <Loading v-if="xhrDeleteComment" :white="true"></Loading>
        </button>
      </div>
    </modal>
    <!-- Block User Modal -->

    <!-- Delete Modal -->
    <modal v-if="deleteModal" @close="deleteModal = false">
      <div slot="title">Delete Comment</div>
      <div class="row head">
        <div class="col">
          <i class="fas fa-exclamation-triangle"></i>
          Warning
        </div>
        <div class="col text-right">
          <i class="fas fa-times" @click="deleteModal = false"></i>
        </div>
      </div>
      <div slot="body">
        You are deleting a comment.
        <br />Comment will be deleted from the linked account.
      </div>
      <div slot="footer">
        <button class="btn btn-primary" @click="deleteModal = false">
          CANCEL
        </button>
        <button
          class="btn btn-danger"
          @click="deleteComment"
          :disabled="xhrDeleteComment"
        >
          <span v-if="!xhrDeleteComment"> DELETE </span>

          <Loading v-if="xhrDeleteComment" :white="true"></Loading>
        </button>
      </div>
    </modal>
    <!-- Delete Modal -->
    <!-- Filter Desktop -->
    <div class="row filter d-none d-md-block">
      <div class="col-12 left-filter">
        <!-- Left -->
        <div class="col-7 left">
          <!-- Search -->
          <div
            class="search-area has-icon"
            :class="{ 'animated shake': shakeSearch, active: searchActive }"
          >
            <i class="fas fa-search"></i>

            <input-tag
              v-model="search"
              class="search taggable"
              placeholder="Search comment, @user or word..."
              :add-tag-on-keys="[188, 13, 9]"
              :before-adding="checkSearchValue"
            ></input-tag>
            <button class="search-clear-all" @click="search = []">
              Clear All
            </button>
          </div>
          <!-- Search -->

          <div
            class="col filters p-0 has-icon elem"
            :class="{ disappear: searchActive }"
          >
            <i
              class="dot main all"
              v-if="selectedFilter[0] == 'All' && selectedFilter.length == 1"
            ></i>
            <v-select
              v-model="selectedFilter"
              :options="filters"
              :class="{
                selectedFilterPosition: selectedFilter.length > 1,
              }"
              :clearable="true"
              :searchable="false"
              :closeOnSelect="closeOnSelect"
              multiple
              :tabindex="10"
            >
              <template
                slot="selected-option-container"
                slot-scope="selectedFilter"
              >
                <div>
                  <i
                    class="dot main positive"
                    v-if="selectedFilter.option.label == 'Positive'"
                  ></i>
                  <i
                    class="dot main negative"
                    v-if="selectedFilter.option.label == 'Negative'"
                  ></i>
                  <i
                    class="dot main neutral"
                    v-if="selectedFilter.option.label == 'Neutral'"
                  ></i>
                  <i
                    class="dot main question"
                    v-if="selectedFilter.option.label == 'Question'"
                  ></i>
                  <i
                    class="dot main hidden"
                    v-if="selectedFilter.option.label == 'Hidden'"
                  ></i>
                  <i
                    class="dot main spam"
                    v-if="selectedFilter.option.label == 'Spam/Ad Content'"
                  ></i>
                  <i
                    class="dot main notassigned"
                    v-if="selectedFilter.option.label == 'Not Assigned'"
                  ></i>

                  <div
                    class="all"
                    v-if="
                      selectedFilter.option.label.trim() == 'All' &&
                        selectedFilterCount == 1
                    "
                  >
                    Sentiments
                  </div>
                </div>
              </template>

              <template slot="option" slot-scope="option">
                <div
                  @click="
                    option.label == 'All' ? (selectedFilter = ['All']) : ''
                  "
                >
                  <i
                    class="dot"
                    :class="[
                      { all: option.label == 'All' },
                      { positive: option.label == 'Positive' },
                      { negative: option.label == 'Negative' },
                      { neutral: option.label == 'Neutral' },
                      { question: option.label == 'Question' },
                      { hidden: option.label == 'Hidden' },
                      { spam: option.label == 'Spam/Ad Content' },
                    ]"
                  ></i>
                  {{ option.label }}
                  <small v-if="option.label == 'Positive'"
                    >( {{ polarityCounts.positive }} )</small
                  >
                  <small v-if="option.label == 'All'"
                    >( {{ polarityCounts.totalComments }} )</small
                  >
                  <small v-if="option.label == 'Negative'"
                    >( {{ polarityCounts.negative }} )</small
                  >
                  <small v-if="option.label == 'Neutral'"
                    >( {{ polarityCounts.neutral }} )</small
                  >
                  <small v-if="option.label == 'Hidden'"
                    >( {{ polarityCounts.hidden }} )</small
                  >
                  <small v-if="option.label == 'Question'"
                    >( {{ polarityCounts.question }} )</small
                  >
                  <small v-if="option.label == 'Not Assigned'"
                    >( {{ polarityCounts.notassigned }} )</small
                  >
                  <small v-if="option.label == 'Spam/Ad Content'"
                    >( {{ polarityCounts.spam }} )</small
                  >

                  <template v-for="(filter, index) in selectedFilter">
                    <span
                      :key="index"
                      class="remove"
                      v-if="
                        option.label.toLowerCase() == filter.toLowerCase() &&
                          option.label != 'All'
                      "
                      @click.stop.prevent=""
                      @click="removeFilter(option)"
                    >
                      <i class="fas fa-times"></i>
                    </span>
                  </template>
                </div>
              </template>
            </v-select>
          </div>
          <!-- Emotions Filter -->
          <div
            class="col filters p-0 has-icon elem emotions-filter"
            :class="{ disappear: searchActive }"
          >
            <!-- <component :is="'neutral'" class="main-emoji"></component> -->

            <component
              class="main-icon"
              :is="'offensive'"
              v-if="selectedEmotion[0] == 'All' && selectedEmotion.length == 1"
            ></component>
            <v-select
              v-model="selectedEmotion"
              :options="feelings"
              :clearable="true"
              :searchable="false"
              :closeOnSelect="false"
              multiple
              :tabindex="10"
              :class="{
                selectedEmotionPosition: selectedEmotion.length > 1,
              }"
            >
              <template
                slot="selected-option-container"
                slot-scope="selectedEmotion"
              >
                <div>
                  <component
                    :is="'profanity'"
                    v-if="
                      selectedEmotion.option.label.toLowerCase() == 'profanity'
                    "
                  ></component>
                  <component
                    :is="'hate'"
                    v-if="
                      selectedEmotion.option.label.toLowerCase() ==
                        'hate speech'
                    "
                  ></component>
                  <div
                    class="all"
                    v-if="
                      selectedEmotion.option.label.trim() == 'All' &&
                        selectedEmotionCount == 1
                    "
                  >
                    Offensive
                  </div>
                </div>
              </template>

              <template slot="option" slot-scope="option">
                <div
                  @click="
                    option.label == 'All' ? (selectedEmotion = ['All']) : ''
                  "
                >
                  <component
                    :is="'profanity'"
                    v-if="option.label.toLowerCase() == 'profanity'"
                  ></component>
                  <component
                    :is="'hate'"
                    v-if="option.label.toLowerCase() == 'hate speech'"
                  ></component>

                  {{ option.label }}
                  <small v-if="option.label.toLowerCase() != 'all'"
                    >(
                    {{
                      option.label.toLowerCase() == "profanity"
                        ? offensiveCounts.profanity
                        : offensiveCounts.hate
                    }}
                    )
                  </small>

                  <template v-for="(filter, index) in selectedEmotion">
                    <span
                      :key="index"
                      class="remove"
                      @click.stop.prevent=""
                      v-if="
                        option.label.toLowerCase() == filter.toLowerCase() &&
                          option.label != 'All'
                      "
                    >
                      <i
                        class="fas fa-times"
                        @click="removeFeeling(option)"
                      ></i>
                    </span>
                  </template>
                </div>
              </template>
            </v-select>
          </div>
          <!-- Emotions Filter End -->
          <button
            class="btn-filter elem search-trigger badge-wrapper"
            type="button"
            @click="searchActive = !searchActive"
          >
            <span class="badge" v-if="search.length > 0"
              >{{ search.length }}
            </span>
            <span class="icon--search">
              <i class="fas fa-search" v-if="!searchActive"></i>
            </span>
            <icon_triangle
              class="icon--search--close"
              :dark="true"
              v-if="searchActive"
            ></icon_triangle>
          </button>
          <button
            class="btn-filter elem mr-n-2"
            type="button"
            id="dropdownMenu"
            data-toggle="dropdown"
          >
            <img
              @error="(e) => (e.target.src = $defaultCardAvatar)"
              src="@/assets/images/comment.svg"
              alt
              v-if="orderBy == 'numOfReplies'"
            />
            <img
              @error="(e) => (e.target.src = $defaultCardAvatar)"
              src="@/assets/images/sort/desc.svg"
              alt
              v-if="order == 'DESC' && orderBy == 'createdTime'"
            />
            <img
              @error="(e) => (e.target.src = $defaultCardAvatar)"
              src="@/assets/images/sort/asc.svg"
              alt
              v-if="order == 'ASC' && orderBy == 'createdTime'"
            />
            <img
              @error="(e) => (e.target.src = $defaultCardAvatar)"
              src="@/assets/images/sort/heart.svg"
              alt
              v-if="orderBy == 'numOfLikes'"
            />
          </button>
          <div class="dropdown-menu order animate slideIn">
            <button
              class="dropdown-item"
              :class="{ selected: order == 'DESC' && orderBy == 'createdTime' }"
              type="button"
              @click="
                order = 'DESC';
                orderBy = 'createdTime';
              "
            >
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/sort/desc.svg"
                @click="mobileSearchActive = true"
                alt
              />
              Newest
            </button>
            <button
              class="dropdown-item"
              :class="{ selected: order == 'ASC' && orderBy == 'createdTime' }"
              type="button"
              @click="
                order = 'ASC';
                orderBy = 'createdTime';
              "
            >
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/sort/asc.svg"
                @click="mobileSearchActive = true"
                alt
              />
              Oldest
            </button>
            <button
              class="dropdown-item"
              :class="{ selected: order == 'DESC' && orderBy == 'numOfLikes' }"
              type="button"
              @click="
                order = 'DESC';
                orderBy = 'numOfLikes';
              "
            >
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/sort/heart.svg"
                @click="mobileSearchActive = true"
                alt
              />
              Most Liked
            </button>
            <button
              class="dropdown-item"
              :class="{
                selected: order == 'DESC' && orderBy == 'numOfReplies',
              }"
              type="button"
              @click="
                order = 'DESC';
                orderBy = 'numOfReplies';
              "
            >
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/comment.svg"
                @click="mobileSearchActive = true"
                alt
              />
              Most Replies
            </button>
          </div>
          <!-- Left End -->
        </div>

        <!-- Right -->
        <div class="col-5 text-right pr-0 topfilter">
          <range-filter :range="range"></range-filter>
          <DatePicker
            ref="datepicker"
            class="datepicker"
            v-model="date"
            placeholder="Select Date"
            range
            :disabled-date="disabledDates"
          >
            <template v-slot:header="{ emit }">
               <!-- <button
                class="btn radio p-0 d-block w-100 text-left"
                @click="setRange('ALL')"
              >
                <radio-button :checked="range == 'ALL'"></radio-button>All Time
              </button> -->
              <button
                class="btn radio p-0 d-block w-100 text-left"
                @click="setQuickRange('14Days')"
              >
                <radio-button :checked="quickRange == '14Days'"></radio-button
                >Last 14 Days
              </button>
              <button
                class="btn radio p-0 d-block w-100 text-left"
                @click="setQuickRange('30Days')"
              >
                <radio-button :checked="quickRange == '30Days'"></radio-button
                >Last 30 Days
              </button>
              <button
                class="btn radio p-0 d-block w-100 text-left"
                @click="setQuickRange('ThisWeek')"
              >
                <radio-button :checked="quickRange == 'ThisWeek'"></radio-button
                >This Week
              </button>
              <button
                class="btn radio p-0 d-block w-100 text-left"
                @click="setQuickRange('LastWeek')"
              >
                <radio-button :checked="quickRange == 'LastWeek'"></radio-button
                >Last Week
              </button>
              <button
                class="btn radio p-0 d-block w-100 text-left"
                @click="setQuickRange('ThisMonth')"
              >
                <radio-button
                  :checked="quickRange == 'ThisMonth'"
                ></radio-button
                >This Month
              </button>
              <button
                class="btn radio p-0 d-block w-100 text-left mb-2"
                @click="setQuickRange('LastMonth')"
              >
                <radio-button
                  :checked="quickRange == 'LastMonth'"
                ></radio-button
                >Last Month
              </button>
            </template>
          </DatePicker>
        </div>
        <!-- Right End -->
      </div>
    </div>
    <!-- Filter Desktop End -->

    <!-- Filter mobile -->
    <div class="row mobile-filter p-0 d-sm-none">
      <div class="p-0" :class="[mobileSearchActive ? 'col-5' : 'col-4']">
        <span>
          <img
            @error="(e) => (e.target.src = $defaultCardAvatar)"
            src="@/assets/images/search.svg"
            v-if="!mobileSearchActive"
            @click="mobileSearchActive = true"
            alt="Search"
          />
          <img
            @error="(e) => (e.target.src = $defaultCardAvatar)"
            src="@/assets/images/close.svg"
            v-if="mobileSearchActive"
            @click="mobileSearchActive = false"
            alt="Close"
          />
        </span>
        <input-tag
          v-model="search"
          class="taggable mobile-search"
          :class="{ active: mobileSearchActive }"
          placeholder="Search comment, @user or word..."
        ></input-tag>
      </div>
      <div
        class="text-center group-select"
        :class="[mobileSearchActive ? 'col-3 p-0' : 'col-4']"
      >
        <span @click="showFilter">
          <img
            @error="(e) => (e.target.src = $defaultCardAvatar)"
            src="@/assets/images/filter-outline.svg"
            class="filter-outline"
            alt="Filter"
          />
          <span
            class="badge"
            v-if="selectedFilterCount > 1 && selectedFilter[1] != 'All'"
            >{{ selectedFilterCount - 1 }}
          </span>
        </span>
        <span @click="showEmotionFilter">
          <component :is="'offensive'"></component>
          <span
            class="badge"
            v-if="selectedEmotionCount > 1 && selectedEmotion[1] != 'All'"
            >{{ selectedEmotionCount - 1 }}
          </span>
        </span>
      </div>
      <div
        class="text-right mobile"
        :class="[mobileSearchActive ? 'col ' : 'col-4']"
      >
        <span @click="mobileOrderModal = true">
          <img
            @error="(e) => (e.target.src = $defaultCardAvatar)"
            src="@/assets/images/filter.svg"
            alt="Filter"
          />
        </span>
        <span @click="mobileRangeModal = true">
          <img
            @error="(e) => (e.target.src = $defaultCardAvatar)"
            src="@/assets/images/calander.svg"
            alt="Set"
          />
        </span>
      </div>

      <div class="col-12 tags" v-if="search.length > 0">
        <span v-for="(q, index) in search" :key="index">
          {{ q }}
          <i class="remove" @click="removeTag(q)">x</i>
        </span>
      </div>
    </div>
    <!-- Filter mobile -->
    <!-- Result Info -->
    <div class="row result-info">
      <div class="col-12 col-sm-7">
        <div class="row" v-if="resultCount > 0">
          <div class="col-10 col-sm-6">Listed {{ resultCount }} comments</div>
          <div class="col-2 col-sm-6 text-right p-0">
            <button
              class="btn-filter download-button d-none d-sm-inline-block"
              type="button"
              :disabled="xhrDownload"
              @click="exportExcel"
            >
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/download.svg"
                alt="Download"
                v-if="!xhrDownload"
              />
              <span v-if="!xhrDownload">Export</span>
              <Loading v-if="xhrDownload"></Loading>
            </button>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-5"></div>
    </div>
    <!-- Result Info -->
    <!-- Comments -->
    <div class="row">
      <!-- has active add when select some elements -->
      <div
        id="comments"
        class="col-12 col-md-7 list"
        :class="[
          { 'no-data': comments.length == 0 && show },
          { 'has-selected': selectedComment.id != null },
        ]"
      >
        <span class="placeholder" v-if="comments.length == 0 && show">
          <img
            @error="(e) => (e.target.src = $defaultCardAvatar)"
            src="@/assets/images/icon.svg"
            class="logo"
            alt="Socialays"
          />
          No Comments</span
        >
        <div v-if="!show && page == 0">
          <comment-placeholder v-for="n in 5" :key="n"></comment-placeholder>
        </div>

        <comment
          v-for="comment in comments"
          :key="comment.id"
          :id="comment.id"
          :commentId="comment.id"
          :isSub="false"
          :comment="comment"
          :name="comment.authorName"
          :profilePic="comment.authorProfilePicUrl"
          :postImageUrl="comment.postImageUrl"
          :location="comment.locations"
          :content="comment.content"
          :countOfReplies="comment.numOfReplies"
          :hidden="comment.hidden"
          :isQuestion="
            comment.isQuestionGpt ? comment.isQuestionGpt : comment.isQuestion
          "
          :isSpam="comment.isSpam"
          :polarity="comment.polarity"
          :polarityStrings="
            comment.polarityStringsGpt
              ? comment.polarityStringsGpt
              : comment.polarityStrings
          "
          :commentImgs="comment.imageUrl"
          :isLiked="comment.liked"
          :activeAccountDetails="activeAccountDetails"
          :likeCount="comment.numOfLikes"
          :authorId="comment.authorId"
          :date="comment.createdTime"
          class="comment-item"
          :class="{ active: comment.id == selectedComment.id }"
          :isManual="comment.isManual"
        ></comment>
        <div class="end" v-if="comingMore">
          <comment-placeholder v-for="n in 5" :key="n"></comment-placeholder>
        </div>
        <div
          class="end placeholder"
          v-if="endOfComments && comments.length != 0"
        >
          <img src="@/assets/images/icon.svg" class="logo" alt="Socialays" />
          No More Comments
        </div>
      </div>

      <!-- add Not selected class if not selected element  -->
      <div
        id="right-details"
        class="col-12 col-sm-5 list"
        :class="{
          'no-data': view == null,
          'show-details': isShowDetailsOnMobile,
          filteractive: isMobileFilterActive,
        }"
      >
        <span class="placeholder" v-if="view == null && comments.length != 0">
          <img
            @error="(e) => (e.target.src = $defaultCardAvatar)"
            src="@/assets/images/icon.svg"
            class="logo"
            alt="Socialays"
          />
          Please Select a Comment
        </span>

        <rightCommentView
          id="right-content"
          v-if="view == 'comment'"
          :selectedComment="selectedComment"
          :activeAccountDetails="activeAccountDetails"
          @close="close"
        ></rightCommentView>
        <div class="clearfix"></div>
        <!-- right start -->

        <right-post-view
          v-if="view == 'post' && selectedCommentPost != null"
          id="right-content"
          :post="selectedCommentPost"
          :account="activeAccountDetails"
          :profilePic="profileImg('', activeAccountDetails.accountId)"
          @close="close"
        ></right-post-view>
        <!-- right end -->
        <!-- right start -->

        <right-author-view
          v-if="view == 'author' && selectedAuthor.show"
          id="right-content"
          :selectedAuthor="selectedAuthor"
          @close="close"
        ></right-author-view>
        <!-- right end -->

        <!-- coming from search -->
        <rightCommentView
          v-if="$route.query.comment && selectedComment.id != null"
          id="right-content"
          :selectedComment="selectedComment"
          :activeAccountDetails="activeAccountDetails"
          :profilePic="profileImg('', activeAccountDetails.accountId)"
          @close="close"
        ></rightCommentView>
        <!-- coming from search -->

        <!-- SubComments Filter -->
        <subcommentfilter
          v-if="view && selectedCommentPost"
          @filterSubComment="filterSubComment"
          :polarityCounts="selectedCommentPost.polarityCounts"
          :totalComments="selectedCommentPost.totalElements"
        >
        </subcommentfilter>
        <!-- SubComments Filter END -->

        <!-- subcomments start -->

        <div class="subcomments" :class="{ sub: view == 'comment' }">
          <div v-if="showSubComments">
            <div v-for="subcomment in subcomments" :key="subcomment.commentId">
              <comment
                :id="subcomment.id"
                :profilePic="subcomment.authorProfilePicUrl"
                :commentId="subcomment.commentId"
                :comment="subcomment"
                :name="subcomment.authorName"
                :content="subcomment.content"
                :countOfReplies="subcomment.numOfReplies"
                :hidden="subcomment.hidden"
                :isQuestion="
                  subcomment.isQuestionGpt
                    ? subcomment.isQuestionGpt
                    : subcomment.isQuestion
                "
                :isSpam="subcomment.isSpam"
                :polarity="subcomment.polarity"
                :polarityStrings="
                  subcomment.polarityStringsGpt
                    ? subcomment.polarityStringsGpt
                    : subcomment.polarityStrings
                "
                :disableSelect="true"
                :commentImgs="subcomment.imageUrl"
                :isLiked="subcomment.liked"
                :activeAccountDetails="activeAccountDetails"
                :isSub="true"
                :likeCount="subcomment.numOfLikes"
                :date="subcomment.createdTime"
                :isManual="subcomment.isManual"
                :authorId="subcomment.authorId"
              ></comment>

              <!-- comment replies -->
              <div
                class="comment sub wrap col-sm-11 offset-sm-1"
                v-if="subcomment.numOfReplies > 0"
              >
                <div
                  v-for="reply in getCommentReplies(subcomment.id)"
                  :key="reply.commentId"
                >
                  <comment
                    :id="reply.id"
                    :profilePic="reply.authorProfilePicUrl"
                    :commentId="reply.commentId"
                    :postImageUrl="reply.postImageUrl"
                    :name="reply.authorName"
                    :content="reply.content"
                    :countOfReplies="reply.numOfReplies"
                    :hidden="reply.hidden"
                    :isQuestion="
                      reply.isQuestionGpt
                        ? reply.isQuestionGpt
                        : reply.isQuestion
                    "
                    :isSpam="reply.isSpam"
                    :polarity="reply.polarity"
                    :polarityStrings="
                      reply.polarityStringsGpt
                        ? reply.polarityStringsGpt
                        : reply.polarityStrings
                    "
                    :disableSelect="true"
                    :commentImgs="reply.imageUrl"
                    :isLiked="reply.liked"
                    :activeAccountDetails="activeAccountDetails"
                    :isReply="true"
                    :likeCount="reply.numOfLikes"
                    :date="reply.createdTime"
                    :isManual="reply.isManual"
                    :authorId="reply.authorId"
                    :comment="reply"
                  >
                  </comment>
                </div>
              </div>
              <!-- comment replies end -->
            </div>
          </div>
          <div
            class="end"
            v-if="xhrSubComment && !endOfSubComments && view != null"
          >
            <comment-placeholder v-for="n in 5" :key="n"></comment-placeholder>
          </div>
          <div
            class="end placeholder"
            v-if="endOfSubComments && subcomments.length != 0"
          >
            <img src="@/assets/images/icon.svg" class="logo" alt="Socialays" />
            No More Comments
          </div>
          <div
            class="end placeholder"
            v-if="endOfSubComments && subcomments.length == 0"
          >
            <img src="@/assets/images/icon.svg" class="logo" alt="Socialays" />
            No Comments
          </div>
        </div>
        <!-- subcomments end -->
      </div>
    </div>
    <!-- Comments End -->
    <!-- Mobile Status Filter Start -->
    <div class="mobile-ai-filter" :class="{ show: mobileAiFilter }">
      <div class="close-trigger" @click="filtersDone"></div>
      <div class="wrap">
        <div class="head">
          <div class="row">
            <div class="col">
              <span class="h5">Filter</span>
            </div>
            <div class="col text-right done" @click="filtersDone">Done</div>
          </div>
        </div>
        <div class="body">
          <div class="row" @click="setFilter('positive')">
            <div class="col-8">
              <status :status="'positive'"></status>Positive
              <small>( {{ polarityCounts.positive }} )</small>
            </div>
            <div class="col text-right">
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/checked.svg"
                v-if="selectedFilter.includes('positive')"
              />
            </div>
          </div>
          <div class="row" @click="setFilter('neutral')">
            <div class="col-8">
              <status :status="'neutral'"></status>Neutral
              <small>( {{ polarityCounts.neutral }} )</small>
            </div>
            <div class="col text-right">
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/checked.svg"
                v-if="selectedFilter.includes('neutral')"
              />
            </div>
          </div>
          <div class="row" @click="setFilter('negative')">
            <div class="col-8">
              <status :status="'negative'"></status>Negative
              <small>( {{ polarityCounts.negative }} )</small>
            </div>
            <div class="col text-right">
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/checked.svg"
                v-if="selectedFilter.includes('negative')"
              />
            </div>
          </div>

          <div class="row" @click="setFilter('hidden')">
            <div class="col-8">
              <status :status="'hidden'"></status>Hidden
              <small>( {{ polarityCounts.hidden }} )</small>
            </div>
            <div class="col text-right">
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/checked.svg"
                v-if="selectedFilter.includes('hidden')"
              />
            </div>
          </div>
          <div class="row" @click="setFilter('question')">
            <div class="col-8">
              <status :status="'question'"></status>Question
              <small>( {{ polarityCounts.question }} )</small>
            </div>
            <div class="col text-right">
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/checked.svg"
                v-if="selectedFilter.includes('question')"
              />
            </div>
          </div>
          <div class="row" @click="setFilter('spam')">
            <div class="col-8">
              <status :status="'spam'"></status>Spam/Ad Content
              <small>( {{ polarityCounts.spam }} )</small>
            </div>
            <div class="col text-right">
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/checked.svg"
                v-if="selectedFilter.includes('spam')"
              />
            </div>
          </div>
          <div class="row" @click="setFilter('notassigned')">
            <div class="col-8">
              <status></status>Not Assigned
              <small>( {{ polarityCounts.notassigned }} )</small>
            </div>
            <div class="col text-right">
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/checked.svg"
                v-if="selectedFilter.includes('notassigned')"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Mobile Status Filter End -->
    <!-- Mobile Emotion Filter Start -->
    <div class="mobile-ai-filter" :class="{ show: mobileEmotionFilter }">
      <div class="close-trigger" @click="filtersDone"></div>
      <div class="wrap">
        <div class="head">
          <div class="row">
            <div class="col">
              <span class="h5">Filter</span>
            </div>
            <div class="col text-right done" @click="filtersDone">Done</div>
          </div>
        </div>
        <div class="body">
          <div
            class="row"
            v-for="(emotion, index) in feelings.slice(1)"
            @click="setEmotion(emotion)"
            :key="index"
          >
            <div class="col-9">
              <component
                :is="'profanity'"
                v-if="emotion.toLowerCase() == 'profanity'"
              ></component>
              <component
                :is="'hate'"
                v-if="emotion.toLowerCase() == 'hate speech'"
              ></component>

              {{ emotion }}
              <small v-if="emotion.toLowerCase() != 'all'"
                >(
                {{
                  emotion.toLowerCase() == "profanity"
                    ? offensiveCounts.profanity
                    : offensiveCounts.hate
                }}
                )
              </small>
            </div>
            <div class="col text-right">
              <img
                @error="(e) => (e.target.src = $defaultCardAvatar)"
                src="@/assets/images/checked.svg"
                v-if="selectedEmotion.includes(emotion)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Mobile Emotion Filter End -->
    <!-- Mobile Order Modal Start -->
    <mobileSortFilter
      :mobileOrderModal="mobileOrderModal"
      :f_order="order"
      :f_orderBy="orderBy"
      @close="mobileSortModalSetAndClose"
    ></mobileSortFilter>
    <!-- Mobile Order Modal End -->
    <!-- Mobile Range Modal Start -->
    <mobileRangeFilter
      :mobileRangeModal="mobileRangeModal"
      :date="date"
      :f_range="range"
      @close="mobileRangeModalSetAndClose"
    ></mobileRangeFilter>
    <!-- Mobile Order Modal -->
  </div>
</template>
<script>
/* eslint-disable */
import { HTTP } from "@/main-source";
import EventBus from "@/components/event-bus";
import comment from "@/components/facebook/comment.vue";
import commentHolder from "@/components/placeholders/comment.vue";
import rightPostView from "@/components/facebook/right-post-view.vue";
import rightCommentView from "@/components/facebook/right-comment-view.vue";
import rightAuthorView from "@/components/facebook/right-author-view.vue";
import { mapGetters, mapActions } from "vuex";
import rangeFilter from "@/components/filters/range";
import subcommentfilter from "@/components/filters/subcomments";
import InputTag from "vue-input-tag";
import modal from "@/components/modal";
import status from "@/components/dot";
import router from "@/router";
import close from "@/components/close";
import icon_triangle from "@/components/icons/arrow";
import Loading from "@/components/Loading";
import radioButton from "@/components/radio-button";
// Emotions - Will be improved
import hate from "@/components/icons/offensive/hate";
import offensive from "@/components/icons/offensive/offensive";
import profanity from "@/components/icons/offensive/profanity";
// Subcomment filter for mobile
import mobileRangeFilter from "@/components/filters/mobile/date";
import mobileSortFilter from "@/components/filters/mobile/sort";
// Axios Cancel Token
import axios from "axios";
// Excel
import XLSX from "xlsx";
import { read, writeFileXLSX } from "xlsx";

let request = axios.CancelToken.source();

export default {
  name: "comments",
  data() {
    return {
      comments: [],
      subcomments: [],
      replies: [],
      selectedComment: {
        id: null,
      },
      closeOnSelect: false,
      filters: [
        "All",
        "Positive",
        "Neutral",
        "Negative",
        "Hidden",
        "Question",
        "Spam/Ad Content",
        "Not Assigned",
      ],
      selectedFilter: ["All"],
      feelings: ["All", "Hate Speech", "Profanity"],
      xhrDeleteComment: false,
      xhrSubComment: false,
      selectedEmotion: ["All"],
      searchActive: false,
      selectedCommentPost: null,
      isShowSubComments: false,
      commentPost: [],
      page: 0,
      subPage: 0,
      date: null,
      start: null,
      finish: null,
      order: "DESC",
      search: [],
      range: localStorage.getItem("selectedRange") || "ALL",
      quickRange: localStorage.getItem("selectedQuickRange") || null,
      view: null,
      resultCount: 0,
      polarityCounts: {
        totalComments: null,
      },
      offensiveCounts: {
        hate: 0,
        prafanity: 0,
      },
      pocket: {
        polarityStrings: null,
        hidden: null,
        isQuestion: null,
        isSpam: null,
        emotion: [],
      },
      facebookAccessToken: "",
      activeAccountDetails: {
        accountId: null,
      },
      show: false,
      showSubComments: false,
      isShowDetailsOnMobile: false,
      deleteModal: false,
      commentUniqueId: null,
      isDeletedCommentSub: false,
      orderBy: "createdTime",
      mobileSearchActive: false,
      mobileAiFilter: false,
      mobileEmotionFilter: false,
      mobileOrderModal: false,
      mobileRangeModal: false,
      comingMore: false,
      endOfComments: false,
      endOfSubComments: false,
      selectedAuthor: {
        authorId: null,
        polarityCounts: null,
        show: false,
      },
      subOrder: "DESC",
      subOrderBy: "createdTime",
      subFilter: null,
      searchQuery: null,
      shakeSearch: false,
      isMobileFilterActive: false,
      xhrDownload: false,
      deleteAllCommentByUserModal: false,
      blockUserModal: false,
      processingAuthorId: null,
    };
  },
  components: {
    comment,
    rightCommentView,
    "right-post-view": rightPostView,
    "range-filter": rangeFilter,
    "comment-placeholder": commentHolder,
    "right-author-view": rightAuthorView,
    subcommentfilter,
    InputTag,
    modal,
    close,
    status,
    mobileRangeFilter,
    mobileSortFilter,
    hate,
    offensive,
    profanity,
    Loading,
    radioButton,
    icon_triangle,
  },
  methods: {
    ...mapActions([
      "setBlockedUserList",
      "clearBlockedUserList",
      "removeUserFromList",
    ]),
    isCompetitor() {
      return this.activeAccountDetails.type == "COMPETITOR";
    },
    getComments: function(next = false) {
      let filterText = "",
        emotionFilterText = "";

      if (next && !this.endOfComments) {
        this.comingMore = true;
        this.page++;
      }

      let uri =
        "social/" +
        this.getActiveSocialMediaAccount +
        "/" +
        this.getActiveSocialMedia +
        "/comment/?page=" +
        this.page +
        "&order=" +
        this.order +
        "&orderby=" +
        this.orderBy;

      if (this.range) {
        uri += "&range=" + this.range;
      }

      if (this.start && this.finish) {
        uri +=
          "&page=" +
          this.page +
          "&start=" +
          this.start +
          "&finish=" +
          this.finish;
      }

      if (this.searchQuery) {
        uri += "&text=" + this.searchQuery;
      }

      // Set query selected filters
      this.selectedFilter.forEach((filter) => {
        if (filter != "All") {
          if (filterText != "") {
            filterText += ",";
          }
          filter = filter.split(" ").join("");

          if (filter == "Spam/AdContent") {
            filter = "spam";
          }

          filterText += filter.toLowerCase();
        }
      });

      // this.selectedEmotion.forEach((filter) => {
      //   if (filter != "All") {
      //     if (emotionFilterText != "") {
      //       emotionFilterText += ",";
      //     }
      //     filter = filter.split(" ").join("");

      //     emotionFilterText += filter.toLowerCase();
      //   }
      // });

      // Set query selected filters end
      // Add Query filters
      if (filterText.length > 0) {
        uri += "&polarity=" + filterText;
      }

      // Hate Speech Filter
      if (this.selectedEmotion.includes("Hate Speech")) {
        uri += "&emotion=hate";
      }

      // Profanity Filter
      if (this.selectedEmotion.includes("Profanity")) {
        uri += "&profanity=true";
      }

      // Add Query filters End

      if (
        this.getActiveSocialMediaAccount &&
        !this.endOfComments &&
        !uri.includes("null")
      ) {
        this.show = false;

        HTTP.get(uri, { cancelToken: request.token })
          .then((result) => {
            // Blocked Users List
            // this.blockedUserList = this.blockedUserList.concat(
            //   result.data.blockedUserList
            // );
            // console.log("🚀 ~ file: Comments.vue ~ line 1171 ~ .then ~ this.blockedUserList", blockedUserList)

            this.setBlockedUserList(result.data.blockedUserList);
            this.polarityCounts = result.data.polarityCounts;
            this.offensiveCounts = result.data.offensiveCounts;
            this.polarityCounts.totalComments = result.data.totalElements;
            this.resultCount = result.data.count;
            if (!next) {
              this.comments = [];
              // this.page = 0;
            }
            result.data.content.forEach((comment) => {
              this.comments.push(comment);
            });
            this.show = true;
            this.comingMore = false;
          })
          .catch((e) => {
            if (e.response) {
              if (e.response.status == 400) {
                if (this.comments.length == 0) {
                  this.resultCount = 0;
                  this.polarityCounts = {
                    hidden: 0,
                    negative: 0,
                    neutral: 0,
                    notassigned: 0,
                    positive: 0,
                    question: 0,
                    spam: 0,
                    totalComments: 0,
                  };
                  this.offensiveCounts = {
                    prafanity: 0,
                    hate: 0,
                  };
                }
                if (this.page == 0) {
                  this.comments = [];
                }
                this.show = true;
                this.comingMore = false;
                this.endOfComments = true;
              }
            }
          });
      } else {
        this.show = true;
      }
    },
    getReplies: function(commentId) {
      HTTP.get(
        "social/" +
          this.getActiveSocialMediaAccount +
          "/" +
          this.getActiveSocialMedia +
          "/comment/" +
          commentId +
          "/subcomments?order=ASC"
      ).then((result) => {
        result.data.data.forEach((comment) => {
          let isexists = this.replies.filter(
            (reply) => reply.commentId == comment.commentId
          ).length;
          if (isexists == 0) {
            this.replies.push(comment.data);
          }
        });
      });
      // .catch(e => {
      //   if (e.response.status == 400) {
      //     this.repliesEnd = true;
      //   }
      // });
    },
    selectComment: function(commentId, view) {
      this.subFilter = null;
      this.endOfSubComments = false;
      if (this.$route.query.comment || this.$route.query.post) {
        let route = {
          name: this.$route.name,
          params: {
            socialmedia: this.$route.params.socialmedia,
            accountId: this.getActiveSocialMediaAccount,
          },
          query: null,
        };
        if (
          this.$route.query.filter ||
          this.$route.query.range ||
          this.$route.query.start ||
          this.$route.query.finish
        ) {
          route.query.filter = this.$route.query.filter;
          route.query.range = this.$route.query.range;
          route.query.start = this.$route.query.start;
          route.query.finish = this.$route.query.finishr;
        }
        router.replace(route);
      }

      this.subcomments = [];
      this.replies = [];
      this.subPage = 0;
      let detailsView = document.getElementById("right-details");
      detailsView.scrollTop = 0;

      this.view = view;
      this.selectedCommentPost = null;

      this.selectedComment = this.comments.filter(
        (comment) => comment.id == commentId
      )[0];

      if (this.view == "post") {
        HTTP.get(
          "social/" +
            this.getActiveSocialMediaAccount +
            "/" +
            this.getActiveSocialMedia +
            "/post/" +
            this.selectedComment.postId
        ).then((result) => {
          // Post Data
          this.selectedCommentPost = result.data;
        });
      }

      this.getSubComments();
    },
    clear: function() {
      this.close();
      this.page = 0;
      this.comments = [];
      this.subcomments = [];
      this.selectedCommentPost = null;
      this.selectedComment = { id: null };
      this.isShowSubComments = false;
      this.view = null;
      this.endOfComments = false;
      this.endOfSubComments = false;
    },
    setRange: function(value) {
      localStorage.setItem("selectedRange", value);
      localStorage.removeItem("selectedQuickRange");
      this.range = value;

      if (this.range != null) {
        this.clear();
        this.date = null;
        this.start = null;
        this.finish = null;
        this.quickRange = null;
        this.close();
        this.getComments();
      }

      this.$refs.datepicker.closePopup();
    },
    setQuickRange: function(which) {
      localStorage.setItem("selectedQuickRange", which);
      localStorage.removeItem("selectedRange");
      this.range = null;

      this.clear();
      this.close();
      this.date = null;
      this.start = null;
      this.finish = null;
      let today = new Date();
      switch (which) {
        case "14Days":
          this.quickRange = "14Days";
          today = new Date();
          let f14 = new Date(today.setDate(new Date().getDate() - 14));
          this.date = [f14, new Date()];
          break;
        case "30Days":
          this.quickRange = "30Days";
          today = new Date();
          let f30 = new Date(today.setDate(new Date().getDate() - 30));
          this.date = [f30, new Date()];
          break;
        case "ThisWeek":
          this.quickRange = "ThisWeek";
          let curr = new Date();
          let first = curr.getDate() - curr.getDay() + 1;
          let last = first + 6;

          let firstday = new Date(curr.setDate(first));
          let lastday = new Date(curr.setDate(last));
          this.date = [firstday, lastday];
          break;
        case "LastWeek":
          this.quickRange = "LastWeek";
          today = new Date();
          let curl = new Date(today.setDate(new Date().getDate() - 7));
          let firstl = curl.getDate() - curl.getDay() + 1;
          let lastl = firstl + 6;

          let firstlday = new Date(curl.setDate(firstl));
          let lastlday = new Date(curl.setDate(lastl));
          this.date = [firstlday, lastlday];
          break;
        case "ThisMonth":
          this.quickRange = "ThisMonth";
          var date = new Date(),
            y = date.getFullYear(),
            m = date.getMonth();
          var firstDay = new Date(y, m, 1);
          var lastDay = new Date(y, m + 1, 0);
          this.date = [firstDay, lastDay];
          break;
        case "LastMonth":
          this.quickRange = "LastMonth";
          var date = new Date();
          var firstDayPrevMonth = new Date(
            date.getFullYear(),
            date.getMonth() - 1,
            1
          );
          var lastDayPrevMonth = new Date(
            date.getFullYear(),
            date.getMonth(),
            0
          );
          this.date = [firstDayPrevMonth, lastDayPrevMonth];
          break;
      }

      this.getComments();

      this.$refs.datepicker.closePopup();
    },
    removeFilter: function(option) {
      console.log(option);
      console.log(this.selectedFilter);
      this.selectedFilter = this.selectedFilter.filter(
        (label) => label != option.label
      );
    },
    removeFeeling: function(option) {
      this.selectedEmotion = this.selectedEmotion.filter(
        (filter) => filter != option.label
      );
    },
    scroll: function() {
      let commentListView = document.getElementById("comments");
      if (!commentListView) {
        return;
      }
      // let lastItem = document.querySelector("#comments .col-12:last-child");
      commentListView.onscroll = () => {
        let position = commentListView.scrollTop - 200;
        let end =
          commentListView.scrollHeight - commentListView.offsetHeight - 200;
        if (position.toFixed() >= end) {
          this.getComments(true);
        }
      };
    },
    detailScroll: function() {
      let commentListView = document.getElementById("right-details");
      console.log(
        "🚀 ~ file: Comments.vue:1361 ~ commentListView",
        commentListView
      );
      // let lastItem = document.querySelector("#comments .col-12:last-child");
      if (!commentListView) {
        return;
      }
      commentListView.onscroll = () => {
        let position = commentListView.scrollTop - 200;
        let end =
          commentListView.scrollHeight - commentListView.offsetHeight - 200;

        if (position.toFixed() >= end) {
          this.getSubComments(true);
        }
      };
    },
    /*eslint-disable*/
    filterSubComment: function(filter, order, orderBy) {
      this.subPage = 0;
      this.subcomments = [];
      this.subFilter = filter;
      this.subOrder = order;
      this.subOrderBy = orderBy;
      this.endOfSubComments = false;
      this.getSubComments(false);
      // if (this.view != "author") {
      // } else {
      //   this.subcomments = [];
      // }
    },
    close: function() {
      this.view = null;
      this.selectedCommentPost = null;
      this.selectedComment = {
        id: null,
      };
      this.subcomments = [];
      this.isShowDetailsOnMobile = false;
      this.endOfSubComments = false;
      // this.selectedAuthor = null;
      this.subPage = 0;
    },
    updateClassification: function(id, field, isSave, isSub, isReply) {
      var comment;

      if (isSub) {
        comment = this.subcomments.filter((item) => item.commentId == id)[0];
      } else if (isReply) {
        comment = this.replies.filter((item) => item.commentId == id)[0];
      } else {
        comment = this.comments.filter((item) => item.commentId == id)[0];
      }

      switch (field) {
        case "positive":
        case "negative":
        case "neutral":
          if (comment.polarityStrings.includes(field)) {
            comment.polarityStrings = comment.polarityStrings.filter(
              (pol) => pol != field
            );
          } else {
            comment.polarityStrings.push(field);
          }
          break;

        case "hidden":
          // if (comment.hidden != null || comment.hidden != undefined) {
          //   comment.hidden = !comment.hidden;
          // } else {
          //   comment.hidden = true;
          // }
          comment.hidden = !comment.hidden;
          break;
        case "question":
          comment.isQuestion = !comment.isQuestion;
          break;

        case "spam/Ad Content":
          comment.isSpam = !comment.isSpam;
          break;
      }

      if (isSave) {
        let data = {
          id: comment.id,
          originalUserId: comment.originalUserId,
          polarityStrings: comment.polarityStrings,
          hidden: comment.hidden,
          isQuestion: comment.isQuestion,
          isSpam: comment.isSpam,
          emotion: comment.emotion,
          pageId: this.getActiveSocialMediaAccount,
        };

        if (field == "hidden") {
          // comment = pocket;
          HTTP.put("social/hide", data).then((result) => {
            if (result.status == 200) {
              // if (!isSub && ) {
              //   this.close();
              // }
              EventBus.$emit("commentEditSuccess");
              comment.isManual = true;
            }
          });
        } else {
          // comment = pocket;
          HTTP.put("social/changestatus", data).then((result) => {
            if (result.status == 200) {
              // if (!isSub && ) {
              //   this.close();
              // }
              EventBus.$emit("commentEditSuccess");
              comment.isManual = true;
            }
          });
        }

        // Update All Side
        let subcomment = this.subcomments.filter(
          (item) => item.commentId == id
        )[0];
        let reply = this.replies.filter((item) => item.commentId == id)[0];
        let maincomment = this.comments.filter(
          (item) => item.commentId == id
        )[0];

        if (subcomment) {
          this.subcomments.filter((item) => item.commentId == id)[0].hidden =
            comment.hidden;
          this.subcomments.filter(
            (item) => item.commentId == id
          )[0].polarityStrings = comment.polarityStrings;
        }
        if (reply) {
          reply.hidden = comment.hidden;
          reply.polarityStrings = comment.polarityStrings;
        }
        if (maincomment) {
          this.comments.filter((item) => item.commentId == id)[0].hidden =
            comment.hidden;
          this.comments.filter(
            (item) => item.commentId == id
          )[0].polarityStrings = comment.polarityStrings;
        }

        // Update All Side
      }
    },
    updateEmotions: function(id, field, isSave, isSub, isReply) {
      var comment;

      if (isSub) {
        comment = this.subcomments.filter((item) => item.commentId == id)[0];
      } else if (isReply) {
        comment = this.replies.filter((item) => item.commentId == id)[0];
      } else {
        comment = this.comments.filter((item) => item.commentId == id)[0];
      }

      if (comment.emotion.includes(field)) {
        comment.emotion = comment.emotion.filter((pol) => pol != field);
      } else {
        comment.emotion.push(field);
      }

      if (isSave) {
        let data = {
          id: comment.id,
          originalUserId: comment.originalUserId,
          polarityStrings: comment.polarityStrings,
          hidden: comment.hidden,
          isQuestion: comment.isQuestion,
          isSpam: comment.isSpam,
          pageId: this.getActiveSocialMediaAccount,
        };

        // comment = pocket;
        HTTP.put("social/changestatus", data).then((result) => {
          if (result.status == 200) {
            EventBus.$emit("commentEditSuccess");
            comment.isManual = true;
          }
        });

        // Update All Side
        let subcomment = this.subcomments.filter(
          (item) => item.commentId == id
        )[0];
        let reply = this.replies.filter((item) => item.commentId == id)[0];
        let maincomment = this.comments.filter(
          (item) => item.commentId == id
        )[0];

        if (subcomment) {
          this.subcomments.filter((item) => item.commentId == id)[0].hidden =
            comment.hidden;
          this.subcomments.filter(
            (item) => item.commentId == id
          )[0].polarityStrings = comment.polarityStrings;
        }
        if (reply) {
          reply.hidden = comment.hidden;
          reply.polarityStrings = comment.polarityStrings;
        }
        if (maincomment) {
          this.comments.filter((item) => item.commentId == id)[0].hidden =
            comment.hidden;
          this.comments.filter(
            (item) => item.commentId == id
          )[0].polarityStrings = comment.polarityStrings;
        }

        // Update All Side
      }
    },
    profileImg: function(img, authorId) {
      let imgUrl = img ? img : null;
      if (
        (this.getActiveSocialMedia == "facebook" && authorId) ||
        this.getActiveSocialMedia == "instagram"
      ) {
        // "https://graph.facebook.com/v3.2/101698591437761/picture?type=large&access_token=512465416305145|19fee8c38b6b550dfa469b8875f7fa37"
        // imgUrl =
        //   "https://graph.facebook.com/" + authorId + "/picture?type=square";
        imgUrl =
          "https://via.placeholder.com/200/ff6c37/c40d3c/?text=" + authorId;
      }
      // http://graph.facebook.com/101698591437761/picture?type=large
      return imgUrl;
    },
    likeTrigger: function(id) {
      let data = {
        id: id,
        originalUserId: this.getActiveSocialMediaAccount,
        pageId: this.getActiveSocialMediaAccount,
      };
      HTTP.put("social/like", data).then((result) => {
        if (result.status == 200) {
          let comment = this.comments.filter((comment) => comment.id == id)[0];
          if (comment) {
            comment.liked = !comment.liked;
          }
          let subcomment = this.subcomments.filter(
            (comment) => comment.id == id
          )[0];
          if (subcomment) {
            subcomment.liked = !subcomment.liked;
          }
          let reply = this.replies.filter((comment) => comment.id == id)[0];
          if (reply) {
            reply.liked = !subcomment.liked;
          }
        }
      });
    },
    replyComment: function(id, reply) {
      let comment = this.comments.filter((comment) => comment.id == id)[0];
      if (this.view == "comment") {
        comment = this.subcomments.filter((comment) => comment.id == id)[0];
      }

      let data = {
        authorId: this.getActiveSocialMediaAccount,
        authorName: this.activeAccountDetails.accountName,
        content: reply,
        contentType: this.getActiveSocialMedia + "_comment",
        originalUserId: this.getActiveSocialMediaAccount,
        pageId: this.getActiveSocialMediaAccount,
        parentId: id,
        postId: comment.postId,
      };

      HTTP.post("social/create", data).then((result) => {
        comment.numOfReplies += 1;
        if (this.view == "post") {
          this.selectedCommentPost.totalElements += 1;
        }
        EventBus.$emit("replySent", true);
        this.$notify({
          group: "info",
          // title: "Important message",
          text: "Your reply has been sent",
        });
      });
    },
    setActiveSocialMediaAccount: function() {
      return new Promise((resolve, reject) => {
        let accountId = "";
        accountId = this.$route.params.accountId
          ? this.$route.params.accountId
          : this.getActiveSocialMediaAccount;

        HTTP.get("user/me")
          .then((result) => {
            this.activeAccountDetails = result.data.socialMediaAccounts.filter(
              (account) => account.accountId == accountId
            )[0];

            if (this.activeAccountDetails == undefined) {
              this.activeAccountDetails = result.data.authorizedAccounts.filter(
                (account) => account.accountId == accountId
              )[0];
            }
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    deleteComment: function() {
      this.xhrDeleteComment = true;
      /*eslint-disable*/
      let payLoad = {
        uniqueId: this.commentUniqueId,
        originalUserId: this.getActiveSocialMediaAccount,
        pageId: this.getActiveSocialMediaAccount,
      };
      /*eslint-disable*/

      HTTP.delete("social", { params: payLoad }).then((result) => {
        if (result.status == 200) {
          if (this.isDeletedCommentSub) {
            this.subcomments = this.subcomments.filter(
              (subcomment) => subcomment.id != this.commentUniqueId
            );
          } else {
            this.comments = this.comments.filter(
              (comment) => comment.id != this.commentUniqueId
            );
          }

          this.replies = this.replies.filter(
            (reply) => reply.id != this.commentUniqueId
          );

          this.xhrDeleteComment = false;
          this.deleteModal = false;
          // this.getComments();
        }
      });
    },
    setRoutedFilter: function() {
      return new Promise((resolve, reject) => {
        if (typeof this.$route.query.filter != undefined) {
          switch (parseInt(this.$route.query.filter)) {
            case 0:
              this.setFilter("Positive");
              break;
            case 1:
              this.setFilter("Neutral");
              break;
            case 2:
              this.setFilter("Negative");
              break;
            case 3:
              this.setFilter("Question");
              break;
            case 4:
              this.setFilter("Spam/Ad Content");
              break;
            case 5:
              this.setFilter("Hidden");
              break;
            case 6:
              this.setFilter("Not Assigned");
              break;
          }
        }

        if (typeof this.$route.query.range != "undefined") {
          this.range = this.$route.query.range
            ? this.$route.query.range
            : "ALL";
        }
        if (typeof this.$route.query.start != "undefined") {
          this.start = this.$route.query.start;
        }
        if (typeof this.$route.query.finish != "undefined") {
          this.finish = this.$route.query.finish;
        }
        if (this.$route.query.profile) {
          this.selectedAuthor = {
            authorId: this.$route.query.profile,
            polarityCounts: {
              totalComments: null,
            },
            show: true,
            name: this.$route.query.name,
          };

          this.getByAuthor();
          this.showDetailsOnMobile();
        }
        if (this.$route.query.post) {
          this.subPage = 0;

          this.selectedComment.postId = this.$route.query.post;
          HTTP.get(
            "social/" +
              this.getActiveSocialMediaAccount +
              "/" +
              this.getActiveSocialMedia +
              "/post/" +
              this.$route.query.post
          ).then((result) => {
            // Post Data
            this.view = "post";
            this.selectedCommentPost = result.data;
            this.getSubComments();
            this.showSubComments = true;
          });

          HTTP.get(
            "social/" +
              this.getActiveSocialMediaAccount +
              "/" +
              this.getActiveSocialMedia +
              "/comment/" +
              this.$route.query.comment
          ).then((result) => {
            this.selectedComment = result.data.data;
          });
        }
        resolve();
      });
    },
    /*eslint-disable*/
    mobileAndTabletCheck: function() {
      let check = false;
      (function(a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    },
    /*eslint-disable*/
    showDetailsOnMobile: function() {
      if (this.mobileAndTabletCheck()) {
        this.isShowDetailsOnMobile = true;
      }
    },
    setFilter: function(option) {
      if (this.selectedFilter.includes(option)) {
        this.selectedFilter = this.selectedFilter.filter(
          (filter) => filter != option
        );
      } else {
        this.selectedFilter.push(option);
      }
    },
    setEmotion: function(option) {
      if (this.selectedEmotion.includes(option)) {
        this.selectedEmotion = this.selectedEmotion.filter(
          (filter) => filter != option
        );
      } else {
        this.selectedEmotion.push(option);
      }
    },
    filtersDone: function() {
      this.mobileAiFilter = false;
      this.mobileEmotionFilter = false;
      EventBus.$emit("mobileAiFilter", false);
      this.page = 0;
      this.comments = [];
      this.close();
      this.getComments();
    },
    showFilter: function() {
      this.mobileAiFilter = true;
      EventBus.$emit("mobileAiFilter", true);
    },
    showEmotionFilter: function() {
      this.mobileEmotionFilter = true;
      EventBus.$emit("mobileAiFilter", true);
    },
    removeTag: function(t) {
      this.search = this.search.filter((q) => q != t);
    },
    getByAuthor: function() {
      let uri =
        "social/" +
        this.getActiveSocialMediaAccount +
        "/" +
        this.getActiveSocialMedia +
        "/author/" +
        this.selectedAuthor.authorId +
        "/?page=" +
        this.subPage +
        "&order=" +
        this.subOrder +
        "&orderby=" +
        this.subOrderBy;
      if (this.subFilter != null) {
        uri += "&polarity=" + this.subFilter;
      }

      if (this.endOfSubComments) {
        return;
      }

      HTTP.get(uri)
        .then((res) => {
          if (res.data.content) {
            // console.log(this.selectedAuthor);
            //    if (this.$route.query.profile) {
            // this.selectedAuthor = {
            //   authorId: this.$route.query.profile,
            //   polarityCounts: {
            //     totalComments: null
            //   },
            //   show: true,
            //   name: this.$route.query.name
            // };
            //    }

            this.view = "author";

            this.selectedAuthor.polarityCounts = res.data.polarityCounts
              ? res.data.polarityCounts
              : { totalComments: 0 };
            this.selectedAuthor.show = true;
            res.data.content.forEach((c) => {
              this.subcomments.push(c);
            });
            this.showSubComments = true;
          }
        })
        .catch((e) => {
          this.xhrSubComment = false;
          if (e.response.status == 400) {
            this.endOfSubComments = true;
          }
        });
    },
    getSubComments: function(
      next = false,
      filter = null,
      order = null,
      orderBy = null
    ) {
      this.xhrSubComment = true;

      if (this.endOfSubComments) {
        return;
      }

      if (next) {
        this.subPage++;
      }

      if (order) {
        this.subOrder = order;
      }
      if (orderBy) {
        this.subOrderBy = orderBy;
      }

      if (this.view == "post") {
        // Get Subcommentsget
        let uri =
          "social/" +
          this.getActiveSocialMediaAccount +
          "/" +
          this.getActiveSocialMedia +
          "/post/" +
          this.selectedComment.postId +
          "/comments/?";

        // if (this.subFilter) {
        //   uri += this.subFilter + "&";
        // }
        uri +=
          "polarity=" +
          (this.subFilter || "") +
          "&page=" +
          this.subPage +
          "&order=" +
          this.subOrder +
          "&orderby=" +
          this.subOrderBy;

        HTTP.get(uri)
          .then((result) => {
            result.data.data.forEach((comment) => {
              this.subcomments.push(comment.data);
              // Get Replies
              if (comment.data.numOfReplies > 0) {
                this.getReplies(comment.data.commentId);
              }
              // Get Replies End
            });
            this.xhrSubComment = false;
            this.showSubComments = true;
          })
          .catch((e) => {
            this.xhrSubComment = false;
            if (e.response.status == 400) {
              this.endOfSubComments = true;
            }
          });
        // Get Subcomments End
      } else if (this.view == "comment") {
        HTTP.get(
          "social/" +
            this.getActiveSocialMediaAccount +
            "/" +
            this.getActiveSocialMedia +
            "/comment/" +
            this.selectedComment.id +
            "/subcomments" +
            "?page=" +
            this.subPage
        )
          .then((result) => {
            result.data.data.forEach((comment) => {
              this.subcomments.push(comment.data);
              // Get Replies
              if (comment.data.numOfReplies > 0) {
                this.getReplies(comment.data.commentId);
              }
              // Get Replies End
            });
            this.xhrSubComment = false;
            this.showSubComments = true;
          })
          .catch((e) => {
            this.xhrSubComment = false;
            if (e.response.status == 400) {
              this.endOfSubComments = true;
            }
          });
      } else if (this.view == "author") {
        this.getByAuthor();
      }
    },
    disabledDates: function(date) {
      return this.$disabledDates(date);
    },
    checkSearchValue: function(query) {
      console.log(query);
      if (query.length < 3) {
        if (this.search.length == 0) {
          this.$notify({
            group: "info",
            text: "Min. 3 character needed",
          });
          this.shakeSearch = true;
          setTimeout(() => {
            this.shakeSearch = false;
          }, 500);

          return false;
        } else {
          this.page = 0;
          this.comments = [];
          this.getComments();
          this.mobileSearchActive = false;
        }
      } else {
        return query;
      }
    },
    getCommentReplies: function(commentId) {
      return this.replies.filter((reply) => reply.parentId == commentId);
    },
    mobileRangeModalSetAndClose: function(data) {
      if (data.range != "CUSTOM") {
        this.setRange(data.range);
      } else {
        this.range = "CUSTOM";
        this.date = [new Date(data.start), new Date(data.finish)];
      }

      this.mobileRangeModal = false;
    },
    mobileSortModalSetAndClose: function(data) {
      this.order = data.order;
      this.orderBy = data.orderBy;
      this.mobileOrderModal = false;
    },
    exportExcelXlsx(jsonData, ReportTitle) {
      // Create a workbook object
      const workbook = XLSX.utils.book_new();

      // Convert JSON to sheet
      const sheet = XLSX.utils.json_to_sheet(jsonData);

      // Add sheet to workbook
      XLSX.utils.book_append_sheet(workbook, sheet, "Sheet1");

      // Export workbook as Excel file
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const blob = new Blob([excelBuffer], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = ReportTitle + ".xlsx";
      document.body.appendChild(link);
      link.click();
      this.xhrDownload = false;
    },
    exportExcel: function() {
      this.xhrDownload = true;
      let filterText = "",
        uri =
          "social/" +
          this.getActiveSocialMediaAccount +
          "/" +
          this.getActiveSocialMedia +
          "/comment/" +
          "?offset=5000&order=" +
          this.order +
          "&orderby=" +
          this.orderBy;

      if (this.range) {
        uri += "&range=" + this.range;
      }

      if (this.start && this.finish) {
        uri +=
          "&page=" +
          this.page +
          "&start=" +
          this.start +
          "&finish=" +
          this.finish;
      }

      if (this.searchQuery) {
        uri += "&text=" + this.searchQuery;
      }

      // Set query selected filters
      this.selectedFilter.forEach((filter) => {
        if (filter != "All") {
          if (filterText != "") {
            filterText += ",";
          }
          filter = filter.split(" ").join("");

          if (filter == "Spam/AdContent") {
            filter = "spam";
          }

          filterText += filter.toLowerCase();
        }
      });

      // Set query selected filters end
      // Add Query filters
      if (filterText.length > 0) {
        uri += "&polarity=" + filterText;
      }

      // Hate Speech Filter
      if (this.selectedEmotion.includes("Hate Speech")) {
        uri += "&emotion=hate";
      }

      // Profanity Filter
      if (this.selectedEmotion.includes("Profanity")) {
        uri += "&profanity=true";
      }

      // http://localhost:8080/social/69/17841403061613429/instagram/comment/export?polarity=positive
      // social/17841403061613429/instagram/comment/export/?order=DESC&orderby=createdTime&range=ALL

      HTTP.get(this.$apiURL + uri)
        .then((res) => {
          try {
            this.JSONToCSVConvertor(
              res.data.content,
              this.activeAccountDetails.accountName +
                " (" +
                this.getActiveSocialMediaAccount +
                ") " +
                this.getActiveSocialMedia +
                " Comments " +
                new Date().toLocaleDateString(),
              true
            );

            this.xhrDownload = false;
          } catch (error) {
            console.log("🚀 ~ file: Comments.vue:2087 ~ .then ~ error", error);
          }
        })
        .catch(() => {
          this.xhrDownload = false;
        });
    },
    JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel) {
      if (JSONData) {
        if (JSONData.length > 0) {
          JSONData = JSONData.map(function(item) {
            return {
              "Created Time":
                new Date(item.createdTime).toLocaleDateString("en") +
                " " +
                new Date(item.createdTime).toLocaleTimeString("en"),
              "Post Url": item.postUrl,
              "Author Name": item.authorName,
              Comment: item.content,
              Replies: item.numOfReplies,
              Likes: item.numOfLikes,
              ReTweets: item.numOfReTweets,
              "Quesion?": item.isQuestion ? "Yes" : "No",
              "Spam?": item.isSpam ? "Yes" : "No",
              "Hate Speech?": item.emotion.includes("hate") ? "Yes" : "No",
              "Is Hidden?": item.hidden ? "Yes" : "No",
              "User Liked": item.userLiked ? "Yes" : "No",
              Polarity: item.polarityStrings.toString(),
            };
          });
          this.exportExcelXlsx(JSONData, ReportTitle);
        }
      }

      // //Generate a file name
      // var fileName = "";

      // fileName += ReportTitle;

      // var uri = "data:text/csv;charset=utf-8," + encodeURIComponent(CSV);

      // var link = document.createElement("a");
      // link.href = uri;

      // link.style = "visibility:hidden";
      // link.download = fileName + ".csv";

      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);
    },
    deleteAllCommentByUser() {
      let uri =
        "/social/" +
        this.getActiveSocialMediaAccount +
        "/" +
        this.getActiveSocialMedia +
        "/author/" +
        this.processingAuthorId;
      HTTP.delete(uri)
        .then((result) => {
          this.$notify({
            group: "actions",
            type: "success",
            duration: 2000,
            text: "All comments by this user has been deleted.",
          });
          this.comments = this.comments.filter(
            (comment) => comment.authorId != this.processingAuthorId
          );
          this.processingAuthorId = null;
          this.deleteAllCommentByUserModal = false;
        })
        .catch((error) => {
          this.$notify({
            group: "notifications",
            type: "error",
            duration: 2500,
            text: "Error: " + error.message,
          });
        });
    },
    blockUser(isBlocked = false) {
      let uri =
        "/social/" +
        this.getActiveSocialMediaAccount +
        "/" +
        this.getActiveSocialMedia +
        "/author/" +
        this.processingAuthorId;
      HTTP.put(uri)
        .then((result) => {
          if (isBlocked) {
            this.removeUserFromList(this.processingAuthorId);
          } else {
            let blockedUser = new Array(this.processingAuthorId);
            this.setBlockedUserList(blockedUser);
            this.$notify({
              group: "actions",
              type: "success",
              duration: 2000,
              text: "You blocked a user",
            });
            this.processingAuthorId = null;
            this.blockUserModal = false;
          }
        })
        .catch((error) => {
          this.$notify({
            group: "notifications",
            type: "error",
            duration: 2500,
            text: "Error: " + error.message,
          });
        });
    },
  },

  mounted() {
    let customDate = JSON.parse(localStorage.getItem("selectedDate")) || null;
    if (this.quickRange != null && customDate == null) {
      this.setQuickRange(this.quickRange);
    }

    if (customDate != null) {
      customDate[0] = new Date(customDate[0]);
      customDate[1] = new Date(customDate[1]);
      this.date = customDate;
    }

    this.scroll();
    this.detailScroll();
    this.setActiveSocialMediaAccount().then(
      this.setRoutedFilter().then(this.getComments())
    );

    EventBus.$on("getSubComments", (commentId) => {
      this.selectComment(commentId, "comment");
      this.showDetailsOnMobile();
    });
    EventBus.$on("getComments", (commentId) => {
      this.selectComment(commentId, "post");
      this.showDetailsOnMobile();
    });
    EventBus.$on(
      "updateClassification",
      (id, field, isSave, isSub, isReply) => {
        this.updateClassification(id, field, isSave, isSub, isReply);
      }
    );

    EventBus.$on("updateEmotions", (id, field, isSave, isSub, isReply) => {
      this.updateEmotions(id, field, isSave, isSub, isReply);
    });

    EventBus.$on("clearCommentStatus", (id, isSub, isReply) => {
      let comment = null;
      if (isSub) {
        comment = this.subcomments.filter(
          (comment) => comment.commentId == id
        )[0];
      } else if (isReply) {
        comment = this.replies.filter((comment) => comment.commentId == id)[0];
      } else {
        comment = this.comments.filter((comment) => comment.commentId == id)[0];
      }
      this.pocket.polarityStrings = comment.polarityStrings;
      this.pocket.hidden = comment.hidden;
      this.pocket.isQuestion = comment.isQuestion;
      this.pocket.isSpam = comment.isSpam;
      this.pocket.emotion = comment.emotion;
      comment.polarityStrings = [];
      comment.emotion = [];
      comment.hidden = false;
      comment.isQuestion = false;
      comment.isSpam = false;
    });
    EventBus.$on("getBackCommentStatus", (id, isSub, isReply) => {
      let comment = null;
      if (isSub) {
        comment = this.subcomments.filter(
          (comment) => comment.commentId == id
        )[0];
      } else if (isReply) {
        comment = this.replies.filter((comment) => comment.commentId == id)[0];
      } else {
        comment = this.comments.filter((comment) => comment.commentId == id)[0];
      }
      comment.polarityStrings = this.pocket.polarityStrings;
      comment.hidden = this.pocket.hidden;
      comment.isQuestion = this.pocket.isQuestion;
      comment.isSpam = this.pocket.isSpam;
      comment.emotion = this.pocket.emotion;
    });
    EventBus.$on("likeTrigger", (id) => {
      this.comments;
      this.likeTrigger(id);
    });

    EventBus.$on("setRange", (range) => {
      this.setRange(range);
    });

    EventBus.$on("byAuthor", (data) => {
      if (this.selectedAuthor != data && data) {
        this.subcomments = [];
        this.selectedAuthor = data;
        this.getByAuthor();
        this.showDetailsOnMobile();
      }
    });

    EventBus.$on("deleteComment", (id, isSub) => {
      this.deleteModal = true;
      this.commentUniqueId = id;
      this.isDeletedCommentSub = isSub;
    });
    EventBus.$on("replyTrigger", (id, text) => {
      this.replyComment(id, text);
    });
    EventBus.$on("mobileAiFilter", (val) => {
      this.isMobileFilterActive = val;
    });
    EventBus.$on("deleteAllCommentByUser", (authorId) => {
      this.processingAuthorId = authorId;
      this.deleteAllCommentByUserModal = true;
    });
    EventBus.$on("blockUser", (authorId, isBlocked) => {
      this.processingAuthorId = authorId;
      if (isBlocked) {
        this.blockUser(isBlocked);
      } else {
        this.blockUserModal = true;
      }
    });
  },
  beforeDestroy: function() {
    EventBus.$off("replyTrigger");
    EventBus.$off("getSubComments");
    EventBus.$off("getComments");
    EventBus.$off("updateClassification");
    EventBus.$off("clearCommentStatus");
    EventBus.$off("getBackCommentStatus");
    EventBus.$off("likeTrigger");
    EventBus.$off("setRange");
    EventBus.$off("byAuthor");
    EventBus.$off("deleteAllCommentByUser");
    EventBus.$off("blockUser");
  },
  computed: {
    ...mapGetters([
      "getActiveSocialMedia",
      "getActiveSocialMediaAccount",
      "getBlockedUserList",
    ]),
    selectedFilterCount: function() {
      return this.selectedFilter.length;
    },
    selectedEmotionCount: function() {
      return this.selectedEmotion.length;
    },
  },
  watch: {
    search: function() {
      this.clear();
      let searchQuery = "";
      let i = 0;
      if (this.search.length > 0) {
        this.search.forEach((q) => {
          if (i != 0) {
            searchQuery += ",";
          }
          if (q.length > 2) {
            searchQuery += q;
            i++;
          }
        });
      } else {
        this.endOfComments = false;
        this.show = true;
        this.page = 0;
        this.comments = [];
        this.getComments();
        this.mobileSearchActive = false;
      }
      this.searchQuery = searchQuery;
    },
    searchQuery: function() {
      this.endOfComments = false;
      this.show = true;
      if (!this.searchQuery) {
        this.page = 0;
        this.comments = [];
        this.getComments();
        this.mobileSearchActive = false;
      } else {
        this.getComments();
      }
    },
    order: function() {
      this.page = 0;
      this.endOfComments = false;
      this.comments = [];
      this.getComments();
    },
    orderBy: function() {
      this.page = 0;
      this.endOfComments = false;
      this.comments = [];
      this.getComments();
    },
    selectedFilter: function() {
      if (
        !this.$mobileAndTabletCheck() &&
        this.selectedFilter.length == 1 &&
        this.selectedFilter[0] == "All"
      ) {
        document.getElementsByClassName("new-tag")[0].focus();
        document.getElementsByClassName("new-tag")[0].blur();
      }

      if (this.mobileAndTabletCheck != false) {
        this.page = 0;
        this.endOfComments = 0;
        this.comments = [];
        this.close();
        this.getComments();
      }
    },
    selectedEmotion: function() {
      if (
        !this.$mobileAndTabletCheck() &&
        this.selectedEmotion.length == 1 &&
        this.selectedEmotion[0] == "All"
      ) {
        document.getElementsByClassName("new-tag")[0].focus();
        document.getElementsByClassName("new-tag")[0].blur();
      }
      if (this.mobileAndTabletCheck != false) {
        this.page = 0;
        this.comments = [];
        this.endOfComments = 0;
        this.close();
        this.getComments();
      }
    },
    date: {
      deep: true,
      handler: function() {
        localStorage.setItem("selectedDate", JSON.stringify(this.date));
        this.clear();
        console.log(this.date);
        if (this.date[0]) {
          this.range = null;
          this.start = this.date[0].getTime();
          this.finish = this.date[1].getTime() + 86399000;
          this.comments = [];
          this.subcomments = [];
          this.page = 0;
          this.getComments();
        } else {
          this.range = "ALL";
          this.quickRange = null;
          this.start = null;
          this.finish = null;
          this.comments = [];
          this.subcomments = [];
          this.page = 0;
          this.getComments();
        }
      },
    },
    "$route.params.accountId": function() {
      if (
        Object.keys(this.$route.query).length === 0 &&
        this.$route.query.constructor === Object
      ) {
        this.page = 0;
        this.comments = [];
        this.subcomments = [];
        this.selectedComment = {
          id: null,
        };
        this.selectedCommentPost = null;
        this.endOfComments = false;
        this.selectedFilter = ["All"];
        this.selectedEmotion = ["All"];

        this.clearBlockedUserList();

        this.setActiveSocialMediaAccount(this.$route.params.accountId);
        this.getComments();
      }
    },
    mobileOrderModal: function() {
      EventBus.$emit("mobileAiFilter", this.mobileOrderModal);
    },
    mobileRangeModal: function() {
      EventBus.$emit("mobileAiFilter", this.mobileRangeModal);
    },
  },
};
</script>
