<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="45"
    height="45"
    viewBox="0 0 45 45"
  >
    <defs>
      <linearGradient
        id="t-linear-gradient"
        x1="-0.01"
        x2="1"
        y2="1"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="#abdcff" />
        <stop offset="1" stop-color="#0396ff" />
      </linearGradient>
      <filter
        id="Rectangle_Copy_3"
        x="0"
        y="0"
        width="45"
        height="45"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="20" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="7.5" result="blur" />
        <feFlood flood-opacity="0.039" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g id="Component_2_1" data-name="Component 2 – 1">
      <g id="Twitter">
        <g filter="url(#Rectangle_Copy_3)">
          <rect
            id="Rectangle_Copy_3-2"
            data-name="Rectangle Copy 3"
            width="45"
            height="45"
            rx="13"
            :fill="[active == 'true' ? 'url(#t-linear-gradient)' : '']"
          />
        </g>
        <path
          id="Fill_4"
          data-name="Fill 4"
          d="M24,2.273a7.562,7.562,0,0,1-.557.737,9.205,9.205,0,0,1-1.8,1.66l-.083.152a12.678,12.678,0,0,1-.108,2.127,14.016,14.016,0,0,1-1.292,4.272A14.164,14.164,0,0,1,17.479,15,13.326,13.326,0,0,1,10.7,18.68a15.534,15.534,0,0,1-2.74.315c-.148,0-.3.006-.442.006a13.992,13.992,0,0,1-7.4-2.071L0,16.858a11.175,11.175,0,0,0,1.154.061A10.184,10.184,0,0,0,4.92,16.2a9.812,9.812,0,0,0,2.344-1.337,4.851,4.851,0,0,1-4.54-3.33,4.836,4.836,0,0,0,.912.087,5.287,5.287,0,0,0,1.192-.14l.074-.036a4.814,4.814,0,0,1-3.171-2.2A4.577,4.577,0,0,1,1.026,6.7a4.923,4.923,0,0,0,2.182.574,4.76,4.76,0,0,1-2-2.909A4.624,4.624,0,0,1,1.769.891,14.029,14.029,0,0,0,11.854,5.908c-.026-.2-.052-.359-.079-.529a4.567,4.567,0,0,1,.751-3.132A4.741,4.741,0,0,1,15.868.074,5.641,5.641,0,0,1,16.762,0a4.793,4.793,0,0,1,3.4,1.412.263.263,0,0,0,.18.082.352.352,0,0,0,.077-.01A9.854,9.854,0,0,0,23.279.416l.065-.036a4.744,4.744,0,0,1-2.087,2.611A9.677,9.677,0,0,0,24,2.273"
          transform="translate(10 13)"
          :fill="[active == 'true' ? '#fff' : '#B2B6C4']"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: ["active"]
};
</script>

<style lang="scss"></style>
