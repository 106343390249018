<template>
  <div>
    <Forgot-Password :isLoading="isLoading" :result="result"></Forgot-Password>
    <div class="col authAction">
      Don’t you have an account?
      <router-link
        :to="{
          name: 'signup'
        }"
        >Sign Up.
      </router-link>
    </div>
  </div>
</template>

<script>
import ForgotPassword from "@/components/user/Forgot.vue";
import EventBus from "@/components/event-bus";
import { HTTP } from "@/main-source";

export default {
  name: "Forgot",
  data() {
    return {
      isLoading: false,
      result: null
    };
  },
  components: {
    ForgotPassword
  },
  methods: {
    sendForgotMail: function(email) {
      this.isLoading = true;
      let body = {
        email: email
      };
      HTTP.post("auth/forgot-password", body)
        .then(result => {
          this.result = result;
          this.isLoading = false;
        })
        .catch(e => {
          this.result = e.response.data;
          this.isLoading = false;
        });
    }
  },
  mounted() {
    EventBus.$on("resetpassword", email => {
      this.sendForgotMail(email);
    });
  }
};
</script>
