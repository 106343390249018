<template>
  <div>
    <div class="col-12 wrap">
      <div class="d-flex">
        <div class="comment" :class="[isSub ? 'col-12' : 'col-9']">
          <div class="col p-0">
            <img @error="(e) => (e.target.src = $defaultCardAvatar)"
              class="profile"
              :src="commenterPhoto"
              alt="Profile"
              @click.stop.prevent="selectComment"
              @click="show"
            />
            <div class="info">
              <div @click.stop.prevent="selectComment" @click="show">
                <span class="name">{{ name ? name : "Author Name" }}</span>
              </div>
              <div>
                <span class="city" @click.stop.prevent="selectComment">{{
                  location
                }}</span>
              </div>
              <div>
                <span class="likeCount">
                  <heart :isLiked="likeCount > 0 ? true : false"></heart>
                  {{ likeCount }}
                </span>
                <span class="date">
                  {{ timeSince(date) }}
                </span>
              </div>
            </div>
            <div class="clearfix"></div>
          </div>
          <div class="col text" @click="showpost">{{ content }}</div>
          <div class="imgs" v-if="commentImgs">
            <img @error="(e) => (e.target.src = $defaultCardAvatar)"
              v-for="(img, index) in commentImgs"
              :src="img"
              :key="index"
              alt="Comment Image"
            />
          </div>
        </div>
        <div class="post-thumb col-2">
          <facebookIcon
            :active="'true'"
            class="social-badge"
            v-if="type.split('_')[0].toLowerCase() == 'facebook'"
          ></facebookIcon>
          <instagramIcon
            :active="'true'"
            class="social-badge"
            v-if="type.split('_')[0].toLowerCase() == 'instagram'"
          ></instagramIcon>
          <twitterIcon
            :active="'true'"
            class="social-badge"
            v-if="type.split('_')[0].toLowerCase() == 'twitter'"
          ></twitterIcon>
          <youtubeIcon
            :active="'true'"
            class="social-badge"
            v-if="type.split('_')[0].toLowerCase() == 'youtube'"
          ></youtubeIcon>
          <img @error="(e) => (e.target.src = $defaultCardAvatar)" v-if="postImg" :src="postImg" alt="POST" />
        </div>
      </div>
      <div class="bottom">
        <div
          class="statuses d-sm-flex p-0"
          @click="editStatus = true"
          v-if="!editStatus"
        >
          <span
            class="status black"
            v-for="(polarity, index) in polarityStringsC"
            :key="index"
          >
            <status :status="polarity.toLowerCase()"></status>
            <span class="text">{{ polarity.toLowerCase() }}</span>
          </span>
          <span v-if="isQuestion" class="status black">
            <status status="question"></status>
            <span class="text">Question</span>
          </span>
          <span v-if="isSpam" class="status black">
            <status status="spam"></status>
            <span class="text">Spam</span>
          </span>

          <!-- <span class="status black" v-if="isQuestion">
            
          </span> -->
          <div class="emotions black">
            <template v-for="(emotion, n) in comment.emotion">
              <span v-if="emotion != 'notassigned'" :key="n">
                <component :is="emotion.toLowerCase()"></component>
                <span class="text">{{ emotion.toLowerCase() }}</span>
              </span>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EventBus from "@/components/event-bus";
import { mapGetters } from "vuex";
import status from "@/components/dot";
import facebookIcon from "@/components/icons/facebook";
import instagramIcon from "@/components/icons/instagram";
import twitterIcon from "@/components/icons/twitter";
import youtubeIcon from "@/components/icons/youtube";
import { HTTP } from "@/main-source";
import heart from "@/components/icons/heart";
import like from "@/components/icons/like";
// Emotions
import fun from "@/components/icons/emotions/fun";
import happiness from "@/components/icons/emotions/happiness";
import hate from "@/components/icons/emotions/hate";
import love from "@/components/icons/emotions/love";
import neutral from "@/components/icons/emotions/neutral";
import relief from "@/components/icons/emotions/relief";
import sadness from "@/components/icons/emotions/sadness";
import surprise from "@/components/icons/emotions/surprise";
import worry from "@/components/icons/emotions/worry";

export default {
  name: "message",
  props: [
    "id",
    "commentId",
    "name",
    "profilePic",
    "location",
    "content",
    "countOfReplies",
    "isHidden",
    "isQuestion",
    "polarity",
    "polarityStrings",
    "commentImgs",
    "disableSelect",
    "isLiked",
    "activeAccountDetails",
    "isSub",
    "likeCount",
    "date",
    "type",
    "pageId",
    "accountName",
    "authorId",
    "postImageUrl",
    "comment",
    "isSpam",
  ],
  components: {
    status,
    facebookIcon,
    instagramIcon,
    twitterIcon,
    youtubeIcon,
    heart,
    like,
    fun,
    happiness,
    hate,
    love,
    neutral,
    relief,
    sadness,
    surprise,
    worry,
  },
  data() {
    return {
      reply: false,
      editStatus: false,
      c_isHidden: null,
      c_isQuestion: null,
      c_polarityStrings: [],
      replyText: "",
      commenterPhoto: "/images/no-image.jpg",
      postImg: this.postImageUrl,
    };
  },
  created() {
    if (this.polarityStrings == null) {
      this.polarityStrings = [];
    }
    // Set Comment Author Photo
    let socialmediaapp = this.type.split("_")[0].toLowerCase();

    if (socialmediaapp == "instagram" || socialmediaapp == "facebook") {
      HTTP.get(
        "/media/" +
          socialmediaapp +
          "?accountid=" +
          this.comment.originalUserId +
          "&uniqueid=" +
          this.comment.postId
      ).then((result) => {
        if (result.status == 200 && result.data[0]) {
          this.postImg =
            result.data[0].type == "VIDEO"
              ? result.data[0].videoThumbImage
              : result.data[0].url;
        }
      });
    }

    if (socialmediaapp == "facebook") {
      HTTP.get(
        "/picture/" +
          socialmediaapp +
          "?accountid=" +
          this.pageId +
          "&authorId=" +
          this.authorId
      ).then((result) => {
        if (result.status == 200) {
          this.commenterPhoto = result.data.url;
        }
      });
    } else {
      if (this.profilePic) {
        this.commenterPhoto = this.profilePic;
      }
    }
    // Set Comment Author Photo End
  },
  methods: {
    /*eslint-disable*/
    replyComment: function (id) {
      this.reply = false;
      EventBus.$emit("replyTrigger", id, this.replyText);

      this.replyText = null;
    },
    /*eslint-disable*/
    show: function () {},
    showpost: function () {},
    exitEdit: function (id) {
      this.editStatus = false;
      EventBus.$emit("getBackCommentStatus", id, this.isSub);
    },
    selectComment: function (commentId) {
      if (typeof commentId == "string") {
        console.log(commentId);
        EventBus.$emit("getComments", commentId);
      }
    },
    getSubComments: function (commentId) {
      EventBus.$emit("getSubComments", commentId);
    },
    deleteComment: function (id) {
      EventBus.$emit("deleteComment", id, this.isSub);
    },
    updateClassification: function (id, field, isSave) {
      if (this.editStatus == true) {
        EventBus.$emit("updateClassification", id, field, isSave, this.isSub);
      }
    },
    clearCommentStatus: function (id) {
      EventBus.$emit("clearCommentStatus", id, this.isSub);
    },
    likeTrigger: function (id) {
      EventBus.$emit("likeTrigger", id);
    },
    timeSince: function (timeStamp) {
      if (!(timeStamp instanceof Date)) {
        timeStamp = new Date(timeStamp);
      }

      if (isNaN(timeStamp.getDate())) {
        return "Invalid date";
      }

      var now = new Date(),
        secondsPast = (now.getTime() - timeStamp.getTime()) / 1000;

      var formatDate = function (date, format, utc) {
        var MMMM = [
          "\x00",
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
        var MMM = [
          "\x01",
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        var dddd = [
          "\x02",
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ];
        var ddd = ["\x03", "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

        function ii(i, len) {
          var s = i + "";
          len = len || 2;
          while (s.length < len) s = "0" + s;
          return s;
        }

        var y = utc ? date.getUTCFullYear() : date.getFullYear();
        format = format.replace(/(^|[^\\])yyyy+/g, "$1" + y);
        format = format.replace(
          /(^|[^\\])yy/g,
          "$1" + y.toString().substr(2, 2)
        );
        format = format.replace(/(^|[^\\])y/g, "$1" + y);

        var M = (utc ? date.getUTCMonth() : date.getMonth()) + 1;
        format = format.replace(/(^|[^\\])MMMM+/g, "$1" + MMMM[0]);
        format = format.replace(/(^|[^\\])MMM/g, "$1" + MMM[0]);
        format = format.replace(/(^|[^\\])MM/g, "$1" + ii(M));
        format = format.replace(/(^|[^\\])M/g, "$1" + M);

        var d = utc ? date.getUTCDate() : date.getDate();
        format = format.replace(/(^|[^\\])dddd+/g, "$1" + dddd[0]);
        format = format.replace(/(^|[^\\])ddd/g, "$1" + ddd[0]);
        format = format.replace(/(^|[^\\])dd/g, "$1" + ii(d));
        format = format.replace(/(^|[^\\])d/g, "$1" + d);

        var H = utc ? date.getUTCHours() : date.getHours();
        format = format.replace(/(^|[^\\])HH+/g, "$1" + ii(H));
        format = format.replace(/(^|[^\\])H/g, "$1" + H);

        var h = H > 12 ? H - 12 : H == 0 ? 12 : H;
        format = format.replace(/(^|[^\\])hh+/g, "$1" + ii(h));
        format = format.replace(/(^|[^\\])h/g, "$1" + h);

        var m = utc ? date.getUTCMinutes() : date.getMinutes();
        format = format.replace(/(^|[^\\])mm+/g, "$1" + ii(m));
        format = format.replace(/(^|[^\\])m/g, "$1" + m);

        var s = utc ? date.getUTCSeconds() : date.getSeconds();
        format = format.replace(/(^|[^\\])ss+/g, "$1" + ii(s));
        format = format.replace(/(^|[^\\])s/g, "$1" + s);

        var f = utc ? date.getUTCMilliseconds() : date.getMilliseconds();
        format = format.replace(/(^|[^\\])fff+/g, "$1" + ii(f, 3));
        f = Math.round(f / 10);
        format = format.replace(/(^|[^\\])ff/g, "$1" + ii(f));
        f = Math.round(f / 10);
        format = format.replace(/(^|[^\\])f/g, "$1" + f);

        var T = H < 12 ? "AM" : "PM";
        format = format.replace(/(^|[^\\])TT+/g, "$1" + T);
        format = format.replace(/(^|[^\\])T/g, "$1" + T.charAt(0));

        var t = T.toLowerCase();
        format = format.replace(/(^|[^\\])tt+/g, "$1" + t);
        format = format.replace(/(^|[^\\])t/g, "$1" + t.charAt(0));

        var tz = -date.getTimezoneOffset();
        var K = utc || !tz ? "Z" : tz > 0 ? "+" : "-";
        if (!utc) {
          tz = Math.abs(tz);
          var tzHrs = Math.floor(tz / 60);
          var tzMin = tz % 60;
          K += ii(tzHrs) + ":" + ii(tzMin);
        }
        format = format.replace(/(^|[^\\])K/g, "$1" + K);

        var day = (utc ? date.getUTCDay() : date.getDay()) + 1;
        format = format.replace(new RegExp(dddd[0], "g"), dddd[day]);
        format = format.replace(new RegExp(ddd[0], "g"), ddd[day]);

        format = format.replace(new RegExp(MMMM[0], "g"), MMMM[M]);
        format = format.replace(new RegExp(MMM[0], "g"), MMM[M]);

        format = format.replace(/\\(.)/g, "$1");

        return format;
      };

      if (secondsPast < 0) {
        // Future date
        return timeStamp;
      }
      if (secondsPast < 60) {
        // Less than a minute
        return parseInt(secondsPast) + "secs";
      }
      if (secondsPast < 3600) {
        // Less than an hour
        return parseInt(secondsPast / 60) + "mins";
      }
      if (secondsPast <= 86400) {
        // Less than a day
        return parseInt(secondsPast / 3600) + "hrs";
      }
      if (secondsPast <= 172800) {
        // Less than 2 days
        return "Yesderday at " + formatDate(timeStamp, "h:mmtt");
      }
      if (secondsPast > 172800) {
        // After two days
        var timeString;

        if (secondsPast <= 604800)
          timeString =
            formatDate(timeStamp, "dddd") +
            " at " +
            formatDate(timeStamp, "h:mmtt");
        // with in a week
        else if (now.getFullYear() > timeStamp.getFullYear())
          timeString = formatDate(timeStamp, "MMMM d, yyyy");
        // a year ago
        else if (now.getMonth() > timeStamp.getMonth())
          timeString = formatDate(timeStamp, "MMMM d");
        // months ago
        else
          timeString =
            formatDate(timeStamp, "MMMM d") +
            " at " +
            formatDate(timeStamp, "h:mmtt"); // with in a month

        return timeString;
      }
    },
  },
  watch: {
    editStatus: function () {
      if (this.editStatus == true) {
        this.clearCommentStatus(this.id);
      }
    },
  },
  mounted() {
    EventBus.$on("commentEditSuccess", () => {
      this.editStatus = false;
    });
  },
  computed: {
    ...mapGetters(["getActiveSocialMedia", "getActiveSocialMediaAccount"]),
    profileImg: function () {
      let uri = this.activeAccountDetails.imageUrl;
      if (
        this.getActiveSocialMedia == "facebook" ||
        this.getActiveSocialMedia == "instagram"
      ) {
        // uri =
        //   "https://graph.facebook.com/" +
        //   this.activeAccountDetails.accountId +
        //   "/picture?access_token=" +
        //   this.activeAccountDetails.accessToken;
        uri =
          "https://graph.facebook.com/" +
          this.activeAccountDetails.accountId +
          "/picture";
      }
      return uri;
    },
    polarityStringsC: function () {
      let polarityStrings = this.polarityStrings.filter(
        (polarity) => polarity != "notassigned"
      );

      if (
        polarityStrings.length == 0 &&
        this.isQuestion == false &&
        this.isSpam == false
      ) {
        polarityStrings.push("notassigned");
      }
      return polarityStrings;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/colors.scss";
.wrap {
  border-radius: 30px;
  background: $white;
  padding: 0;
  margin-bottom: 20px;
  transition: all 0.25s ease;
  .likeCount {
    color: $light-gray;
    font-size: 12px;
    margin-right: 5px;
  }
  .date {
    // width: 60%;
    display: inline-block;
    color: $light-gray;
    font-size: 12px;
  }
  &:hover {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
  }
  cursor: pointer;
  &:hover {
    transform: scale(1.01);
    // box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  }
  .comment {
    min-height: 80px;
    padding: 15px 15px 10px;
    position: relative;
    .info {
      float: left;
      div {
        display: block;
      }
    }
    .profile {
      width: 45px;
      height: 45px;
      object-fit: cover;
      object-position: center;
      border-radius: 50%;
      float: left;
      margin-right: 10px;
    }
    .name {
      font-weight: bold;
    }
    .city {
      font-size: 12px;
      position: relative;
      top: -5px;
    }
    .imgs {
      width: 100%;
      clear: both;
      position: relative;
      margin-top: 20px;
      img {
        width: 100px;
        height: 100px;
        object-fit: cover;
        object-position: center;
        border-radius: 15px;
      }
    }
  }

  .date {
    color: $light-gray;
    font-size: 12px;
  }
  .bottom {
    width: 100%;
    padding: 10px 17px;
    border-top: 1px solid lighten($black, 95%);
    display: flex;
    flex-wrap: wrap;
    font-size: 12px;
    position: relative;
    justify-content: space-between;
    color: $light-gray;
    .status {
      margin-top: 2px;
      svg {
        margin-top: 0;
      }
    }
  }
  .text {
    padding: 0;
    margin-top: 5px;
  }
}
.status {
  // .dot {
  // 	float: left;
  // 	margin-right: 2px;
  // 	transform: scale(0.8);
  // }
}
.statuses {
  display: flex;
  padding: 0;
  justify-content: space-around;
  margin-left: -10px;
  &:after {
    clear: both;
  }
  .status {
    margin-right: 10px;
    line-height: 19px;
  }
  .emotions {
    display: inline-block;
  }
}
.emotions {
  margin-top: 2px;
  margin-left: 5px;
  span {
    margin-right: 2px;
  }
  svg {
    margin-top: -2px;
    margin-right: 2px;
    width: 15px;
    height: 15px;
  }
}
.quick-actions {
  img,
  svg {
    margin-left: 10px;
    transition: all 0.25s ease;
    &:hover {
      transform: scale(1.18);
    }
  }
  #like {
    path {
      transition: all 0.25s ease;
    }
    &:hover {
      path {
        fill: $brandcolor !important;
        stroke: $brandcolor !important;
      }
    }
  }
  .liked {
    path {
      fill: $brandcolor;
      stroke: $brandcolor;
    }
  }
}
.reply-wrap {
  margin: 0;
  height: 0;
  transition: all 0.3s ease;
  opacity: 0;
}
.reply-wrap.show {
  opacity: 1;
  height: auto;
}
.post-thumb {
  height: 70px;
  margin: 20px 35px 20px 40px;
  text-align: right;
  padding: 0;
  position: relative;
  img {
    width: 80px;
    height: 80px;
    border-radius: 10px;
    margin: 0;
    border: 1px solid #ccc;
    display: inline-block;
    line-height: 80px;
    text-align: center;
  }
  .social-badge {
    position: absolute;
    width: 20px;
    height: auto;
    left: 40px;
    top: -5px;
  }
}
.reply {
  border-radius: 15px;
  background: $white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
  min-height: 40px;
  margin-bottom: 20px;
  padding: 10px;
  .info {
    float: left;
    div {
      display: block;
    }
  }
  .profile {
    width: 45px;
    height: 45px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    float: left;
    margin-right: 10px;
  }
  .name {
    font-weight: bold;
  }
  .city {
    font-size: 12px;
    position: relative;
    top: -5px;
  }
  textarea {
    border: 0;
    resize: none;
    margin: 0;
    margin: 0px -10px;
    &:focus {
      box-shadow: none;
    }
  }
  .send {
    position: absolute;
    right: 10px;
    bottom: 10px;
    width: 20px;
    height: auto;
    cursor: pointer;
  }
  .close {
    margin-top: -5px;
    i {
      font-size: 14px;
    }
  }
}
.showReplies {
  transition: all 0.25s ease;
  margin-top: 2px;
  img {
    margin-top: -2px;
  }
}
.hide {
  opacity: 0;
  z-index: -1;
}
.attention {
  text-align: center;
  font-size: 12px;
  background: $white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
  margin: 0;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  border-top: 1px solid lighten($black, 95%);
  padding: 0px;
  height: 0px;
  transition: all 0.2s ease;
  overflow: hidden;
}
.show {
  padding: 5px;
  height: auto;
}
.saveStatus {
  opacity: 0;
  z-index: -1;
  position: absolute;
  top: 7px;
  right: 0px;
  text-align: right;
  .btn {
    padding: 0px 15px;
    margin-right: 5px;
    border-radius: 15px;
    transition: all 0.2s ease;
    &:hover {
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
    }
  }
  .btn-default {
    background: darken($white, 5%);
  }
}
.showStatusEdit {
  opacity: 1;
  z-index: 9;
}
.black {
  color: $black;
  text-transform: capitalize !important;
}
@media (max-width: 767.98px) {
  .status {
    .dot {
      float: none !important;
    }
  }
}
@media screen and (max-device-width: 1450px) {
  .bottom {
    font-size: 10px !important;
  }
}
</style>
