<script>
import { Doughnut } from "vue-chartjs";

export default {
  name: "pieChart",
  extends: Doughnut,
  props: ["graph", "options"],
  mounted() {
    this.renderChart(
      {
        labels: ["1", "2", "3", "4", "5", "6", "7"],
        datasets: [
          {
            data: this.graph,
            borderColor: ["rgba(2, 150, 60, .3)"],

            backgroundColor: [
              "#00a1ed",
              "#ffd800",
              "#f00",
              "#a54cff",
              "#edc7a2",
              "#a2a2a2",
            ],
          },
        ],
      },
      this.options
    );
  },
};
</script>
