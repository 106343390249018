<template>
  <div id="conversation" class="conversation">
    <div
      class="message"
      v-for="(message, index) in conversation"
      :key="index"
      :class="{ right: message.authorId == getActiveSocialMediaAccount }"
    >
      <span>
        {{ message.content }}
        <div class="imgs" v-if="message.imageUrl">
          <img @error="(e) => (e.target.src = $defaultCardAvatar)"
            v-for="(img, index) in message.imageUrl"
            :src="img"
            :key="index"
            alt="Message Image"
          />
        </div>
        <div class="datetime">
          {{ new Date(message.createdTime).toLocaleDateString() }} -
          {{ new Date(message.createdTime).toLocaleTimeString() }}
        </div>
      </span>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "conversation",
  data() {
    return {};
  },
  props: ["conversation"],
  computed: {
    ...mapGetters(["getActiveSocialMedia", "getActiveSocialMediaAccount"])
  }
};
</script>
<style lang="scss">
.conversation {
  .imgs {
    width: 100%;
    clear: both;
    position: relative;
    margin: 15px auto 10px auto;
    img {
      width: 100px;
      height: 100px;
      object-fit: cover;
      object-position: center;
      border-radius: 5px;
      margin-right: 10px;
    }
  }
  .datetime {
    font-size: 12px;
    opacity: 0.6;
  }
}
</style>
