<template>
  <div class="movement">
    <div class="img">
      <img @error="(e) => (e.target.src = $defaultCardAvatar)"
        :src="movement.profileImg"
        class="img-fluid"
        v-if="movement.profileImg"
      />
      <img @error="(e) => (e.target.src = $defaultCardAvatar)"
        class="img-fluid"
        src="@/assets/images/profile.png"
        alt="Selected Account"
        v-if="!movement.profileImg"
      />
    </div>
    <div class="info col-9">
      <span class="name">{{ movement.systemUserName }}</span>
      {{ operation }}
      <template v-if="movement.oldValue">
        <span v-for="(value, index) in movement.oldValue" :key="index">{{
          value
        }}</span>
        comment to
      </template>
      <template v-if="movement.newValue">
        <span v-for="(value, index) in movement.newValue" :key="'n' + index">{{
          value
        }}</span>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  name: "userMovements",
  props: ["movement"],
  created() {},
  computed: {
    operation: function() {
      let operation = "";
      switch (this.movement.type) {
        case "CHANGE_STATUS":
          operation = "changed";
          break;
        case "CREATE":
          operation = "created";
          break;
        case "LIKE":
          operation = "liked";
          break;
        case "CREATE_MESSAGE":
          operation = "sended";
          break;
        case "DELETE":
          operation = "deleted";
          break;

        default:
          operation = "";
          break;
      }
      return operation;
    }
  }
};
</script>
<style lang="scss">
@import "@/scss/colors.scss";
.movement {
  display: flex;
  padding: 10px;
  border-radius: 30px;
  span {
    display: inline !important;
  }
  .img {
    padding: 0;
    img {
      border-radius: 50%;
      width: 50px !important;
      height: auto !important;
    }
  }
  .info {
    padding: 0px 10px;
    .name {
      font-weight: bold;
    }

    .slug {
      font-size: 12px;
    }
  }
}
</style>
