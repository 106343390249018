<template>
  <div class="setting account">
    <div class="head">
      <img @error="(e) => (e.target.src = $defaultCardAvatar)" src="@/assets/images/settings/socialaccounts.svg" alt="Email Settings" />
      <span class="notify-dot right" v-if="brokenAccounts.length > 0">
        {{ brokenAccounts.length }}
      </span>
      <span>Paid Accounts</span>
      <div class="float-right" v-if="accountType != 'ACTIVE'">
        <button @click="goToUpgrade" class="btn btn-success">Subscribe Now</button>
      </div>
    </div>
    <div class="body l-accounts" :class="{ 'no-data': laccounts.length == 0 }">
      <span v-if="laccounts.length == 0"> No Accounts </span>
      <account
        v-for="(account, index) in laccounts"
        :key="index"
        :account="account"
        :user="user"
        :linked="true"
        :subscriptionDetail="getSubscriptionDetail(account.accountId)"
      ></account>
    </div>
  </div>
</template>
<script>
import ListItem from '@/components/settings/Linked-Account-Item';
import router from '@/router';

export default {
  name: 'Linked-Accounts',
  props: ['laccounts', 'user', 'subscriptionDetails'],
  data() {
    return {
      brokenAccounts: [],
      accountType: JSON.parse(localStorage.getItem('accountStatus')).status,
    };
  },
  components: {
    account: ListItem,
  },
  created() {
    this.brokenAccounts = this.laccounts.filter((a) => a.hasError == true);
  },
  methods: {
    getSubscriptionDetail: function (accountId) {
      return this.subscriptionDetails.filter((s) => s.accountId == accountId)[0];
    },
    goToUpgrade: function () {
      let accountStatus = JSON.parse(localStorage.getItem('accountStatus'));
      if (accountStatus.count == 0) {
        router
          .push({
            name: 'home',
            query: {
              addAccount: 'true',
            },
          })
          .catch(() => {
            this.$notify({
              group: 'info',
              type: 'error',
              text: 'Please select social media',
            });
          });
        this.setActiveSocialMedia('');
        this.setActiveSocialMediaAccount('');
      } else {
        this.$goToUpgrade();
      }
    },
  },
};
</script>

<style lang="scss">
@import '../../scss/colors.scss';

.l-accounts {
  width: calc(100% - 30px);
  // padding: 10px;
  top: -7px;
  padding: 0;
  overflow: hidden;
  .img {
    img {
      width: 50px;
      height: 50px;
    }
  }
  .info {
    padding-top: 0 !important;
  }
  .social-account {
    padding: 10px;

    transition: all 0.2s ease;
    border-radius: 30px;
    margin-bottom: 10px;
    cursor: pointer;
    &:hover {
      background: lighten($black, 90%);
    }
    &:last-child {
      border-bottom: 0;
      margin-bottom: 0px;
    }
  }
}
</style>
