<template>
  <div class="mobile-ai-filter" :class="{ show: mobileOrderModal }">
    <div class="close-trigger" @click="setAndClose"></div>
    <div class="wrap">
      <div class="head">
        <div class="row">
          <div class="col">
            <span class="h5">Order</span>
          </div>
          <div class="col text-right done" @click="setAndClose">Done</div>
        </div>
      </div>
      <div class="body">
        <div
          class="row"
          @click="
            order = 'DESC';
            orderBy = 'createdTime';
          "
        >
          <div class="col-8">
            <img
              src="@/assets/images/sort/desc.svg"
              @click="mobileSearchActive = true"
              alt
            />
            Newest
          </div>
          <div class="col text-right">
            <img
              src="@/assets/images/checked.svg"
              v-if="order == 'DESC' && orderBy == 'createdTime'"
            />
          </div>
        </div>

        <div
          class="row"
          @click="
            order = 'ASC';
            orderBy = 'createdTime';
          "
        >
          <div class="col-8">
            <img
              src="@/assets/images/sort/asc.svg"
              @click="mobileSearchActive = true"
              alt
            />
            Oldest
          </div>
          <div class="col text-right">
            <img
              src="@/assets/images/checked.svg"
              v-if="order == 'ASC' && orderBy == 'createdTime'"
            />
          </div>
        </div>

        <div
          class="row"
          @click="
            order = 'DESC';
            orderBy = 'numOfLikes';
          "
        >
          <div class="col-8">Most Liked</div>
          <div class="col text-right">
            <img
              src="@/assets/images/checked.svg"
              v-if="order == 'DESC' && orderBy == 'numOfLikes'"
            />
          </div>
        </div>
        <div
          class="row"
          @click="
            order = 'DESC';
            orderBy = 'numOfReplies';
          "
        >
          <div class="col-8">Most Replies</div>
          <div class="col text-right">
            <img
              src="@/assets/images/checked.svg"
              v-if="order == 'DESC' && orderBy == 'numOfReplies'"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "sort",
  props: ["mobileOrderModal", "f_order", "f_orderBy"],
  data() {
    return {
      order: null,
      orderBy: null,
    };
  },
  created() {
    this.order = this.f_order;
    this.orderBy = this.f_orderBy;
  },
  methods: {
    setAndClose: function () {
      this.$emit("close", { order: this.order, orderBy: this.orderBy });
    },
  },
};
</script>