<template>
  <div>
        <!-- New Card Modal -->
    <modal
      v-if="modalUpdateForce"
      @close="modalUpdateForce = false"
      class="newCardModal"
    >
      <div slot="title">Attention</div>
      <div class="row head">
        <div class="col">
          <i class="fas fa-exclamation-triangle"></i>
          Warning
        </div>
        <div class="col text-right">
          <i class="fas fa-times" @click="modalUpdateForce = false"></i>
        </div>
      </div>
      <div slot="body" class="newcard-form">
    
       <p>
         This social media account is linked to another Socialays account. We are using unique Account settings for the same social media account. This action affects another Socialays account settings too.
       </p>
      </div>
      <div slot="footer">
        <button class="btn btn-default" @click="modalUpdateForce = false">
          Cancel
        </button>
        <button class="btn btn-primary" @click="updateForce">
          Confirm
        </button>
      </div>
    </modal>
    <!-- New Card Modal End -->
    <muuri-grid
      id="draggable"
      class="settings drag"
      :options="dragOptions"
      v-if="show"
    >
      <ItemMd>
        <AutoBlock :settings="accountSettings.autoBlock" :key="'1'"></AutoBlock>
      </ItemMd>
      <!-- <ItemMd>
        <EmailReporting
          :settings="accountSettings.emailNotification.emailReporting"
          :key="'2'"
        ></EmailReporting>
      </ItemMd> -->
      <ItemMd>
        <EmailNotification
          :settings="accountSettings.emailNotification"
          :key="'3'"
        ></EmailNotification>
      </ItemMd>
      <ItemMd>
        <NegativeComments
          :settings="accountSettings.negativeComments"
          :key="'4'"
        ></NegativeComments>
      </ItemMd>
    </muuri-grid>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EventBus from "@/components/event-bus";
import { HTTP } from "@/main-source";
import EmailReporting from "@/components/facebook/account-settings/Email-Reporting";
import AutoBlock from "@/components/facebook/account-settings/Auto-Block";
import EmailNotification from "@/components/facebook/account-settings/Email-Notification";
import NegativeComments from "@/components/facebook/account-settings/Negative-Comments";
import { ItemMd } from "vue-muuri";
import modal from "@/components/modal";

const componentList = [
  "EmailReporting",
  "AutoBlock",
  "EmailNotification",
  "NegativeComments",
];

export default {
  name: "AccountSettings",
  data() {
    return {
      accountSettings: {
        emailReporting: [],
        autoBlock: [],
        emailNotification: {},
        negativeComments: [],
      },
      order: componentList.map((name, index) => {
        return { name, order: index };
      }),
      dragOptions: this.$dragOptions,
      show: false,
      pocketData:null,
      modalUpdateForce:false
    };
  },
  created() {
    if (this.getActiveSocialMediaAccount) {
      this.getAccountSettings();
    }
  },
  components: {
    EmailReporting,
    AutoBlock,
    EmailNotification,
    NegativeComments,
    ItemMd,
    modal
  },
  methods: {
    getAccountSettings: function () {
      let uri =
        "social/" + this.getActiveSocialMediaAccount + "/accountsettings";
      HTTP.get(uri).then((result) => {
        this.accountSettings = result.data;
        this.show = true;
      });
    },
    updateAccountSetting: function (data) {
      let uri = "social/" + this.getActiveSocialMediaAccount + "/settings";
      data.id = this.accountSettings.id;
      HTTP.put(uri, data).then((result) => {
        console.log(result);
        
      }).catch(e=>{
        if (e.response.status == 409) {
          this.modalUpdateForce = true;
          this.pocketData = data;
        }
      });
    },
    updateForce:function(){
      let uri = "social/" + this.getActiveSocialMediaAccount + "/settings?force=true";
       HTTP.put(uri, this.pocketData).then((result) => {
        this.pocketData = null;
        this.modalUpdateForce = false;
      })
    },
    updateAccountNotification: function (data) {
      let uri = "social/" + this.getActiveSocialMediaAccount + "/notifications";
      data.id = this.accountSettings.emailNotification.id;
      HTTP.put(uri, data).then((result) => {
        console.log(result);
      });
    },
    getKey: function (name) {
      return this.order.filter((ord) => ord.name == name)[0].order;
    },
  },
  mounted() {
    EventBus.$on("updateAccountSetting", (data) => {
      this.updateAccountSetting(data);
    });
    EventBus.$on("updateAccountNotification", (data) => {
      this.updateAccountNotification(data);
    });
  },
  beforeDestroy: function () {
    EventBus.$off("updateAccountSetting");
    EventBus.$off("updateAccountNotification");
  },
  computed: {
    ...mapGetters(["getActiveSocialMediaAccount"]),
  },
  watch: {
    getActiveSocialMediaAccount: function () {
      this.getAccountSettings();
    },
    order: function () {
      console.log(...this.order);
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/colors.scss";
.settings {
  column-count: 3;
  // display: -webkit-flex; /* Safari */
  // display: flex;
  .setting {
    background: $white;
    border-radius: 30px;
    break-inside: avoid;
    margin-bottom: 15px;
    &:nth-child(3) {
      order: 1;
    }
    .head {
      cursor: grab;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    }
    .break {
      border-bottom: 1px solid darken($white, 5%);
      width: calc(100% + 30px);
      margin: 10px -15px;
    }
    .sub {
      font-size: 13px;
    }
  }

  .head {
    padding: 13px 15px;
    color: lighten($black, 50%);
    font-size: 15px;
    font-weight: bold;
    img {
      margin-right: 10px;
      width: 20px;
      height: auto;
    }
  }
  .body {
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    div {
      width: 100%;
      margin: 5px 0px;
      position: relative;
      padding-right: 20px;
    }
  }
}
// margin-right: -15px;
//   margin-left: -15px;
//   height: calc(100vh - 170px);
//   .card-columns {
//     width: 100%;
//     padding: 0px 15px;
//   }
@media (max-width: 767.98px) {
  .settings {
    -webkit-column-count: 1 !important;
    -moz-column-count: 1 !important;
    column-count: 1 !important;
  }
}
</style>
