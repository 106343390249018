<template>
  <div class="col-12 wrap">
    <div class="comment">
      <div class="likewrapper">
        {{ numOfLikes }}
        <heart :isLiked="parseInt(numOfLikes) > 0 ? true : false"></heart>
      </div>
      <div class="col text">
        <img @error="(e) => (e.target.src = $defaultCardAvatar)"
          class="post-image"
          v-if="postImg"
          :src="postImg"
          alt="Post Image" />
        <img @error="(e) => (e.target.src = $defaultCardAvatar)"
          class="post-image"
          v-if="!postImg"
          src="@/assets/images/no-image.jpg"
          alt="Profile" />
        <div class="max-content">
          {{
  content
  ? content.substr(0, maxContentLength)
          : "“This post shared without a description”"
          }}
          <template v-if="content">
            {{ content.length > maxContentLength ? "..." : "" }}
          </template>
        </div>
        <div class="date">{{ timeSince(date) }}</div>

        <div class="bottom">
          <div class="comments">
            <img @error="(e) => (e.target.src = $defaultCardAvatar)"
              src="@/assets/images/comment.svg" />
            Comments ({{ countOfReplies }})
          </div>
        </div>
      </div>
    </div>
    <div class="card--footer">
      <div class="status-wrapper mr-3"
        :class="{ biggest: polarityCounts.positive != 0 }">
        <status :status="'positive'"></status>
        {{ abbreviate(polarityCounts.positive) }}
      </div>
      <div class="status-wrapper mr-3"
        :class="{ biggest: polarityCounts.neutral != 0 }">
        <status :status="'neutral'"></status>
        {{ abbreviate(polarityCounts.neutral) }}
      </div>
      <div class="status-wrapper mr-3"
        :class="{ biggest: polarityCounts.negative != 0 }">
        <status :status="'negative'"></status>
        {{ abbreviate(polarityCounts.negative) }}
      </div>
      <div class="status-wrapper mr-3"
        :class="{ biggest: polarityCounts.question != 0 }">
        <status :status="'question'"></status>
        {{ abbreviate(polarityCounts.question) }}
      </div>
      <div class="status-wrapper mr-3"
        :class="{ biggest: polarityCounts.spam != 0 }">
        <status status="spam"></status>
        {{ abbreviate(polarityCounts.spam) }}
      </div>
      <div class="status-wrapper has-language mr-3">
        <component :is="'hate'"
          :filled="true"></component>
        {{ abbreviate(post.offensiveCounts.hate) }}
      </div>
      <div class="status-wrapper has-language mr-3">
        <component :is="'profanity'"
          :filled="true"></component>
        {{ abbreviate(post.offensiveCounts.profanity) }}
      </div>

    </div>
  </div>
</template>

<script>
import { HTTP } from "@/main-source";
import { mapGetters } from "vuex";
import status from "@/components/dot";
import heart from "@/components/icons/heart";
import abbreviate from "number-abbreviate";
import hate from "@/components/icons/offensive/hate";
import offensive from "@/components/icons/offensive/offensive";
import profanity from "@/components/icons/offensive/profanity";

export default {
  name: "comment",
  props: [
    "name",
    "location",
    "content",
    "countOfReplies",
    "numOfLikes",
    "isHidden",
    "isQuestion",
    "polarity",
    "img",
    "date",
    "majorStatus",
    "polarityCounts",
    "post",
  ],
  data() {
    return {
      maxContentLength: this.$mobileAndTabletCheck() ? 20 : 120,
      postImg: this.$defaultCardAvatar,
    };
  },

  components: {
    status,
    heart,
    hate,
    offensive,
    profanity
  },
  created() {
    if (
      this.getActiveSocialMedia == "instagram" ||
      this.getActiveSocialMedia == "facebook" ||
      this.getActiveSocialMedia == "youtube"
    ) {
      HTTP.get(
        "/media/" +
        this.getActiveSocialMedia +
        "?accountid=" +
        this.getActiveSocialMediaAccount +
        "&uniqueid=" +
        this.post.id
      ).then((result) => {
        if (result.status == 200) {
          this.postImg =
            result.data[0].type == "VIDEO"
              ? result.data[0].videoThumbImage
              : result.data[0].url;
        }
      });
    }

    if (this.getActiveSocialMedia == "youtube") {
      this.postImg = this.img;
    }
  },

  computed: {
    ...mapGetters(["getActiveSocialMedia", "getActiveSocialMediaAccount"]),
  },
  methods: {
    abbreviate(value) {
      return value < 10000
        ? this.$intlNumber(value)
        : `${abbreviate(value)}`.toUpperCase();
    },
    timeSince: function (timeStamp) {
      if (!(timeStamp instanceof Date)) {
        timeStamp = new Date(timeStamp);
      }

      if (isNaN(timeStamp.getDate())) {
        return "Invalid date";
      }

      var now = new Date(),
        secondsPast = (now.getTime() - timeStamp.getTime()) / 1000;

      var formatDate = function (date, format, utc) {
        var MMMM = [
          "\x00",
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
        var MMM = [
          "\x01",
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        var dddd = [
          "\x02",
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ];
        var ddd = ["\x03", "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

        function ii(i, len) {
          var s = i + "";
          len = len || 2;
          while (s.length < len) s = "0" + s;
          return s;
        }

        var y = utc ? date.getUTCFullYear() : date.getFullYear();
        format = format.replace(/(^|[^\\])yyyy+/g, "$1" + y);
        format = format.replace(
          /(^|[^\\])yy/g,
          "$1" + y.toString().substr(2, 2)
        );
        format = format.replace(/(^|[^\\])y/g, "$1" + y);

        var M = (utc ? date.getUTCMonth() : date.getMonth()) + 1;
        format = format.replace(/(^|[^\\])MMMM+/g, "$1" + MMMM[0]);
        format = format.replace(/(^|[^\\])MMM/g, "$1" + MMM[0]);
        format = format.replace(/(^|[^\\])MM/g, "$1" + ii(M));
        format = format.replace(/(^|[^\\])M/g, "$1" + M);

        var d = utc ? date.getUTCDate() : date.getDate();
        format = format.replace(/(^|[^\\])dddd+/g, "$1" + dddd[0]);
        format = format.replace(/(^|[^\\])ddd/g, "$1" + ddd[0]);
        format = format.replace(/(^|[^\\])dd/g, "$1" + ii(d));
        format = format.replace(/(^|[^\\])d/g, "$1" + d);

        var H = utc ? date.getUTCHours() : date.getHours();
        format = format.replace(/(^|[^\\])HH+/g, "$1" + ii(H));
        format = format.replace(/(^|[^\\])H/g, "$1" + H);

        var h = H > 12 ? H - 12 : H == 0 ? 12 : H;
        format = format.replace(/(^|[^\\])hh+/g, "$1" + ii(h));
        format = format.replace(/(^|[^\\])h/g, "$1" + h);

        var m = utc ? date.getUTCMinutes() : date.getMinutes();
        format = format.replace(/(^|[^\\])mm+/g, "$1" + ii(m));
        format = format.replace(/(^|[^\\])m/g, "$1" + m);

        var s = utc ? date.getUTCSeconds() : date.getSeconds();
        format = format.replace(/(^|[^\\])ss+/g, "$1" + ii(s));
        format = format.replace(/(^|[^\\])s/g, "$1" + s);

        var f = utc ? date.getUTCMilliseconds() : date.getMilliseconds();
        format = format.replace(/(^|[^\\])fff+/g, "$1" + ii(f, 3));
        f = Math.round(f / 10);
        format = format.replace(/(^|[^\\])ff/g, "$1" + ii(f));
        f = Math.round(f / 10);
        format = format.replace(/(^|[^\\])f/g, "$1" + f);

        var T = H < 12 ? "AM" : "PM";
        format = format.replace(/(^|[^\\])TT+/g, "$1" + T);
        format = format.replace(/(^|[^\\])T/g, "$1" + T.charAt(0));

        var t = T.toLowerCase();
        format = format.replace(/(^|[^\\])tt+/g, "$1" + t);
        format = format.replace(/(^|[^\\])t/g, "$1" + t.charAt(0));

        var tz = -date.getTimezoneOffset();
        var K = utc || !tz ? "Z" : tz > 0 ? "+" : "-";
        if (!utc) {
          tz = Math.abs(tz);
          var tzHrs = Math.floor(tz / 60);
          var tzMin = tz % 60;
          K += ii(tzHrs) + ":" + ii(tzMin);
        }
        format = format.replace(/(^|[^\\])K/g, "$1" + K);

        var day = (utc ? date.getUTCDay() : date.getDay()) + 1;
        format = format.replace(new RegExp(dddd[0], "g"), dddd[day]);
        format = format.replace(new RegExp(ddd[0], "g"), ddd[day]);

        format = format.replace(new RegExp(MMMM[0], "g"), MMMM[M]);
        format = format.replace(new RegExp(MMM[0], "g"), MMM[M]);

        format = format.replace(/\\(.)/g, "$1");

        return format;
      };

      if (secondsPast < 0) {
        // Future date
        return timeStamp;
      }
      if (secondsPast < 60) {
        // Less than a minute
        return parseInt(secondsPast) + "secs";
      }
      if (secondsPast < 3600) {
        // Less than an hour
        return parseInt(secondsPast / 60) + "mins";
      }
      if (secondsPast <= 86400) {
        // Less than a day
        return parseInt(secondsPast / 3600) + "hrs";
      }
      if (secondsPast <= 172800) {
        // Less than 2 days
        return "Yesderday at " + formatDate(timeStamp, "h:mmtt");
      }
      if (secondsPast > 172800) {
        // After two days
        var timeString;

        if (secondsPast <= 604800)
          timeString =
            formatDate(timeStamp, "dddd") +
            " at " +
            formatDate(timeStamp, "h:mmtt");
        // with in a week
        else if (now.getFullYear() > timeStamp.getFullYear())
          timeString = formatDate(timeStamp, "MMMM d, yyyy");
        // a year ago
        else if (now.getMonth() > timeStamp.getMonth())
          timeString = formatDate(timeStamp, "MMMM d");
        // months ago
        else
          timeString =
            formatDate(timeStamp, "MMMM d") +
            " at " +
            formatDate(timeStamp, "h:mmtt"); // with in a month

        return timeString;
      }
    },
    polarityCountsTotal: function () {
      return (
        this.polarityCounts.positive +
        this.polarityCounts.negative +
        this.polarityCounts.question +
        this.polarityCounts.neutral
      );
    },
    polarityCountsBiggest: function () {
      let biggest = {
        field: "positive",
        value: this.polarityCounts.positive,
      };
      if (this.polarityCounts.negative > biggest.value) {
        biggest = {
          field: "negative",
          value: this.polarityCounts.negative,
        };
      }
      if (this.polarityCounts.neutral > biggest.value) {
        biggest = {
          field: "neutral",
          value: this.polarityCounts.neutral,
        };
      }
      if (this.polarityCounts.question > biggest.value) {
        biggest = {
          field: "question",
          value: this.polarityCounts.question,
        };
      }
      return biggest.value != 0 ? biggest : { field: "" };
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/colors.scss";

.wrap {
  border-radius: 30px;
  background: $white;

  &:hover {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
  }

  padding: 0;
  margin-bottom: 20px;
  transition: all 0.25s ease;
  cursor: pointer;

  &:hover {
    transform: scale(1.01);
    // box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  }

  .likewrapper {
    position: absolute;
    right: 15px;
    top: 15px;
    color: $light-gray;
    font-size: 12px;
  }

  .date {
    color: $light-gray;
    font-size: 12px;
  }

  .comment {
    min-height: 80px;
    padding: 15px 15px 15px;

    .post-image {
      width: 90px;
      height: 90px;
      object-fit: cover;
      object-position: center;
      border-radius: 15px;
      float: left;
      margin-right: 10px;
      margin-bottom: 10px;
    }

    .name {
      font-weight: bold;
      display: block;
    }

    .city {
      font-size: 12px;
      display: block;
      margin-top: -2px;
    }
  }

  .bottom {
    width: calc(100% - 100px);
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    font-size: 11px;
    position: absolute;
    bottom: -2px;
    left: 100px;
    color: $light-gray;

    .comments {
      img {
        margin-top: -2px;
      }
    }
  }

  .text {
    padding: 0px 50px 0px 0px;
    // margin-top: 10px;
    display: block;
    height: 90px;
    position: relative;
  }
}

.status {
  position: absolute;
  right: 0;
  line-height: 20px;

  svg {
    margin-top: 0px;
  }
}

.statuses {
  display: flex;
  padding: 0;
  justify-content: space-around;

  .status {
    margin-right: 10px;
    line-height: 19px;
  }
}

.quick-actions {
  text-align: right;

  img {
    margin-left: 10px;
    transition: all 0.25s ease;

    &:hover {
      transform: scale(1.1);
    }
  }
}

.card--footer {
  border-top: 1px solid var(--color--border--primary);
  display: flex;
  flex-wrap: wrap;
  padding: 10px 16px;
  justify-content: center;

  .status-wrapper {
    width: 52px;
    text-align: center;

    svg {
      margin-top: 0px;
    }
  }

  @media (max-width: 767.98px) {
    .status-wrapper {
      line-height: 30px;

      svg {
        margin-top: -2px;
        margin-right: -1px;
      }
    }

    .has-language {
      svg {
        margin-top: -12px !important;
      }
    }

  }
}

.max-content {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media (min-width: 767.98px) and (max-width: 1200px) {
  #posts .text {
    height: 90px;
    font-size: 12px !important;
  }
}
</style>
