<template>
  <div
    class="page-item"
    @click="!noaction ? selectAccount(account.userId) : ''"
  >
    <div class="img">
      <img @error="(e) => (e.target.src = $defaultCardAvatar)" :src="page.imageUrl" class="img-fluid" v-if="page.imageUrl" />
      <img @error="(e) => (e.target.src = $defaultCardAvatar)"
        class="img-fluid"
        src="@/assets/images/profile.png"
        alt="Selected Account"
        v-if="!page.imageUrl"
      />
    </div>
    <div class="info col-11">
      <span class="name">
        {{ page.accountName }}
        <small>/ {{ page.socialMediaApp }}</small>
      </span>
      <span class="slug">
        {{ page.accountName | username }}
      </span>
      <img @error="(e) => (e.target.src = $defaultCardAvatar)"
        src="@/assets/images/comments/delete.svg"
        class="delete"
        alt="Resend confirmation email"
        @click="deleteAssignedPageTrigger"
      />
    </div>
  </div>
</template>
<script>
import EventBus from "@/components/event-bus";

export default {
  name: "page",
  props: ["page", "noaction"],
  methods: {
    deleteAssignedPageTrigger: function() {
      EventBus.$emit("deleteAssignedPageTrigger", this.page);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/scss/colors.scss";
.page-item {
  display: flex;
  padding: 10px;
  border-radius: 30px;

  .img {
    padding: 0;
    img {
      border-radius: 50%;
      width: 50px !important;
      height: auto !important;
    }
  }
  .info {
    padding: 0px 10px;
    position: relative;
    .name {
      font-weight: bold;
    }
    span {
      display: block;
    }
    .slug {
      font-size: 12px;
    }
    .delete {
      position: absolute;
      right: 40px;
      top: 20px;
      opacity: 0;
      transition: all 0.2s ease;
    }
    &:hover {
      .delete {
        opacity: 1;
      }
    }
  }
}
</style>
