<template>
  <svg
    id="_004-happy"
    data-name="004-happy"
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <path
      id="Path_709"
      data-name="Path 709"
      d="M13.657,2.343A8,8,0,1,0,2.343,13.657,8,8,0,1,0,13.657,2.343Zm-.663,10.651A7.063,7.063,0,1,1,3.006,3.006a7.063,7.063,0,0,1,9.988,9.988Z"
      transform="translate(0)"
      fill="#5a5a5a"
    />
    <path
      id="Path_710"
      data-name="Path 710"
      d="M297.275,200.017a.469.469,0,0,0-.469.469.676.676,0,1,1-1.353,0,.469.469,0,1,0-.938,0,1.614,1.614,0,1,0,3.228,0A.469.469,0,0,0,297.275,200.017Z"
      transform="translate(-285.312 -193.766)"
      fill="#5a5a5a"
    />
    <path
      id="Path_711"
      data-name="Path 711"
      d="M117.417,200.486a.469.469,0,1,0-.938,0,.676.676,0,1,1-1.353,0,.469.469,0,0,0-.938,0,1.614,1.614,0,1,0,3.228,0Z"
      transform="translate(-110.621 -193.766)"
      fill="#5a5a5a"
    />
    <path
      id="Path_712"
      data-name="Path 712"
      d="M156.6,331.887a19.344,19.344,0,0,1-5.493-.009l-.028,0a.469.469,0,0,0-.486.674,3.553,3.553,0,0,0,6.491.014.469.469,0,0,0-.484-.676Zm-2.758,1.838a2.6,2.6,0,0,1-1.839-.8,20.277,20.277,0,0,0,3.669.008A2.6,2.6,0,0,1,153.84,333.725Z"
      transform="translate(-145.837 -321.498)"
      fill="#5a5a5a"
    />
    <path
      id="Path_713"
      data-name="Path 713"
      d="M114.362,100.222a.468.468,0,0,0,.658-.08,2.979,2.979,0,0,1,1.944-1.048.469.469,0,1,0-.044-.937,3.891,3.891,0,0,0-2.638,1.405A.469.469,0,0,0,114.362,100.222Z"
      transform="translate(-110.614 -95.09)"
      fill="#5a5a5a"
    />
    <path
      id="Path_714"
      data-name="Path 714"
      d="M294.965,99.1a2.978,2.978,0,0,1,1.943,1.047.469.469,0,0,0,.738-.579,3.891,3.891,0,0,0-2.638-1.405.469.469,0,1,0-.044.937Z"
      transform="translate(-285.314 -95.091)"
      fill="#5a5a5a"
    />
  </svg>
</template>

<script>
export default {
  name: "relief"
};
</script>
