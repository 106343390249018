<template>
  <div class="chart-card">
    <div class="chart-header">
      <span class="count">
        {{
          sumValues == 0 && this.metric.insightData.length == 0
            ? "~"
            : sumValues
        }}
      </span>
      <span class="sub-title">{{ metric.title }}</span>
      <span class="chart-desc d-none">
        <status :status="false"></status> %50 Toplam Sayfa Görüntülenmeleri
      </span>
    </div>
    <div :class="{ 'not-enough': metric.insightData.length == 0 }">
      <line-chart
        :graph="metric.insightData"
        class="line-chart"
        :class="{ transparented: sumValues == 0 }"
        :options="options"
        :height="180"
        v-if="metric.insightData.length > 0"
      ></line-chart>
      <div class="col-12 transparented" v-if="metric.insightData.length == 0">
        Not enough data
      </div>
    </div>
  </div>
</template>
<script>
import LineChart from "@/components/charts/components/LineChart";
import statusTriangle from "@/components/icons/triangle";

export default {
  name: "LineChart",
  props: ["metric"],
  components: {
    "line-chart": LineChart,
    status: statusTriangle,
  },

  mounted() {
    this.setSum();
  },
  data() {
    return {
      graphData: [],
      sumValues: 0,

      options: {
        showAllTooltips: false,
        responsive: true,
        legend: {
          display: false,
        },
        layout: {
          margin: 0,
        },
        scales: {
          yAxes: [
            {
              display: false,
              ticks: {
                fontColor: "rgba(0,0,0,0.5)",
                fontStyle: "bold",
                beginAtZero: true,
                maxTicksLimit: 7,
              },
              gridLines: {
                display: false,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: {
                padding: 15,
                fontColor: "rgba(0,0,0,0.25)",
                fontStyle: "bold",
              },
            },
          ],
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem) => {
              return this.$intToString(tooltipItem.value);
            },
          },
        },
      },
    };
  },
  methods: {
    setSum: function () {
      let sum = 0;

      if (this.metric.insightData) {
        this.metric.insightData.forEach((d) => {
          sum += d.value;
        });
      }
      this.sumValues = this.$intToString(sum);
      if (this.metric.insightData.length > 0 && sum != 0) {
        this.sumValues = this.$intToString(
          Math.round(sum / this.metric.insightData.length)
        );
      }
    },
  },
  watch: {
    metric: {
      deep: true,
      handler: function () {
        localStorage.setItem("selectedDate", JSON.stringify(this.date));
        this.setSum();
      },
    },
  },
  computed: {
    values: function () {
      let values = this.metric.insightData.map((d) => {
        return d.value;
      });
      return values;
    },
  },
};
</script>


<style lang="scss" scoped>
@import "@/scss/colors.scss";
.wrap {
  background: $white;
  min-height: 40px;
  border-radius: 30px;
  padding: 15px;
  .head {
    font-weight: bold;
    color: $darker;
  }
}
.report {
  .line-chart {
    margin: 15px;
  }
}
</style>
