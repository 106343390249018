<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13.49"
    height="12.057"
    viewBox="0 0 13.49 12.057"
    class="like"
  >
    <path
      id="heart"
      d="M11.96,1.131A3.493,3.493,0,0,0,9.361,0,3.268,3.268,0,0,0,7.32.7a4.176,4.176,0,0,0-.825.862A4.174,4.174,0,0,0,5.67.7,3.267,3.267,0,0,0,3.629,0a3.493,3.493,0,0,0-2.6,1.131A4.061,4.061,0,0,0,0,3.9,4.835,4.835,0,0,0,1.288,7.069,27.472,27.472,0,0,0,4.514,10.1c.447.381.953.813,1.479,1.272a.763.763,0,0,0,1,0c.526-.46,1.033-.892,1.48-1.273A27.46,27.46,0,0,0,11.7,7.069,4.835,4.835,0,0,0,12.99,3.9,4.06,4.06,0,0,0,11.96,1.131Zm0,0"
      transform="translate(0.25 0.25)"
      fill="none"
      stroke="#787878"
      :class="{ liked: isLiked }"
      stroke-width="0.5"
    />
  </svg>
</template>

<script>
export default {
  name: "like",
  props: ["isLiked"]
};
</script>
