import Vue from 'vue';
import to from 'await-to-js';
import safeGet from 'lodash.get';
import round from 'lodash.round';
import vCloseOnReclick from '@/plugins/vCloseOnReclick';

export function register() {

  // Lodash
  Vue.prototype.$safeGet = safeGet;

  Vue.prototype.$defaultProfileImage = require('@/assets/images/profile.png')
  Vue.prototype.$defaultCardAvatar = require('@/assets/images/no-image.jpg')
  Vue.prototype.$defaultCardImage = require('@/assets/images/no-card-image.jpg')
  Vue.prototype.$async = async promise => {
    const [error, response] = await to(promise)

    return { error, response }
  }

  Vue.prototype.$intlNumber = (number, decimalPlaces = 0, localeCode = 'en-BE') => {
    if (decimalPlaces) {
      number = round(number, decimalPlaces)
    }

    return number.toLocaleString(localeCode)
  }

  // username Filter: Converts accountName into @username format
  Vue.filter('username', (accountName) => {
    if (!accountName) {
      return '@account'
    }

    const username = accountName.replace(/ /g, '').toLowerCase()
    return `@${username}`
  })

  Vue.directive('close-on-reclick', vCloseOnReclick)

  // Event Bus
  const eventBus = new Vue();

  eventBus.$register = (eventName, callback = () => { }) => {
    eventBus.$off(eventName);
    eventBus.$on(eventName, callback);
  }

  Vue.prototype.$eventBus = eventBus;
}

export default {
  register
}