<template>
  <div>
    <div class="col-12 wrap">
      <div class="comment">
        <div class="col p-0">
          <div class="profile"></div>
          <div class="info">
            <div class="name col-8"></div>
            <div class="city col-6"></div>
          </div>
          <div class="clearfix"></div>
        </div>
        <div class="col text">
          <div class="col-12"></div>
          <div class="col-2"></div>
        </div>
      </div>
      <div class="bottom">
        <div class="pr-0 col-2"></div>
        <div class="statuses d-sm-flex col-6 offset-1"></div>
        <div class="quick-actions d-sm-flex col-2 offset-1"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "comment"
};
</script>
<style lang="scss" scoped>
@import "@/scss/colors.scss";
@-webkit-keyframes placeholderSkeleton {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@-webkit-keyframes skeletonAnimate {
  from {
    background-position: top left;
  }
  to {
    background-position: top right;
  }
}
.wrap {
  border-radius: 30px;
  background: $white;
  &:hover {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
  }
  padding: 0;
  margin-bottom: 20px;
  transition: all 0.25s ease;

  .comment {
    min-height: 80px;
    padding: 15px 15px 10px;
    .info {
      float: left;
      display: flex;
      width: 70%;
      flex-wrap: wrap;
    }
    .profile {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      float: left;
      margin-right: 10px;
      background: $main-bg-color;
    }
    .name {
      width: 60%;
      height: 15px;
      background: $main-bg-color;
      font-weight: bold;
      margin-bottom: 5px;
    }
    .city {
      width: 20%;
      height: 15px !important;
      background: $main-bg-color;
    }
  }
  .bottom {
    width: 100%;
    padding: 10px 17px;
    border-top: 1px solid lighten($black, 95%);
    display: flex;
    flex-wrap: wrap;
    font-size: 12px;
    position: relative;
    justify-content: space-between;
    div {
      background: $main-bg-color;
      height: 20px;
    }
  }
  .text {
    padding: 0;
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    div {
      background: $main-bg-color;
      height: 10px;
      margin-bottom: 5px;
    }
  }
  .profile,
  .info .name,
  .info .city,
  .text div,
  .bottom div {
    -webkit-animation-duration: 1.5s;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: placeholderSkeleton;
    -webkit-animation-timing-function: linear;
    background: #f6f7f8;
    background-image: -webkit-gradient(
      linear,
      left center,
      right center,
      from(#f6f7f8),
      color-stop(0.2, #edeef1),
      color-stop(0.4, #f6f7f8),
      to(#f6f7f8)
    );
    background-image: -webkit-linear-gradient(
      left,
      #f6f7f8 0%,
      #edeef1 20%,
      #f6f7f8 40%,
      #f6f7f8 100%
    );
    background-repeat: no-repeat;
    border-radius: 5px;
  }
}

@media (max-width: 767.98px) {
  .status {
    .dot {
      float: none !important;
    }
  }
}
@media screen and (max-device-width: 1450px) {
  .bottom {
    font-size: 10px !important;
  }
}
</style>
