<template>
  <svg xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    viewBox="0 0 17 17"
    class="m">
    <g id="Group_1086"
      data-name="Group 1086"
      transform="translate(4288 7814)">
      <g id="RadioButton_normal"
        transform="translate(-4288 -7814)"
        fill="none"
        stroke="#3b86ff"
        stroke-width="2">
        <rect width="17"
          height="17"
          rx="8.5"
          stroke="none" />
        <rect x="1"
          y="1"
          width="15"
          height="15"
          rx="7.5"
          fill="none" />
      </g>
      <rect v-if="checked"
        id="Rectangle_1019"
        data-name="Rectangle 1019"
        width="9"
        height="9"
        rx="4.5"
        transform="translate(-4284 -7810)"
        fill="#3b86ff" />
    </g>
  </svg>
</template>

<script>
export default {
  name: "radio-button",
  props: ["checked"]
};
</script>

<style lang="scss" scoped>
.m {
  margin: -2px 5px 0px 5px;
}
</style>
