<template>
  <div class="row social">
    <div class="col">
      <button
        class="btn btn-primary btn-block facebook"
        @click="loginWith('facebook')"
      >
        <img @error="(e) => (e.target.src = $defaultCardAvatar)" src="@/assets/images/facebook.png" alt="Facebook Logo" />
        Login with Facebook
      </button>
    </div>
    <div class="col">
      <button
        class="btn btn-primary btn-block google"
        @click="loginWith('google')"
      >
        <img @error="(e) => (e.target.src = $defaultCardAvatar)" src="@/assets/images/google.png" alt="Google Logo" />
        Login with Google
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "SocialLogin",
  data() {
    return {
      apiURL: this.$apiURL,
      redirectURL: this.$redirectURL + "oauth2/callback",
    };
  },
  methods: {
    loginWith: function (socialApp) {
      if (this.$route.name == "signup") {
        this.redirectURL = this.redirectURL + "?signedup=true";
      }
      window.location =
        this.apiURL +
        "/oauth2/authorize/" +
        socialApp +
        "?redirect_uri=" +
        this.redirectURL;
    },
  },
};
</script>
