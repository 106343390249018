<template>
  <div class="loader" :class="{ white: white }"></div>
</template>
<script>
export default {
  name: "loading",
  props: {
    white: {
      value: {
        type: Boolean,
        default: false
      }
    }
  }
}; 
</script>
<style>
.loader,
.loader:after {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  display: inline-block;
}
.loader {
  margin-bottom: -3px;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 3px solid rgba(255, 108, 55, 0.6);
  border-right: 3px solid rgba(255, 108, 55, 0.6);
  border-bottom: 3px solid rgba(255, 108, 55, 0.6);
  border-left: 3px solid #ff6c37;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear; 
}
.white {
  border-top: 3px solid rgba(255, 255, 255, 0.6);
  border-right: 3px solid rgba(255, 255, 255, 0.6);
  border-bottom: 3px solid rgba(255, 255, 255, 0.6);
  border-left: 3px solid #fff;  
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
