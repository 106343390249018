<template>
  <div class="wrap" v-if="data.map">
    <div class="row head">
      <img @error="(e) => (e.target.src = $defaultCardAvatar)" class="profile" :src="commenterPhoto" alt="Profile" />
      <div class="info">
        <div>
          <span class="name">{{ name }}</span>
        </div>
        <!-- <div>
          <span class="city">İstanbul</span>
        </div> -->
      </div>
    </div>
    <div class="row info">
      <div class="col-6">
        <div class="row">
          <div class="col-8 pr-0">
            <i class="dot positive"></i>
            Positive
          </div>
          <div class="col">{{ data.map.Positive }}</div>
        </div>
      </div>
      <div class="col-6">
        <div class="row">
          <div class="col-8 pr-0">
            <i class="dot neutral"></i>
            Neutral
          </div>
          <div class="col">{{ data.map.Neutral }}</div>
        </div>
      </div>
      <div class="col-6">
        <div class="row">
          <div class="col-8 pr-0">
            <i class="dot negative"></i>
            Negative
          </div>
          <div class="col">{{ data.map.Negative }}</div>
        </div>
      </div>
      <div class="col-6">
        <div class="row">
          <div class="col-8 pr-0">
            <i class="dot question"></i>
            Questions
          </div>
          <div class="col">{{ data.map.Questioned }}</div>
        </div>
      </div>
      <div class="col-6">
        <div class="row">
          <div class="col-8 pr-0">
            <i class="dot hidden"></i>
            Hidden
          </div>
          <div class="col">{{ data.map.Hidden }}</div>
        </div>
      </div>
      <div class="col-6">
        <div class="row">
          <div class="col-8 pr-0">
            <i class="dot"></i>
            Not Assigned
          </div>
          <div class="col">{{ data.map.Unassigned }}</div>
        </div>
      </div>
    </div>

    <div class="desc" @click="goToProfile">
      {{ data.description }}
      <img @error="(e) => (e.target.src = $defaultCardAvatar)" src="@/assets/images/link.svg" alt="Detail" />
      <span class="float-right">{{ data.count }}</span>
    </div>
  </div>
</template>
<script>
import router from '@/router';
import { mapGetters } from 'vuex';
import { HTTP } from '@/main-source';
export default {
  name: 'general-follower',
  data() {
    return {
      title: 'general-most',
      commenterPhoto: '/images/no-image.jpg',
    };
  },
  props: ['data', 'account'],
  created() {
    if (
      this.getActiveSocialMedia == 'facebook' ||
      this.getActiveSocialMedia == 'FACEBOOK' ||
      this.getActiveSocialMedia == 'twitter' ||
      this.getActiveSocialMedia == 'TWITTER'
    ) {
      // Set Comment Author Photo
      console.log('requested');

      HTTP.get(
        '/picture/' +
          this.getActiveSocialMedia +
          '?accountid=' +
          this.getActiveSocialMediaAccount +
          '&authorId=' +
          this.data.key
      ).then((result) => {
        this.commenterPhoto = result.data ? result.data.url : this.commenterPhoto;
      });

      // Set Comment Author Photo End
    }
  },
  methods: {
    goToProfile: function () {
      router.push({
        name: 'comments',
        params: {
          socialmedia: this.getActiveSocialMedia.toLowerCase(),
          accountId: this.account.accountId,
        },
        query: {
          profile: this.data.key,
          name: this.data.map.AuthorName,
        },
      });
    },
  },
  computed: {
    ...mapGetters(['getActiveSocialMedia', 'getActiveSocialMediaAccount']),
    name: function () {
      // let data = JSON.parse(this.data.key);
      return this.data.map ? this.data.map.AuthorName : '';
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/colors.scss';
.wrap {
  height: 240px;
  padding: 0;
  position: relative;
  padding: 15px;
  border-radius: 30px;
  background: $white;
}
.head {
  padding: 0px 15px 5px 15px;
  position: relative;
  .profile {
    width: 45px;
    height: 45px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    float: left;
    margin-right: 10px;
  }
  .name {
    font-weight: bold;
  }
  .city {
    font-size: 12px;
    position: relative;
    top: -5px;
  }
  .icon {
    position: absolute;
    right: 20px;
    top: 2px;
    width: auto;
    height: 40px;
    display: flex;
    align-items: center;
  }
}
.post-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  z-index: 1;
}
.bg {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 2;
  background: linear-gradient(rgba(0, 66, 118, 0) 0%, #02223b 100%);
}
.desc {
  position: absolute;
  width: calc(100% - 40px);
  left: 20px;
  bottom: 15px;
  z-index: 3;
  font-weight: bold;
  cursor: pointer;
  img {
    cursor: pointer;
  }
}
.row.info {
  font-size: 13px;
  margin-top: 10px;

  .row {
    margin-bottom: 10px;
    color: $darker;
  }
  i {
    float: left;
    margin-right: 5px;
  }
  line-height: 20px;
}
</style>
