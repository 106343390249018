<template>
  <div
    id="sidebar"
    class="sidebar"
    :class="[
      { 'sidebar-collapsed': getActiveSocialMedia == '' },
      { closed: !menu },
      { 'facebook-bg': getActiveSocialMedia == 'facebook' },
      { 'instagram-bg': getActiveSocialMedia == 'instagram' },
      { 'twitter-bg': getActiveSocialMedia == 'twitter' },
      { 'youtube-bg': getActiveSocialMedia == 'youtube' },
    ]"
  >
    <sidebar-left></sidebar-left>
    <sidebar-right></sidebar-right>
  </div>
</template>

<script>
import sidebarLeft from "./first.vue";
import sidebarRight from "./second.vue";
import { mapGetters } from "vuex";
import EventBus from "@/components/event-bus";

export default {
  name: "Sidebar",
  props: {
    msg: String,
  },
  data() {
    return {
      menu: false,
    };
  },
  components: {
    "sidebar-left": sidebarLeft,
    "sidebar-right": sidebarRight,
  },
  computed: {
    ...mapGetters(["getActiveSocialMedia"]),
  },
  mounted() {
    EventBus.$on("menu", (showMenu) => {
      this.menu = showMenu;
    });
  },
};
</script>

<style lang="scss">
@import "../../scss/colors.scss";
.sidebar {
  position: fixed;
  left: 0px;
  bottom: 0;
  width: 300px;
  height: 100%;
  padding-top: 70px;
  // background: linear-gradient(#0f61ac 0%, #002445 100%);
  background: darken($main-bg-color, 15%);
  color: $white;
  display: flex;
  transition: all 0.5s ease-out;
  z-index: 1;
  .second {
    transition: all 0.3s ease 0.2s;
  }
}
.facebook-bg {
  background: linear-gradient(#97abff 0%, #123597 100%);
}
.instagram-bg {
  background: linear-gradient(#db1472 0%, #8b30ef 100%);
}
.twitter-bg {
  background: linear-gradient(#99d4ff 0%, #169eff 100%);
}
.youtube-bg {
  background: linear-gradient(#ff835c 0%, #cc1829 100%);
}

.sidebar-collapsed {
  width: 80px;
  overflow: hidden;
  display: block;
  .second {
    opacity: 0;
  }
}
@media (max-width: 767.98px) {
  .closed {
    transform: translateX(-100%);
  }
  .sidebar {
    width: 100%;
    z-index: 9;
  }
  .sidebar-collapsed {
    width: 80px;
    background: $white !important;
  }
}
</style>
