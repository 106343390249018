var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row filter"},[_c('div',{staticClass:"col-12 left-filter"},[_c('div',{staticClass:"col-8 col-md-6 left"},[_c('div',{staticClass:"tab"},[_c('router-link',{class:{ active: _vm.$route.name == 'general' },attrs:{"to":{
            name: 'ai-reports',
            params: {
              accountId: _vm.getActiveSocialMediaAccount,
            },
          }}},[_vm._v("AI Reports")]),_c('router-link',{class:{ active: _vm.$route.name == 'insights' },attrs:{"to":{
            name: 'insights',
            params: {
              accountId: _vm.getActiveSocialMediaAccount,
            },
          }}},[_vm._v("Insights")])],1)]),_c('div',{staticClass:"col text-right d-sm-none mobile-filter"},[_c('span',[_c('img',{attrs:{"src":require("@/assets/images/filter.svg"),"alt":""},on:{"error":(e) => (e.target.src = _vm.$defaultCardAvatar)}})]),_c('span',[_c('img',{attrs:{"src":require("@/assets/images/calander.svg"),"alt":""},on:{"error":(e) => (e.target.src = _vm.$defaultCardAvatar)}})])]),_c('div',{staticClass:"col-12 col-md-6 text-right pr-0 topfilter d-none d-sm-block"},[(false)?_c('range-filter',{attrs:{"range":_vm.range}}):_vm._e(),_c('DatePicker',{staticClass:"datepicker",attrs:{"placeholder":"Last 90 days","range":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)])]),(_vm.xhrRequest)?_c('div',{staticClass:"row"},_vm._l((7),function(n){return _c('placeholder',{key:n,staticClass:"col-12 col-sm-4"})}),1):_vm._e(),(!_vm.xhrRequest)?_c('div',{staticClass:"row report-summary"},_vm._l((_vm.getMetricsByType('card')),function(topMetric,key){return _c('div',{key:key,staticClass:"col-6 col-sm-3"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col summary"},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:(topMetric.calculatedInsight.currentTotal),expression:"topMetric.calculatedInsight.currentTotal",modifiers:{"bottom":true}}],staticClass:"value"},[_vm._v(" "+_vm._s(topMetric.calculatedInsight.currentTotal ? _vm.abbreviate(topMetric.calculatedInsight.currentTotal) : "~")+" ")]),_c('span',{staticClass:"sub-title"},[_vm._v(_vm._s(topMetric.title)+" ")]),_c('span',{staticClass:"o-5"},[_vm._v("Total")])]),_c('div',{staticClass:"col-12 col-sm-6 graph d-none d-sm-flex"},[_c('span',{staticClass:"status text-right"},[(topMetric.calculatedInsight.percentage)?_c('div',[_c('status-triangle',{attrs:{"status":topMetric.calculatedInsight.verticalMovement == 'UP'
                      ? true
                      : false}}),_c('div',{staticClass:"desc",class:[
                    topMetric.calculatedInsight.verticalMovement == 'UP'
                      ? 'color-green'
                      : 'color-red'
                  ]},[_vm._v(" "+_vm._s(topMetric.calculatedInsight.percentage + "%")+" ")]),(false)?_c('div',{staticClass:"o-5"},[_vm._v(" vs date coming ")]):_vm._e()],1):_vm._e()]),(topMetric.insightData.length > 0)?_c('LineChartGraph',{staticClass:"line-chart",class:{ transparented: topMetric.calculatedInsight.currentTotal == 0 },attrs:{"graph":topMetric.insightData,"options":_vm.options,"width":135,"height":50}}):_vm._e()],1)])])])])}),0):_vm._e(),(!_vm.xhrRequest)?_c('div',{staticClass:"row chart-wrapper"},[_vm._l((_vm.metrics),function(metric,index){return [_c('div',{key:index,staticClass:"col-12 col-sm-4 wrap",attrs:{"id":metric.insightName}},[_c(_vm.whichComponent(metric.chartType),{tag:"component",attrs:{"metric":metric}})],1)]})],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }