<template>
  <div>
    <Renew-Pass :isLoading="isLoading" :result="result"></Renew-Pass>
    <div class="col authAction">
      <router-link
        :to="{
          name: 'login'
        }"
      >
        Sign in
      </router-link>
      |
      <router-link
        :to="{
          name: 'signup'
        }"
      >
        Sign Up.
      </router-link>
    </div>
  </div>
</template>

<script>
import RenewPass from "@/components/user/renew-password.vue";
import EventBus from "@/components/event-bus";
import { HTTP } from "@/main-source";
import router from "@/router";

export default {
  name: "RenewPassword",
  data() {
    return {
      isLoading: false,
      result: null,
      token: this.$route.query.token
    };
  },
  created() {
    if (!this.token) {
      router.push({ name: "login" });
    }
  },
  components: {
    RenewPass
  },
  methods: {
    renewPass: function(data) {
      this.isLoading = true;
      HTTP.post("auth/reset-password", data)
        .then(result => {
          this.result = result;
          this.isLoading = false;
        })
        .catch(e => {
          this.result = e.response.data;
          this.isLoading = false;
        });
    }
  },
  mounted() {
    EventBus.$on("renewpassword", data => {
      this.renewPass(data);
    });
  }
};
</script>
