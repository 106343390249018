<template>
  <div>
    <div class="d-none d-sm-block">
      <div class="topbar col-12">
        <div class="logo">
          <router-link :to="{ name: 'home' }"
            @click.native="setActiveSocialMedia('')">
            <img @error="(e) => (e.target.src = $defaultCardAvatar)"
              src="@/assets/images/icon.svg"
              class="icon"
              alt="Socialays" />
          </router-link>
        </div>
        <div class="col-5 col-xl-3 pl-3 master-search">
          <input-tag v-model="q"
            class="search"
            placeholder="Search in every account, word and user..."
            :before-adding="checkSearchValue"
            name="search-query"
            @keyup.enter="search"
            :class="{ 'animated shake': shake }"></input-tag>
        </div>

        <div class="profile-action d-none d-sm-block"
          id="dropdownMenu"
          data-toggle="dropdown">
          <span class="name">{{ getUsername }}</span>
          <span class="notify-dot"
            v-if="totalCountOfNotifies > 0">
            {{ totalCountOfNotifies }}
          </span>
          <i class="fas fa-angle-down"></i>
          <img class="profile-img"
            :src="profileImg || $defaultProfileImage"
            @error="(e) => (e.target.src = $defaultProfileImage)"
            alt="Profile Image" />
        </div>
        <div class="dropdown-menu dropdown-menu-right">
          <router-link :to="{ name: 'settings' }"
            class="dropdown-item"
            @click.native="clear">
            <span class="notify-dot"
              v-if="notifies.invitations > 0 || notifies.brokenAccounts > 0"></span>
            Settings
          </router-link>
          <!-- <router-link
            :to="{ name: 'subusers' }"
            class="dropdown-item"
            @click.native="clear"
          >
            <span class="notify-dot" v-if="notifies.subusers > 0"></span>
            Subusers
          </router-link> -->

          <a class="dropdown-item"
            target="_blank"
            href="https://socialays.com/support/">
            Support
          </a>
          <button class="dropdown-item"
            type="button"
            @click="logout">
            Log out
          </button>
        </div>
      </div>
    </div>
    <div class="d-block d-sm-none">
      <div class="topbar col-12 p-1">
        <div class="col-4 h-menu">
          <img @error="(e) => (e.target.src = $defaultCardAvatar)"
            src="@/assets/images/h-menu.svg"
            alt="Socialays"
            @click="showMenu = !showMenu" />
        </div>
        <div class="col-4 text-center">
          <router-link :to="{ name: 'home' }"
            @click.native="
    setActiveSocialMedia('');
  showMenu = false;
            ">
            <img @error="(e) => (e.target.src = $defaultCardAvatar)"
              src="@/assets/images/icon.svg"
              class="m-logo"
              alt="Socialays" />
          </router-link>
        </div>
        <div class="col-4 text-right">
          <div class="profile-action"
            id="dropdownMenu"
            data-toggle="dropdown">
            <img @error="(e) => (e.target.src = $defaultCardAvatar)"
              class="profile-img"
              :src="profileImg || $defaultProfileImage"
              alt="Profile Image" />
          </div>
          <div class="dropdown-menu dropdown-menu-right">
            <router-link :to="{ name: 'settings' }"
              class="dropdown-item"
              @click.native="clear">Settings</router-link>
            <!-- <router-link
              :to="{ name: 'subusers' }"
              class="dropdown-item"
              @click.native="clear"
              >Subusers</router-link
            > -->
            <a class="dropdown-item"
              target="_blank"
              href="https://socialays.com/support/">
              Support
            </a>
            <button class="dropdown-item"
              type="button"
              @click="logout">
              Log out
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import EventBus from "@/components/event-bus";
import router from "@/router";
import { HTTP } from "@/main-source";
import InputTag from "vue-input-tag";

export default {
  name: "topbar",
  data() {
    return {
      showMenu: false,
      profileImg: localStorage.getItem("profileImg")
        ? localStorage.getItem("profileImg")
        : null,
      q: [],
      searchQuery: this.$route.query.q ? this.$route.query.q : null,
      notifies: { brokenAccounts: 0, invitations: 0, subusers: 0 },
      shake: false,
    };
  },
  components: {
    InputTag,
  },
  methods: {
    ...mapActions(["setActiveSocialMedia", "setActiveSocialMediaAccount"]),
    logout: function () {
      HTTP.get("auth/logout");
      delete HTTP.defaults.headers.common["Authorization"];
      EventBus.$emit("notify", false);
      this.setActiveSocialMedia("");
      localStorage.removeItem("token");
      localStorage.removeItem("profileImg");
      localStorage.removeItem("lastSelectedinstagramAccount");
      localStorage.removeItem("lastSelectedfacebookAccount");
      localStorage.removeItem("lastSelectedtwitterAccount");
      localStorage.removeItem("lastSelectedyoutubeAccount");
      localStorage.setItem("notifyClosed", false);

      router.push({
        name: "login",
      });
    },
    clear: function () {
      this.setActiveSocialMedia("");
      this.setActiveSocialMediaAccount("");
    },

    checkSearchValue: function (query) {
      let accountStatus = JSON.parse(localStorage.getItem("accountStatus"));
      if (accountStatus.count == 0) {
        this.$notify({
          group: "info",
          text: "Please add account first",
        });
      } else {
        if (query.length < 3) {
          this.$notify({
            group: "info",
            text: "Min. 3 character needed",
          });
          this.shake = true;
          setTimeout(() => {
            this.shake = false;
          }, 500);

          return false;
        } else {
          return query;
        }
      }
    },
    getProfilePhoto: function () {
      return localStorage.getItem("profileImg");
    },
    setPhoto: function () {
      this.profileImg = localStorage.getItem("profileImg");
    },
    showBrokenAccount: function (count) {
      this.notifies.brokenAccounts = count;
    },
    showInvitationsNotify: function (count) {
      this.notifies.invitations = count;
    },
  },
  mounted() {
    EventBus.$on("photoUpdated", () => {
      this.setPhoto();
    });
    EventBus.$on("clearSearchInput", () => {
      this.q = [];
      this.searchQuery = "";
    });
    EventBus.$on("brokenAccount", (count) => {
      this.showBrokenAccount(count);
    });
    EventBus.$on("invitations", (count) => {
      this.showInvitationsNotify(count);
    });

    // HTTP.get("settings/my-pending-invitations").then((result) => {
    //   if (result.data.length > 0) {
    //     this.showInvitationsNotify(result.data.length);
    //   }
    // });
  },
  computed: {
    ...mapGetters(["getActiveSocialMedia", "getUsername"]),
    totalCountOfNotifies: function () {
      return this.notifies.brokenAccounts + this.notifies.invitations;
    },
  },
  watch: {
    showMenu: function () {
      EventBus.$emit("menu", this.showMenu);
    },
    shake: function () {
      if (this.shake) {
        setTimeout(() => {
          this.shake = false;
        }, 2000);
      }
    },
    q: function () {
      let searchQuery = "";
      let i = 0;

      this.q.forEach((q) => {
        if (i != 0) {
          searchQuery += ",";
        }

        searchQuery += q;
        i++;
      });
      if (this.q.length == 0 && !this.$route.params.socialmedia) {
        router.push({
          name: "home",
        });
      }

      this.searchQuery = searchQuery;
    },
    searchQuery: function () {
      if (this.searchQuery) {
        this.clear();
        router.push({
          name: "search",
          query: {
            q: this.searchQuery,
          },
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../scss/colors.scss";

.topbar {
  background: $white;
  height: 90px;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05);
  align-items: center;
  display: flex;
  padding: 15px;
  z-index: 2;
  position: fixed;

  .notify-dot {
    width: 17px;
    height: 17px;
    line-height: 18px;
    background: $brand-red;
    position: absolute;
    right: 16px;
    top: 16px;
    border-radius: 50%;
    color: $white;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
  }
}

.main-title {
  position: relative;
  padding-left: 25px;

  &:before {
    content: "";
    left: 0;
    top: 2px;
    width: 20px;
    height: 20px;
    background: $sidebar-border;
    display: block;
    position: absolute;
    border-radius: 3px;
  }
}

.profile-action {
  display: block;
  margin-left: auto;
  order: 2;
  margin-right: 5px;
  cursor: pointer;

  .profile-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-left: 10px;
  }

  .name {
    margin-right: 5px;
  }
}

.logo {
  width: 45px;
  margin-left: 7px;
  margin-right: 20px;

  img {
    width: 100%;
    height: auto;
  }
}

.search {
  .new-tag {
    border: 1px solid $light-gray;
    border-radius: 30px;
    padding: 8px 15px;
    background: $main-bg-color;
    border: 0;
    margin-left: 20px;
    transition: all 0.3s ease;

    &:focus {
      // box-shadow: 0 0px 20px rgba(0, 0, 0, 0.15);
    }
  }
}

.dropdown-menu {
  a {
    position: relative;
    display: block;
  }

  .notify-dot {
    width: 6px;
    height: 6px;
    left: 10px;
    top: calc(50% - 3px);
  }
}

@media (max-width: 767.98px) {
  .col {
    height: auto !important;
    justify-content: space-between !important;
    color: $white;
    background: $brandcolor-2;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    padding: 15px !important;
  }

  .profile-action {
    margin-left: 0px !important;

    .profile-img {
      margin-left: 0;
    }
  }

  .menu-btn {
    width: 30px;
    height: auto;
  }

  .close {
    width: 25px;
    height: auto;
  }

  .logo {
    width: 35px;
    height: auto;
  }

  .mobile-title {
    font-weight: 800;
    font-size: 21px;
  }

  .m-logo {
    height: 55px;
    width: auto;
  }

  .topbar {
    z-index: 999;
  }
}
</style>
