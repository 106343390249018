<template>
  <svg
    viewBox="0 0 32 32"
    width="20"
    height="20"
    xmlns="http://www.w3.org/2000/svg"
    :class="{ dark: dark }"
  >
    <g id="cross">
      <line class="cls-1" x1="7" x2="25" y1="7" y2="25" />
      <line class="cls-1" x1="7" x2="25" y1="25" y2="7" />
    </g>
  </svg>
</template>
<script>
export default {
  props: ["dark"]
};
</script>

<style lang="scss">
.cls-1 {
  fill: none;
  stroke: #fff;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2px;
}
.dark {
  .cls-1 {
    stroke: #000 !important;
  }
}
</style>
