<template>
  <div id="user">
    <div class="col wrap" :class="{ selected: selected }">
      <img @error="(e) => (e.target.src = $defaultCardAvatar)" class="profile" :src="profilePic" alt="Profile" v-if="profilePic" />
      <img @error="(e) => (e.target.src = $defaultCardAvatar)"
        class="profile"
        src="@/assets/images/profile.png"
        alt="Profile"
        v-if="!profilePic"
      />

      <div class="info">
        <div>
          <span class="name">{{ data.authorName }}</span>
        </div>
        <div>
          <span class="text">{{
            data.lastMessage ? data.lastMessage.substr(0, 16) + ".." : ""
          }}</span>
        </div>
        <div class="status">
          <i
            class="dot"
            :class="data.polarity"
            v-tooltip.top-center="'Majority Comment Status: ' + data.polarity"
          ></i>
        </div>
        <div class="date">4 Jan</div>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "user",
  props: ["selected", "data", "profilePic"]
};
</script>
<style lang="scss">
@import "@/scss/colors.scss";
#user {
  margin: 10px 0px 10px 7px;
  .wrap {
    box-shadow: none;
    border-radius: 0;
    transition: all 0.3s ease;
    border-radius: 10px;
    padding: 10px 15px;
    cursor: pointer;
    &:hover {
      background: darken($white, 3%);
    }
  }
  .selected {
    background: darken($white, 3%);
  }
  .profile {
    width: 45px;
    height: 45px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    float: left;
    margin-right: 10px;
  }
  .name {
    font-weight: bold;
  }
  .text {
    color: lighten($black, 30%);
  }
  .status {
    position: absolute;
    right: 10px;
    bottom: 0;
    .dot {
      margin-right: 2px;
      transform: scale(0.8);
    }
  }
  .date {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}
</style>
